import { ExpandedGroup } from 'src/pages/dashboard/Dashboard.utils'
import { Requirements, Block } from 'types/PivotData.types'
import { SavedQuery } from 'types/Query.types'

export const makeRequirements = (
  hasNps: boolean,
  hasSentiment: boolean,
  numericalFields: string[] | null,
  scoreOptions: {
    name?: string
    agg?: string
    boxVal?: number
    range?: Array<number>
  },
  queries: Partial<SavedQuery | ExpandedGroup>[],
  queryLimit: number | false,
): Requirements => {
  let blocks: Block[] = [
    {
      aggfuncs: [
        {
          new_column: 'frequency',
          src_column: 'document_id',
          aggfunc: 'count',
        },
      ],
    },
  ]

  // get NPS
  if (hasNps) {
    blocks.push({
      aggfuncs: [
        {
          new_column: 'frequency',
          src_column: 'document_id',
          aggfunc: 'count',
        },
      ],
      pivot_field: 'NPS Category',
      metric_calculator: 'nps',
    })
  }

  // get sentiment
  if (hasSentiment) {
    blocks.push({
      aggfuncs: [
        {
          new_column: 'frequency',
          src_column: 'document_id',
          aggfunc: 'count',
        },
      ],
      pivot_field: 'sentiment__',
      metric_calculator: 'sentiment',
    })
  }

  // get numerics
  if (numericalFields?.length) {
    blocks.push(
      ...numericalFields.map((field) => ({
        aggfuncs: [
          {
            new_column: `${field}|count`,
            src_column: `${field}`,
            aggfunc: 'count',
          },
          {
            new_column: `${field}|mean__`,
            src_column: `${field}`,
            aggfunc: 'mean',
          },
        ],
        metric_calculator: 'mean_impact',
      })),
    )
  }

  if (Object.keys(scoreOptions).length > 0) {
    if (scoreOptions.name !== undefined && scoreOptions.range !== undefined && scoreOptions.agg !== undefined) {
      let scoreRange = scoreOptions['range']
      if (['mean', 'sum', 'median'].includes(scoreOptions.agg ?? '')) {
        blocks.push({
          aggfuncs: [
            {
              new_column: 'aggVal|count',
              src_column: scoreOptions.name,
              aggfunc: 'count',
            },
            {
              new_column: 'aggVal|mean__',
              src_column: scoreOptions.name,
              aggfunc: scoreOptions.agg,
            },
          ],
          metric_calculator: 'mean_impact',
        })
      } else {
        let boxValues = []
        let boxVal = scoreOptions['boxVal']
        if (boxVal !== undefined) {
          if (scoreOptions.agg === 'top x box') {
            for (let i = scoreRange[1]; i > scoreRange[1] - boxVal; i--) {
              boxValues.push(i)
            }
          } else {
            // Assuming bot x box here.
            for (let i: number = scoreRange?.[0]; i < scoreRange[0] + boxVal; i++) {
              boxValues.push(i)
            }
          }
          blocks.push({
            pivot_field: scoreOptions.name,
            aggfuncs: [
              {
                new_column: 'frequency',
                src_column: 'document_id',
                aggfunc: 'count',
              },
            ],
            metric_calculator: {
              type: 'box',
              field: scoreOptions.name,
              impact: true,
              box_values: boxValues,
            },
          })
        }
      }
    }
  }

  let queryList = queries ?? []
  if (queryLimit !== false) {
    queryList = queries.slice(0, queryLimit)
  }

  return {
    blocks,
    queries: queryList.map((q) => ({
      name: q.name!,
      value: q.query_value!,
    })),
  }
}
