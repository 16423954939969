<template>
  <Modal :visible="modalVisible" @close="close">
    <template #header>
      <div v-if="step === 'intro'" class="header">Important! Before you start...</div>
      <div v-else-if="step === 'organise'" class="header">Organise Your Data</div>
      <div v-else-if="step === 'headers'" class="header">Column Headers</div>
      <div v-else-if="step === 'sufficient'" class="header">Sufficient Data</div>
    </template>
    <template #content>
      <div class="center modal">
        <div v-if="step === 'intro'" class="content">
          Kapiche requires your data to be formatted correctly before uploading. For best results, please make sure any
          data you upload matches the structure we need.
        </div>
        <div v-else-if="step === 'organise'" class="content">
          <span class="pos">DO</span> organize your data with each column as a variable, with the first row as column
          titles and other rows representing records.
          <div><img src="./assets/tutorial-1.png" srcset="./assets/tutorial-1@2x.png 2x" /></div>
        </div>
        <div v-else-if="step === 'headers'" class="content">
          <span class="neg">DON'T</span> leave any column headers empty.
          <div><img src="./assets/tutorial-2.png" srcset="./assets/tutorial-2@2x.png 2x" /></div>
        </div>
        <div v-else-if="step === 'sufficient'" class="content">
          <span class="pos">DO</span> try to have sufficient text data. A rough guideline is to have at least 250 rows
          of data in your file with a good density of text.
          <div><img src="./assets/tutorial-3.png" srcset="./assets/tutorial-3@2x.png 2x" /></div>
        </div>
      </div>
    </template>
    <template #buttons>
      <div class="actions">
        <button v-if="step === 'intro'" class="ui button blue" @click="next">Okay, show me</button>
        <button v-else-if="step === 'organise'" class="ui button blue" @click="next">Next</button>
        <button v-else-if="step === 'headers'" class="ui button blue" @click="next">Next</button>
        <button v-else-if="step === 'sufficient'" class="ui button blue" @click="close">Okay, I'm ready</button>
        <div v-if="step === 'intro'" class="ui button grey" @click="close">
          I’m willing to take the risk, skip this walkthrough.
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CookiesUtil from 'src/utils/cookies'
import Modal from 'components/Modal.vue'

export default defineComponent({
  components: { Modal },
  data() {
    return {
      inTransition: false,
      step: 'intro',
      modalVisible: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (!CookiesUtil.isSet('data-tutorial')) {
        this.show()
        CookiesUtil.setOption('data-tutorial')
      }
    })
  },
  methods: {
    close() {
      this.modalVisible = false
    },
    // Go to next step
    // `inTransition` is used to hide the modal while the modal content is being replaced
    // and modal position recalculated; this is to avoid an ugly flicker modal position gets
    // adjusted.
    next() {
      this.modalVisible = false
      if (this.step === 'intro') {
        this.step = 'organise'
      } else if (this.step === 'organise') {
        this.step = 'headers'
      } else if (this.step === 'headers') {
        this.step = 'sufficient'
      }
      setTimeout(() => {
        this.modalVisible = true
      }, 100) // need a delay (as opposed to nextTick) for
      // modal.refresh to recalculate position accurately
    },
    show(step) {
      if (step) {
        this.step = step
      }
      this.modalVisible = true
    },
  },
})
</script>

<style lang="sass" scoped>
@import '../../../assets/kapiche.sass'
@import '../../../assets/mixins.sass'

.modal
  border-radius: 5px !important
  &.hidden
    opacity: 0
  .header
    color: $text-black !important
  .content
    font-size: rem(16px) !important
    padding: rem(20px) 0
    img
      margin-top: 20px
    .neg
      color: $red
      font-weight: bold
    .pos
      color: $green
      font-weight: bold
  img
    margin: rem(20px) 0
  .button
    font-size: rem(18px)
    margin: rem(20px)
  .subxtext
    color: $text-grey
    cursor: pointer
    margin-top: rem(10px)
</style>
