import { Limit, SortMethod } from 'components/DataWidgets/QuadrantWidget/QuadrantWidget.utils'
import { ChrysalisFilter } from './DashboardFilters.types'
import { Analysis } from './AnalysisTypes'
import { Project } from './ProjectTypes'
import { QueryRow, SavedQuery } from './Query.types'
import { Resolution } from './widgets.types'
import { SortOption } from 'components/DataWidgets/Timeline/Timeline.utils'

export interface Dashboard {
  id: number
  name: string
  description: string
  url_code: string
  config: DashboardConfig
  analysis: Analysis
  project: Project
  queries: SavedQuery[]
  groupby_field: string
  speaker_field: string
  slice1_name: string
  slice2_name: string
}

// dashboards returned inside analysis is a subset of whole Dashboard type
export interface AnalysisDashboard {
  id: number
  name: string
  locked: boolean
}

export type WidgetName =
  | 'ai-overview-summary'
  | 'ai-summary'
  | 'compare-nps-timeline'
  | 'compare-score-timeline'
  | 'compare-segments'
  | 'compare-sentiment-timeline'
  | 'compare-themes-concepts'
  | 'compare-timeline'
  | 'context-network'
  | 'emergent-concepts'
  | 'key-phrases'
  | 'nps-summary'
  | 'nps-timeline'
  | 'pivot-table'
  | 'quadrant'
  | 'query-details'
  | 'score-timeline'
  | 'segment-correlation'
  | 'segments'
  | 'sentiment-summary'
  | 'sentiment-timeline'
  | 'themes-concepts'
  | 'timeline'
  | 'verbatims'

interface BaseConfig<T extends WidgetName = WidgetName> {
  name: T
  visible: boolean
}

interface NpsSummaryConfig extends BaseConfig<'nps-summary'> {
  options: unknown
}

interface SentimentSummaryConfig extends BaseConfig<'sentiment-summary'> {
  options: unknown
}

interface AiSummaryConfig extends BaseConfig<'ai-summary'> {
  options: unknown
}

interface AiOverviewSummaryConfig extends BaseConfig<'ai-overview-summary'> {
  options: unknown
}

interface ScoreTimelineConfig extends BaseConfig<'score-timeline'> {
  options: {
    dataDisplay?: string
    resolution?: Resolution
    aggMethod?: string
    dateField?: string
  }
}

interface CompareScoreTimelineConfig extends BaseConfig<'compare-score-timeline'> {
  options: {
    dataDisplay?: string
    resolution?: Resolution
    aggMethod?: string
    dateField?: string
  }
}

interface NpsTimelineConfig extends BaseConfig<'nps-timeline'> {
  options: {
    dateField?: string
    resolution?: Resolution
  }
}

interface CompareNpsTimelineConfig extends BaseConfig<'compare-nps-timeline'> {
  options: {
    dateField?: string
    resolution?: Resolution
  }
}

interface SentimentTimelineConfig extends BaseConfig<'sentiment-timeline'> {
  options: {
    dateField?: string | null
    resolution?: Resolution
    display?: string
  }
}

interface CompareSentimentTimelineConfig extends BaseConfig<'compare-sentiment-timeline'> {
  options: {
    dateField?: string | null
    resolution?: Resolution
    display?: string
  }
}

interface ContextNetworkConfig extends BaseConfig<'context-network'> {
  options: unknown
}

export interface VerbatimsConfig extends BaseConfig<'verbatims'> {
  options: {
    perPage?: number
    orderBy?: string
  }
}

interface ThemesConceptsConfig extends BaseConfig<'themes-concepts'> {
  options: {
    display?: string
    data?: string
    sortBy?: number
    ascendingSort?: boolean
    allowShowExpected?: boolean
    rowLimit?: number
    sortOption?: string
  }
}

interface CompareThemesConceptsConfig extends BaseConfig<'compare-themes-concepts'> {
  options: {
    display?: string
    data?: string
    sortBy?: number
    ascendingSort?: boolean
    rowLimit?: number
    sortOption?: string
    sortSlice?: 0 | 1
  }
}

interface QuadrantConfig extends BaseConfig<'quadrant'> {
  options: {
    data?: string
    display?: string
    limitX?: Limit
    limitY?: Limit
    dotLimit?: number
    sortMethod?: SortMethod
  }
}

interface TimelineConfig extends BaseConfig<'timeline'> {
  options: {
    dataDisplayMode?: [string, string]
    displayMode?: [string, string]
    resolution?: Resolution | null
    dateField?: string
    hiddenLegendItems?: string[]
    sortOption?: SortOption
    visibleCount?: number
  }
}

interface CompareTimelineConfig extends BaseConfig<'compare-timeline'> {
  options: {
    dataDisplayMode?: [string, string]
    displayMode?: [string, string]
    resolution?: Resolution
    dateField?: string
    seriesLimit?: number
    seriesSort?: SortOption
    sortSlice?: 0 | 1
  }
}

interface QueryDetailsConfig extends BaseConfig<'query-details'> {
  options: unknown
}

interface SegmentsConfig extends BaseConfig<'segments'> {
  options: {
    order?: string
    field?: string | null
    allowShowExpected?: boolean
  }
}

interface SegmentsCompareConfig extends BaseConfig<'compare-segments'> {
  options: {
    order?: string
    field?: string | null
    allowShowExpected?: boolean
  }
}

interface EmergentConceptsConfig extends BaseConfig<'emergent-concepts'> {
  options: {
    customDateRange?: [string | undefined, string | undefined]
    dateField?: string
    display?: string
    from?: string
  }
}

interface KeyPhrasesConfig extends BaseConfig<'key-phrases'> {
  options: {
    display?: string
    minfreq?: string
  }
}

interface PivotTableConfig extends BaseConfig<'pivot-table'> {
  options: {
    rowFields?: string[]
    colFields?: string[]
    colorPreset?: 'RED_GREEN' | 'BLUE_ORANGE'
    colorSetMethod?: 'single' | 'multiple' | 'preset' | 'none'
    binColors?: string[]
    valueField?: string
    valueMethod?: string
    bucketDateOffset?: 'None' | 'Daily' | 'Weekly' | 'Fortnightly' | 'Monthly' | 'Quarterly' | 'Yearly'
    hideTotals?: boolean
    boxValue?: number
  }
}

interface SegmentCorrelationConfig extends BaseConfig<'segment-correlation'> {
  options: {
    displaySelection?: string
    fieldSelection?: string
    kSelection?: string
  }
}

export type AnyWidgetConfig = WidgetConfig<WidgetName>

export type WidgetConfig<T extends WidgetName> =
  T extends 'nps-summary' ? NpsSummaryConfig
  : T extends 'sentiment-summary' ? SentimentSummaryConfig
  : T extends 'nps-timeline' ? NpsTimelineConfig
  : T extends 'compare-nps-timeline' ? CompareNpsTimelineConfig
  : T extends 'sentiment-timeline' ? SentimentTimelineConfig
  : T extends 'compare-sentiment-timeline' ? CompareSentimentTimelineConfig
  : T extends 'context-network' ? ContextNetworkConfig
  : T extends 'verbatims' ? VerbatimsConfig
  : T extends 'themes-concepts' ? ThemesConceptsConfig
  : T extends 'compare-themes-concepts' ? CompareThemesConceptsConfig
  : T extends 'quadrant' ? QuadrantConfig
  : T extends 'timeline' ? TimelineConfig
  : T extends 'compare-timeline' ? CompareTimelineConfig
  : T extends 'query-details' ? QueryDetailsConfig
  : T extends 'segments' ? SegmentsConfig
  : T extends 'compare-segments' ? SegmentsCompareConfig
  : T extends 'emergent-concepts' ? EmergentConceptsConfig
  : T extends 'key-phrases' ? KeyPhrasesConfig
  : T extends 'pivot-table' ? PivotTableConfig
  : T extends 'segment-correlation' ? SegmentCorrelationConfig
  : T extends 'ai-summary' ? AiSummaryConfig
  : T extends 'ai-overview-summary' ? AiOverviewSummaryConfig
  : T extends 'score-timeline' ? ScoreTimelineConfig
  : T extends 'compare-score-timeline' ? CompareScoreTimelineConfig
  : never

export const OverviewWidgets = [
  'nps-timeline',
  'sentiment-timeline',
  // Could still be in config, we are replacing it with the timeline on load
  'nps-summary',
  // Could still be in config, we are replacing it with the timeline on load
  'sentiment-summary',
  'themes-concepts',
  'compare-themes-concepts',
  'quadrant',
  'timeline',
  'segments',
  'emergent-concepts',
  'pivot-table',
  'segment-correlation',
  'compare-segments',
  'compare-sentiment-timeline',
  'compare-timeline',
  'compare-nps-timeline',
  'score-timeline',
  'compare-score-timeline',
  'ai-overview-summary',
] as const

export const DrilldownWidgets = [
  'nps-timeline',
  'sentiment-timeline',
  'context-network',
  'verbatims',
  'timeline',
  'query-details',
  'segments',
  'emergent-concepts',
  'key-phrases',
  'ai-summary',
  'score-timeline',
  'themes-concepts',
] as const

export type OverviewWidgetName = (typeof OverviewWidgets)[number]
export type DrilldownWidgetName = (typeof DrilldownWidgets)[number]

export interface DashboardConfig {
  dateRange?: DateRangeConfig | null
  filters?: ChrysalisFilter[]
  widgets: {
    overview: WidgetConfig<OverviewWidgetName>[]
    drilldown: WidgetConfig<DrilldownWidgetName>[]
  }
  queryRows: QueryRow[]
  compareQueryRows: QueryRow[]
  compareMode: boolean
  showGroupLabels?: boolean
}

export enum DateRangeTypeEnum {
  ALL_TIME = 'All time',
  CUSTOM = 'Custom',
  LAST_7_DAYS = 'Last 7 Days',
  LAST_14_DAYS = 'Last 14 Days',
  LAST_30_DAYS = 'Last 30 Days',
  LAST_90_DAYS = 'Last 90 Days',
  LAST_12_MONTHS = 'Last 12 Months',
  LAST_WEEK = 'Last Week',
  LAST_MONTH = 'Last Month',
  LAST_QUARTER = 'Last Quarter',
  LAST_YEAR = 'Last Year',
  THIS_WEEK = 'This Week',
  THIS_MONTH = 'This Month',
  THIS_YEAR = 'This Year',
}

export interface DateRangeConfig {
  type: DateRangeTypeEnum
  dateField?: string
  dateFrom?: string
  dateTo?: string
}

export interface DashboardView {
  drillDown: boolean
  groupOrTheme: boolean
  overview: boolean
  concept: boolean
  query: boolean
  themeGroup: boolean
  zoomed: boolean
  segment: boolean
}

export interface DashboardWidgetConfigDiff {
  name: string
  toVisible?: boolean
}

export interface DateRangeDiff {
  original: DateRangeConfig
  current: DateRangeConfig
}
