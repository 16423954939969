<template>
  <div>
    <h1>Project Settings</h1>
    <div v-if="currentProject" class="settings-container">
      <VeeForm
        v-slot="{ meta: { valid, pending }, isSubmitting }"
        :initial-values="initialFormValues"
        :on-submit="updateProject"
      >
        <Field
          v-slot="{ field, errors }"
          rules="required|project_name_available"
          name="projectName"
          label="Project Title"
          validate-on-input
        >
          <label>Project Title:</label>
          <bf-text-input v-bind="field" focus type="text" :errors="errors"></bf-text-input>
        </Field>

        <auto-add-settings
          v-if="integration"
          class="setting"
          :is-loading="isLoadingAutoAdd"
          :integration-type="integration.type"
          :integration-info="currentProject.integration_info"
          :auto-add-files-enabled="autoAddFilesEnabled"
          :auto-add-interval="autoAddInterval"
          :auto-add-partials="autoAddPartials"
          :next-auto-add-time="nextAutoAddTime"
          :last-succeeded-time="lastSucceededTime"
          :last-run-time="lastRunTime"
          :integration-description="integrationDescription"
          :integration-bucket="integrationBucket"
          :selected-folder="selectedFolder"
          :unique-fields="uniqueFields"
          :auto-add-interval-options="Object.keys(autoAddIntervalOptions)"
          @auto-add-changed="toggleAutoAdd"
          @auto-add-partials="autoAddPartials = $event"
          @object-selected="selectedFolder = $event"
          @change-auto-add-interval="changeAutoAddInterval"
        />

        <div class="setting">
          <toggle-slider
            v-model="autoUpdateAnalysesEnabled"
            label="Automatically update analyses when new data is added"
            :disabled="uniqueFields.length > 0"
          />
          <div v-if="uniqueFields.length > 0" class="info">
            This setting must be ON when unique record updates is enabled.
          </div>
        </div>

        <div class="setting">
          <toggle-slider
            v-model="showVerbatimAnnotations"
            label="Show Sentiment and NPS Category annotations on verbatims"
          />
        </div>
        <div class="setting">
          <toggle-slider v-model="skipBadDates" label="Skip Bad or Missing Dates" />
        </div>
        <div class="setting">
          <toggle-slider v-model="allowStructuredThemes" label="Allow structured data in themes" />
        </div>
        <div class="setting">
          <div class="label-value" style="text-align: center">
            <div>Start Day of Week On</div>
            <div>
              <el-select
                :model-value="weekStartDefault"
                style="width: 180px"
                placeholder="Select"
                @update:model-value="weekStartDefault = $event"
              >
                <el-option v-for="item in weekStartOptions" :key="item" :label="item" :value="item"> </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div v-if="currentProject.sentiment_classifier.includes('plumeria_')" class="setting">
          <div class="label-value" style="text-align: center">
            <div>Default Query Scope for New Queries</div>
            <div>
              <el-select
                :model-value="queryScopeDefault"
                style="width: 180px"
                placeholder="Select"
                @update:model-value="queryScopeDefault = $event"
              >
                <el-option v-for="item in queryScopeOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="setting">
          <div class="label-value">
            <div>Date Format</div>
            <div>
              <template v-if="currentProject.day_first_dates"> Day First (e.g. DD/MM/YYYY or DD-MM-YYYY) </template>
              <template v-else> Month First (e.g. MM/DD/YYYY or MM-DD-YYYY) </template>
            </div>
          </div>
        </div>
        <div class="setting">
          <div class="label-value">
            <div>PII Redaction</div>
            <div>
              <template v-if="currentProject.pii_clean"> Enabled </template>
              <template v-else> Disabled </template>
            </div>
          </div>
        </div>

        <collapsable-settings
          :unique-fields="uniqueFields"
          :schema="currentProject.schema"
          :updated-score-columns="updatedScoreColumns"
          @update-score-columns="updateScoreColumns"
          @reset-score-changes="resetScoreColChanges"
        />

        <div class="actions">
          <bf-button type="submit" color="green" :disabled="!valid || isSubmitting || pending"> Update </bf-button>
          <router-link
            class="cancel"
            :to="{ name: 'project-details', params: { projectId: currentProject.id } }"
            :exact="true"
          >
            Cancel
          </router-link>
          <a class="delete" @click.stop="showDeleteModal = true"> Delete Project </a>
        </div>
      </VeeForm>
    </div>

    <div v-if="currentUser && currentUser.is_staff">
      <h1>Project Diagnostics <span class="staff-only"></span></h1>
      <div class="settings-container">
        <div v-if="diagnosticsLoading">
          <bf-spinner class="spinner"> Running Diagnostics. </bf-spinner>
        </div>
        <div v-else>
          <div class="setting">
            <div v-if="diagnosticsGeneralLoading">
              <bf-spinner class="spinner"> Fetching General Info..... </bf-spinner>
            </div>
            <div v-else>
              <h3>
                General Settings
                <icon
                  class="revert-button"
                  color="#7f7f7f"
                  name="revert"
                  :size="18"
                  @click="updateGeneralDiagnostics"
                />
              </h3>
              <div v-if="Object.keys(diagnostics).length != 0">
                <pre v-for="(value, name) in diagnostics" :key="name"
                  >{{ name }} : {{ JSON.stringify(value, null, 2) }}</pre
                >
              </div>
            </div>
          </div>
          <div class="setting">
            <div v-if="diagnosticsSettingsLoading">
              <bf-spinner class="spinner"> Fetching Settings Table..... </bf-spinner>
            </div>
            <div v-else>
              <h3>
                Chrysalis setting table
                <icon
                  class="revert-button"
                  color="#7f7f7f"
                  name="revert"
                  :size="18"
                  @click="updateSettingsDiagnostics"
                />
              </h3>
              <div v-if="settings.length !== 0">
                <el-table :data="settings" border style="width: 100%">
                  <el-table-column prop="name" label="Name" />
                  <el-table-column prop="value" label="Value" />
                </el-table>
              </div>
            </div>
          </div>
          <div class="setting">
            <div v-if="diagnosticsJobsLoading">
              <bf-spinner class="spinner"> Fetching Current Chrysalis Jobs..... </bf-spinner>
            </div>
            <div v-else>
              <h3>
                Chrysalis Current Jobs
                <icon class="revert-button" color="#7f7f7f" name="revert" :size="18" @click="updateJobsDiagnostics" />
              </h3>
              <div v-if="jobs.length !== 0">
                <el-table :data="jobs" border style="width: 100%">
                  <el-table-column prop="task_uuid" label="Task uuid" />
                  <el-table-column prop="details" label="Details" />
                  <el-table-column prop="status" label="Status" />
                  <el-table-column prop="created" label="Created" />
                  <el-table-column prop="completed_at" label="Completed At" />
                </el-table>
              </div>
            </div>
          </div>
          <div class="setting">
            <div v-if="dbStatsLoading">
              <bf-spinner class="spinner"> Fetching Database Stats..... </bf-spinner>
            </div>
            <div v-else>
              <h3>
                Database Stats
                <icon class="revert-button" color="#7f7f7f" name="revert" :size="18" @click="dbStatsDiagnostics" />
              </h3>
              <template v-if="object_sizes.length !== 0">
                <div class="table-title"><strong>Object Sizes</strong></div>
                <el-table :data="object_sizes" border style="width: 100%">
                  <el-table-column prop="tbl_name" label="Table Name" />
                  <el-table-column prop="name" label="Name" />
                  <el-table-column prop="type" label="Type" />
                  <el-table-column prop="size_mb" label="Size (MB)" />
                  <el-table-column prop="packing_percent" label="Packing Percent" />
                </el-table>
              </template>
              <br />
              <template v-if="table_sizes.length !== 0">
                <div class="table-title"><strong>Table Sizes</strong></div>
                <el-table v-if="table_sizes.length !== 0" :data="table_sizes" border style="width: 100%">
                  <el-table-column prop="tbl_name" label="Table Name" />
                  <el-table-column prop="size_mb" label="Size (MB)" />
                  <el-table-column prop="packing_percent" label="Packing Percent" />
                </el-table>
              </template>
            </div>
          </div>
          <div class="setting">
            <bf-button size="" color="blue" @click="updateDiagnostics"> Run Diagnostics </bf-button>
          </div>
        </div>
      </div>
    </div>
    <!-- Delete project modal -->
    <modal v-if="currentProject" :visible="showDeleteModal" @close="showDeleteModal = false">
      <template #header>
        <div>Are you sure you want to delete this project: '{{ currentProject.name }}'?</div>
      </template>
      <template #content>
        <div class="description center">
          <p>Deleting this analysis will <strong>permanently remove</strong>:</p>
          <p>All Analyses, Themes, Theme Groups, Dashboards, Email Digests, and any related filters.</p>
          <p><strong>Once deleted, these cannot be recovered.</strong></p>
          <div class="delete-confirmation mt-4">
            <p>To confirm, type <strong>DELETE</strong> in the box below.</p>
            <div class="ui input">
              <input
                type="text"
                v-model="deleteConfirmText"
                placeholder="Type DELETE to confirm"
                class="delete-confirm-input"
              />
            </div>
          </div>
        </div>
      </template>
      <template #buttons>
        <bf-button class="cancel" size="large" @click="cancelProjectAction"> Cancel </bf-button>
        <bf-button size="large" color="red" :disabled="deleteConfirmText !== 'DELETE'" @click="deleteProject">
          Confirm
        </bf-button>
      </template>
    </modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { defineRule, Form as VeeForm, Field } from 'vee-validate'

import CollapsableSettings from 'components/widgets/ProjectSettings/CollapsableSettings.vue'
import AutoAddSettings from 'components/widgets/ProjectSettings/AutoAddSettings.vue'
import Modal from 'components/Modal.vue'
import Project from 'src/api/project'
import ToggleSlider from 'components/widgets/ToggleSlider.vue'
import HTTPRetryUtil from 'src/utils/httpretry'
import { SchemaColumn } from 'types/SchemaTypes'
import { BfButton, BfTextInput, BfSpinner } from 'components/Butterfly'
import { SET_PROJECT, UPDATE_PROJECT, CLEAR_REQUEST_ERRORS } from 'src/store/types'
import { IntegrationType } from 'types/IntegrationTypes'
import { uniqueFieldsFromSchema } from 'components/widgets/ProjectSettings/UniqueFields'
import Icon from 'components/Icon.vue'

interface FormValues {
  projectName: string
}

export default defineComponent({
  components: {
    CollapsableSettings,
    AutoAddSettings,
    BfTextInput,
    BfButton,
    BfSpinner,
    Modal,
    ToggleSlider,
    VeeForm,
    Field,
    Icon,
  },
  data() {
    return {
      showDeleteModal: false,
      deleteConfirmText: '',
      isLoadingAutoAdd: true,
      isUpdating: false,
      integrationsDict: {},
      showVerbatimAnnotations: false,
      skipBadDates: false,
      allowStructuredThemes: false,
      weekStartDefault: 'Sunday',
      weekStartOptions: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      queryScopeDefault: 'frame',
      queryScopeOptions: [
        {
          value: 'frame',
          label: 'match on verbatim',
        },
        {
          value: 'sentence',
          label: 'match on sentence',
        },
      ],
      autoAddFilesEnabled: false,
      // If we change the autoAddFilesEnabled setting from off to on, then
      // we want to immediately run an import
      autoAddFilesEnabledOriginalValue: false,
      autoAddInterval: undefined,
      nextAutoAddTime: undefined,
      lastSucceededTime: undefined,
      lastRunTime: undefined,
      integrationDescription: undefined,
      autoUpdateAnalysesEnabled: false,
      autoAddPartials: false,
      showFileBrowser: false,
      selectedFolder: '',
      diagnostics: {},
      settings: [],
      jobs: [],
      object_sizes: [],
      table_sizes: [],
      diagnosticsLoading: false,
      diagnosticsGeneralLoading: false,
      diagnosticsJobsLoading: false,
      diagnosticsSettingsLoading: false,
      dbStatsLoading: false,
      updatedScoreColumns: [],
      initialFormValues: {
        projectName: this.$store.getters.currentProject?.name,
      } as FormValues,
      autoAddIntervalOptions: {
        'every hour': {
          every: 1,
          period: 'hours',
        },
        'every day': {
          every: 1,
          period: 'days',
        },
        'every 7 days': {
          every: 7,
          period: 'days',
        },
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentProject', 'currentSite', 'featureFlags']),
    integration(): undefined | { enabled: boolean; type: IntegrationType } {
      return this.currentProject?.integration
    },
    integrationBucket(): string {
      if (this.integration.type === 's3') {
        return this.integrationsDict?.s3?.details?.bucket
      } else if (this.integration.type === 'gcs') {
        return this.integrationsDict?.gcs?.details?.bucket
      }
      return ''
    },
    uniqueFields(): string[] {
      return uniqueFieldsFromSchema(this.currentProject?.schema)
    },
  },
  created() {
    defineRule('project_name_available', async (value) => {
      if (!value) return false
      if (value === this.currentProject?.name) return true

      try {
        await Project.checkProjectName(value)
        return true
      } catch (e: any) {
        const error = e.body?.non_field_errors?.[0]

        if (error.includes('name already exists')) {
          this.$store.dispatch(CLEAR_REQUEST_ERRORS)
          return 'A project with this name already exists'
        }

        if (error.includes('name too long')) {
          this.$store.dispatch(CLEAR_REQUEST_ERRORS)
          return 'This project name is too long'
        }
      }
    })
  },
  metaInfo() {
    return {
      title: this.currentProject ? `${this.currentProject?.name} Settings - Kapiche` : null,
    }
  },
  beforeMount() {
    this.getAutoAddFilesState()
    this.autoUpdateAnalysesEnabled = this.currentProject?.auto_update_analyses_enabled
    this.showVerbatimAnnotations = this.currentProject?.show_verbatim_annotations
    this.skipBadDates = this.currentProject?.skip_bad_dates
    this.queryScopeDefault = this.currentProject?.query_scope_default
    this.weekStartDefault = this.currentProject?.week_start
    this.allowStructuredThemes = this.currentProject?.allow_structured_themes
  },
  mounted() {
    this.loadIntegrations()
  },
  methods: {
    updateScoreColumns(updatedScoreColumn: SchemaColumn) {
      // Filter out the array for any existing entries, then update the array with latest changes.
      this.updatedScoreColumns = this.updatedScoreColumns.filter((col) => col.name !== updatedScoreColumn.name)
      this.updatedScoreColumns.push(updatedScoreColumn)
    },
    resetScoreColChanges(index) {
      this.updatedScoreColumns = this.updatedScoreColumns.filter((col) => col.index !== index)
    },
    changeAutoAddInterval(value: string) {
      this.autoAddInterval = value
    },
    cancelProjectAction() {
      this.showDeleteModal = false
      this.deleteConfirmText = ''
      this.showingDeleteModal = false
    },
    async updateProject({ projectName }: FormValues) {
      await this.$store.dispatch({
        type: UPDATE_PROJECT,
        projectId: this.currentProject?.id,
        data: {
          name: projectName,
          auto_update_analyses_enabled: this.autoUpdateAnalysesEnabled,
          show_verbatim_annotations: this.showVerbatimAnnotations,
          skip_bad_dates: this.skipBadDates,
          query_scope_default: this.queryScopeDefault,
          week_start: this.weekStartDefault,
          allow_structured_themes: this.allowStructuredThemes,
          score_columns: this.updatedScoreColumns,
        },
      })
      const data = {
        enabled: this.autoAddFilesEnabled,
        // If we change the autoAddFilesEnabled setting from off to on then we want to immediately run an import
        run_immediately: !this.autoAddFilesEnabledOriginalValue && this.autoAddFilesEnabled,
        selected_folder: '',
        add_partials: this.autoAddPartials,
        auto_add_interval: this.autoAddIntervalOptions[this.autoAddInterval],
      }
      if (this.integration) {
        if (this.integration?.type === 's3' || this.integration?.type === 'gcs') {
          data.selected_folder = this.selectedFolder
        }
        await Project.updateAutoAddFilesSetting(this.currentProject?.id, data)
      }
      await this.$router.push({ name: 'project-details', params: { projectId: this.currentProject?.id } })
    },
    async deleteProject() {
      const projectId = this.currentProject.id
      await Project.deleteProject(projectId)
      this.$analytics.track.project.delete(projectId)
      this.deleteConfirmText = ''
      this.$store.commit(SET_PROJECT, null)
      await this.$router.push({ name: 'home' })
    },
    loadIntegrations() {
      Project.getAllIntegrations().then((response) => {
        // pack the list into a dict indexed by type
        this.integrationsDict = {}
        for (const i of response.results.filter((r) => r.enabled)) {
          this.integrationsDict[i.type] = i
        }
      })
    },
    getAutoAddFilesState() {
      this.isLoadingAutoAdd = true

      Project.getAutoAddFilesSetting(this.currentProject.id).then((response) => {
        this.autoAddFilesEnabled = !!response.enabled
        this.autoAddInterval = response.interval
        this.nextAutoAddTime = response.next_run
        this.lastSucceededTime = response.last_succeeded
        this.lastRunTime = response.last_ran_at
        this.integrationDescription = response.description
        this.autoAddFilesEnabledOriginalValue = response.enabled
        this.selectedFolder = response.state?.selected_folder
        this.autoAddPartials = response.state?.add_partial_responses
        this.isLoadingAutoAdd = false
      })
    },
    toggleAutoAdd(event: boolean) {
      this.autoAddFilesEnabled = event
      if (event === false && this.integration.type === 'survey_monkey') {
        this.autoAddPartials = false
      }
    },
    async updateGeneralDiagnostics() {
      this.diagnosticsGeneralLoading = true
      try {
        let response = await this.getChrysalisDiagnostics(
          this.currentProject.id,
          this.currentProject.chrysalis_ref,
          true,
          false,
          false,
          false,
        )
        let { ...info } = response
        this.diagnostics = {
          ...info,
          'Chrysalis host': this.currentSite.chrysalis_url,
        }
      } finally {
        this.diagnosticsGeneralLoading = false
      }
    },
    async updateSettingsDiagnostics() {
      this.diagnosticsSettingsLoading = true
      try {
        let response = await this.getChrysalisDiagnostics(
          this.currentProject.id,
          this.currentProject.chrysalis_ref,
          false,
          true,
          false,
          false,
        )
        this.settings = response.settings
      } finally {
        this.diagnosticsSettingsLoading = false
      }
    },
    async updateJobsDiagnostics() {
      this.diagnosticsJobsLoading = true
      try {
        let response = await this.getChrysalisDiagnostics(
          this.currentProject.id,
          this.currentProject.chrysalis_ref,
          false,
          false,
          true,
          false,
        )
        this.jobs = response.jobs
      } finally {
        this.diagnosticsJobsLoading = false
      }
    },
    async dbStatsDiagnostics() {
      this.dbStatsLoading = true
      try {
        let response = await this.getChrysalisDiagnostics(
          this.currentProject.id,
          this.currentProject.chrysalis_ref,
          false,
          false,
          false,
          true,
        )
        this.object_sizes = response.object_sizes
        this.table_sizes = response.table_sizes
      } finally {
        this.dbStatsLoading = false
      }
    },
    async updateDiagnostics() {
      this.diagnosticsLoading = true
      try {
        let response = await this.getChrysalisDiagnostics(
          this.currentProject.id,
          this.currentProject.chrysalis_ref,
          true,
          true,
          true,
          true,
        )
        let { settings, jobs, ...info } = response
        this.diagnostics = {
          ...info,
          'Chrysalis host': this.currentSite.chrysalis_url,
        }
        this.settings = settings
        this.jobs = jobs
      } finally {
        this.diagnosticsLoading = false
      }
    },
    async getChrysalisDiagnostics(
      projectId: number,
      chrysalisRef: string,
      get_general: boolean,
      get_settings_table: boolean,
      get_current_jobs: boolean,
      get_dbstats: boolean,
    ) {
      const urlstr = `projects/${projectId}/tunnel${chrysalisRef}/diagnostics`
      try {
        const response = await HTTPRetryUtil.retry(urlstr, {
          params: {
            chrysalisRef: chrysalisRef,
            // The default for GET is to use the chrysalis cache, but
            // we don't want that here because live diagnostics are needed.
            allow_read_cache: false,
            get_general: get_general,
            get_settings_table: get_settings_table,
            get_current_jobs: get_current_jobs,
            get_dbstats: get_dbstats,
          },
        })
        return response || {}
      } catch (error) {
        return {}
      }
    },
  },
})
</script>
<style lang="sass" scoped>
@import 'assets/kapiche'

.el-select input
  text-align: right
  font-size: 15px

h1
  text-align: center
  padding: 35px 0

.refresh-button
  float: right
  color: $grey-dark
  font-size: 1.7rem
  &:hover
    color: $blue-light
    cursor: pointer

.settings-container
  font-size: 15px
  max-width: 700px
  margin: 0 auto
  padding: 25px 40px
  border: 1px solid rgba(0, 0, 0, 0.13)
  background: $white

  .setting
    padding: 12px 0
    border-top: solid 1px #EBEEF5
    display: flex
    flex-direction: column

    .label-value
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center /* Align vertical */

    .info
      color: $subdued
      margin-top: 8px
  .setting:first-of-type
      border-top: none
      padding-bottom: 20px

.actions
  padding: 25px 0
  display: flex
  align-items: center
  justify-content: center
  .cancel
    margin-left: 10px
  .delete
    color: $red
    flex-grow: 1
    text-align: right
    cursor: pointer

.revert-button
  cursor: pointer
  float: right
  &:hover
    .mask-icon
      background-color: $blue

.description
  text-align: center
</style>
