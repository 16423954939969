<template>
  <div
    id="sidebar"
    class="ui left fixed inverted vertical menu"
    :class="{ collapsed: isCollapsed, expanded: isExpanded }"
    data-visible="true"
    @mouseenter="expandSideBar"
    @mouseleave="collapseSideBar"
  >
    <div class="item brand">
      <router-link
        :to="{ name: 'home' }"
        active-class="never-active"
        @click="$analytics.track.userNavigation('Sidebar: Kapiche Logo')"
      >
        <transition>
          <img v-if="isExpanded" class="ui image logo" src="../../assets/logo-inverted.svg" />
          <img v-else class="ui image small-logo" :src="kapiche_mark_inverse" />
        </transition>
      </router-link>
    </div>
    <div class="item site">
      <el-dropdown
        v-show="isExpanded"
        trigger="click"
        popper-class="site-dropdown"
        @command="changeSite"
        @visible-change="dropdownVisible = $event"
      >
        <div v-if="currentSite" class="dropdown-display">
          {{ currentSite.site_name }} <i v-if="nSites > 1" class="kapiche-icon-chevron-down"></i>
        </div>
        <template #dropdown>
          <el-dropdown-menu class="site-dropdown">
            <el-dropdown-item
              v-for="site in availableSites"
              :key="site.domain"
              class="dropdown-item"
              :value="site.domain"
              :command="site.domain"
            >
              {{ site.site_name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div
      :class="{
        'collapse-container': true,
        'collapsed': !canCollapse,
      }"
    >
      <span @click="toggleCanCollapse">
        <icon
          :style="{
            transform: canCollapse ? '' : 'rotate(180deg)',
          }"
          name="double-chevron-left"
          :size="14"
          color="#fff"
        />
      </span>
    </div>
    <div v-if="showDataUnits" class="slim fitted item">
      <span class="balance"
        >Data Units:
        <strong
          :class="{ low: dataUnits < 500, healthy: dataUnits >= 1000, warning: dataUnits < 1000 && dataUnits >= 500 }"
          >{{ formatDataUnits(dataUnits) }}</strong
        ></span
      >
    </div>
    <div v-if="isATrialSite" v-show="isExpanded" class="slim fitted item">
      <span v-if="trialDaysRemaining === null" class="plan-name">
        <span class="proof-of-concept"> Proof of Concept </span>
      </span>
      <span v-else-if="trialDaysRemaining > 0" class="plan-name">
        <span v-show="trialDaysRemaining"
          >Trial: <span class="trial-time">{{ trialDaysRemaining }}d left</span></span
        >
      </span>
      <span v-else class="trial-expired"> Free Trial Ended </span>
    </div>
    <div class="ui divider"></div>
    <transition name="fade">
      <div v-if="displaySiteMenu" class="sub-menu">
        <div class="item header">
          <i class="el-icon-s-home nav-icon"></i>
          <span v-if="isExpanded">Site Menu</span>
        </div>
        <router-link
          v-show="isExpanded"
          :to="{ name: 'home' }"
          class="item"
          exact
          @click="$analytics.track.userNavigation('Sidebar: Projects')"
        >
          Projects
        </router-link>
        <router-link
          v-if="featureFlags.cx_metrics && isExpanded"
          exact
          class="item"
          :class="{ collapsed: isCollapsed, expanded: isExpanded }"
          :to="{ name: 'alerts' }"
          @click="$analytics.track.userNavigation('Sidebar: Alerts')"
        >
          Manage Alerts
        </router-link>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="displayProjectMenu" class="sub-menu">
        <div class="item header">
          <i class="el-icon-folder-opened nav-icon"></i>
          <span v-if="isExpanded"> Project Menu </span>
        </div>
        <router-link
          v-show="isExpanded"
          :to="{
            name: 'project-details',
            params: {
              site: linkParams.site,
              projectId: linkParams.projectId,
            },
          }"
          class="item"
          exact
        >
          Overview
        </router-link>
        <router-link
          v-show="isExpanded"
          :to="{
            name: 'project-edit',
            params: {
              site: linkParams.site,
              projectId: linkParams.projectId,
            },
          }"
          class="item"
        >
          Settings
        </router-link>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="displayAnalysisMenu" class="sub-menu">
        <div class="item header">
          <i class="el-icon-s-data nav-icon"></i>
          <span v-show="isExpanded">Analysis Menu</span>
        </div>
        <router-link
          v-show="isExpanded"
          :to="{
            name: 'summary',
            params: {
              site: linkParams.site,
              projectId: linkParams.projectId,
              analysisId: linkParams.analysisId,
            },
            state: linkParams,
          }"
          class="item"
        >
          Summary
        </router-link>
        <router-link
          v-show="isExpanded"
          :to="{
            name: 'storyboard',
            params: {
              site: linkParams.site,
              projectId: linkParams.projectId,
              analysisId: linkParams.analysisId,
            },
          }"
          class="item"
        >
          Storyboard
        </router-link>
        <!-- Note: This should say "Query" and not "Themes" as it's a verb. -->
        <router-link
          v-show="isExpanded"
          :to="{
            name: 'browse-excerpts',
            params: {
              site: linkParams.site,
              projectId: linkParams.projectId,
              analysisId: linkParams.analysisId,
            },
          }"
          class="item"
        >
          Query
        </router-link>
        <router-link
          v-show="isExpanded"
          :to="
            linkParams.dashboardId ?
              {
                name: 'analysis-dashboard-overview',
                params: {
                  site: linkParams.site,
                  projectId: linkParams.projectId,
                  analysisId: linkParams.analysisId,
                  dashboardId: linkParams.dashboardId,
                },
              }
            : ''
          "
          class="item"
        >
          Dashboards
        </router-link>
        <router-link
          v-if="!!featureFlags?.workbench"
          v-show="isExpanded"
          :to="
            linkParams.dashboardId ?
              {
                name: 'analysis-workbench',
                params: {
                  site: linkParams.site,
                  projectId: linkParams.projectId,
                  analysisId: linkParams.analysisId,
                  dashboardId: linkParams.dashboardId,
                },
              }
            : ''
          "
          class="item beta"
        >
          Workbench
        </router-link>
        <router-link
          v-show="isExpanded"
          :to="{
            name: 'unmapped',
            params: {
              site: linkParams.site,
              projectId: linkParams.projectId,
              analysisId: linkParams.analysisId,
            },
          }"
          class="item"
        >
          Unmapped Verbatims
        </router-link>
        <router-link
          v-if="featureFlags.concepts_radar && hasDate && isExpanded"
          :to="{
            name: 'radar',
            params: {
              site: linkParams.site,
              projectId: linkParams.projectId,
              analysisId: linkParams.analysisId,
            },
          }"
          class="item"
        >
          Radar
          <div v-if="currentAnalysis.radar_hits" class="radar-hits">
            {{ currentAnalysis.radar_hits }}
          </div>
        </router-link>
        <router-link
          v-if="isExpanded"
          :to="{
            name: 'theme-builder',
            params: {
              site: linkParams.site,
              projectId: linkParams.projectId,
              analysisId: linkParams.analysisId,
            },
          }"
          class="item"
        >
          Theme Builder
        </router-link>
        <div v-show="isExpanded" class="item subheader">
          <span class="text"> Advanced </span>
        </div>
        <router-link
          v-show="isExpanded"
          :to="{
            name: 'data-exports',
            params: {
              site: linkParams.site,
              projectId: linkParams.projectId,
              analysisId: linkParams.analysisId,
            },
          }"
          class="item"
        >
          Data Exports
        </router-link>
        <router-link
          v-if="featureFlags.diagnostic_exports && isExpanded"
          :to="{
            name: 'diagnostic-data-exports',
            params: {
              site: linkParams.site,
              projectId: linkParams.projectId,
              analysisId: linkParams.analysisId,
            },
          }"
          class="item"
        >
          Diagnostic Exports
        </router-link>
        <router-link
          v-show="isExpanded"
          :to="{
            name: 'settings',
            params: {
              site: linkParams.site,
              projectId: linkParams.projectId,
              analysisId: linkParams.analysisId,
            },
          }"
          :exact="true"
          class="item"
        >
          <span v-if="isExpanded">Analysis Settings</span>
        </router-link>
        <router-link
          v-if="featureFlags.fuzzy_search && currentProject && currentProject.embeddings && isExpanded"
          :to="{
            name: 'fuzzy-search',
            params: {
              site: linkParams.site,
              projectId: linkParams.projectId,
              analysisId: linkParams.analysisId,
            },
          }"
          class="item alpha"
        >
          Fuzzy Search
        </router-link>
        <router-link
          v-if="featureFlags.pivot_table && isExpanded"
          :to="{ name: 'pivot-tool', activeClass: 'active' }"
          class="item alpha"
        >
          Pivot Tool
        </router-link>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="showSiteTools" class="sub-menu">
        <div class="item header">
          <i class="el-icon-s-tools nav-icon"></i>
          <span v-if="isExpanded">Tools &#65039;</span>
        </div>
        <router-link v-if="isExpanded" :to="{ name: 'copy-queries' }" class="item"> Copy Themes </router-link>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="currentUser && currentUser.is_staff" class="sub-menu">
        <div class="item header" :class="{ 'staff-only': isExpanded }">
          <i class="el-icon-setting nav-icon"></i>
          <span v-show="isExpanded">Staff Tools &#65039;</span>
        </div>
        <router-link v-show="isExpanded" :to="{ name: 'copy-queries-staff' }" class="item">
          Copy Themes (Manual)
        </router-link>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import dayjs from 'dayjs'
import { debounce } from 'lodash'
import { mapGetters } from 'vuex'
import { LOAD_SUBSCRIPTION } from 'src/store/types'
import { NavigationFailureType } from 'vue-router'
import FormatUtils from 'src/utils/formatters'
import kapiche_mark_inverse from 'assets/img/dashboards/kapiche_mark_inverse.svg'
import Icon from 'components/Icon.vue'

export default defineComponent({
  components: { Icon },
  props: {
    isCollapsed: { type: Boolean, default: false },
  },
  data() {
    return {
      canCollapse: false,
      isCollapseText: false,
      isExpandedText: false,
      dropdownVisible: false,
      kapiche_mark_inverse,
    }
  },
  computed: {
    ...mapGetters([
      'availableSites',
      'currentSite',
      'currentRoute',
      'currentModel',
      'isAdmin',
      'currentUser',
      'loggedIn',
      'dataUnits',
      'isTrialing',
      'plan',
      'subscribedUntil',
      'currentProject',
      'currentAnalysis',
      'featureFlags',
      'hasDate',
    ]),
    isExpanded() {
      return !this.isCollapsed
    },
    displayAnalysisMenu() {
      return this.$route.fullPath.indexOf('/analysis/') !== -1 && this.currentAnalysis
    },
    displayProjectMenu() {
      return (
        this.$route.fullPath.indexOf('/projects/') !== -1 &&
        this.$route.fullPath.indexOf('/projects/create') < 0 &&
        this.currentProject &&
        !this.currentUser?.viewer
      )
    },
    displaySiteMenu() {
      return !this.currentUser?.viewer
    },
    showDataUnits() {
      return ![Infinity, null].includes(this.dataUnits)
    },
    showSiteTools(): boolean {
      return !this.currentUser?.viewer
    },
    isATrialSite() {
      return this.isTrialing
    },
    trialDaysRemaining() {
      if (!this.subscribedUntil) {
        return null
      }
      return dayjs.utc(this.subscribedUntil).diff(dayjs(), 'days')
    },
    linkParams() {
      return {
        site: this.$route.params.site,
        projectId: this.$route.params.projectId,
        analysisId: this.$route.params.analysisId,
        dashboardId: this.currentAnalysis?.dashboards?.[0]?.id,
      }
    },
    selectedSite() {
      return this.$route.params.site
    },
    nSites(): number {
      return this.availableSites?.length || 0
    },
    expandDebounce() {
      return debounce(() => {
        this.toggleCollapse(false)
      }, 100)
    },
    collapseDebounce() {
      return debounce(() => {
        this.toggleCollapse(true)
      }, 100)
    },
  },
  beforeMount() {
    this.$store.dispatch({ type: LOAD_SUBSCRIPTION })
  },
  methods: {
    formatDataUnits: FormatUtils.formatDataUnits,
    /**
     * Change the site to the new site.
     *
     * We're also using this dropdown to switch back to the Projects
     * list page *in the same site*. That means that we want to be able
     * to click the current (active) site in the dropdown and return to
     * the Projects list page for the current site. If this happens,
     * it will *not* throw a NavigationDuplicated error because the route
     * will be different; but, if you're already on the Projects list
     * page then NavigationDuplicated will be thrown. Thus, to deal with
     * that situation we simply catch and absorb the error.
     *
     * @param {string} site
     * @returns {Promise<void>}
     */
    async changeSite(site) {
      try {
        await this.$router.push({ name: 'home', params: { site } })
      } catch (e) {
        if (![NavigationFailureType.duplicated, NavigationFailureType.redirected].includes(e.type)) {
          throw e
        }
      }
    },
    toggleCollapse(value: boolean) {
      // Specifically used to redraw sentiment Bar in SentimentSummary
      window.dispatchEvent(new Event('resize'))
      if (this.canCollapse) {
        this.$emit('toggle-collapse', value)
      }
    },
    collapseSideBar() {
      if (this.canCollapse && !this.dropdownVisible) {
        this.collapseDebounce()
      }
    },
    expandSideBar() {
      if (this.isCollapsed && this.canCollapse) {
        this.expandDebounce()
      }
    },
    toggleCanCollapse() {
      this.canCollapse = !this.canCollapse
      let msg = this.canCollapse ? 'SideBar Collapsed' : 'SideBar Expanded'
      this.$analytics.track.sideBarCollapse(msg)
      if (this.canCollapse) {
        this.collapseSideBar()
      }
    },
  },
})
</script>

<style lang="sass" scoped>
@import "assets/kapiche.sass"

.radar-hits
  color: #fff
  background: $red
  width: 14px
  height: 14px
  border-radius: 100%
  display: inline-flex
  align-items: center
  justify-content: center
  font-size: 9px
  margin-left: 2px
  position: relative
  top: -1px

@keyframes collapse-sidebar
  0%
    width: 17rem
  100%
    width: 5rem

@keyframes expand-sidebar
  0%
    width: 5rem
  100%
    width: 17rem

@keyframes fade-out-text
  0%
    opacity: 100%
    height: 100%
  100%
    opacity: 0%
    transform: translateY(20px)
    height: 0%

@keyframes fade-in-text
  0%
    opacity: 0%
    height: 0%
    transform: translateY(20px)
  100%
    opacity: 100%
    height: 100%

#sidebar
  z-index: 10
  width: 17rem
  padding: 0 .5rem
  margin: 0
  overflow-x: hidden
  min-height: calc(100vh - 8em)
  background-image: linear-gradient(to top, #112126, #15313e 100%)
  &.collapsed
      animation: collapse-sidebar 0.2s ease-in-out forwards
  &.expanded
      animation: expand-sidebar 0.4s ease-out backwards

  .collapse-container
    display: flex
    justify-content: flex-end
    padding-right: 1rem
    .icon-wrapper
      cursor: pointer
  > .item
    padding-left: 1rem
    padding-right: 1rem
  .item
    min-width: 15rem
    border: none
    &::before
      height: 0
    i.home.icon
      margin-left: 0
    a
      img.image.logo
        width: 80%
      img.image.small-logo
        width: 2rem
    &.collapsed
      animation: fade-out-text 0.2 ease-in-out
    &.expanded
      animation: fade-in-text 0.2 ease-in-out
    &.brand
      padding-top: 1.5rem
      padding-bottom: 1.5rem
      min-height: 85px
    &.site
      display: flex
      justify-content: space-between
      font-size: 1.2rem
      min-height: 3.4rem
      color: white
      i
        margin-left: 0.4rem
        font-size: 0.6rem
    .dropdown-display
        font-size: 1.2rem
        color: $white
        cursor: pointer
        word-break: break-word
    &.slim.fitted
      padding-top: 0.25rem
      padding-bottom: 0.25rem
    .menu
      margin-left: 0
      margin-right: 0
      .item
        padding-left: 0
        padding-right: 0
  div.header
    color: $grey-light
    text-transform: uppercase
    font-weight: bold
    padding-bottom: 0
    margin-bottom: 0.5rem
  div.subheader
    padding-bottom: 0
    margin-bottom: 0.5rem
    .text
      color: #3d5966
      text-transform: uppercase
      font-size: 10px
      letter-spacing: 1.4px
      font-weight: bold
  .ui.divider
    margin: 1rem
    background-color: #557d8b
    opacity: 0.2
  a.item
    color: #95a6ac
    font-size: 14px
    padding-top: 0.5rem
    padding-bottom: 0.5rem
    &.active
      font-weight: normal
      background-color: rgba(68, 129, 159, 0.1)
      border-radius: 3px
    img
      width: 16px
      height: 16px
  a.item.disabled
    color: rgba(255, 255, 255, 0.25)
    cursor: not-allowed
  .icon
    float: none
    margin-left: 2px
    margin-right: 5px
  .plan-name
    color: #95a6ac
    .trial-time
      color: #f89516
    .proof-of-concept
      color: #00ff00
  .button.upgrade
    margin-left: 10px
    border-radius: 3px
    text-transform: uppercase
  span.trial-expired
    color: #ee3824
  .balance
    color: #95a6ac
    strong
      margin-left: 3px
      &.healthy
        color: #21ba45
      &.warning
        color: #f89516
      &.low
        color: #ee3824

.sub-menu
  margin-bottom: 1rem
  animation-duration: 0.3s

.nav-icon
  font-size: 1.1rem
  margin-right: 2px

.collapse-icon:hover
  cursor: pointer
  color: $blue

.alpha::after
  margin: 0rem 0.3rem !important
  content: 'ALPHA' !important
  background-color: rebeccapurple !important
  padding: 0.2rem 0.4rem !important
  border-radius: 3px !important
  border: 1px solid mediumorchid
  color: white
  font-size: 0.7rem !important
  font-weight: bold
  letter-spacing: 2px
  height: 1rem !important
  position: relative !important
  top: -0.1rem !important

.site-dropdown
  max-height: 90vh
  overflow-y: auto
</style>
<style lang="sass">
.site-dropdown
  li
    color: #333
</style>
