<template>
  <div class="summary-wrapper">
    <h2 class="summary-header">{{ themeName }}</h2>
    <template v-if="!isLoading">
      <div class="summary-text">
        {{ summary }}
      </div>
      <div class="summary-stats">
        <div>Total verbatims: {{ totalDocs }}</div>
        <span>&bull;</span>
        <div>{{ groupCount }} Groups, {{ themeCount }} Themes</div>
      </div>
    </template>
    <div v-else class="loading">
      <bf-spinner></bf-spinner>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, PropType } from 'vue'
import { BfSpinner } from 'components/Butterfly'
import Query from 'src/api/query'
import { mergeDashboardFiltersWithBotanicQuery } from 'src/utils/query'
import { removemarkdown } from 'src/utils/formatters'
import { QueryType, SavedQuery } from 'src/types/Query.types'

export default defineComponent({
  components: {
    BfSpinner,
  },
  props: {
    dashboardId: { type: Number, required: false, default: null },
    currentProject: { type: Object, default: () => null, required: false },
    currentAnalysis: { type: Object, default: () => null, required: false },
    query: { type: Object as PropType<QueryType>, required: false, default: null },
    mergedFilters: { type: Array, default: () => null, required: false },
    savedQueries: { type: Array as PropType<SavedQuery[]>, default: () => null, required: false },
    themeName: { type: String, required: false, default: null },
    automaticFetch: { type: Boolean, required: false, default: false },
    themeCount: { type: Number, required: false, default: 0 },
    groupCount: { type: Number, required: false, default: 0 },
  },
  setup(props) {
    const isLoading = ref(true)
    const hasLoaded = ref(false)
    const summary = ref('')
    const totalDocs = ref(0)

    const getSummary = async () => {
      try {
        isLoading.value = true
        const themeName = props.themeName
        const summary_type = 'paragraph'
        const summaryResult = await Query.generateSummary(
          props.currentProject.id,
          props.currentProject.chrysalis_ref,
          Number(props.currentAnalysis.topic_framework_id),
          mergeDashboardFiltersWithBotanicQuery(props.query, props.mergedFilters),
          props.savedQueries,
          summary_type,
          themeName,
          3,
          'theme_summary',
        )
        if (summaryResult && summaryResult.payload) {
          summary.value = removemarkdown(summaryResult.payload[0].summary)
          totalDocs.value = summaryResult.payload[0].total_docs
        } else {
          throw new Error(`Failed to get summary data`)
        }
      } catch (e) {
        console.warn(`Error ${e}`)
      } finally {
        isLoading.value = false
        hasLoaded.value = true
      }
    }

    onMounted(() => {
      if (props.automaticFetch) {
        getSummary()
      }
    })

    return {
      isLoading,
      getSummary,
      summary,
      totalDocs,
      fetchData: getSummary,
      hasLoaded,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '~assets/kapiche.sass';

.summary-wrapper {
  width: 100%;
  padding: 20px;
  align-self: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 1, 1, 0.1);
}
.summary-header {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
  color: $blue-light;
}
.summary-text {
  color: $text-black;
  font-size: 16px;
  line-height: 1.7rem;
  align-self: center;
  margin-bottom: 20px;
}
.summary-stats {
  display: flex;
  color: $text-grey;
  font-size: 15px;

  > span {
    margin: 0 12px;
    font-size: 11px;
  }
}
.loading {
  text-align: center;
  padding: 40px 0;
}
</style>
