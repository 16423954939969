<template>
  <div ref="scrollableContent" class="content-wrapper" @scroll="scrollThemeGroupSummaries">
    <div class="sticky">
      <div v-if="selectedQuery" class="breadcrumbs">
        <button @click="$emit('select-theme', null)">Home</button>
        <span>&#62;</span>
        {{ selectedQuery.name }}
      </div>
      <div v-if="selectedQuery !== null" class="top-bar">
        <dropdown>
          <template #trigger>
            <span class="theme-name">
              {{ selectedQuery.name }}
              <i class="kapiche-icon-chevron-down"></i>
            </span>
          </template>
          <template v-if="!isNewTheme">
            <dropdown-item @click="showDashboardModal = true"> Add/Remove to Dashboards </dropdown-item>
            <dropdown-item @click="$emit('rename-theme', [selectedQuery, false])"> Rename </dropdown-item>
          </template>
          <dropdown-item v-if="!isAutoTheme" class="red" @click="$emit('delete-theme', selectedQuery)">
            Delete
          </dropdown-item>
        </dropdown>
        <bf-button
          v-if="stagedQueryIsValid && hasChanges !== false"
          size="mini"
          color="green"
          @click="isNewTheme ? $emit('rename-theme', [selectedQuery, false]) : saveCurrentTheme()"
        >
          {{ isNewTheme ? 'SAVE AS NEW THEME...' : 'SAVE' }}
        </bf-button>
        <bf-button v-if="!isNewTheme" size="mini" color="blue" @click="duplicateTheme()"> SAVE AS... </bf-button>
        <bf-button
          v-if="!isNewTheme && hasChanges !== false"
          class="discard-button"
          size="mini"
          color="transparent"
          @click="resetCurrentTheme"
        >
          <icon name="revert" :size="14" color="#95a6ac" /> DISCARD CHANGES
        </bf-button>
        <bf-button
          v-if="featureFlags.dev_mode"
          size="mini"
          color="orange"
          class="staff-only"
          @click="() => (showJSON = !showJSON)"
        >
          Show JSON
        </bf-button>
      </div>
      <div v-if="showJSON" class="query-json">
        <textarea :value="JSON.stringify(selectedBotanicQuery, null, 2)" />
      </div>
      <filter-bar
        v-if="selectedQuery"
        :query-id="selectedQuery.id"
        :query-name="selectedQuery.name"
        :query-scope="selectedQueryScope"
        :query-rows="selectedQueryRows"
        :concept-list="visibleConceptStrings"
        :allow-structured="currentProject.allow_structured_themes"
        :saved-queries="savedQueries"
        :current-site="currentSite"
        :current-project="currentProject"
        :current-analysis="currentAnalysis"
        :allow-dates="false"
        :location="location"
        @set-query-rows="queryUpdatedMethod"
      />
      <template v-if="selectedQuery !== null && stagedQueryIsValid">
        <div class="query-stats">
          <template v-if="isLoadingStats"> <bf-spinner size="mini" /> Loading coverage... </template>
          <template v-else>
            <b>{{ comma(queryStats.record_count) }} of {{ comma(queryStats.total_record_count) }} records</b>
            <span class="percent"> ({{ percent(queryStats.record_count, queryStats.total_record_count) }}%) </span>
            <span>&bull;</span>
            <b>{{ comma(queryStats.verbatim_count) }} of {{ comma(queryStats.total_verbatim_count) }} verbatims</b>
            <span class="percent"> ({{ percent(queryStats.verbatim_count, queryStats.total_verbatim_count) }}%) </span>
          </template>
        </div>
        <div v-if="!hasNoResults && currentProject.sentiment_classifier.includes('plumeria_')" class="query-controls">
          <!-- Disabled for now due to back end hurdles -->
          <!-- <label>
            <toggle-slider-input
              small
              :value="!!selectedQuery.exclude_mapped"
              @input="setExcludeMapped"
            />
            Exclude mapped verbatims
          </label> -->
          <label>
            Match on
            <dropdown @change="$emit('change-query-scope', $event)">
              <template #trigger>
                <span class="scope-label">
                  {{
                    {
                      frame: 'verbatim',
                      sentence: 'sentence',
                    }[selectedQuery.query_value.level || 'frame']
                  }}
                  <i class="kapiche-icon-chevron-down"></i>
                </span>
              </template>
              <dropdown-item value="frame"> verbatim </dropdown-item>
              <dropdown-item value="sentence"> sentence </dropdown-item>
            </dropdown>
          </label>
        </div>
      </template>
    </div>
    <div>
      <div v-if="selectedBotanicQuery && selectedQuery && stagedQueryIsValid" class="widget-container">
        <!-- <div v-if="isLoadingStats" class="loading-theme">
          <bf-spinner />
        </div> -->
        <div v-if="hasNoResults" class="no-results">No records match this theme.</div>
        <template v-else>
          <div class="column">
            <context-network
              v-bind="fetchedData['context-network'] || {}"
              :masked="false"
              :dev-mode="false"
              :height="640"
              :export-name="currentAnalysis.name"
              :query="selectedBotanicQuery"
              @requires="fetchContextNetwork"
            >
              <template #interaction-menu="interactionMenuProps">
                <button @click="addConceptToQuery(interactionMenuProps.label)">
                  Add <b>{{ interactionMenuProps.label }}</b> to theme
                </button>
                <button @click="replaceQueryWithConcept(interactionMenuProps.label)">
                  Replace query with <b>{{ interactionMenuProps.label }}</b>
                </button>
                <button v-if="showAddConceptAsOR" @click="addConceptAsOR(interactionMenuProps.label)">
                  Add <b>{{ interactionMenuProps.label }}</b> as an OR
                </button>
              </template>
            </context-network>
            <key-phrases
              v-bind="fetchedData['key-phrases'] || {}"
              :masked="false"
              :dev-mode="false"
              :export-name="currentAnalysis.name"
              show-drilldown
              :queries="[
                {
                  id: selectedQuery.id,
                  name: selectedQuery.name,
                  query_value: selectedBotanicQuery,
                },
              ]"
              :group="'overall__'"
              @requires="fetchKeyPhrases"
            >
              <template #interaction-menu="interactionMenuProps">
                <button @click="addConceptToQuery(interactionMenuProps.label)">
                  Add <b>{{ interactionMenuProps.label }}</b> to theme
                </button>
                <button @click="replaceQueryWithConcept(interactionMenuProps.label)">
                  Replace query with <b>{{ interactionMenuProps.label }}</b>
                </button>
                <button v-if="showAddConceptAsOR" @click="addConceptAsOR(interactionMenuProps.label)">
                  Add <b>{{ interactionMenuProps.label }}</b> as an OR
                </button>
              </template>
            </key-phrases>
          </div>
          <div class="column">
            <ai-summary
              ref="aiSummaryRef"
              :masked="false"
              :dev-mode="false"
              :project-id="currentProject.id"
              :current-site="currentSite"
              :current-project="currentProject"
              :current-analysis="currentAnalysis"
              :query="selectedBotanicQuery"
              :merged-filters="[]"
              :saved-queries="stagedQueries"
              :theme-name="selectedQuery?.name"
              :is-staff="currentUser.is_staff"
              widget-title="Theme Summary"
            />
            <verbatims-widget
              v-bind="fetchedData['verbatims'] || {}"
              :masked="false"
              :dev-mode="false"
              :query="selectedBotanicQuery"
              :has-nps="hasNPS"
              :has-sentiment="hasSentiment"
              :has-date="hasDate"
              :has-files="hasFiles"
              :nps-field-name="npsFieldName"
              :model-topics="currentModel.topics"
              :model-terms="currentModel.terms"
              :model-colors="currentModel.conceptColours"
              :show-annotations="false"
              :sentiment-classifier="currentProject.sentiment_classifier"
              :config="verbatimsConfig"
              :saved-queries="stagedQueries"
              :topic-filters="currentUser.is_staff ? aiThemeTopics : null"
              :is-auto-theme="isAutoTheme"
              :explain-data="explainData"
              :explain-data-loading="explainDataLoading"
              @requires="fetchVerbatims"
              @config-changed="verbatimsConfig = $event"
              @generate-explanations="generateExplanation"
            />
          </div>
        </template>
      </div>
      <template v-else>
        <div v-if="autoThemesHomeVisible" class="auto-theme-home">
          <h1>Framework Overview</h1>
          <div class="auto-theme-home-stats">
            <div>
              <p>{{ number(totalVerbatims) }}</p>
              <h2>Total Verbatims</h2>
            </div>
            <div>
              <p>{{ themeGroups.length }}</p>
              <h2>Theme Groups</h2>
            </div>
            <div>
              <p>{{ savedQueries.length }}</p>
              <h2>Themes</h2>
            </div>
            <div>
              <p>{{ lastDataAdded }}</p>
              <h2>New Data Added</h2>
            </div>
          </div>
          <div class="theme-group-summaries">
            <group-summary
              v-for="group in expandedTopLevelGroups"
              ref="summaryRefs"
              :key="group.query.id"
              class="theme-group-summary-widget"
              :current-project="currentProject"
              :current-analysis="currentAnalysis"
              :query="group.query.query_value"
              :merged-filters="[]"
              :saved-queries="stagedQueries"
              :theme-name="group.query.name"
              :theme-count="group.themeCount"
              :group-count="group.groupCount"
              :automatic-fetch="false"
            />
          </div>
        </div>
        <storyboard-page
          v-else-if="!isAutoThemeProject"
          :class="['storyboard', { inTheme: selectedQuery }]"
          :show-concept-list="false"
          :on-concept-clicked="storyboardConceptClicked"
          :default-dim-added-concepts="true"
          :query-list="stagedQueries"
          :dimmed-concepts="ignoredConceptNames"
        />
      </template>
    </div>

    <clicked-outside :enabled="!!clickedStoryboardConcept.name" @clicked-outside="clearClickedConcept">
      <floating-panel
        v-if="!!clickedStoryboardConcept.name"
        :x="clickedStoryboardConcept.x"
        :y="clickedStoryboardConcept.y + 8"
        visible
      >
        <div class="interaction-menu">
          <button @click="addConceptToQuery(clickedStoryboardConcept.name)">
            Create new theme with "<b>{{ clickedStoryboardConcept.name }}</b
            >"
          </button>
          <button
            v-if="!clickedStoryboardConcept.dimmed"
            @click="$emit('go-to-unmapped', clickedStoryboardConcept.name)"
          >
            View "<b>{{ clickedStoryboardConcept.name }}</b
            >" on unmapped page
          </button>
          <template v-if="ignoredConceptNames.includes(clickedStoryboardConcept.name)">
            <button @click="$emit('unignore-concept', clickedStoryboardConcept.name)">
              Unignore "<b>{{ clickedStoryboardConcept.name }}</b
              >"
            </button>
          </template>
          <template v-else>
            <button @click="$emit('ignore-concept', clickedStoryboardConcept.name)">
              Ignore "<b>{{ clickedStoryboardConcept.name }}</b
              >"
            </button>
          </template>
        </div>
      </floating-panel>
    </clicked-outside>

    <theme-dashboard-modal
      :visible="showDashboardModal"
      :dashboard-list="currentAnalysis.dashboards || []"
      :can-add-to-dashboard="canAddToDashboard"
      :dashboard-ids="(selectedQuery && selectedQuery.dashboard_ids) || []"
      @close="showDashboardModal = false"
      @update-theme="dashboardFormSubmit"
    />
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, ref, reactive, toRef, watch, useTemplateRef } from 'vue'
import { cloneDeep, isEqual, throttle } from 'lodash'
import { useStore } from 'vuex'
import dayjs from 'dayjs'

import { Analysis, Concept, Model } from 'src/types/AnalysisTypes'
import { Project } from 'src/types/ProjectTypes'
import StoryboardPage from '../Storyboard.vue'
import VerbatimsWidget from 'src/components/DataWidgets/VerbatimsWidget/VerbatimsWidget.vue'
import AiSummary from 'src/components/DataWidgets/AiSummary/AiSummary.vue'
import KeyPhrases from 'src/components/DataWidgets/KeyPhrases/KeyPhrases.vue'
import ContextNetwork from 'src/components/DataWidgets/ContextNetwork/ContextNetwork.vue'
import { UserProfile } from 'src/types/UserTypes'
import { Site } from 'src/store/modules/app'
import FilterBar from './FilterBar.vue'
import { QueryElement, QueryLocation, QueryType, SavedQuery } from 'src/types/Query.types'
import { comma, percent } from 'src/utils/formatters'
import {
  fetchContextNetwork,
  FetchedData,
  fetchVerbatims,
  fetchWidgetData,
} from 'src/pages/trial/Workbench/Workbench.utils'
import { SchemaColumn } from 'src/types/SchemaTypes'
import { VerbatimsConfig } from 'src/types/DashboardTypes'
import Query, { ExplanationData } from 'src/api/query'
import { CLEAR_REQUEST_ERRORS } from 'src/store/types'
import { hasUnstructured, expandQuery, countTextRows } from 'src/utils/query'
import { getQueryRows } from './ThemeBuilder.utils'
import { ChrysalisFilter } from 'src/types/DashboardFilters.types'
import BfButton from 'src/components/Butterfly/BfButton/BfButton.vue'
import Dropdown from 'src/components/Butterfly/Dropdown/Dropdown.vue'
import DropdownItem from 'src/components/Butterfly/Dropdown/DropdownItem.vue'
import ThemeDashboardModal from './ThemeDashboardModal.vue'
import BfSpinner from 'src/components/Butterfly/BfSpinner/BfSpinner.vue'
import { expandThemeGroup, Group, GroupOrTheme } from 'src/pages/dashboard/Dashboard.utils'
import Icon from 'src/components/Icon.vue'
import FloatingPanel from 'src/components/widgets/FloatingPanel/FloatingPanel.vue'
import ClickedOutside from 'src/components/ClickedOutside.vue'
import GroupSummary from './GroupSummary.vue'
import { number } from 'src/utils/formatters/formatters'

const ThemePage = defineComponent({
  name: 'ThemePage',
  components: {
    StoryboardPage,
    VerbatimsWidget,
    AiSummary,
    KeyPhrases,
    ContextNetwork,
    FilterBar,
    BfButton,
    Dropdown,
    DropdownItem,
    ThemeDashboardModal,
    BfSpinner,
    Icon,
    FloatingPanel,
    ClickedOutside,
    GroupSummary,
  },
  props: {
    selectedQuery: { type: Object as PropType<SavedQuery | null>, default: null },
    isNewTheme: { type: Boolean, required: true },
    selectedBotanicQuery: { type: Object as PropType<QueryType | null>, default: null },
    selectedQueryScope: { type: String, required: true },
    queryStats: { type: Object as PropType<Record<string, number>>, required: true },
    stagedQueryIsValid: { type: Boolean, required: true },
    isLoadingStats: { type: Boolean, required: true },
    selectedQueryRows: { type: Array as PropType<QueryElement[]>, required: true },
    ignoredConceptNames: { type: Array as PropType<string[]>, required: true },
    stagedQueries: { type: Array as PropType<SavedQuery[]>, required: true },
    savedQueries: { type: Array as PropType<SavedQuery[]>, required: true },
    hasChanges: { type: Boolean, required: true },
    saveTheme: {
      type: Function as PropType<(query: SavedQuery, partial?: Partial<SavedQuery>) => Promise<void>>,
      required: true,
    },
    totalVerbatims: { type: Number, required: true },
    topLevelGroups: { type: Array as PropType<Group[]>, required: true },
  },
  emits: [
    'set-query',
    'add-theme',
    'set-selected',
    'select-theme',
    'change-query-scope',
    'go-to-unmapped',
    'ignore-concept',
    'unignore-concept',
    'theme-renamed',
    'delete-theme',
    'rename-theme',
  ],
  setup(props, { emit }) {
    const store = useStore()

    const summaryRefs = useTemplateRef<InstanceType<typeof GroupSummary>[]>('summaryRefs')
    const scrollableContent = useTemplateRef<HTMLDivElement>('scrollableContent')

    const currentAnalysis = computed(() => store.getters.currentAnalysis as Analysis)
    const currentProject = computed(() => store.getters.currentProject as Project)
    const currentUser = computed(() => store.getters.currentUser as UserProfile)
    const currentSite = computed(() => store.getters.currentSite as Site)
    const currentModel = computed(() => store.getters.currentModel as Model)
    const featureFlags = computed(() => store.getters.featureFlags as Record<string, boolean>)
    const hasDate = computed(() => store.getters.hasDate as boolean)
    const hasNPS = computed(() => store.getters.hasNPS as boolean)
    const hasSentiment = computed(() => store.getters.hasSentiment as boolean)
    const hasFiles = computed(() => store.getters.hasFiles as boolean)
    const themeGroups = computed(() => (store.getters['themeGroups'] ?? []) as Group[])
    const aiSummaryRef = ref<InstanceType<typeof AiSummary> | null>(null)

    const countChildren = (parent: GroupOrTheme, count: 'themes' | 'groups'): number => {
      if (parent.type === 'group') {
        return (
          parent.children?.reduce((acc, child) => {
            const plus = count === 'groups' && child.type === 'group' ? 1 : 0
            return acc + countChildren(child, count) + plus
          }, 0) ?? 0
        )
      }
      return count === 'themes' ? 1 : 0
    }

    const expandedTopLevelGroups = computed(() => {
      const expandedGroups = props.topLevelGroups.map((group) => ({
        query: expandThemeGroup(group, props.stagedQueries),
        themeCount: countChildren(group, 'themes'),
        groupCount: countChildren(group, 'groups'),
      }))
      return expandedGroups
    })

    const lastDataAdded = computed(() => {
      if (!currentProject.value.last_data_added) return null
      return dayjs(currentProject.value.last_data_added).fromNow()
    })

    const showJSON = ref(false)

    const hasNoResults = computed(() => {
      return props.stagedQueryIsValid && props.queryStats.record_count === 0
    })

    const isAutoTheme = computed(() => {
      return !!props.selectedQueryRows?.some((q) => q.field === 'aitopic')
    })

    const npsFieldName = computed(() => {
      const schema: SchemaColumn[] = currentProject.value.schema
      const field = schema.find((field) => field.typename === 'NPS')
      return field?.name
    })

    const canAddToDashboard = computed(() => {
      return hasUnstructured(expandQuery('query', props.selectedBotanicQuery, props.savedQueries))
    })

    const dateFieldNames = computed(() => {
      return currentModel.value.dateFields.map((f) => f.name) ?? []
    })

    const verbatimsConfig = ref<VerbatimsConfig>({
      name: 'verbatims',
      visible: true,
      options: {
        perPage: 50,
        orderBy: 'most_relevant',
      },
    })

    const aiThemeTopics = computed(() => {
      const topics: string[] = []
      props.selectedQueryRows.forEach((r) => {
        if (r.field === 'aitopic' && r.operator === 'is') {
          topics.push(...(r.values as string[]))
        }
      })
      return topics
    })

    const explainDataLoading = ref(false)
    const explainData = ref<Array<ExplanationData>>([])
    const generateExplanation = async (frameIds: Array<number>) => {
      try {
        explainDataLoading.value = true
        const explainationPayload = await Query.generateVerbatimExplanation(
          currentProject.value.id,
          currentProject.value.chrysalis_ref,
          currentAnalysis.value.topic_framework_id,
          frameIds,
          aiThemeTopics.value,
        )
        explainData.value = explainationPayload ? explainationPayload.payload : []
      } catch (error) {
        console.error(error)
      } finally {
        explainDataLoading.value = false
      }
    }

    const queryUpdatedMethod = (val: QueryElement[]) => {
      if (props.selectedQuery === null) {
        emit('add-theme')
      }
      emit('set-query', val)
    }

    const scrollToTop = () => {
      scrollableContent.value?.scrollTo({
        behavior: 'smooth',
        top: 0,
        left: 0,
      })
    }

    // AI home summary loading
    const loadSummaries = () => {
      for (const ref of summaryRefs.value ?? []) {
        if (ref) {
          // check if component is visible
          const rect = ref.$el.getBoundingClientRect()
          const isVisible = rect.top < window.innerHeight && rect.bottom > 0
          if (isVisible && !ref.hasLoaded) {
            ref.fetchData()
          }
        }
      }
    }
    const isAutoThemeProject = computed(() => {
      return currentProject.value.aitopic_classification_enabled
    })
    const autoThemesHomeVisible = computed(() => {
      return isAutoThemeProject.value && !props.selectedQuery
    })
    watch(
      autoThemesHomeVisible,
      (newVal) => {
        if (newVal) {
          setTimeout(loadSummaries)
        }
      },
      {
        immediate: true,
      },
    )
    watch(
      summaryRefs,
      () => {
        loadSummaries()
      },
      {
        deep: true,
      },
    )
    const scrollThemeGroupSummaries = throttle(() => {
      if (autoThemesHomeVisible.value) {
        loadSummaries()
      }
    }, 300)

    // Concepts
    const addConceptToQuery = (concept: string) => {
      let newRow: QueryElement = {
        type: 'text',
        values: [concept],
        operator: 'includes',
      }
      queryUpdatedMethod(props.selectedQueryRows.concat(newRow))
      scrollToTop()
      clearClickedConcept()
    }
    const clickedStoryboardConcept = ref<{
      name: string | null
      x: number
      y: number
      dimmed: boolean
    }>({
      name: null,
      x: 0,
      y: 0,
      dimmed: false,
    })
    const storyboardConceptClicked = (concept: string, el: HTMLElement, dimmed: boolean) => {
      const { x, y } = el.getBoundingClientRect()
      clickedStoryboardConcept.value = {
        name: concept,
        x: x + 10,
        y: y,
        dimmed,
      }
    }
    const clearClickedConcept = () => {
      clickedStoryboardConcept.value = {
        name: null,
        x: 0,
        y: 0,
        dimmed: false,
      }
    }
    const replaceQueryWithConcept = (concept: string) => {
      let newRow: QueryElement = {
        type: 'text',
        values: [concept],
        operator: 'includes',
      }
      emit('set-query', [newRow])
      scrollToTop()
    }
    const showAddConceptAsOR = computed(() => {
      return countTextRows(props.selectedQueryRows) === 1
    })
    const addConceptAsOR = (concept: string) => {
      const rows = props.selectedQueryRows.map((r) => {
        if (r.type !== 'text') return r
        r.values = (r.values ?? []).concat([concept])
        return r
      })
      emit('set-query', rows)
      scrollToTop()
    }
    const allConcepts = computed<Concept[]>(() => {
      return currentModel.value?.topics_list
    })
    const visibleConceptStrings = computed<string[]>(() => {
      return allConcepts.value
        .filter((c) => !props.ignoredConceptNames.includes(c.name))
        .map((c) => c.name as string)
        .sort((c1, c2) => c1.localeCompare(c2))
    })

    // Modals
    const submitErrors = ref<string[]>([])
    const showDashboardModal = ref(false)
    const dashboardFormSubmit = async (query: SavedQuery) => {
      let closeModal = true

      await saveCurrentTheme(query).catch((res) => {
        store.dispatch(CLEAR_REQUEST_ERRORS)
        submitErrors.value = res.body?.non_field_errors ?? []
        closeModal = false
      })

      if (closeModal) {
        showDashboardModal.value = false
      }
    }

    // Data fetching
    const fetchedData = reactive<FetchedData>({})
    const requestWidgetData = fetchWidgetData.bind(
      null,
      toRef([] as ChrysalisFilter[]),
      fetchedData,
      currentProject.value,
      currentAnalysis.value,
      toRef(null),
    )
    const requestContextNetwork = fetchContextNetwork.bind(
      null,
      toRef([] as ChrysalisFilter[]),
      fetchedData,
      dateFieldNames,
      toRef(props, 'savedQueries'),
      currentProject.value,
      currentAnalysis.value,
    )
    const requestVerbatims = fetchVerbatims.bind(
      null,
      fetchedData,
      toRef(props, 'savedQueries'),
      currentProject.value,
      currentAnalysis.value,
    )

    // Buttons
    const resetCurrentTheme = () => {
      if (!props.selectedQuery) return
      const id = props.selectedQuery.id
      const savedQuery = props.savedQueries.find((q) => q.id === id)
      if (!savedQuery || !props.selectedQuery) return

      const rows = getQueryRows(savedQuery.query_value)
      emit('set-selected', cloneDeep(savedQuery))
      emit('set-query', rows)
    }
    const saveCurrentTheme = (partial?: Partial<SavedQuery> | undefined) => {
      if (!props.selectedQuery) return Promise.reject()
      return props.saveTheme(props.selectedQuery, partial)
    }
    const duplicateTheme = () => {
      if (!props.selectedQuery) return
      emit('rename-theme', [props.selectedQuery, true])
    }

    // Refresh widgets when the selected query changes
    watch(
      () => props.selectedBotanicQuery,
      (newVal, oldVal) => {
        if (!isEqual(newVal, oldVal)) {
          // This is currently the only widget that needs to be explicitly refreshed as the others watch
          // internally for changes. The Workbench moved to this pattern for better control and consistency,
          // the theme builder should follow suit when the Dashboard no longer relies on the old pattern.
          aiSummaryRef.value?.fetchData()
        }
      },
    )

    return {
      currentAnalysis,
      currentProject,
      currentUser,
      currentSite,
      currentModel,
      featureFlags,
      showJSON,
      comma,
      percent,
      hasDate,
      hasNPS,
      hasNoResults,
      hasSentiment,
      hasFiles,
      fetchedData,
      isAutoTheme,
      visibleConceptStrings,
      npsFieldName,
      verbatimsConfig,
      aiThemeTopics,
      generateExplanation,
      explainData,
      explainDataLoading,
      storyboardConceptClicked,
      location: QueryLocation.ThemeBuilder,
      queryUpdatedMethod,
      showAddConceptAsOR,
      addConceptAsOR,
      clickedStoryboardConcept,
      clearClickedConcept,
      addConceptToQuery,
      replaceQueryWithConcept,
      showDashboardModal,
      dashboardFormSubmit,
      canAddToDashboard,
      submitErrors,
      saveCurrentTheme,
      duplicateTheme,
      fetchKeyPhrases: requestWidgetData('phrases'),
      fetchContextNetwork: requestContextNetwork,
      fetchVerbatims: requestVerbatims,
      scrollToTop,
      resetCurrentTheme,
      themeGroups,
      aiSummaryRef,
      isAutoThemeProject,
      lastDataAdded,
      expandedTopLevelGroups,
      scrollThemeGroupSummaries,
      autoThemesHomeVisible,
      number,
    }
  },
})

export default ThemePage
</script>
<style lang="scss" scoped>
@import 'assets/kapiche.sass';

.content {
  flex: 1;
  .error {
    color: $red;
    background: lighten($red, 40%);
  }
}

.buttons {
  margin-top: auto;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  > div:nth-child(2) {
    flex: 1;
  }
}

.widget-container {
  background: $grey-light-background;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  user-select: text;

  .column {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
  .widget {
    min-width: 400px;
    flex: 1;
    &:not(:first-child) {
      margin-left: 20px !important;
    }
  }
  @media screen and (max-width: 1920px) {
    .column {
      flex-direction: column !important;
    }
    .widget {
      &:not(:first-child) {
        margin-left: 0 !important;
        margin-top: 40px !important;
      }
    }
  }
}

.top-bar {
  height: 34px;
  line-height: 34px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  ::v-deep .dropdown-menu {
    margin-top: 9px !important;
  }
  > * {
    white-space: nowrap;
    &:not(:last-child) {
      margin-right: 14px;
    }
  }
  .theme-name {
    margin-right: 20px;
    font-size: 22px;
    font-weight: 600;
    cursor: pointer;
    i {
      font-size: 9px;
      margin-left: 3px;
      position: relative;
      top: -1px;
    }
  }
}

.query-stats,
.query-controls {
  color: $text-black;
  margin: 15px 0;
  white-space: nowrap;
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 24px;
  span {
    font-size: 8px;
    margin: 0 10px;
    opacity: 0.8;
  }
  .percent {
    font-size: inherit;
    font-weight: normal;
    margin: 0 0 0 6px;
  }
}

.bf-button {
  &.staff-only {
    position: relative;
    padding-right: 70px;
    &::after {
      position: absolute !important;
      right: 4px !important;
      top: 50% !important;
      margin-top: -1em !important;
      height: 2em !important;
    }
  }
}

.query-json {
  border-radius: 4px;
  background: #fff;
  margin-block: 20px;

  textarea {
    width: 100%;
    resize: vertical;
    min-height: 200px;
    border: none;
  }
}

.storyboard {
  min-height: 800px;
  height: 100%;
  &.inTheme {
    margin-top: 20px !important;
  }
}

.red {
  color: $red;
  &:hover {
    color: $red !important;
  }
}

.discard-button {
  color: $text-grey !important;
  padding-left: 0;
  padding-right: 0;
  .icon-wrapper {
    margin-right: 4px;
  }
  span {
    background-color: $text-grey !important;
    margin-right: 8px;
  }
}

.wrapper {
  flex: 1;
  height: 100%;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 9;
  background: #f6f6f6;
}

.interaction-menu {
  background: $white;
  padding: 20px 30px;
  box-shadow: 0px 1px 5px 1px rgba(0, 1, 1, 0.1);
  z-index: 999;
  border-radius: 5px;
  width: max-content;
  ::v-deep hr {
    border: 0;
    border-top: 1px solid $grey;
    padding: 5px 0;
  }
  ::v-deep button {
    border: 0;
    padding: 0;
    display: block;
    font-size: 16px;
    line-height: 24px;
    color: $text-black;
    cursor: pointer;
    white-space: nowrap;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    &:hover {
      color: lighten($text-black, 30%);
    }
  }
}

.query-controls {
  margin-bottom: 15px;
  > label {
    font-weight: normal;
    display: flex;
    align-items: center;
  }
  .switch {
    margin-right: 6px;
  }
}

.no-results,
.loading-theme {
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: $text-grey;
}

.scope-label {
  font-size: 16px !important;
  margin: 0 0 0 6px !important;
  color: $blue;
  cursor: pointer;
  position: relative;
  top: 1.5px;
  i {
    font-size: 9px;
    margin-left: 2px;
  }
}

.ignored-concepts {
  ::v-deep .concept-item {
    filter: grayscale(1);
    opacity: 0.5;
  }
}

.breadcrumbs {
  color: $text-black;
  border-bottom: 1px solid transparentize($text-grey, 0.5);
  padding-bottom: 4px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-size: 12.5px;
  font-weight: bold;
  opacity: 0.6;
  button {
    font-weight: bold;
    text-transform: uppercase;
    background: none;
    color: $text-grey;
    border: none;
    padding: 0;
    cursor: pointer;
    &:hover {
      color: $text-black;
    }
  }
  span {
    margin: 0 10px;
  }
}

.loading-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auto-theme-home {
  > h1 {
    text-align: center;
    font-size: 24px;
  }

  .auto-theme-home-stats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 80px;

    > div {
      text-align: center;
      h2 {
        font-size: 12px;
        text-transform: uppercase;
        margin: 6px 0 0 0;
        font-weight: 600;
      }
      p {
        font-size: 22px;
        margin: 0;
        color: $purple;
      }
    }
  }
}

.theme-group-summaries {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
</style>
