<template>
  <div>
    <div class="ui segments borderless">
      <!-- Dashboard view -->
      <div v-if="headerIcon" class="ui segment borderless">
        <div class="segment-header">
          <img
            class="header-icon"
            src="../../../../../assets/img/dashboards/dash-context-network.svg"
            alt="Network icon"
          />
          <div class="header-text">Context Network</div>
          <div v-if="headerTools" class="dashboard-icons">
            <download-export-button
              :name="`${currentDashboard.name}-Context Network`"
              :get-el="getNetworkEl"
              :get-svg-export-config="getExportConfig"
              short-name="Context Network"
            >
            </download-export-button>
          </div>
        </div>
      </div>
      <!-- Query screen view -->
      <div v-else class="ui clearing segment header">
        <span class="left floated title">Context Network</span>
        <span v-if="headerTools" class="icons right floated">
          <help-icon :content="networkHelp"></help-icon>
          <download-export-button
            :name="`${currentAnalysis.name}-Context Network`"
            :get-el="getNetworkEl"
            :get-svg-export-config="getExportConfig"
            short-name="Context Network"
          >
          </download-export-button>
        </span>
      </div>

      <div class="ui segment body no-padding">
        <div class="network-wrapper" :style="`height:${height}px`">
          <div v-if="disabled" class="msg">
            <div>
              Context Networks are temporarily disabled due to a performance issue.<br />
              We are currently investigating.
            </div>
          </div>
          <!-- Network visualisation -->
          <template v-else-if="model && !isInsufficientData">
            <network-layout
              v-bind="$attrs"
              ref="networkLayout"
              :height="height"
              :model="model"
              :draw-hulls="false"
              :clickable-nodes="clickableNodes"
              @loaded="isNetworkDrawn = true"
              @concept-selected="$emit('concept-selected', $event)"
            ></network-layout>
          </template>

          <!-- No data -->
          <template v-else-if="!loading && isInsufficientData">
            <div class="no-data">
              <div>{{ noDataMsg }}</div>
            </div>
          </template>
        </div>
      </div>

      <!-- Loading dimmer -->
      <div
        id="network-dimmer"
        class="ui dimmer inverted"
        :class="{ active: loading || (!isNetworkDrawn && !isInsufficientData) }"
      >
        <div class="ui loader"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

import NetworkLayout from '../widgets/NetworkLayout.vue'
import DownloadExportButton from './DownloadExportButton.vue'
import HelpIcon from './HelpIcon.vue'

const MIN_CONCEPTS = 3 // minimum number of concepts to render the network

export default defineComponent({
  components: {
    DownloadExportButton,
    HelpIcon,
    NetworkLayout,
  },
  props: {
    clickableNodes: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    headerIcon: { type: Boolean, default: false },
    headerTools: { type: Boolean, default: true },
    height: { type: Number, default: 450 },
    model: { type: Object, default: null },
    noDataMsg: { type: String, default: 'Not enough data for network' },
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      isNetworkDrawn: false,
      networkHelp:
        "<p>The context of a query is depicted in this visualization. It shows all of the terms that have a meaningful relationship with the terms or concepts in your query and the strength of the relationship between them. If your query doesn't contain any terms or concepts, the network will simply show the most frequent terms and concepts from the query results.</p>" +
        '<p>Concepts from your query will appear in their storyboard color. Terms from your query will appear in light grey. Circle sizes are based on the frequency of that term/concept. Clicking on a term/concept will add it to the query.</p>' +
        '<p>The lines linking nodes indicate a relationship between the terms. The thicker the line linking two nodes the stronger the relationship between those two terms. If there is no line linking two terms, then the relationship between those two terms is too weak to consider or there is no relationship.</p>' +
        '<p>By default, not all lines are drawn. Instead, only the thickest lines are drawn to connect every node. Hover a node to see all of its relationships.</p>',
    }
  },
  computed: {
    isInsufficientData() {
      const len = this.model?.concepts?.length || 0
      return len < MIN_CONCEPTS
    },
    ...mapGetters(['currentAnalysis', 'currentDashboard']),
  },
  watch: {
    model: function () {
      // reset state after underlying data changes
      this.isNetworkDrawn = false
    },
  },
  methods: {
    getExportConfig() {
      return this.$refs.networkLayout.getExportConfig()
    },
    getNetworkEl() {
      return this.$el.querySelector('.network-wrapper svg')
    },
  },
})
</script>

<style lang="sass" scoped>
.header .left.floated
  float: left
.header
  padding: 0 !important
  .header-buttons
    padding: 10px 20px
    .button.active, .button:hover
      background: rgba(149, 166, 172, 1)
      color: white
    .button
      background: rgba(149, 166, 172, 0.15)
      border: 1px solid rgba(149, 166, 172, 0.4) !important
      color: rgb(149, 166, 172)
      font-weight: bold
      width: 115px
      &:first-child
        border-right: 0 !important
    .ui.buttons .button:first-child
      border-top-left-radius: .28571429rem
      border-bottom-left-radius: .28571429rem
    .ui.buttons .button:last-child
      border-top-right-radius: .28571429rem
      border-bottom-right-radius: .28571429rem
.dashboard-icons
  position: absolute
  right: 20px
  top: 20px

div.msg
  display: table
  width: 100%
  height: 100%
  > div
    display: table-cell
    text-align: center
    vertical-align: middle
    font-size: 18px
    color: #95a6ac
</style>
