<template>
  <widget-frame
    ref="root"
    :zoomed="isZoomed"
    :masked="masked"
    :is-loading="isLoading"
    :dev-mode="devMode"
    :has-errored="hasErrored"
    :banner="banner"
    class="segment-correlation"
    @resize="setChartDimensions"
  >
    <!--======================== ACTIONS -->
    <template #actions>
      <download-export-button
        v-if="isZoomed"
        :name="`${exportName} - Top Correlations`"
        short-name="Top Correlations"
        :get-csv-data="getCsvData"
        :is-loading="isLoading"
      ></download-export-button>
      <router-link v-if="!isZoomed && zoomToRoute" class="widget-action expand" :to="zoomToRoute">
        <i class="kapiche-icon-fullscreen"></i>
      </router-link>
      <a :href="CONST.widget_help_links.segment_correlation" class="widget-action help" target="_blank">
        <i class="kapiche-icon-info"></i>
      </a>
    </template>
    <!--======================== ICON -->
    <template #icon>
      <img class="header-icon" :src="icon" alt="SegmentCorrelation Icon" />
    </template>
    <!--======================== HEADING -->
    <template #header> Top Correlations </template>
    <!--======================== MENU -->
    <template #menu>
      <div class="menu-list">
        <widget-menu :menus="menus" :vertical="isZoomed" :bound="$el" @onSelect="setMenuSelection" />
      </div>
    </template>
    <!--======================== DEV PANEL -->
    <template #devPanel>
      <div>
        Start: {{ new Date(startTime) }}<br />
        Done: {{ new Date(doneTime) }}<br />
        Elapsed: {{ (doneTime - startTime) / 1000 }} seconds<br />
        Status: {{ status }}<br />
        Error: {{ error }}
        <hr />
        <h2>this.props</h2>
        <code style="white-space: pre">
          {{ JSON.stringify($props, null, 2) }}
        </code>
        <hr />
        <h2>this.data</h2>
        <code style="white-space: pre">
          {{ JSON.stringify($data, null, 2) }}
        </code>
      </div>
    </template>
    <!--======================== ERROR PANEL -->
    <template #error-panel>
      <div v-if="error" class="error-panel">
        <h3>
          <img class="errorIcon" :src="errorIcon" alt="widget error icon" />
          Opps, something went wrong while loading this widget.
        </h3>
        <div class="action">
          Try
          <button @click.stop="reload">reloading this widget</button>
          or
          <button @click.stop="refresh">reloading the page</button>
        </div>
        <div class="action">
          <button @click.stop="contact">Contact support</button>
          if the problem persists.
        </div>
        <div v-if="userError" class="message">
          {{ userError }}
        </div>
      </div>
    </template>
    <!--======================== CONTENT -->
    <template v-if="dataSetTooLarge" #content>
      <div class="warning">
        Not yet available for datasets this large or with a such a large amount of fields.
        <br />
        - If your dataset is over 1,000,000 records. This is not currently supported.
        <br />
        - If your dataset is over 400,000 records and has more than 20 fields.
        <br />
        <template v-if="fieldSelection.length > 20">
          Please deselect {{ fieldSelection.length - 20 }} fields to continue.
        </template>
      </div>
    </template>
    <template v-else-if="groupbyNotSupported" #content>
      <div class="warning">Group by is not yet supported for this widget.</div>
    </template>
    <template v-else-if="tooManyFields" #content>
      <div class="warning">
        We only allow correlations to be calculated between {{ maxFields }} fields or less.
        <br />
        Please deselect {{ fieldSelection.length - maxFields }} fields to continue.
      </div>
    </template>
    <template v-else-if="hasValidData" #content>
      <div ref="wrapper" class="table-wrapper" @mousemove="updateMousePosition">
        <table class="table">
          <thead>
            <tr>
              <th>
                <div
                  class="table-header sortable"
                  :class="{ sorted: sort && sort.field === 'freq' }"
                  @click="sortFreqClick"
                >
                  Freq
                  <up-down
                    :up="sort && sort.field === 'freq' && sort.asc"
                    :down="sort && sort.field === 'freq' && !sort.asc"
                  />
                </div>
              </th>
              <th>
                <div class="table-header">Pair</div>
              </th>
              <th v-if="hasNps">
                <div
                  class="table-header sortable"
                  :class="{ sorted: sort && sort.field === 'impact' }"
                  @click="sortImpactClick"
                >
                  {{ sort.absolute ? 'Abs ' : '' }}Impact on NPS
                  <up-down
                    :up="sort && sort.field === 'impact' && (sort.asc || sort.absolute)"
                    :down="sort && sort.field === 'impact' && (!sort.asc || sort.absolute)"
                  />
                </div>
              </th>
              <th>
                <div
                  class="table-header sortable"
                  :class="{ sorted: sort && sort.field === 'odds_ratio' }"
                  @click="sortOddsRatioClick"
                >
                  <div>Odds Ratio</div>
                  <up-down
                    :up="sort && sort.field === 'odds_ratio' && (sort.asc || sort.absolute)"
                    :down="sort && sort.field === 'odds_ratio' && (!sort.asc || sort.absolute)"
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <table-row
              v-for="(row, i) in dataRows"
              ref="tableRows"
              :key="`${row.a_label}${row.a_name}_${row.b_label}${row.b_name}`"
              :clicked="clickedRow === i"
              :row="row"
              :has-nps="hasNps"
              @click="rowClick(i)"
              @menu-closed="rowClick(null)"
              @open-pivot-table="openPivotTable"
              @exclude-field="excludeField"
              @toggle-filters="toggleFilters"
              @drilldown="drilldown"
              @row-hover="toolTipRow = row"
              @end-hover="toolTipRow = null"
            />
          </tbody>
        </table>
        <floating-panel
          v-if="clickedRow === null && toolTipRow"
          :visible="true"
          :y="mouseY"
          :x="mouseX"
          :bound="$refs['wrapper']"
        >
          <data-tool-tip v-bind="toolTipContentV2">
            <template #title>
              <div class="tooltip-title">
                <div>
                  <b>A:</b> {{ toolTipRow.a_label }}: {{ toolTipRow.a_name }}
                  <span v-if="showGroupLabels && getThemeGroupLabel(toolTipRow, 'a')" class="group-tag">
                    [{{ getThemeGroupLabel(toolTipRow, 'a') }}]
                  </span>
                </div>
                <div>
                  <b>B:</b> {{ toolTipRow.b_label }}: {{ toolTipRow.b_name }}
                  <span v-if="showGroupLabels && getThemeGroupLabel(toolTipRow, 'b')" class="group-tag">
                    [{{ getThemeGroupLabel(toolTipRow, 'b') }}]
                  </span>
                </div>
              </div>
            </template>
          </data-tool-tip>
        </floating-panel>
      </div>
    </template>
    <template v-else #content>
      <widget-message-panel>
        <template #title>
          <span>No Data</span>
        </template>
        <template #message>
          <span>There is not sufficient data to display this widget.</span>
        </template>
      </widget-message-panel>
    </template>

    <template v-if="showFooter" #footer>
      <router-link v-if="!isZoomed" :to="zoomToRoute" class="footer-link"> Click to view more pairs </router-link>
      <div v-else>
        <small>Showing top {{ nExpandedRows }} pairs</small>
      </div>
    </template>
  </widget-frame>
</template>

<script lang="ts">
import { ComputedRef, PropType, computed, defineComponent, inject } from 'vue'
import { cloneDeep, isEqual, debounce } from 'lodash'
import DownloadExportButton from 'components/project/analysis/results/widgets/DownloadExportButton.vue'
import WidgetMenu from 'components/DataWidgets/WidgetMenu/WidgetMenu.vue'
import { WidgetMenuOptions } from 'types/components/WidgetMenu.types'
import WidgetFrame from 'components/widgets/WidgetFrame/WidgetFrame.vue'
import { fetch_correlation_data_v2 } from 'src/store/modules/data/api'
import icon from 'assets/img/dashboards/dash-correlation.svg'
import errorIcon from 'assets/icons/alert-bubble.svg'
import { WidgetConfig } from 'types/DashboardTypes'
import UpDown from 'components/widgets/UpDown/UpDown.vue'
import TableRow from './TableRow.vue'
import { SchemaColumn } from 'types/SchemaTypes'
import FloatingPanel from 'components/widgets/FloatingPanel/FloatingPanel.vue'
import DataToolTip from 'components/DataWidgets/DataToolTip/DataToolTip.vue'
import { DataToolTipInterface } from 'types/components/DataToolTip.types'
import WidgetMessagePanel from 'components/widgets/WidgetMessagePanel/WidgetMessagePanel.vue'
import { useStore } from 'vuex'
import { ExpandedGroup } from 'src/pages/dashboard/Dashboard.utils'
import { DashboardSavedQuery, ChrysalisQueryType } from 'types/Query.types'

export interface Row {
  a_id: string
  b_id: string
  a_label: string
  b_label: string
  a_name: string
  b_name: string
  a_type: 'categorical' | 'numeric' | 'theme' | 'theme group'
  b_type: 'categorical' | 'numeric' | 'theme' | 'theme group'
  a_freq: number
  b_freq: number
  pair_freq: number
  pmi: number
}

/* Example of V2

        {
            "group1": {
                "type": "theme",
                "field": "Theme",
                "value": "Products",
                "count": 649,
                "nps": 26.964560862865948,
                "impact": -1.0160394138294677
            },
            "group2": {
                "type": "categorical",
                "field": "NPS Category",
                "value": "Promoter",
                "count": 2577,
                "nps": 100,
                "impact": 74.07553567774562
            },
            "intersection": {
                "odds_ratio": 0.83017294162805,
                "npmi": -0.029039477411278602,
                "count": 316,
                "p_value": 0.02720637877318542,
                "impact": 4.590375610656
            }
        },

We have to describe the TypeScript type for this structure.
*/
interface ResultV2 {
  group1: {
    type: 'theme' | 'categorical' | 'numeric' | 'theme group'
    field: string
    value: string
    count: number
    nps: number
    impact: number
  }
  group2: {
    type: 'theme' | 'categorical' | 'numeric' | 'theme group'
    field: string
    value: string
    count: number
    nps: number
    impact: number
  }
  intersection: {
    odds_ratio: number
    npmi: number
    count: number
    p_value: number
    impact: number
  }
}

const roundValue = (num: number): number => {
  return Math.round(num * 100) / 100
}

const makeDatumV2 = (
  data: ResultV2,
  themeNameMap: Record<number, string>,
  themeGroupNameMap: Record<number, string>,
): Row => {
  const a = data.group1
  const b = data.group2
  const freq = data?.intersection
  const pmi = parseFloat(freq.npmi?.toFixed(3) || 'NaN')
  let a_name = a.value
  let b_name = b.value
  let a_label = a.field
  let b_label = b.field

  if (a.type === 'theme') {
    const id = Number(a.value.replace('q_', ''))
    a_name = themeNameMap[id] || a.value
  }

  if (b.type === 'theme') {
    const id = Number(b.value.replace('q_', ''))
    b_name = themeNameMap[id] || b.value
  }

  if (a.value.includes('g_')) {
    const id = Number(a.value.replace('g_', ''))
    a_name = themeGroupNameMap[id] || a.value
    a.type = 'theme group'
    a_label = 'Theme Group'
  }

  if (b.value.includes('g_')) {
    const id = Number(b.value.replace('g_', ''))
    b_name = themeGroupNameMap[id] || b.value
    b.type = 'theme group'
    b_label = 'Theme Group'
  }

  return {
    a_id: a.value,
    b_id: a.value,
    a_label: a_label,
    b_label: b_label,
    a_name,
    b_name,
    a_type: a.type,
    b_type: b.type,
    a_freq: a.count,
    b_freq: b.count,
    pair_freq: freq.count,
    pmi,

    a_impact: roundValue(a.impact ?? NaN),
    b_impact: roundValue(b.impact ?? NaN),

    a_nps: roundValue(a.nps ?? NaN),
    b_nps: roundValue(b.nps ?? NaN),

    odds_ratio: roundValue(freq.odds_ratio),
    p_value: roundValue(freq.p_value),
    pair_impact: roundValue(freq.impact ?? NaN),
  }
}

export const getOddsRatioLabel = (value: number) => {
  if (value >= 2.0) return 'High positive correlation'
  if (value >= 1.2) return 'Positive correlation'
  if (value > 1.0) return 'Little or no correlation'
  if (value > -0.5) return 'Negative correlation'
  return 'High negative correlation'
}

export const getOddsRatioColour = (value: number) => {
  if (value >= 2.0) return '#21ba45'
  if (value >= 1.2) return '#11acdf'
  if (value > 1.0) return '#7F7F7F'
  if (value > -0.5) return '#f89516'
  return '#ee3824'
}

export const getNPMILabel = (npmi: number) => {
  if (npmi >= 0.5) return 'High positive correlation'
  if (npmi >= 0.25) return 'Positive correlation'
  if (npmi > -0.25) return 'Little or no correlation'
  if (npmi > -0.5) return 'Negative correlation'
  return 'High negative correlation'
}

export const getNPMIColour = (npmi: number) => {
  if (npmi >= 0.5) return '#21ba45'
  if (npmi >= 0.25) return '#11acdf'
  if (npmi > -0.25) return '#7F7F7F'
  if (npmi > -0.5) return '#f89516'
  return '#ee3824'
}

interface Sort {
  field: 'freq' | 'pmi' | 'odds_ratio' | 'impact'
  asc: boolean
  absolute?: boolean | null
}

const defaultPairTypes = ['theme/categorical', 'theme/numeric']

export default defineComponent({
  components: {
    DownloadExportButton,
    WidgetFrame,
    WidgetMenu,
    TableRow,
    UpDown,
    FloatingPanel,
    DataToolTip,
    WidgetMessagePanel,
  },
  props: {
    data: { type: Object, required: false, default: null },
    exportName: { type: String, required: false, default: '' },
    devMode: { type: Boolean, required: false, default: false },
    zoomToRoute: { type: Object, required: false, default: null },
    isZoomed: { type: Boolean, required: false, default: false },
    themeGroups: { type: Array as PropType<ExpandedGroup[]>, required: false, default: () => [] },
    banner: { type: Object, default: () => null, required: false },
    masked: { type: Boolean, required: false, default: false },
    queries: { type: Array<DashboardSavedQuery>, required: false, default: () => [] },
    schema: { type: Array, required: true },
    config: { type: Object as PropType<WidgetConfig<'segment-correlation'> | null>, required: false, default: null },
    documentCount: { type: Number, required: false, default: null },
    totalDocsCount: { type: Number, required: false, default: null },
    documentQuery: { type: Number, required: false, default: null },
    /** dashboardId (for analytics) */
    dashboardId: { type: Number, required: true },
    toQueryRoute: { type: Object, required: false, default: null },
    groupbyNotSupported: { type: Boolean, default: false },
    hasNps: { type: Boolean, required: false, default: false },
  },
  setup(props) {
    const store = useStore()
    const themeToGroupNameMap = computed<Record<number, string>>(() => store.getters['themeToGroupNameMapById'])
    const themeGroupNameMap = props.themeGroups.reduce((obj, g) => {
      obj[g.id] = g.name
      return obj
    }, {})

    const getThemeGroupLabel = (row: Row, side: 'a' | 'b'): string | null => {
      if (row[`${side}_type`] !== 'theme') return null
      const id = Number(row[`${side}_id`].replace('q_', ''))
      return themeToGroupNameMap.value[id] ?? null
    }

    const showGroupLabels = inject<ComputedRef<boolean>>(
      'showGroupLabels',
      computed(() => true),
    )

    return {
      themeToGroupNameMap,
      getThemeGroupLabel,
      themeGroupNameMap,
      showGroupLabels,
    }
  },
  data() {
    return {
      icon,
      errorIcon,
      hasErrored: false,
      width: 0,
      height: 0,
      fieldOptions: [],
      fieldSelection: [],
      minPairFreq: 50,
      minCorrelation: -0.99,
      maxCorrelation: 0.99,
      pairTypes: defaultPairTypes,
      thresholdFilters: ['dependent'],
      sort: { field: 'pmi', asc: false, absolute: true } as Sort,
      maxFields: 80,
      clickedRow: null as number | null,
      toolTipRow: null as Row | null,
      mouseX: 0,
      mouseY: 0,
      nCondensedRows: 6,
      nExpandedRows: 100,
    }
  },
  computed: {
    themeNameMap() {
      return this.queries.reduce(
        (p, c) => {
          p[c.id] = c.name
          return p
        },
        {} as Record<number, string>,
      )
    },
    toolTipContentV2() {
      if (!this.toolTipRow) return null

      return {
        title: null,
        action: 'Click for explore options',
        data: [
          {
            longText: {
              text: `B is ${this.toolTipRow.odds_ratio}x more likely to ${['theme', 'theme group'].includes(this.toolTipRow.a_type) ? 'mention' : 'occur with'} A`,
              style: { 'font-weight': 'bold', 'color': getOddsRatioColour(this.toolTipRow.odds_ratio) },
            },
          },
          {
            label: 'p-value:',
            value: {
              text: `${this.toolTipRow.p_value < 0.01 ? '<0.01' : this.toolTipRow.p_value}`,
              style: { 'font-weight': 'bold' },
            },
          },
          ...(this.hasNPS ?
            [
              {
                label: 'Pair Impact on NPS',
                value: {
                  text: `${this.toolTipRow.pair_impact}`,
                  style: { 'font-weight': 'bold' },
                },
              },
            ]
          : []),
          {
            label: 'Frequency of A (#/%):',
            value: {
              text: `${this.toolTipRow.a_freq} / ${roundValue((this.toolTipRow.a_freq / this.documentCount) * 100)}%`,
              style: { 'font-weight': 'bold' },
            },
          },
          {
            label: 'Frequency of B (#/%):',
            value: {
              text: `${this.toolTipRow.b_freq} / ${roundValue((this.toolTipRow.b_freq / this.documentCount) * 100)}%`,
              style: { 'font-weight': 'bold' },
            },
          },
          {
            label: 'Intersection frequency of A,B:',
            value: {
              text: `${this.toolTipRow.pair_freq}`,
              style: { 'font-weight': 'bold' },
            },
          },
          {
            label: 'Percentage of intersection in A:',
            value: {
              text: `${roundValue((this.toolTipRow.pair_freq / this.toolTipRow.a_freq) * 100)}%`,
              style: { 'font-weight': 'bold' },
            },
          },
          {
            label: 'Percentage of intersection in B:',
            value: {
              text: `${roundValue((this.toolTipRow.pair_freq / this.toolTipRow.b_freq) * 100)}%`,
              style: { 'font-weight': 'bold' },
            },
          },
        ] as DataToolTipInterface[],
      }
    },
    hasValidData(): boolean {
      return this.dataRows.length > 0
    },
    dataRows(): Row[] {
      let datumMaker
      let results
      let data
      results = cloneDeep(this.data?.results ?? []) as ResultV2[]
      datumMaker = makeDatumV2
      data = results.map((r) => datumMaker(r, this.themeNameMap, this.themeGroupNameMap))
      const limit = this.isZoomed ? this.nExpandedRows : this.nCondensedRows
      return data.slice(0, limit)
    },
    showFooter() {
      const limit = this.isZoomed ? this.nExpandedRows : this.nCondensedRows
      return (this.data?.results ?? []).length > limit - 1
    },
    minPairFreqofTotal() {
      if (!this.documentQuery) return 30
      return Math.round(this.documentQuery * 0.005)
    },
    menus(): WidgetMenuOptions[] {
      const miscfilters = {
        name: 'Misc. Filters',
        selection: `${this.minCorrelation} – ${this.maxCorrelation}`,
        options: [
          [
            {
              title: 'Minimum Odds Ratio',
              type: 'inputNumber',
              // Without having something in the options list,
              // the dropdown shows "No Data"
              options: ['inputNumber'],
              settings: {
                min: 1.0,
                step: 0.1,
                max: 1000,
              },
              showSelected: true,
              selected: this.minCorrelation,
            },
            {
              title: 'Maximum Odds Ratio',
              type: 'inputNumber',
              // Without having something in the options list,
              // the dropdown shows "No Data"
              options: ['inputNumber'],
              settings: {
                min: 1.0,
                step: 0.1,
                max: 1000,
              },
              showSelected: true,
              selected: this.maxCorrelation,
            },
            {
              title: 'Other Filters',
              type: 'checkbox',
              options: [{ label: 'Coverage < 100%', value: 'dependent' }],
              showSelected: true,
              selected: this.thresholdFilters,
            },
          ],
        ],
      }
      return [
        {
          name: 'Fields',
          selection: `${this.fieldSelection.length} selected`,
          options: [
            [
              {
                type: 'checkbox',
                options: this.fieldOptions,
                showSelected: true,
                selected: this.fieldSelection,
              },
            ],
          ],
        },
        {
          name: 'Min Pair Freq (#)',
          selection: this.minPairFreq,
          options: [
            [
              {
                type: 'inputNumber',
                // Without having something in the options list,
                // the dropdown shows "No Data"
                options: ['inputNumber'],
                settings: {
                  min: 0,
                  step: 10,
                },
                showSelected: true,
                selected: this.minPairFreq,
              },
            ],
          ],
        },
        {
          name: 'Pair Types',
          selection: `${this.pairTypes.length} selected`,
          options: [
            [
              {
                type: 'checkbox',
                options: [
                  'theme/theme',
                  'theme/categorical',
                  'theme/numeric',
                  'theme group/categorical',
                  'theme group/numeric',
                  'categorical/categorical',
                  'categorical/numeric',
                  'numeric/numeric',
                ],
                showSelected: true,
                selected: this.pairTypes,
              },
            ],
          ],
        },
        miscfilters,
      ]
    },
    tooManyFields() {
      return this.fieldSelection.length > this.maxFields
    },
    dataSetTooLarge() {
      return this.totalDocsCount > 1_000_000 || (this.totalDocsCount > 400_000 && this.fieldSelection.length > 20)
    },
    isLoading() {
      return this.data === null && !this.hasValidData
    },
  },
  watch: {
    config: {
      deep: true,
      handler(newVal, oldVal) {
        if (!isEqual(oldVal, newVal)) {
          this.setOptionsFromConfig()
        }
      },
    },
    schema: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        if (!isEqual(oldVal, newVal)) {
          this.updateFieldOptions()
        }
      },
    },
    data: {
      deep: true,
      handler(newVal, oldVal) {
        if (!isEqual(oldVal, newVal)) {
          this.clickedRow = null
        }
      },
    },
    queries: {
      deep: true,
      handler(newVal, oldVal) {
        if (!isEqual(oldVal, newVal)) {
          this.fetchData()
        }
      },
    },
    sort: {
      deep: true,
      handler(newVal, oldVal) {
        if (!isEqual(oldVal, newVal)) {
          this.fetchData()
        }
      },
    },
    fieldSelection() {
      this.fetchData()
    },
    minPairFreq: debounce(function () {
      this.fetchData()
    }, 500),
    pairTypes: debounce(function () {
      this.fetchData()
    }, 500),
    thresholdFilters: debounce(function () {
      this.fetchData()
    }, 500),
    minCorrelation: debounce(function () {
      this.fetchData()
    }, 500),
    maxCorrelation: debounce(function () {
      this.fetchData()
    }, 500),
  },
  mounted() {
    this.setOptionsFromConfig()
    this.minCorrelation = 1.0
    this.maxCorrelation = 1000.0
    this.sort = { field: 'odds_ratio', asc: false, absolute: null } as Sort
    this.fetchData()
  },
  created() {
    if (typeof window !== 'undefined') {
      document.addEventListener('click', this.clickedOutside)
    }
  },
  beforeUnmount() {
    if (typeof window !== 'undefined') {
      document.removeEventListener('click', this.clickedOutside)
    }
  },
  methods: {
    clickedOutside(e: MouseEvent) {
      if (this.clickedRow === null) return
      const contained = this.$refs.wrapper.contains(e.target)
      if (!contained) {
        this.clickedRow = null
      }
    },
    getChartEl(): SVGElement | null {
      return this.$el.querySelector('svg')
    },
    updateConfig() {
      const updated: typeof this.config = Object.assign({}, this.config, {
        options: {
          fieldSelection: this.fieldSelection,
          minPairFreq: this.minPairFreq,
          pairTypes: this.pairTypes,
          thresholdFilters: this.thresholdFilters,
          minCorrelation: this.minCorrelation,
          maxCorrelation: this.maxCorrelation,
        },
      })
      this.$emit('config-changed', updated)
    },
    setOptionsFromConfig() {
      this.fieldSelection = this.config?.options?.fieldSelection ?? this.fieldOptions
      this.minPairFreq = this.config?.options?.minPairFreq ?? this.minPairFreqofTotal
      this.pairTypes = this.config?.options?.pairTypes ?? defaultPairTypes
      this.thresholdFilters = this.config?.options?.thresholdFilters ?? ['dependent']
      this.minCorrelation = this.config?.options?.minCorrelation ?? -0.99
      this.maxCorrelation = this.config?.options?.maxCorrelation ?? 0.99
    },
    setChartDimensions(width: number, height: number): void {
      this.width = width
      this.height = height
    },
    async fetchData(force = false) {
      this.hasErrored = false
      if (this.tooManyFields) return
      if (this.dataSetTooLarge) return
      const fetcher = fetch_correlation_data_v2

      let hasThemes: boolean = false
      for (let pt of this.pairTypes) {
        hasThemes = pt.includes('theme') && !pt.includes('theme group') ? true : false
        if (hasThemes) break
      }
      let hasThemeGroups: boolean = false
      for (let pt of this.pairTypes) {
        hasThemeGroups = pt.includes('theme group') ? true : false
        if (hasThemeGroups) break
      }
      const pairTypes = hasThemeGroups ? this.pairTypes.map((pt) => pt.replace('theme group', 'theme')) : this.pairTypes
      let queries: ChrysalisQueryType[] = []
      queries =
        hasThemes ?
          this.queries.map((q: any) => ({
            name: `q_${q.id}`,
            value: q.query_value,
          }))
        : queries
      queries =
        hasThemeGroups ?
          queries.concat(
            this.themeGroups.map((g: any) => ({
              name: `g_${g.id}`,
              value: g.query_value,
            })),
          )
        : queries

      try {
        this.$emit(
          'requires',
          'segment-correlation',
          {
            fields: this.fieldSelection,
            queries: queries,
            min_pair_frequency: this.minPairFreq,
            pair_combinations: pairTypes,
            threshold_filters: this.thresholdFilters,
            min_correlation: this.minCorrelation,
            max_correlation: this.maxCorrelation,
            sort: this.sort,
            limit: this.isZoomed ? this.nExpandedRows : this.nCollapsedRows,
          },
          force,
          true,
          fetcher,
        )
      } catch (e) {
        this.hasErrored = true
      }
    },
    setMenuSelection(name: string, [title, val]: [string, string]) {
      if (name === 'Fields') {
        this.fieldSelection = val
        this.$analytics.track.correlationsWidget.changeFields(name, val)
      }
      if (name === 'Min Pair Freq (#)') {
        this.minPairFreq = val
        this.$analytics.track.correlationsWidget.changeMinPairFreq(name, val)
      }
      if (name === 'Pair Types') {
        this.pairTypes = val
        this.$analytics.track.correlationsWidget.changePairTypes(name, val)
      }
      if (name === 'Misc. Filters') {
        if (title === 'Minimum Correlation') {
          this.minCorrelation = val
        }
        if (title === 'Maximum Correlation') {
          this.maxCorrelation = val
        }
        if (title === 'Other Filters') {
          this.thresholdFilters = val
        }
        // this.$analytics.track.correlationsWidget.changeThresholds(name, val)
        // TODO
      }
      this.updateConfig()
    },
    rowClick(index: number) {
      if (index === this.clickedRow) {
        this.clickedRow = null
      } else {
        this.clickedRow = index
      }
    },
    closeRowMenu() {
      this.clickedRow = null
    },
    updateFieldOptions() {
      this.fieldOptions = (this.schema as SchemaColumn[])
        .filter((row) => !['TEXT', 'DATE', 'DATE_TIME'].includes(row.typename))
        .map((row) => row.name)
    },
    sortFreqClick() {
      this.sort = {
        field: 'freq',
        asc: this.sort?.field === 'freq' ? !this.sort.asc : false,
        absolute: null,
      }
      this.$analytics.track.correlationsWidget.changeSort('freq', this.sort.asc, this.sort.absolute)
    },
    sortImpactClick() {
      if (this.sort?.field !== 'impact') {
        this.sort = {
          field: 'impact',
          asc: false,
          absolute: true,
        }
      } else {
        let asc
        let absolute

        if (this.sort.absolute) {
          // Absolute -> Positive
          asc = false
          absolute = false
        } else if (!this.sort.asc) {
          // Positive -> Negative
          asc = true
          absolute = false
        } else if (this.sort.asc) {
          // Negative -> Absolute
          asc = false
          absolute = true
        }

        this.sort = {
          field: 'impact',
          asc,
          absolute,
        }
      }
    },
    sortOddsRatioClick() {
      if (this.sort?.field !== 'odds_ratio') {
        this.sort = {
          field: 'odds_ratio',
          asc: false,
          absolute: null,
        }
      }
      this.sort = {
        field: 'odds_ratio',
        asc: !this.sort.asc,
        absolute: null,
      }
      this.$analytics.track.correlationsWidget.changeSort('odds_ratio', this.sort.asc, this.sort.absolute)
    },
    sortPmiClick() {
      if (this.sort?.field !== 'pmi') {
        this.sort = {
          field: 'pmi',
          asc: false,
          absolute: true,
        }
      } else {
        let asc
        let absolute

        if (this.sort.absolute) {
          // Absolute -> Positive
          asc = false
          absolute = false
        } else if (!this.sort.asc) {
          // Positive -> Negative
          asc = true
          absolute = false
        } else if (this.sort.asc) {
          // Negative -> Absolute
          asc = false
          absolute = true
        }

        this.sort = {
          field: 'pmi',
          asc,
          absolute,
        }
      }
      this.$analytics.track.correlationsWidget.changeSort('pmi', this.sort.asc, this.sort.absolute)
    },
    excludeField(field: string) {
      this.fieldSelection = this.fieldSelection.filter((f: string) => f !== field)
      this.$analytics.track.correlationsWidget.excludeFromActionMenu(field)
      this.updateConfig()
      this.closeRowMenu()
    },
    toggleFilters(segments: [string, string][]) {
      for (const [field, segment] of segments) {
        this.$emit('toggle-filter', field, segment)
      }
      this.$analytics.track.correlationsWidget.filterFromActionMenu(segments)
      this.closeRowMenu()
    },
    openPivotTable(col: string, row: string) {
      if (col === 'Theme') col = 'Themes'
      if (row === 'Theme') row = 'Themes'
      this.$analytics.track.correlationsWidget.openPivotTableFromActionMenu(col, row)
      this.closeRowMenu()
      this.$emit('go-to-pivot', col, row)
    },
    updateMousePosition(event: MouseEvent) {
      this.mouseX = event.clientX
      this.mouseY = event.clientY
    },
    drilldown(name: string) {
      const query = this.queries.find((q: any) => q.name === name)
      if (query) {
        this.$emit('go-to-theme', query.id)
        this.$emit('scroll-to-top')
        this.$analytics.track.correlationsWidget.drilldownTheme(name)
      }
    },
    getCsvData() {
      this.$analytics.track.correlationsWidget.exportCSV()
      return this.dataRows.map((row: Row) => ({
        'A': `${row.a_label}: ${row.a_name}`,
        'B': `${row.b_label}: ${row.b_name}`,
        'A Frequency': row.a_freq,
        'B Frequency': row.b_freq,
        'Pair Frequency': row.pair_freq,
        'NPMI': row.pmi,
      }))
    },
    // error panel items
    refresh() {
      window.location.reload()
    },
    reload() {
      this.fetchData(true)
    },
    contact() {
      try {
        window.Intercom('show')
      } catch (e) {
        console.warn('intercom show failed')
      }
    },
  },
})
</script>

<style lang="sass" scoped>
@import '~assets/kapiche.sass'

.error-panel
  display: flex
  flex-direction: column
  align-items: center
  font-size: 16px
  padding-bottom: 30px

.errorIcon
  position: relative
  height: 32px
  width: 32px
  display: inline-block
  top: 10px

::v-deep footer
  flex-direction: row

.action
  padding-top: 20px

.table
  width: 100%
  border-collapse: separate
  margin-top: -15px
  thead
    tr
      position: relative
      th
        text-align: left
        &:nth-child(2)
          width: 100%

  th:nth-child(1),
  th:nth-child(2)
    padding-right: 20px
  th:nth-child(3)
    > div
      justify-content: end

.table-header
  display: flex
  align-items: center
  text-transform: uppercase
  user-select: none
  font-size: 12px
  line-height: 18px
  font-weight: 700
  &.sortable
    cursor: pointer
    justify-content: flex-end
  &.sorted
    color: $blue
  > svg
    margin-left: 5px

.table-wrapper
  position: relative
  width: 100%
  margin-top: 15px

.footer-link
  font-weight: bold

.tooltip-title
  padding-bottom: 15px
  margin-bottom: 15px
  border-bottom: 1px solid $grey
  line-height: 24px
  max-width: 550px

.warning
  text-align: center
  margin: 40px 0

.empty-message
  text-align: center
  color: $subdued
  font-size: 2rem
  margin: 40px 0

.toggle-checkbox
  padding-top: 5px
</style>
