<template>
  <li
    class="node-wrapper"
    :style="{ paddingLeft: level > 0 ? '16px' : '0px' }"
    @dragover.prevent
    @dragenter.stop="onDragEnter"
    @drop.stop="onDrop"
  >
    <div
      class="content-wrapper"
      :draggable="draggable && (!allowDrag || allowDrag(node))"
      @dragstart="onDragStart"
      @dragover.prevent
      @dragend="onDragEnd"
      @click="isExpandable() && onToggle()"
    >
      <slot :data="node" :level="level" :can-expand="isExpandable()" />
    </div>
    <ul v-if="isExpanded && children.length" :class="['child-list', { highlighted: isHighlighted }]">
      <LazyTreeNode
        v-for="child in children"
        :key="child.key"
        :node="child"
        :level="level + 1"
        :highlight-key="highlightKey"
        :parent-node="node"
        :force-expand="forceExpand"
      >
        <template #default="props: { data: NodeData; level: number; canExpand: boolean }">
          <slot name="default" v-bind="props"></slot>
        </template>
      </LazyTreeNode>
    </ul>
  </li>
</template>

<script lang="ts">
import { defineComponent, inject, PropType, watch, computed, ref, onBeforeUnmount, Ref } from 'vue'

export interface NodeData {
  id: number
  type: 'theme' | 'group'
  name: string
  key: string
  children?: NodeData[]
  loading?: boolean
  expanded?: boolean
  loaded?: boolean
}

export interface NodeProps {
  isLeaf?: (node: NodeData) => boolean
}

export default defineComponent({
  name: 'LazyTreeNode',
  props: {
    node: { type: Object as PropType<NodeData>, required: true },
    level: { type: Number, default: 0 },
    highlightKey: { type: String, default: '' },
    parentNode: { type: Object as PropType<NodeData | null>, default: null },
    forceExpand: { type: Boolean, default: false },
  },
  setup(props) {
    const expandEvent = inject<(node: NodeData) => void>('expandEvent')!
    const collapseEvent = inject<(node: NodeData) => void>('collapseEvent')!
    const fetchNode = inject<(node: NodeData, debounce?: () => boolean) => void>('fetchNode')!
    const toggleNode = inject<(node: NodeData) => void>('toggleNode')!
    const handleDragStart = inject<(e: DragEvent, node: NodeData) => void>('handleDragStart')!
    const handleDragEnter =
      inject<(e: DragEvent, node: NodeData, parentNode: NodeData | null) => void>('handleDragEnter')!
    const handleDrop = inject<(e: DragEvent, node: NodeData, parentNode: NodeData | null) => void>('handleDrop')!
    const handleDragEnd = inject<(e: DragEvent, node: NodeData) => void>('handleDragEnd')!
    const treeProps = inject<NodeProps>('treeProps')!
    const draggable = inject<boolean>('draggable')!
    const allowDrag = inject<(node: NodeData) => boolean>('allowDrag')!
    const filterNodes = inject<(nodes: NodeData[]) => NodeData[]>('filterNodes')!
    const expandedFilteredKeys = inject<Ref<string[]>>('expandedFilteredKeys')!

    const isVisible = ref(true)

    onBeforeUnmount(() => {
      if (isVisible.value) {
        collapseEvent(props.node)
      }
      isVisible.value = false
    })

    const isExpandable = (): boolean => {
      if (props.node.loading) return false
      if (!props.node.children?.length) return false
      if (treeProps && typeof treeProps.isLeaf === 'function') {
        return !treeProps.isLeaf(props.node)
      }
      return false
    }

    const isExpanded = computed(() => {
      return props.node.type === 'group' && (props.node.expanded || props.forceExpand)
    })

    const shouldFetch = computed(() => {
      return isExpanded.value && autoExpand.value
    })

    const autoExpand = ref(!props.forceExpand)
    watch(
      () => props.forceExpand,
      (newVal) => {
        autoExpand.value = !newVal
        if (newVal) {
          expandEvent(props.node)
          setTimeout(() => {
            if (isVisible.value) {
              autoExpand.value = true
            }
          }, 400)
        }
      },
      {
        immediate: true,
      },
    )

    watch(
      shouldFetch,
      async (newVal, oldVal) => {
        if (!oldVal && newVal && !props.node.loaded) {
          fetchNode(props.node)
        }
      },
      {
        immediate: true,
      },
    )

    const onToggle = () => toggleNode(props.node)
    const onDragStart = (e: DragEvent) => {
      if (draggable && allowDrag && !allowDrag(props.node)) {
        e.preventDefault()
        return
      }
      handleDragStart(e, props.node)
    }
    const onDragEnter = (e: DragEvent) => handleDragEnter(e, props.node, props.parentNode)
    const onDrop = (e: DragEvent) => handleDrop(e, props.node, props.parentNode)
    const onDragEnd = (e: DragEvent) => handleDragEnd(e, props.node)

    const children = computed(() => {
      const c = props.node.children ?? []
      return expandedFilteredKeys.value.includes(props.node.key) ? c : filterNodes(c)
    })

    const isHighlighted = computed(() => {
      return props.highlightKey === props.node.key
    })

    return {
      isExpandable,
      onToggle,
      onDragStart,
      onDragEnter,
      onDrop,
      onDragEnd,
      draggable,
      allowDrag,
      children,
      isHighlighted,
      isExpanded,
    }
  },
})
</script>
<style lang="scss" scoped>
@import 'assets/kapiche.sass';

@keyframes border-in {
  from {
    border-color: rgba($grey-light, 0);
  }
  to {
    border-color: rgba($grey-light, 1);
  }
}

$spacing: 12px;

.node-wrapper {
  overflow-y: auto;
  overflow-x: hidden;

  &:not(:first-child) {
    > .content-wrapper {
      margin-top: $spacing;
    }
  }
}

.child-list {
  border-left: 2px solid rgba($grey-light, 0);
  animation: border-in 0.3s ease-out forwards;
  animation-delay: 0.2s;
  margin-top: $spacing;

  &.highlighted {
    border-left-color: $blue !important;
  }
}
</style>
