<template>
  <widget-frame
    ref="root"
    :zoomed="false"
    :masked="masked"
    :is-loading="false"
    :dev-mode="devMode"
    :has-errored="false"
    class="summary"
    @resize="setChartDimensions"
  >
    <!--======================== ACTIONS -->
    <template #actions>
      <div>
        <div class="default-actions">
          <a :href="CONST.widget_help_links.ai_summary" class="widget-action help" target="_blank">
            <i class="kapiche-icon-info"></i>
          </a>
        </div>
      </div>
    </template>

    <template #icon>
      <img class="header-icon" :src="icon" alt="Dashboard themes icon" />
    </template>

    <template #header>
      {{ widgetTitle }}
    </template>

    <template #menu>
      <span class="label">AI GENERATED </span>
    </template>

    <template #devPanel>
      <div>
        <!-- Start: {{ new Date(sliceOneData.startTime || 0) }}<br />
        Done: {{ new Date(sliceOneData.doneTime || 0) }}<br />
        Elapsed: {{ ((sliceOneData.doneTime || 0) - (sliceOneData.startTime || 0)) / 1000 }} seconds<br />
        Status: {{ sliceOneData.status }}<br />
        Error: {{ sliceOneData.error }} -->
        <hr />
        <h2>this.props</h2>
        <code style="white-space: pre">
          {{ JSON.stringify($props, null, 2) }}
        </code>
      </div>
    </template>

    <template #error-panel> </template>

    <template #content>
      <div v-if="isLoading" class="loading">
        <br />
        <bf-spinner></bf-spinner>
        <br />
        <br />
        One moment, reading verbatims...
        <br />
        <br />
      </div>
      <div v-else-if="summary" class="summary-wrapper">
        <div class="summary-text">
          {{ summary }}
        </div>
        <el-collapse class="collapse">
          <el-collapse-item>
            <template #title>
              <span class="subheader-text">Sample Verbatims</span>
            </template>
            <ul>
              <li v-for="v in sampleVerbatims" :key="v" class="verbatim-text">
                <q>{{ v }}</q>
              </li>
            </ul>
          </el-collapse-item>
          <el-collapse-item v-if="recommendation && isStaff">
            <template #title>
              <span class="subheader-text staff-only">Suggested Actions</span>
            </template>
            <ul class="recommendations-list">
              <li v-for="(sentence, index) in recommendationSentences" :key="index" class="verbatim-text">
                {{ sentence }}
              </li>
            </ul>
          </el-collapse-item>
        </el-collapse>
      </div>
      <bf-button v-else color="blue" @click="getSummary"> Generate </bf-button>
    </template>
  </widget-frame>
</template>

<script lang="ts">
import { defineComponent, inject, ref, onMounted, computed, PropType } from 'vue'
import WidgetFrame from 'components/widgets/WidgetFrame/WidgetFrame.vue'
import icon from 'assets/img/dashboards/dash-summary.svg'
import { BfButton, BfSpinner } from 'components/Butterfly'
import { Analytics } from 'src/analytics'
import Query from 'src/api/query'
import { mergeDashboardFiltersWithBotanicQuery } from 'src/utils/query'
import { removemarkdown } from 'src/utils/formatters'
import { QueryType, SavedQuery } from 'src/types/Query.types'

export default defineComponent({
  components: {
    WidgetFrame,
    BfSpinner,
    BfButton,
  },
  props: {
    devMode: { type: Boolean, required: false, default: false },
    /** Add a skeleton mask (used when reloading state between dashboards) */
    masked: { type: Boolean, required: false, default: false },
    dashboardId: { type: Number, required: false, default: null },
    projectId: { type: Number, required: false, default: null },
    currentProject: { type: Object, default: () => null, required: false },
    currentAnalysis: { type: Object, default: () => null, required: false },
    query: { type: Object as PropType<QueryType>, required: false, default: null },
    mergedFilters: { type: Array, default: () => null, required: false },
    savedQueries: { type: Array as PropType<SavedQuery[]>, default: () => null, required: false },
    themeName: { type: String, required: false, default: null },
    widgetTitle: { type: String, required: false, default: 'At a Glance' },
    automaticFetch: { type: Boolean, required: false, default: false },
    isStaff: { type: Boolean, required: false, default: false },
  },
  setup(props) {
    const analytics = inject<Analytics>('analytics')
    const root = ref<InstanceType<typeof WidgetFrame> | null>(null)

    const isLoading = ref(false)
    const summary = ref('')
    const recommendation = ref('')
    const sampleVerbatims = ref([])
    const width = ref(300)

    const recommendationSentences = computed(() => {
      if (!recommendation.value) return []
      // Split by periods, exclamation marks, or question marks followed by a space
      return recommendation.value.split(/(?<=[.!?])\s+/).filter((sentence) => sentence.trim() !== '')
    })

    const refresh = () => {
      window.location.reload()
    }

    const setChartDimensions = (w: number): void => {
      width.value = w
    }

    const contact = () => {
      try {
        window.Intercom('show')
      } catch {
        console.warn('intercom show failed')
      }
    }

    const reload = () => {
      getSummary()
    }

    const getSummary = async () => {
      try {
        isLoading.value = true
        analytics?.track.dashboard.tellAStory()
        const themeName = props.themeName
        const summary_type = 'paragraph'
        const summaryResult = await Query.generateSummary(
          props.projectId,
          props.currentProject.chrysalis_ref,
          Number(props.currentAnalysis.topic_framework_id),
          mergeDashboardFiltersWithBotanicQuery(props.query, props.mergedFilters),
          props.savedQueries,
          summary_type,
          themeName,
          3,
          'theme_summary',
        )
        if (summaryResult && summaryResult.payload) {
          summary.value = removemarkdown(summaryResult.payload[0].summary)
          recommendation.value = summaryResult.payload[0].recommendation
          sampleVerbatims.value = summaryResult.payload[0].sample_verbatims
          if (props.isStaff) {
            console.log('Prepared Prompt for Summary:', summaryResult.payload[0].llm_prompt)
          }
        } else {
          throw new Error(`Failed to get summary data`)
        }
      } catch (e) {
        console.warn(`Error ${e}`)
      } finally {
        isLoading.value = false
      }
    }

    onMounted(() => {
      if (props.automaticFetch) {
        getSummary()
      }
    })

    return {
      icon,
      root,
      refresh,
      contact,
      reload,
      isLoading,
      getSummary,
      summary,
      sampleVerbatims,
      recommendation,
      setChartDimensions,
      width,
      recommendationSentences,
      // This provides a way for the workbench to trigger a refresh
      fetchData: () => (summary.value ? getSummary() : null),
    }
  },
})
</script>

<style lang="scss" scoped>
@import 'assets/colours.sass';
@import '~assets/kapiche.sass';

$dark-orange: #dc7070;

.header-icon {
  height: 32px;
  width: 100%;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.error-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  padding-bottom: 30px;
}
.action {
  padding-top: 20px;
}
button {
  background: none;
  border: none;
  border-bottom: 2px solid $blue;
  padding: 3px 4px;

  &:hover {
    background-color: $grey-light;
  }
  &:focus {
    border: 2px solid $blue-light;
    outline: none;
  }
  // These settings are required to allow the chart to resize correctly. If you comment these out,
  // weird things happen with the sizing of the timeline.
}
.timeline-container {
  width: inherit;
  align-items: unset;
}
.summary-wrapper {
  width: 100%;
  padding: 20px;
  align-self: center;
}
.summary-text {
  color: $text-black;
  font-size: 16px;
  line-height: 1.7rem;
  align-self: center;
  margin-bottom: 20px;
}
.loading {
  text-align: center;
}
.label {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.6px;
  color: #8064aa;
  margin-bottom: 3px;
  text-transform: uppercase;
  align-self: center;
}
.verbatim-text {
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.6px;
}
.subheader-text {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.6px;
}
.el-collapse {
  margin-top: 20px;
}
.recommendations-list {
  padding-left: 20px;
  margin-top: 10px;

  li {
    margin-bottom: 8px;
  }
}
</style>
