import * as d3 from 'd3'
import { TimeInterval } from 'd3'
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash'
import { GroupOrTheme } from 'src/pages/dashboard/Dashboard.utils'
import { MenuOptionLabelled } from 'src/types/components/WidgetMenu.types'
import { TrendLine } from 'src/types/widgets.types'

export type Resolution = 'daily' | 'weekly' | 'monthly' | 'quarterly' | 'yearly'

export const xTickFormatter = (
  resolution: Resolution,
  dataLength: number,
  widgetWidth = 1000,
  allowedIndices: number[] | null = null,
) => {
  let lastYear = new Date(0)

  return (date: Date, i: number): string => {
    if (allowedIndices && !allowedIndices.includes(i)) return ''

    const formatMonth = d3.timeFormat('%b')
    const formatMonthYear = d3.timeFormat("%b '%y")
    const formatQuarter = d3.timeFormat("Q%q '%y")
    const formatYear = d3.timeFormat('%Y')

    // target minimum width of 80px per label
    const maxLabelWidth = 80
    const maxLabelCount = widgetWidth / maxLabelWidth
    const spacingFactor = Math.floor(dataLength / maxLabelCount)
    if (!allowedIndices && i % spacingFactor) return ''

    switch (resolution) {
      case 'daily':
      case 'weekly':
      case 'monthly': {
        // Calculate whether we need to indicate that
        // the x-axis labels have entered a new year
        const thisYear = d3.timeYear(date)
        if (thisYear > lastYear) {
          lastYear = thisYear
          return formatMonthYear(date)
        }

        return formatMonth(date)
      }
      case 'quarterly':
        return formatQuarter(date)
      case 'yearly':
        return formatYear(date)
    }
  }
}

export const getTickInterval = (resolution: string): TimeInterval | null => {
  switch (resolution) {
    default:
    case 'daily':
    case 'weekly':
    case 'monthly':
      return d3.utcMonth.every(1)
    case 'quarterly':
      return d3.utcMonth.every(3)
    case 'yearly':
      return d3.utcYear.every(1)
  }
}

export const formatTooltipDate = (date: Date, resolution: Resolution, short = false): string => {
  switch (resolution) {
    default:
      return dayjs(date).format(short ? 'MMM YYYY' : 'MMMM YYYY')
    case 'daily':
      return dayjs(date).format(short ? 'DD MMM YYYY' : 'dddd, DD MMMM YYYY')
    case 'weekly':
      return `Week beginning ${dayjs(date).format(short ? 'DD MMM YYYY' : 'DD MMMM YYYY')}`
  }
}

export const sortOptions = [
  'Lowest average',
  'Lowest median',
  'Highest average',
  'Highest median',
  'Highest absolute change',
  'Highest relative change',
] as const

export type SortOption = (typeof sortOptions)[number]

export const sortBy: Record<SortOption, (a: TrendLine, b: TrendLine) => number> = {
  'Lowest average': (a, b) => {
    const aMean = d3.mean(a.counts) ?? 0
    const bMean = d3.mean(b.counts) ?? 0
    return aMean - bMean
  },
  'Lowest median': (a, b) => {
    const aMedian = d3.median(a.counts) ?? 0
    const bMedian = d3.median(b.counts) ?? 0
    return aMedian - bMedian
  },
  'Highest average': (a, b) => {
    const aMean = d3.mean(a.counts) ?? 0
    const bMean = d3.mean(b.counts) ?? 0
    return bMean - aMean
  },
  'Highest median': (a, b) => {
    const aMedian = d3.median(a.counts) ?? 0
    const bMedian = d3.median(b.counts) ?? 0
    return bMedian - aMedian
  },
  'Highest absolute change': (a, b) => {
    const aRange = (d3.max(a.counts) ?? 0) - (d3.min(a.counts) ?? 0)
    const bRange = (d3.max(b.counts) ?? 0) - (d3.min(b.counts) ?? 0)
    return bRange - aRange
  },
  'Highest relative change': (a, b) => {
    const calculateZScoreSum = (counts: number[]) => {
      const mean = d3.mean(counts) ?? 0
      const stdDev = Math.sqrt(d3.mean(counts.map((x) => Math.pow(x - mean, 2))) ?? 0)

      // Avoid division by zero if all values are the same
      if (stdDev === 0) {
        return 0
      }

      const zScores = counts.map((x) => Math.abs((x - mean) / stdDev))
      return d3.sum(zScores)
    }

    const aZScoreSum = calculateZScoreSum(a.counts)
    const bZScoreSum = calculateZScoreSum(b.counts)

    return bZScoreSum - aZScoreSum
  },
}

// Sort a list of trend lines based on a given sort option
export const sortSeries = (data: TrendLine[], sortOption: SortOption): TrendLine[] => {
  const clonedData = cloneDeep(data)
  clonedData.sort(sortBy[sortOption])
  return clonedData
}

// Transform a tree of theme groups into a nested list of menu options
export const transformTree = (nodes: GroupOrTheme[]): MenuOptionLabelled[] => {
  return nodes.reduce((acc, node) => {
    if (node.type === 'group') {
      const n: MenuOptionLabelled = {
        label: node.name,
        value: `g_${node.id}`,
        children: transformTree(node.children),
      }
      acc.push(n)
    }
    return acc
  }, [] as MenuOptionLabelled[])
}
