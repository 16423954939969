<template>
  <widget-frame
    :zoomed="isZoomed"
    :masked="masked"
    :is-loading="status === 'fetching'"
    :dev-mode="devMode"
    :has-errored="error != null"
    :banner="banner"
    class="sentiment-timeline"
  >
    <template #icon>
      <img class="header-icon" :src="icon" alt="Dashboard themes icon" />
    </template>

    <template #header> Sentiment </template>

    <template #actions>
      <download-export-button
        :name="exportName + '-Sentiment-Timeline'"
        :is-loading="status === 'fetching'"
        :get-el="getTrendEl"
        :get-csv-data="getCsvData"
        :get-svg-export-config="getExportConfig"
        :make-ppt-slide="hasDate ? makePptSlide : undefined"
        short-name="Sentiment-Timeline"
        show-alerts
      ></download-export-button>
      <router-link v-if="!isZoomed && zoomToRoute" class="widget-action expand" :to="zoomToRoute">
        <i class="kapiche-icon-fullscreen"></i>
      </router-link>
    </template>

    <template v-if="hasDate" #menu>
      <widget-menu :menus="menus" :vertical="isZoomed" :bound="$el" @onSelect="setMenuSelection" />
    </template>

    <template #devPanel>
      <div>
        Start: {{ new Date(startTime) }}<br />
        Done: {{ new Date(doneTime) }}<br />
        Elapsed: {{ (doneTime - startTime) / 1000 }} seconds<br />
        Status: {{ status }}<br />
        Error: {{ error }}
        <hr />
        <h2>this.props</h2>
        <code style="white-space: pre">
          {{ JSON.stringify($props, null, 2) }}
        </code>
        <hr />
        <h2>this.data</h2>
        <code style="white-space: pre">
          {{ JSON.stringify($data, null, 2) }}
        </code>
      </div>
    </template>

    <template #error-panel>
      <div v-if="error" class="error-panel">
        <h3>
          <img class="errorIcon" :src="errorIcon" alt="widget error icon" />
          Opps, something went wrong while loading this widget.
        </h3>
        <div class="action">
          Try
          <button @click.stop="reload">reloading this widget</button>
          or
          <button @click.stop="refresh">reloading the page</button>
        </div>
        <div class="action">
          <button @click.stop="contact">Contact support</button>
          if the problem persists.
        </div>
        <div v-if="userError" class="message">
          {{ userError }}
        </div>
      </div>
    </template>

    <template v-if="overallData" #content>
      <insight-cues
        ref="cueContent"
        v-if="getTimelineCues && hasDate && aiUse"
        :cues-stale="areTimelineCuesStale"
        :loading="timelineCuesLoading"
        :content="formattedTimelineCues"
        :selected-date-field="menuSelections['Date Field']"
        @set-filters="(filters) => $emit('set-filters', filters)"
        @toggle-filter="toggleFilters"
        @fetch="fetchTimelineCues"
        @date-hover-start="dateHover"
        @date-hover-end="dateHoverEnd"
        @metric-hover-start="metricHoverStart"
        @metric-hover-end="metricHoverEnd"
      ></insight-cues>

      <div>
        <stats-row v-loading="!overallData || overallData.status === 'fetching'" :sentiment="querySentiment" />
      </div>

      <div v-if="selectedDataInPlottableFormat.length > 0" class="row timeline-container">
        <timeline
          ref="timeline"
          v-if="hasDate"
          :timeline-id="'timeline-trend'"
          :all-series="selectedDataInPlottableFormat"
          :y-label="displayOptions.yAxisLabel"
          :resolution="menuSelections['Resolution'].toLowerCase()"
          :y-value-number-format="displayOptions.numberType"
          :y-range="yRange"
          :x-label="menuSelections['Date Field']"
          :records="records"
          @series-visibility-changed="calculateYRange"
        ></timeline>
      </div>
    </template>
    <template v-else #content>
      <widget-message-panel>
        <template #title>
          <span>No Data</span>
        </template>
        <template #message>
          <span>There is not sufficient data to display this widget.</span>
        </template>
      </widget-message-panel>
    </template>
  </widget-frame>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import PptxGenJS from 'pptxgenjs'
import WidgetMenu from 'components/DataWidgets/WidgetMenu/WidgetMenu.vue'
import WidgetFrame from 'components/widgets/WidgetFrame/WidgetFrame.vue'
import icon from 'assets/img/dashboards/dash-sentiment.svg'
import errorIcon from 'assets/icons/alert-bubble.svg'
import DownloadExportButton from 'components/project/analysis/results/widgets/DownloadExportButton.vue'
import Timeline from 'components/project/analysis/results/widgets/Timeline.vue'
import InsightCues from 'components/DataWidgets/InsightCues/InsightCues.vue'
import { getCsvData, regroupData, formatDataForTimelineCues } from './SentimentTimeline.utils'
import { getAggregationOffset, getDataAbsMax, makeTimelineSlide } from '../DataWidgetUtils'
import { WidgetConfig } from 'src/types/DashboardTypes'
import StatsRow from './StatsRow.vue'
import { PivotData } from 'src/types/widgets.types'
import WidgetMessagePanel from 'components/widgets/WidgetMessagePanel/WidgetMessagePanel.vue'
import { markdown } from 'src/utils/formatters'
import { FetchedDataPayload } from 'src/pages/trial/Workbench/Workbench.utils'

export default defineComponent({
  components: {
    WidgetFrame,
    DownloadExportButton,
    Timeline,
    WidgetMenu,
    StatsRow,
    WidgetMessagePanel,
    InsightCues,
  },
  props: {
    /** data to render */
    data: { type: Object, required: false, default: null },
    isZoomed: { type: Boolean, required: false, default: false },
    zoomToRoute: { type: Object, required: false, default: null },
    overallData: { type: Object as PropType<FetchedDataPayload<PivotData>>, required: false, default: null },
    group: { type: String, required: false, default: 'overall__' },
    exportName: { type: String, required: false, default: '' },
    /** `fetching`, `done` or `''` */
    status: { type: String, required: false, default: '' },
    /** error object for dev panel */
    error: { type: String, required: false, default: null },
    /** nicer error message for user  */
    userError: { type: String, required: false, default: null },
    startTime: { type: Number, required: false, default: null },
    doneTime: { type: Number, required: false, default: null },
    devMode: { type: Boolean, required: false, default: false },
    /** widget banner to display */
    banner: { type: Object, default: () => null, required: false },
    dateFields: { type: Array, required: true },
    defaultDateField: { type: String, required: false, default: null },
    weekStart: { type: String, required: false, default: null },
    /** Add a skeleton mask (used when reloading state between dashboards) */
    masked: { type: Boolean, required: false, default: false },
    config: { type: Object as PropType<WidgetConfig<'sentiment-timeline'> | null>, required: false, default: null },
    dayFirstDates: { type: Boolean, required: false, default: false },
    aiUse: { type: Boolean, required: false, default: true },
    getTimelineCues: { type: Boolean, required: false, default: false },
    timelineCues: { type: String, required: false, default: null },
    isStaff: { type: Boolean, required: false, default: false },
    timelineCuesLoading: { type: Boolean, required: false, default: true },
  },
  emits: ['toggle-filter', 'config-changed', 'requires', 'timeline-cues', 'set-filters'],
  data() {
    return {
      icon,
      errorIcon,
      widgetErrors: false,
      resolution: 'monthly',
      yRange: [0, 1],
      yLabel: 'Records',
      selectedDataInPlottableFormat: [],
      records: {},
      lastEmittedReqs: '',
      lastCueReqs: '',

      // Default menu options
      menuSelections: {
        'Date Field': null as string | null,
        'Display': 'Values',
        'Resolution': 'Monthly',
      },
    }
  },
  computed: {
    formattedTimelineCues() {
      return this.timelineCues || null
    },
    areTimelineCuesStale() {
      const currentState = {
        dateField: this.menuSelections['Date Field'],
        resolution: this.menuSelections['Resolution'],
      }
      return JSON.stringify(currentState) !== this.lastCueReqs
    },
    hasDate() {
      return this.dateFields.length > 0
    },
    // If the timeline is being shown on the dashboard overview, then the data
    // is the overall data. This means we cannot calculate impact on X because
    // there is no subset of data to use in the calculation. (e.g if we were
    // showing the stats for a theme, the impact would be calculated using
    // the impact of the theme ON the overall dataset).
    hasImpact() {
      return this.group !== 'overall__'
    },
    displayOptions() {
      return {
        label: 'Frequency (%)',
        numberType: 'percentage',
        yAxisLabel: this.showingImpact ? 'Impact' : 'Values',
        yCapValue: 2,
        yMultipleOf: 0.1,
      }
    },
    menus() {
      const options = [
        {
          name: 'Date Field',
          selection: this.menuSelections['Date Field'],
          options: [
            [
              {
                title: 'Date Field',
                type: 'menu',
                showSelected: true,
                selected: this.menuSelections['Date Field'],
                options: this.dateFields.map(({ name }) => name),
              },
            ],
          ],
        },
        {
          name: 'Resolution',
          selection: this.menuSelections['Resolution'],
          options: [
            [
              {
                title: 'Resolution',
                type: 'menu',
                showSelected: true,
                selected: this.menuSelections['Resolution'],
                options: ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly'],
              },
            ],
          ],
        },
      ]
      if (this.hasImpact) {
        // We want the display menu to show up between date field and resolution
        options.splice(1, 0, {
          name: 'Display',
          selection: this.menuSelections['Display'],
          options: [
            [
              {
                title: 'Display',
                type: 'menu',
                showSelected: true,
                selected: this.menuSelections['Display'],
                options: ['Values', 'Impact'],
              },
            ],
          ],
        })
      }
      return options
    },
    querySentiment() {
      const groupRow = this.overallData?.data?.payload?.find((p) => p.group__ == this.group)

      const format = (value: number | undefined) => {
        return (value ?? 0).toFixed(0)
      }

      return this.showingImpact ?
          {
            positive: format(groupRow?.['sentiment__|positive%i_rto__']),
            negative: format(groupRow?.['sentiment__|negative%i_rto__']),
            mixed: format(groupRow?.['sentiment__|mixed%i_rto__']),
            neutral: format(groupRow?.['sentiment__|neutral%i_rto__']),
          }
        : {
            positive: format(groupRow?.['sentiment__|positive%__']),
            negative: format(groupRow?.['sentiment__|negative%__']),
            mixed: format(groupRow?.['sentiment__|mixed%__']),
            neutral: format(groupRow?.['sentiment__|neutral%__']),
          }
    },
    showingImpact() {
      return this.menuSelections['Display'] === 'Impact'
    },
  },
  watch: {
    'config': {
      deep: true,
      handler() {
        this.setOptionsFromConfig()
      },
    },
    'menuSelections': {
      deep: true,
      handler() {
        this.fetchData()
      },
    },
    'menuSelections.Display'() {
      this.regroupData()
    },
    'data'(val) {
      if (val === null) return
      this.regroupData()
    },
    'group'() {
      this.fetchData()
    },
    'timelineCues'() {
      const currentState = {
        dateField: this.menuSelections['Date Field'],
        resolution: this.menuSelections['Resolution'],
      }
      this.lastCueReqs = JSON.stringify(currentState)
    },
  },
  mounted() {
    if (this.hasDate) {
      this.menuSelections['Date Field'] = this.defaultDateField ?? this.dateFields[0].name
    }
    this.setOptionsFromConfig()
    if (this.data === null) {
      this.fetchData()
    } else {
      this.regroupData()
    }
  },
  methods: {
    updateConfig() {
      const options: NonNullable<typeof this.config>['options'] = {
        resolution: this.menuSelections['Resolution'],
        dateField: this.menuSelections['Date Field'],
        display: this.menuSelections['Display'],
      }
      const updated = Object.assign({}, this.config, { options })
      this.$emit('config-changed', updated)
    },
    setOptionsFromConfig() {
      const resolution = this.config?.options?.resolution ?? 'Monthly'
      const dateField =
        this.config?.options?.dateField ?? this.defaultDateField ?? (this.hasDate && this.dateFields[0].name)
      const display = this.config?.options?.display ?? 'Values'

      this.setMenuSelection(null, ['Resolution', resolution], true)
      this.setMenuSelection(null, ['Date Field', dateField], true)
      this.setMenuSelection(null, ['Display', display], true)
    },
    setMenuSelection(_, path: [string, string | null], fromConfig = false) {
      this.menuSelections[path[0]] = path[1]
      if (!fromConfig) this.updateConfig()
    },
    regroupData(): void {
      if (!this.hasDate || !this.data) return
      const [records, ...groupedData] = regroupData(
        this.data,
        this.menuSelections['Date Field'],
        this.menuSelections['Display'] === 'Impact',
        this.group,
      )
      // only show series with data
      this.selectedDataInPlottableFormat = groupedData.filter(({ counts = [] }) => counts.length > 0)
      this.records = records
      this.calculateYRange()
    },
    async fetchTimelineCues() {
      const data = formatDataForTimelineCues(this.selectedDataInPlottableFormat, this.menuSelections)
      if (this.isStaff) {
        console.log(this.selectedDataInPlottableFormat)
        console.log('SENTIMENT DATA', data)
      }
      this.$emit('timeline-cues', 'sentiment', data, this.menuSelections['Date Field'], 'sentiment_timeline_cues')
    },
    fetchData(force = false) {
      const blocks = [
        {
          aggfuncs: [
            {
              new_column: 'frequency',
              src_column: 'document_id',
              aggfunc: 'count',
            },
          ],
          pivot_field: 'sentiment__',
          metric_calculator: 'sentiment',
        },
      ]

      const requirements = {
        blocks,
        date_fieldname: this.menuSelections['Date Field'],
        date_aggregation_offset: getAggregationOffset(this.menuSelections['Resolution']),
        week_start: this.weekStart,
      }

      if (this.hasDate) {
        // Data by date resolution
        this.$emit('requires', 'sentiment-timeline', requirements, force, true)
      }

      // Overall data
      this.$emit(
        'requires',
        'sentiment-timeline-overall',
        {
          blocks: [
            {
              aggfuncs: [
                {
                  new_column: 'frequency',
                  src_column: 'document_id',
                  aggfunc: 'count',
                },
              ],
              pivot_field: 'sentiment__',
              metric_calculator: 'sentiment',
            },
          ],
        },
        force,
        true,
      )
      this.lastEmittedReqs = JSON.stringify(requirements)
    },
    refresh() {
      window.location.reload()
    },
    contact() {
      try {
        window.Intercom('show')
      } catch {
        console.warn('intercom show failed')
      }
    },
    reload() {
      this.fetchData(true)
    },
    getCsvData() {
      return getCsvData(this.selectedDataInPlottableFormat, this.menuSelections)
    },
    calculateYRange() {
      const visibleDataset = this.selectedDataInPlottableFormat.filter((series) => series.visible)
      const dataMax = getDataAbsMax(visibleDataset, this.displayOptions.yCapValue, this.displayOptions.yMultipleOf)
      const dataMin = this.showingImpact ? -dataMax : 0
      this.yRange = [dataMin, dataMax]
    },
    getExportConfig() {
      return {
        dims: this.getTrendEl().getBoundingClientRect(),
        css: `
          text {
            color: #383838;
            font-size: 14px;
            stroke: none;
          }
          .line {
            stroke-width: 2px;
          }
          .axis path, .axis line {
            shape-rendering: crispEdges;
            stroke: #ebebeb;
            stroke-width: 2px;
            opacity: 0.5;
          }
        `,
      }
    },
    getTrendEl() {
      return this.$el.querySelector('div.content')
    },
    makePptSlide(pptx: PptxGenJS) {
      if (!this.hasDate) return

      const slide = pptx.addSlide()
      makeTimelineSlide(
        pptx,
        slide,
        this.selectedDataInPlottableFormat.map((d) => ({
          ...d,
          // Format the counts as percentages
          counts: d.counts.map((c) => c * 100),
        })),
        this.exportName + ' - Sentiment Timeline',
        'Percentage',
        this.dayFirstDates,
      )
    },
    dateHover(date: string) {
      if (this.$refs.timeline) {
        this.$refs.timeline.showDateVerticalLine(date)
      }
    },
    dateHoverEnd() {
      if (this.$refs.timeline) {
        this.$refs.timeline.hideDateVerticalLine()
      }
    },
    metricHoverStart(metric) {
      if (metric.metric_type) {
        const idx = this.selectedDataInPlottableFormat.findIndex((series) => series.name === metric.metric_type)
        if (this.$refs.timeline && idx !== -1) {
          this.$refs.timeline.hoverSeries(idx)
        }
      }
    },
    metricHoverEnd() {
      if (this.$refs.timeline) {
        this.$refs.timeline.unhoverSeries()
      }
    },
    toggleFilters(segments: [string, string][]) {
      segments.forEach(([field, segment]) => {
        this.$emit('toggle-filter', field, segment)
      })
    },
    collapseCues() {
      if (this.$refs.cueContent?.$el) {
        this.$refs.cueContent.$el.querySelector('.el-collapse-item').classList.remove('is-active')
      }
    },
  },
})
</script>

<style lang="sass" scoped>

.header-icon
  height: 32px
  width: 100%

.row
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center

.stats
  display: flex
  flex-direction: row
  justify-content: space-evenly
  align-items: center

.stat
  font-size: 26px
  min-width: 5em
  font-weight: bold
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  flex-grow: 1

.standard
  color: #068CCC

.positive
  color: rgb(33, 186, 69)

.negative
  color: rgb(238, 56, 36)

.neutral
  color: rgb(127, 127, 127)

.label
  margin: 10px
  font-size: 16px
  font-weight: normal
</style>

<style lang="sass" scoped>
@import '~assets/kapiche.sass'

.error-panel
  display: flex
  flex-direction: column
  align-items: center
  font-size: 16px
  padding-bottom: 30px

.message
  display: flex
  flex-direction: row
  justify-content: center
  background-color: rgba(255, 0, 0, 0.1)
  padding: 6px
  color: $text-black
  width: 100%
  max-height: 30px
  position: absolute
  bottom: 0


.errorIcon
  position: relative
  height: 32px
  width: 32px
  display: inline-block
  top: 10px

.action
  padding-top: 20px

button
  background: none
  border: none
  border-bottom: 2px solid $blue
  padding: 3px 4px

  &:hover
    background-color: $grey-light

  &:focus
    border: 2px solid $blue-light
    outline: none
</style>

<style lang="sass" scoped>
@import "assets/colours.sass"

.ui.dimmer
  z-index: 5

/* Local styles */
.column.mask
  background-color: white
  opacity: 0.3
  pointer-events: none



div.dropdown-list
  div.variable-select-timeline
    margin: 0 15px 0 15px
    font-size: 15px
    font-weight: bold
    text-align: center
    align-items: center
    min-width: 152px
  .dropdown-wrapper
    margin-left: 40px
    margin-right: 40px
    .dropdown-menu
      // TODO: Because parent divs in the new widgets have all switched to flex, the "margin-left: 50%" used in
      // the dropdown menus no longer works because the .dropdown-list widget is not block level, and therefore
      // the 50% no longer is based on its width. We need to figure out what to do here.
      margin-left: unset

.ui.horizontal.list.dropdowns
  padding-left: 2rem
  .item
    padding-right: 2rem

.variable-select-timeline
  background: white
  .ui.dropdown
    font-weight: bold
    color: #068ccc
    .icon
      margin-left: 0.5em

// These settings are required to allow the chart to resize correctly. If you comment these out,
// weird things happen with the sizing of the timeline.
.timeline-container
  width: inherit
  align-items: unset
</style>
