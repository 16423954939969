<template>
  <div class="wrapper">
    <workbench-header
      :dashboard-type="drilldown"
      :query-id="drilldownId"
      :theme-group-tree="themeGroupTree"
      :drilldown-segment="drilldownSegment"
      :viewer-mode="viewerMode"
    />
    <workbench
      :dashboard-id="dashboardId"
      :drilldown="drilldown"
      :drilldown-id="drilldownId"
      :zoom-widget-key="zoomWidgetKey"
      :viewer-mode="viewerMode"
      @update:theme-group-tree="themeGroupTree = $event"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, ref, computed } from 'vue'
import WorkbenchHeader from './Header.vue'
import Workbench from 'src/pages/trial/Workbench/Workbench.vue'
import { Drilldown } from 'src/pages/trial/Workbench/Workbench.utils'
import { GroupOrTheme } from '../Dashboard.utils'

export default defineComponent({
  name: 'WorkbenchV2',
  components: {
    WorkbenchHeader,
    Workbench,
  },
  props: {
    dashboardId: {
      type: Number,
      required: true,
    },
    drilldown: {
      type: [String, Boolean] as PropType<Drilldown>,
      default: false,
    },
    drilldownId: {
      type: String as PropType<string | null>,
      default: null,
    },
    zoomWidgetKey: {
      type: String as PropType<string | null>,
      default: null,
    },
    viewerMode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const themeGroupTree = ref<GroupOrTheme[]>([])
    const drilldownSegment = computed(() => {
      if (props.drilldown !== 'segment') return null
      const [fieldName, segment] = props.drilldownId?.split(':') ?? []
      return { fieldName, segment }
    })
    return {
      themeGroupTree,
      drilldownSegment,
    }
  },
})
</script>
<style lang="scss" scoped>
.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
</style>
