<template>
  <div class="theme-tree">
    <ul v-if="!isEmpty">
      <TreeNode
        v-for="node in nodes"
        :key="node.key"
        :node="node"
        :level="0"
        :highlight-key="highlightKey"
        :parent-node="null"
        :force-expand="forceExpand"
      >
        <template #default="{ data, level, canExpand }">
          <slot :data="data" :level="level" :can-expand="canExpand"></slot>
        </template>
      </TreeNode>
    </ul>
    <div v-else-if="!isLoading" class="empty-message">
      <slot name="empty" />
    </div>
    <div v-else class="loading-message">
      <slot name="loading" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, provide, onMounted, PropType, computed, watch } from 'vue'
import TreeNode, { NodeData, NodeProps } from './LazyTreeNode.vue'
import { findNode, findParentNode } from 'src/components/project/analysis/results/ThemeBuilder/ThemeBuilder.utils'

type LoadFunction = (node: NodeData | null, callback: (children: NodeData[] | null) => void) => void

export default defineComponent({
  name: 'LazyTree',
  components: { TreeNode },
  props: {
    load: { type: Function as PropType<LoadFunction>, required: true },
    props: { type: Object as PropType<NodeProps>, default: () => ({}) },
    defaultExpandedKeys: { type: Array, default: () => [] },
    allowDrop: { type: Function, default: null },
    allowDrag: { type: Function, default: null },
    draggable: { type: Boolean, default: false },
    filterString: { type: String, default: '' },
    highlightKey: { type: String, default: '' },
    forceExpand: { type: Boolean, default: false },
  },
  emits: ['node-expand', 'node-collapse', 'node-drop', 'node-drag-enter', 'node-drag-start', 'node-drag-end'],
  setup(props, { emit }) {
    const treeData = ref<NodeData[]>([])
    const draggingNode = ref<NodeData | null>(null)
    const dropTarget = ref<NodeData | null>(null)
    const isLoading = ref(true)

    const setDefaultExpanded = (node: NodeData) => {
      if (props.defaultExpandedKeys.includes(node.key)) {
        node.expanded = true
      }
      if (node.children) {
        node.children.forEach((child) => setDefaultExpanded(child))
      }
    }

    if (treeData.value.length) {
      treeData.value.forEach((node) => setDefaultExpanded(node))
    }

    // Load the root nodes on mount if lazy and no initial data.
    onMounted(() => {
      props.load(null, (children) => {
        if (children) treeData.value = children
        treeData.value.forEach((node) => setDefaultExpanded(node))
        isLoading.value = false
      })
    })

    const fetchNode = (node: NodeData) => {
      if (node.loaded || node.type === 'theme') return
      node.loading = true
      props.load(node, (children) => {
        node.loading = false
        if (children) node.children = children
        node.loaded = true
      })
    }

    const expandEvent = (node: NodeData) => {
      setTimeout(() => emit('node-expand', node))
    }

    const collapseEvent = (node: NodeData) => {
      setTimeout(() => emit('node-collapse', node))
    }

    const toggleNode = async (node: NodeData) => {
      if (node.expanded) {
        if (props.filterString) {
          expandedFilteredKeys.value = expandedFilteredKeys.value.filter((key) => key !== node.key)
        }
        node.expanded = false
        collapseEvent(node)
      } else {
        if (props.filterString) {
          expandedFilteredKeys.value = [...expandedFilteredKeys.value, node.key]
        }
        expandEvent(node)
        node.expanded = true
      }
    }

    const handleDragStart = (e: DragEvent, node: NodeData) => {
      if (props.allowDrag && !props.allowDrag(node)) {
        e.preventDefault()
        return
      }
      draggingNode.value = node
      if (e.dataTransfer) {
        e.dataTransfer.effectAllowed = 'move'
      }
      emit('node-drag-start', node)
    }
    const handleDragEnter = (e: DragEvent, node: NodeData, parentNode: NodeData | null) => {
      if (props.allowDrop && !props.allowDrop(node, draggingNode.value, parentNode)) return
      dropTarget.value = node
      emit('node-drag-enter', draggingNode.value, node)
    }
    const handleDrop = (e: DragEvent, node: NodeData, parentNode: NodeData | null) => {
      e.preventDefault()
      if (props.allowDrop && !props.allowDrop(node, draggingNode.value, parentNode)) return
      emit('node-drop', draggingNode.value, node)
      draggingNode.value = null
      dropTarget.value = null
    }
    const handleDragEnd = (e: DragEvent, node: NodeData) => {
      draggingNode.value = null
      dropTarget.value = null
      emit('node-drag-end', node)
    }

    const matchStrings = (pattern: string, text: string): boolean => {
      const lowerPattern = pattern.toLowerCase()
      const lowerText = text.toLowerCase()
      return lowerText.includes(lowerPattern)
    }

    const expandedFilteredKeys = ref<string[]>([])

    const filterNodes = (nodes: NodeData[]): NodeData[] => {
      if (!props.filterString) return nodes
      return nodes.filter((node) => {
        const childrenHit = filterNodes(node.children ?? []).length > 0
        const nameMatch = matchStrings(props.filterString, node.name)
        return nameMatch || childrenHit
      })
    }

    watch(
      () => props.filterString,
      () => {
        expandedFilteredKeys.value = []
      },
    )

    provide('expandedFilteredKeys', expandedFilteredKeys)
    provide('collapseEvent', collapseEvent)
    provide('expandEvent', expandEvent)
    provide('toggleNode', toggleNode)
    provide('fetchNode', fetchNode)
    provide('handleDragStart', handleDragStart)
    provide('handleDragEnter', handleDragEnter)
    provide('handleDrop', handleDrop)
    provide('handleDragEnd', handleDragEnd)
    provide('treeProps', props.props)
    provide('draggable', props.draggable)
    provide('allowDrag', props.allowDrag)
    provide('filterNodes', filterNodes)

    const removeNode = (key: string) => {
      const parent = findParentNode(treeData.value, { key })
      if (parent) {
        parent.children = parent.children!.filter((n) => n.key !== key)
      } else {
        treeData.value = treeData.value.filter((n) => n.key !== key)
      }
    }

    const updateNode = (key: string, data: Partial<NodeData>) => {
      const node = findNode(treeData.value, { key })
      if (node) {
        Object.assign(node, data)
      }
    }

    const getNode = (key: string) => {
      return findNode(treeData.value, { key })
    }

    const getParentNode = (key: string) => {
      return findParentNode(treeData.value, { key })
    }

    const insertNode = (parentId: number | null, node: NodeData) => {
      if (parentId == null) {
        treeData.value.push(node)
      } else {
        const parent = findNode(treeData.value, { id: parentId, type: 'group' })
        if (parent?.children) {
          parent.children.push(node)
        }
      }
    }

    const getOpenNodes = () => {
      const openNodes: NodeData[] = []
      const traverse = (node: NodeData) => {
        if (node.expanded) {
          openNodes.push(node)
        }
        if (node.children) {
          node.children.forEach(traverse)
        }
      }
      treeData.value.forEach(traverse)
      return openNodes
    }

    const getVisibleNodes = () => {
      const openNodes: NodeData[] = []
      const traverse = (node: NodeData, parent?: NodeData) => {
        if (node.expanded || !parent || parent.expanded) {
          openNodes.push(node)
        }
        if (node.children) {
          node.children.forEach((child) => traverse(child, node))
        }
      }
      treeData.value.forEach((node) => traverse(node))
      return openNodes
    }

    const nodes = computed(() => filterNodes(treeData.value))

    return {
      treeData,
      removeNode,
      updateNode,
      getNode,
      getParentNode,
      insertNode,
      filterNodes,
      nodes,
      isEmpty: computed(() => nodes.value.length === 0),
      isLoading,
      getOpenNodes,
      getVisibleNodes,
      expandedFilteredKeys,
    }
  },
})
</script>

<style lang="scss" scoped>
.theme-tree {
  :deep(ul) {
    list-style: none;
    padding-left: 0;
  }
}
</style>
