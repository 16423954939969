<template>
  <widget-frame
    ref="root"
    :zoomed="isZoomed"
    :is-loading="false"
    :masked="masked"
    :dev-mode="devMode"
    :has-errored="!!false"
    :banner="banner"
    class="themes"
    @resize="width = $event"
  >
    <!--======================== ACTIONS -->
    <template #actions>
      <div>
        <div class="default-actions">
          <download-export-button
            :name="exportName + '-Themes'"
            :is-loading="isLoading"
            :get-el="getChartEl"
            :get-csv-data="getCsvData"
            :get-csv-data-all-data="getCsvDataAllData"
            :make-ppt-slide="makePptSlide"
            :make-ppt-slide-all-data="makePptSlideAllData"
            short-name="Themes"
          ></download-export-button>
          <router-link v-if="!isZoomed && zoomToRoute" class="widget-action expand" :to="zoomToRoute">
            <i class="kapiche-icon-fullscreen"></i>
          </router-link>
          <a :href="CONST.widget_help_links.themes" class="widget-action help" target="_blank">
            <i class="kapiche-icon-info"></i>
          </a>
        </div>
      </div>
    </template>

    <!--======================== ICON -->
    <template #icon>
      <img class="header-icon" :src="icon" alt="Themes Icon" />
    </template>

    <!--======================== HEADING -->
    <template #header>
      {{ isDrilldown ? 'Co-occurring Themes' : 'Themes' }}
    </template>

    <!--======================== MENU -->
    <template #menu>
      <widget-menu :menus="menus" :vertical="isZoomed" :bound="$el" :max-label-length="35" @on-select="setSelection" />
    </template>

    <!--======================== DEV PANEL -->
    <template #devPanel>
      <div>
        <h2>this.props</h2>
        <code style="white-space: pre"
          ><!--
          -->{{ JSON.stringify($props, null, 2) }}
        </code>
        <hr />
        <h2>this.data</h2>
        <code style="white-space: pre"
          ><!--
          -->{{ JSON.stringify($data, null, 2) }}
        </code>
      </div>
    </template>
    <!--======================== ERROR PANEL -->
    <template #error-panel>
      <div class="error-panel">
        <h3>
          <img class="errorIcon" :src="errorIcon" alt="widget error icon" />
          Oops, something went wrong while loading this widget.
        </h3>
        <div class="action">
          Try
          <button @click.stop="fetchData()">reloading this widget</button> or
          <button @click.stop="refresh">reloading the page</button>
        </div>
        <div class="action">
          <button @click.stop="Intercom('show')">Contact support</button> if the problem persists.
        </div>
        <div v-if="userErrors" class="message">
          {{ userErrors }}
        </div>
      </div>
    </template>
    <template v-if="!noData" #content>
      <div v-show="isLoading" class="loading-wrapper">
        <bf-spinner />
      </div>
      <div v-show="!isLoading" class="content">
        <theme-tree
          ref="tree"
          :headers="headers"
          :project-id="project.id"
          :analysis-id="analysis.id"
          :fetch-column-data="fetchColumnData"
          :focus-node="drilldownNode"
          :is-percent="isPercent"
          :fetch-tree="fetchTree"
          :allow-drag-drop="false"
          @tree-loading="isLoading = $event"
          @name-click="tooltipNode = null"
          @node-toggle="tooltipNode = null"
          @mousemove="updateMouse"
          @mouseleave-row="tooltipNode = null"
          @mouseenter-row="rowHover"
          @dropdown-visible-change="dropdownVisible = $event"
          @tree-sorted="updateConfig()"
        >
          <template #dropdown="{ data }">
            <el-dropdown-menu>
              <el-dropdown-item
                @click="data.type === 'theme' ? $emit('go-to-theme', data.id) : $emit('go-to-theme-group', data.id)"
              >
                Drill into&nbsp;<b>{{ data.name }}</b>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </theme-tree>
        <floating-panel :visible="tooltipNode != null && tooltipData && !nameHovered" :y="mouseY" :x="mouseX">
          <data-tool-tip :v-if="tooltipData" v-bind="tooltipData" />
        </floating-panel>
      </div>
    </template>
    <template v-else #content>
      <widget-message-panel>
        <template #title>
          <span>No Data</span>
        </template>
        <template #message>
          <span>There is not sufficient data to display this widget.</span>
        </template>
      </widget-message-panel>
    </template>
    <template #footer>
      <div>
        <small>{{ footerText }} </small>
      </div>
    </template>
  </widget-frame>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue'
import { isEqual } from 'lodash'
import { useStore } from 'vuex'
import PptxGenJS from 'pptxgenjs'

import DownloadExportButton from 'components/project/analysis/results/widgets/DownloadExportButton.vue'
import WidgetFrame from 'components/widgets/WidgetFrame/WidgetFrame.vue'
import WidgetMenu from 'components/DataWidgets/WidgetMenu/WidgetMenu.vue'
import icon from 'assets/img/dashboards/dash-queries.svg'
import errorIcon from 'assets/icons/alert-bubble.svg'
import { Requirements } from 'types/PivotData.types'
import { WidgetMenuOptions } from 'types/components/WidgetMenu.types'
import { WidgetConfig } from 'src/types/DashboardTypes'
import { SavedQuery } from 'src/types/Query.types'
import { ExpandedQueryOrGroup, expandThemeGroup, GroupOrTheme } from 'src/pages/dashboard/Dashboard.utils'
import { SchemaColumn } from 'src/types/SchemaTypes'
import { ChrysalisFilter } from 'src/types/DashboardFilters.types'
import { getScoreOptionsForRequirements } from './ScoreUtils'
import {
  getAllOptionKeys,
  getHeader,
  makeMenu,
  makeRequirements,
  payloadDataToRows,
  getTooltipData,
} from './ThemeTreeWidget.utils'
import { Analysis } from 'src/types/AnalysisTypes'
import { Project } from 'src/types/ProjectTypes'
import { useFetchData } from 'components/project/analysis/results/ThemeBuilder/ThemeBuilder.utils'
import { fetch_pivot_data } from 'src/store/modules/data/api'
import { PivotData } from 'src/pages/trial/Workbench/Workbench.utils'
import BfSpinner from 'components/Butterfly/BfSpinner/BfSpinner.vue'
import FloatingPanel from 'components/widgets/FloatingPanel/FloatingPanel.vue'
import DataToolTip from '../DataToolTip/DataToolTip.vue'
import { number } from 'src/utils/formatters'
import { makeBarChartSlide } from '../DataWidgetUtils'
import ThemeTree from './ThemeTree.vue'
import { NodeData } from './LazyTreeNode.vue'
import { ThemeGroup } from 'src/api/query'
import { expandQuery } from 'src/utils/query'

// TODO: This should be moved to a shared location
// but is specific to this widget for now
const { fetch } = useFetchData()
const fetchWidgetData = (
  id: string,
  requirements: Requirements,
  projectId: number,
  chrysalisRef: string,
  topic_framework_id: number,
  filters: ChrysalisFilter[] = [],
  force = false,
) => {
  const fetchParams = [
    {
      id: id,
      projectId: projectId,
      chrysalisRef: chrysalisRef,
      topicId: topic_framework_id,
    },
    requirements,
    filters,
  ]

  const cacheKey = { fetchParams, name: id }
  return fetch<PivotData>(cacheKey, fetch_pivot_data, fetchParams, force)
}

const getNodeId = (node: GroupOrTheme) => {
  return node.type === 'group' ? `group_${node.id}` : `theme_${node.id}`
}

const ThemesWidget = defineComponent({
  name: 'ThemesWidget',
  components: {
    WidgetFrame,
    WidgetMenu,
    DownloadExportButton,
    BfSpinner,
    FloatingPanel,
    DataToolTip,
    ThemeTree,
  },
  props: {
    exportName: { type: String, required: false, default: '' },
    isZoomed: { type: Boolean, required: false, default: false },
    zoomToRoute: { type: Object, required: false, default: null },
    devMode: { type: Boolean, required: false, default: false },
    isFiltered: { type: Boolean, required: false, default: false },
    hasNps: { type: Boolean, required: false, default: false },
    hasSentiment: { type: Boolean, required: false, default: false },
    hasNumericFields: { type: Boolean, default: false, required: false },
    banner: { type: Object, default: () => null, required: false },
    masked: { type: Boolean, required: false, default: false },
    config: { type: Object as PropType<WidgetConfig<'themes-concepts'> | null>, required: false, default: null },
    schema: { type: Array as PropType<SchemaColumn[]>, required: true },
    dashboardFilters: { type: Array<ChrysalisFilter>, required: false, default: [] },
    analysis: { type: Object as PropType<Analysis>, required: true },
    project: { type: Object as PropType<Project>, required: true },
    baseQuery: { type: Object as PropType<ExpandedQueryOrGroup | null>, required: false, default: null },
    visibleThemeIds: { type: Array as PropType<number[]>, default: () => [] },
  },
  emits: ['go-to-theme', 'go-to-theme-group', 'config-changed'],
  setup(props, { emit }) {
    const store = useStore()

    const savedQueries = computed(() => store.getters.savedQueries as SavedQuery[])
    const themeToGroupNameMap = computed<Record<number, string>>(() => store.getters['themeToGroupNameMapById'] ?? {})
    const groupToGroupNameMap = computed<Record<number, string>>(() => store.getters['groupToGroupNameMapById'] ?? {})

    const root = ref<InstanceType<typeof WidgetFrame> | null>(null)
    const tree = ref<InstanceType<typeof ThemeTree> | null>(null)

    const isLoading = ref(false)
    const selectedDisplay = ref('Frequency')
    const width = ref(300)
    const mouseX = ref(0)
    const mouseY = ref(0)
    const tooltipNode = ref<GroupOrTheme | null>(null)
    const dropdownVisible = ref(false)
    const nameHovered = computed(() => {
      return tree.value?.nameHovered ?? false
    })
    const noData = ref(false)

    // The data for each node, filtered
    const pivotData = ref<PivotData['payload']>([])
    // The data for each node, unfiltered
    const overallData = ref<PivotData['payload']>([])
    // The data for the drilldown node, filtered
    const drilldownData = ref<PivotData['payload']>([])
    // The data for each node AND drilldown query, filtered
    const drilldownOverlapData = ref<PivotData['payload']>([])

    // Table headers
    const headers = computed(() => {
      const headers = getHeader('THEME/THEME GROUP', selectedDisplay.value, props.isFiltered && !isDrilldown.value)

      return headers
    })

    const isDrilldown = computed(() => {
      return props.baseQuery != null
    })

    // Fetch coverage stats for a group of tree nodes
    const fetchColumnData = async (nodes: GroupOrTheme[]): Promise<number[][]> => {
      const expandQ = (node: GroupOrTheme) => {
        const query = savedQueries.value.find((q) => q.id === node.id)
        if (query) {
          return {
            ...query,
            query_value: expandQuery(query.name, query.query_value, savedQueries.value),
          }
        }
      }

      // Convert nodes to queries
      const themeQueries = (includeBase: boolean, matchAll: boolean) =>
        nodes
          .map((node) => {
            const query = node.type === 'group' ? expandThemeGroup(node, savedQueries.value) : expandQ(node)
            if (!query) return null

            if (node.type === 'group' && node.children.length === 0) {
              return null
            }

            const query_value = {
              includes: [query.query_value],
              type: matchAll ? 'match_all' : 'match_any',
            }

            if (includeBase && props.baseQuery?.query_value) {
              query_value.includes.push(props.baseQuery.query_value)
            }

            return {
              ...query,
              name: getNodeId(node),
              query_value,
            } as SavedQuery
          })
          .filter((query) => query !== null)

      // Build pivot requirements
      const scoreColumns = props.schema.filter((col) => col.type === 8)
      const scoreOptions = getScoreOptionsForRequirements(selectedDisplay.value, scoreColumns)
      let numericSelected = null
      if (selectedDisplay.value.startsWith('__avg__')) numericSelected = [selectedDisplay.value.slice(7)]
      if (selectedDisplay.value.startsWith('__impact_on_avg__')) numericSelected = [selectedDisplay.value.slice(17)]

      const fetchData = (id: string, queries: SavedQuery[], includeFilters = true) => {
        return fetchWidgetData(
          id,
          makeRequirements(props.hasNps, props.hasSentiment, numericSelected, scoreOptions, queries, false),
          props.project.id,
          props.project.chrysalis_ref,
          props.analysis.topic_framework_id,
          includeFilters ? props.dashboardFilters : [],
        )
      }

      // Filtered pivot data (if filters are applied)
      const filteredRows = await fetchData('themes', themeQueries(false, false))
      pivotData.value = [...pivotData.value, ...filteredRows.payload]

      // Overall pivot data (no filters)
      const overallRows = props.isFiltered ? await fetchData('themes-overall', themeQueries(false, false), false) : null
      if (overallRows) {
        overallData.value = [...overallData.value, ...overallRows.payload]
      }

      // If in drilldown mode, in order to calculate the relative freq %
      // we need to fetch the freq for the drilldown node along with the
      // freq for the drilldown node AND each tree node
      let drilldownRows = null
      let drilldownOverlapRows = null
      if (props.baseQuery && drilldownNode.value) {
        drilldownOverlapRows = await fetchData('themes-overlap-drilldown', themeQueries(true, true))
        if (drilldownOverlapRows) {
          drilldownOverlapData.value = [...drilldownOverlapData.value, ...drilldownOverlapRows.payload]
        }

        drilldownRows = await fetchData('themes-drilldown', [
          {
            ...props.baseQuery,
            name: 'drilldown',
          } as SavedQuery,
        ])
        if (drilldownRows) {
          drilldownData.value = [...drilldownData.value, ...drilldownRows.payload]
        }
      }

      const rows = payloadDataToRows(
        filteredRows.payload,
        overallRows ? overallRows.payload : null,
        drilldownRows ? drilldownRows.payload : null,
        drilldownOverlapRows ? drilldownOverlapRows.payload : null,
        nodes,
        selectedDisplay.value,
        props.isFiltered,
      )

      return rows.map((row) => {
        // Append diff if filtered
        if (props.isFiltered && !isDrilldown.value) {
          return row.concat(row[0] - row[1])
        }
        return row
      })
    }

    // Determine which columns should be formatted as a percentage
    const isPercent = computed(() => {
      const result = [false, false, false]
      if (
        selectedDisplay.value.match(/^__score(__impact)?__(top|bot)_box/) ||
        selectedDisplay.value.endsWith(' Sentiment') ||
        selectedDisplay.value === 'Frequency (%)'
      ) {
        return [true, true, true]
      }
      if (selectedDisplay.value === 'Frequency') {
        return [false, true, false]
      }
      return result
    })

    const menus = computed((): WidgetMenuOptions[] => {
      if (isDrilldown.value) return []
      return makeMenu(
        props.hasNps,
        selectedDisplay.value,
        props.hasSentiment,
        props.hasNumericFields,
        props.isFiltered,
        props.schema,
      )
    })

    const tooltipData = computed(() => {
      if (tooltipNode.value == null) return null
      return getTooltipData(
        tooltipNode.value,
        pivotData.value,
        overallData.value,
        drilldownData.value,
        drilldownOverlapData.value,
        selectedDisplay.value,
        props.hasNps,
        props.hasSentiment,
        props.isFiltered,
      )
    })

    const footerText = computed(() => {
      const q = props.baseQuery
      return q ?
          `Calculated relative to Theme${q.group ? ' Group' : ''}: ${q.name} (including filters).`
        : 'Calculated relative to overall data (including filters).'
    })

    // Update the selected display option if it is no longer valid
    const validateDisplayOption = () => {
      if (!menus?.value?.length) return
      const validatedOptions = getAllOptionKeys(menus.value[0])
      if (!validatedOptions.includes(selectedDisplay.value)) {
        selectedDisplay.value = validatedOptions[0] ?? 'Frequency'
      }
    }

    // Initial data fetch
    const fetchData = async () => {
      if (isLoading.value) return
      isLoading.value = true
      pivotData.value = []
      overallData.value = []
      drilldownData.value = []
      drilldownOverlapData.value = []
      tree.value?.resetTree()
      isLoading.value = false
    }

    const setSelection = (menuName: 'Display', field: [string | null, string]) => {
      if (menuName === 'Display') {
        selectedDisplay.value = field[1]
      }
      fetchData()
      updateConfig()
    }

    const updateMouse = (e: MouseEvent) => {
      mouseX.value = e.clientX
      mouseY.value = e.clientY
    }

    // The tree node that is currently being drilled into
    const drilldownNode = computed<NodeData | null>(() => {
      if (!isDrilldown.value || !props.baseQuery) return null
      const type = props.baseQuery.group ? 'group' : 'theme'
      return {
        type,
        id: Number(props.baseQuery.id),
        name: props.baseQuery.name ?? '',
        key: `${type}_${props.baseQuery.id}`,
      }
    })

    const flattenGroupTree = (tree: NodeData[]): NodeData[] => {
      const flattened: NodeData[] = []
      const stack: NodeData[] = [...tree].reverse()

      while (stack.length) {
        const node = stack.pop()!
        flattened.push(node)
        if ('children' in node && node.children) {
          for (let i = node.children.length - 1; i >= 0; i--) {
            stack.push(node.children[i])
          }
        }
      }
      return flattened
    }

    const getLabel = (node: NodeData) => {
      const groupNameMap = node.type === 'theme' ? themeToGroupNameMap.value : groupToGroupNameMap.value

      if (groupNameMap?.[Number(node.id)]) {
        let groupName = groupNameMap[Number(node.id)]
        return `${node.name} [${groupName}]`
      }

      return node.name ?? ''
    }

    const getCsvData = () => {
      const out: Record<string, number | string>[] = []
      const flattenedNodes = flattenGroupTree(tree.value!.treeData)

      flattenedNodes.forEach((node) => {
        const vals = tree.value!.columnValueMap[`${node.type}_${node.id}`]
        if (vals) {
          const typeMap = { group: 'Theme Group', theme: 'Theme' }
          const rowData = [node.name, typeMap[node.type], ...vals]
          const rowObj: Record<string, number | string> = {}
          const rowHeaders = ['NAME', 'TYPE', ...headers.value.slice(1)]
          rowHeaders.forEach((header, i) => (rowObj[header] = rowData[i]))
          out.push(rowObj)
        }
      })
      return out
    }

    const getCsvDataAllData = async () => {
      const out: Record<string, number | string>[] = []

      let group_tree = await fetchTree()
      const flattenedNodes = flattenGroupTree(group_tree)
      const data = await fetchColumnData(flattenedNodes)
      let columnValueMap: Record<string, Array<number>> = {}
      for (const [i, node] of flattenedNodes.entries()) {
        columnValueMap[`${node.type}_${node.id}`] = data[i]
      }

      flattenedNodes.forEach((node) => {
        const vals = columnValueMap[`${node.type}_${node.id}`]
        if (vals) {
          const typeMap = { group: 'Theme Group', theme: 'Theme' }
          const rowData = [node.name, typeMap[node.type], ...vals]
          const rowObj: Record<string, number | string> = {}
          const rowHeaders = ['NAME', 'TYPE', ...headers.value.slice(1)]
          rowHeaders.forEach((header, i) => (rowObj[header] = rowData[i]))
          out.push(rowObj)
        }
      })
      return out
    }

    const makePptSlide = (pptx: PptxGenJS) => {
      const slide = pptx.addSlide()

      const nodes = flattenGroupTree(tree.value!.treeData).filter(
        (n) => !!tree.value!.columnValueMap[`${n.type}_${n.id}`],
      )
      const header = headers.value[Math.max(tree.value!.sortAttribute, 1)]

      makeBarChartSlide(
        pptx,
        slide,
        [
          {
            name: 'Themes',
            labels: nodes.map((n) => getLabel(n)),
            values: nodes.map((n) => tree.value!.getSortedVal(n.type, Number(n.id))),
          },
        ],
        `${props.exportName} - Themes`,
        header,
        'Themes & Groups',
      )
    }

    const makePptSlideAllData = async (pptx: PptxGenJS) => {
      const slide = pptx.addSlide()

      let group_tree = await fetchTree()
      const nodes = flattenGroupTree(group_tree)
      const data = await fetchColumnData(nodes)
      let columnValueMap: Record<string, Array<number>> = {}
      for (const [i, node] of nodes.entries()) {
        columnValueMap[`${node.type}_${node.id}`] = data[i]
      }

      const header = headers.value[Math.max(tree.value!.sortAttribute, 1)]

      // Get the value of the sorted column for a node
      const getSortedVal = (type: string, id: number) => {
        const index = Math.max(0, tree.value!.sortAttribute - 1)
        const key = `${type}_${id}`
        if (!columnValueMap[key]) return 0
        return columnValueMap[key][index]
      }
      makeBarChartSlide(
        pptx,
        slide,
        [
          {
            name: 'Themes',
            labels: nodes.map((n) => getLabel(n)),
            values: nodes.map((n) => getSortedVal(n.type, Number(n.id))),
          },
        ],
        `${props.exportName} - Themes`,
        header,
        'Themes & Groups',
      )
    }

    watch(width, () => {
      setTimeout(() => {
        tree.value?.resize()
      }, 400)
    })

    watch(
      () => props.dashboardFilters,
      (newVal, oldVal) => {
        if (!isEqual(oldVal, newVal)) {
          fetchData()
        }
      },
      {
        deep: true,
      },
    )

    watch(
      () => props.isFiltered,
      () => {
        validateDisplayOption()
      },
    )

    watch(selectedDisplay, () => {
      fetchData()
    })

    const setOptionsFromConfig = () => {
      const sortBy = props.config?.options.sortBy
      const ascendingSort = props.config?.options.ascendingSort
      const display = props.config?.options.display
      if (display) {
        selectedDisplay.value = display
      }
      if (tree.value && sortBy != null) {
        tree.value.sortAttribute = sortBy
      }
      if (tree.value && ascendingSort != null) {
        tree.value.isAscending = ascendingSort
      }
    }

    const updateConfig = () => {
      const updated = Object.assign({}, props.config, {
        options: {
          display: selectedDisplay.value,
          sortBy: tree.value?.sortAttribute,
          ascendingSort: tree.value?.isAscending,
        },
      } as typeof props.config)
      emit('config-changed', updated)
    }

    onMounted(() => {
      setOptionsFromConfig()
      fetchData()
      validateDisplayOption()
    })

    const rowHover = (event: MouseEvent, data: GroupOrTheme) => {
      if (!dropdownVisible.value && !nameHovered.value) {
        tooltipNode.value = data
      }
      updateMouse(event)
    }

    // Remove themes not in visibleThemeIds and empty groups
    const filterTree = (tree: GroupOrTheme[]): GroupOrTheme[] => {
      return tree
        .map((node) => {
          if (node.type === 'theme') {
            if (!props.visibleThemeIds.length) return node
            return props.visibleThemeIds.includes(Number(node.id)) ? node : null
          }
          const children = filterTree(node.children)
          return children.length > 0 ? { ...node, children } : null
        })
        .filter((node) => node !== null) as GroupOrTheme[]
    }

    watch(
      () => props.visibleThemeIds,
      (newVal, oldVal) => {
        if (!isEqual(newVal, oldVal)) {
          fetchData()
        }
      },
    )

    const fetchTree = async () => {
      const { group_tree } = await ThemeGroup.list(props.project.id, props.analysis.id)
      if (group_tree.length === 0) {
        noData.value = true
      }
      return filterTree(group_tree)
    }

    return {
      getChartEl: () => root.value?.$el.querySelector('div.content'),
      root,
      icon,
      errorIcon,
      refresh: window.location.reload,
      isLoading,
      menus,
      setSelection,
      width,
      userErrors: null,
      selectedDisplay,
      fetchData,
      Intercom: window.Intercom,
      headers,
      number,
      isPercent,
      isLeaf: (n: GroupOrTheme) => n.type === 'theme',
      updateMouse,
      tooltipNode,
      mouseX,
      mouseY,
      tooltipData,
      footerText,
      isDrilldown,
      dropdownVisible,
      nameHovered,
      getCsvData,
      getCsvDataAllData,
      makePptSlide,
      makePptSlideAllData,
      tree,
      noData,
      fetchColumnData,
      drilldownNode,
      rowHover,
      fetchTree,
      updateConfig,
    }
  },
})

export default ThemesWidget
</script>
<style lang="scss" scoped>
@import '~assets/kapiche.sass';

.empty-message {
  text-align: center;
  color: $subdued;
  font-size: 2rem;
  margin: 40px 0;
}
.error-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  padding-bottom: 30px;

  button {
    background: none;
    border: none;
    border-bottom: 2px solid $blue;
    padding: 3px 4px;
    &:hover {
      background-color: $grey-light;
    }
    &:focus {
      border: 2px solid $blue-light;
      outline: none;
    }
  }
}
.message {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgba(255, 0, 0, 0.1);
  padding: 6px;
  color: $text-black;
  width: 100%;
  max-height: 30px;
  position: absolute;
  bottom: 0;
}

.errorIcon {
  position: relative;
  height: 32px;
  width: 32px;
  display: inline-block;
  top: 10px;
}
.action {
  padding-top: 20px;
}
.default-actions {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

.content {
  width: 100%;
}
</style>
