<template>
  <div class="workbench-header">
    <router-link :to="{ name: 'home' }" active-class="never-active">
      <img class="kapiche-logo" :src="kapiche_logo" />
    </router-link>

    <div v-if="breadcrumbItems" class="breadcrumbs">
      <template v-for="(item, index) in breadcrumbItems" :key="index">
        <div class="chevron">
          <img class="chevron-img" :src="dashboard_chevron_right" />
        </div>
        <div class="breadcrumb">
          <span class="label-item">{{ item.labelItem }}</span>
          <router-link v-if="item.condition()" :to="item.routerLinkTo" :class="[item.routerLinkClass]">
            {{ item.routerLinkValue }}
          </router-link>
        </div>
      </template>
    </div>

    <div
      v-if="currentUser && !viewerMode"
      class="back-to-analysis"
      :style="{
        marginLeft: 'auto',
      }"
    >
      <router-link :to="{ name: 'storyboard' }">
        <icon :size="14" name="chevron-left" color="#fff" />
        Back to analysis
      </router-link>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed, toRef, PropType } from 'vue'
import { useStore } from 'vuex'

import Icon from 'components/Icon.vue'
import { Dashboard } from 'src/types/DashboardTypes'
import { UserProfile } from 'src/types/UserTypes'
import { DrilldownSegment, GroupOrTheme, useBreadcrumbItems } from '../Dashboard.utils'
import { Drilldown } from 'src/pages/trial/Workbench/Workbench.utils'

export default defineComponent({
  name: 'WorkbenchHeader',
  components: {
    Icon,
  },
  props: {
    dashboardType: {
      type: [String, Boolean] as PropType<Drilldown>,
      default: false,
    },
    drilldownConcepts: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    drilldownSegment: {
      type: Object as PropType<DrilldownSegment | null>,
      default: null,
    },
    themeGroupTree: {
      type: Array as PropType<GroupOrTheme[]>,
      default: () => [],
    },
    queryId: {
      type: Number as PropType<string | number | null>,
      default: null,
    },
    viewerMode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore()

    const currentUser = computed(() => store.getters.currentUser as UserProfile | null)
    const dashboard = computed(() => store.getters.currentDashboard as Dashboard | null)

    const isDrilldown = false

    const breadcrumbItems =
      !props.viewerMode &&
      useBreadcrumbItems(
        toRef(props, 'dashboardType'),
        toRef(props, 'drilldownConcepts'),
        toRef(props, 'drilldownSegment'),
        toRef(props, 'themeGroupTree'),
        toRef(props, 'queryId'),
        true,
      )

    return {
      isDrilldown,
      currentUser,
      dashboard,
      /* eslint-disable @typescript-eslint/no-require-imports */
      kapiche_logo: require('assets/img/dashboards/kapiche_mark_inverse.svg'),
      dashboard_chevron_right: require('assets/img/dashboards/dashboard-chevron-right.svg'),
      breadcrumbItems,
    }
  },
})
</script>
<style lang="scss" scoped>
@import 'assets/kapiche.sass';

$icon-color: rgba(255, 255, 255, 0.3);

.workbench-header {
  display: flex;
  align-items: center;
  background-color: $blue-dark;
  padding: 16px 30px;

  a {
    cursor: pointer;
  }
}

.kapiche-logo {
  height: 40px;
  margin-right: 16px;
}

.back-to-analysis {
  a {
    color: $grey-mid;
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  :deep(.icon-wrapper > span) {
    margin-right: 4px;
    background-color: $grey-mid !important;
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  .breadcrumb {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
    min-width: 0;
    flex: 0 1 auto;
    color: rgba(255, 255, 255, 0.9);
  }
  .chevron {
    padding-left: 0;
    padding-right: 0;
    .chevron-img {
      height: 15px;
      width: 15px;
      opacity: 0.3;
    }
  }
  .label-item {
    font-size: 11px;
    font-weight: bold;
    line-height: 13px;
    color: rgba(149, 166, 172, 0.7);
    opacity: 0.7;
    text-transform: uppercase;
  }
  .link {
    font-size: 16px;
    color: rgb(255, 255, 255);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}
</style>
