<template>
  <widget-frame
    ref="root"
    :zoomed="isZoomed"
    :is-loading="isLoading"
    :masked="masked"
    :dev-mode="devMode"
    :has-errored="!!hasErrored"
    :banner="banner"
    class="themes"
    @resize="setChartDimensions"
  >
    <!--======================== ACTIONS -->
    <template #actions>
      <div>
        <div class="default-actions">
          <download-export-button
            :name="exportName + '-Compare Themes, Concepts & Phrases'"
            :is-loading="isLoading"
            :get-el="getChartEl"
            :get-csv-data="getCsvData"
            :get-svg-export-config="getSvgExportConfig"
            :make-ppt-slide="makePptSlide"
            short-name="Compare Themes, Concepts & Phrases"
          ></download-export-button>
          <router-link v-if="!isZoomed && zoomToRoute" class="widget-action expand" :to="zoomToRoute">
            <i class="kapiche-icon-fullscreen"></i>
          </router-link>
          <a :href="CONST.widget_help_links.themes" class="widget-action help" target="_blank">
            <i class="kapiche-icon-info"></i>
          </a>
        </div>
      </div>
    </template>

    <!--======================== ICON -->
    <template #icon>
      <img class="header-icon" :src="icon" alt="Themes Icon" />
    </template>

    <!--======================== HEADING -->
    <template #header> Themes, Concepts &amp; Phrases </template>

    <!--======================== MENU -->
    <template #menu>
      <sort-controls
        v-if="isZoomed"
        :sort-options="sortOptions"
        :selected-sort-option="sortOption"
        :visible-count="rowLimit"
        :visible-options="[6, 9, 12, 15]"
        :slice-names="[sliceOneName, sliceTwoName]"
        :slice-index="sortSlice"
        @update-visible-count="rowLimit = $event"
        @update-sort-method="sortOption = $event"
        @update-sort-slice="sortSlice = $event"
      />
      <widget-menu :menus="menus" :vertical="isZoomed" :bound="$el" :max-label-length="35" @on-select="setSelection" />
    </template>

    <!--======================== DEV PANEL -->
    <template #devPanel>
      <div>
        segment fields: {{ segmentFields }} <br />
        isLoading: {{ isLoading }} <br />
        hasNps: {{ hasNps }} <br />
        hasSentiment: {{ hasSentiment }} <br />
        <hr />
        <h2>this.props</h2>
        <code style="white-space: pre"
          ><!--
          -->{{ JSON.stringify($props, null, 2) }}
        </code>
      </div>
    </template>
    <!--======================== ERROR PANEL -->
    <template #error-panel>
      <div class="error-panel">
        <h3>
          <img class="errorIcon" :src="errorIcon" alt="widget error icon" />
          Opps, something went wrong while loading this widget.
        </h3>
        <div class="action">
          Try
          <button @click.stop="reload">reloading this widget</button> or
          <button @click.stop="refresh">reloading the page</button>
        </div>
        <div class="action"><button @click.stop="contact">Contact support</button> if the problem persists.</div>
      </div>
    </template>
    <!--======================== CONTENT -->
    <template v-if="rowsForChart.length > 0" #content>
      <div ref="wrapper">
        <sort-controls
          v-if="!isZoomed"
          :sort-options="sortOptions"
          :selected-sort-option="sortOption"
          :visible-count="rowLimit"
          :visible-options="[6, 9, 12, 15]"
          :slice-names="[sliceOneName, sliceTwoName]"
          :slice-index="sortSlice"
          @update-visible-count="rowLimit = $event"
          @update-sort-method="sortOption = $event"
          @update-sort-slice="sortSlice = $event"
        />
        <segmentation-chart
          v-if="!isLoading"
          :rows="rowsForChart"
          :headings="headings"
          :legend="legend"
          :max="range['max']"
          :min="range['min']"
          :width="width"
          :show-indicator-bar="false"
          :series-labels="seriesLabels"
          :series-tags="groupTags"
          empty-segment-text="(No Value)"
          @menu-closed="handleRowClicked(null)"
          @clicked-heading="setSorting"
          @row-clicked="handleRowClicked"
          @hover-row="setHoverRowIndex"
        >
          <template #row-tool-tip>
            <data-tool-tip v-if="clickedRowIndex === null" v-bind="hoverTheme" />
          </template>
          <template #interaction-menu="{ index, row }">
            <button @click="navigateTo(row)">
              Drill into <b>{{ interactionLabel[index] }}</b>
              <span v-if="groupTags[row.id]" :style="{ fontSize: 'inherit', marginLeft: '5px' }" class="group-tag">
                [{{ groupTags[row.id] }}]
              </span>
            </button>
          </template>
        </segmentation-chart>
        <router-link
          v-if="!isZoomed && zoomToRoute && rowLimit != 999 && totalRowCount > 0"
          class="show-all-link"
          :to="zoomToRoute"
          @click="rowLimit = 999"
        >
          View all {{ totalRowCount }}
        </router-link>
      </div>
    </template>
    <template v-else #content>
      <widget-message-panel>
        <template #title>
          <span>No Data</span>
        </template>
        <template #message>
          <span>There is not sufficient data to display this widget.</span>
        </template>
      </widget-message-panel>
    </template>
    <template #footer>
      <div>
        <small>{{ footerText }} </small>
      </div>
    </template>
  </widget-frame>
</template>

<script lang="ts">
import {
  computed,
  ComputedRef,
  defineComponent,
  inject,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  watch,
  Ref,
  toRef,
} from 'vue'
import PptxGenJS from 'pptxgenjs'
import { isEqual } from 'lodash'
import { useStore } from 'vuex'

import SegmentationChart from 'components/charts/SegmentationChart/SegmentationChart.vue'
import DownloadExportButton from 'components/project/analysis/results/widgets/DownloadExportButton.vue'
import WidgetFrame from 'components/widgets/WidgetFrame/WidgetFrame.vue'
import WidgetMenu from 'components/DataWidgets/WidgetMenu/WidgetMenu.vue'
import icon from 'assets/img/dashboards/dash-queries.svg'
import errorIcon from 'assets/icons/alert-bubble.svg'
import WidgetMessagePanel from 'components/widgets/WidgetMessagePanel/WidgetMessagePanel.vue'

import ProjectAPI from 'src/api/project'
import {
  minMaxValues,
  sortRows,
  payloadDataToRowsCompare,
  payloadDataToCSVExport,
  payloadToDataCompare,
  toolTipDataCompare,
  CompareItem,
  truncationSort,
} from './ThemesWidget.utils'
import { makeMenuCompare, formatScoreColumns } from './ThemesWidget.menu'
import { makeRequirements } from 'components/DataWidgets/ThemesWidget/ThemesWidget.requirements'
import DataToolTip from '../DataToolTip/DataToolTip.vue'
import { PayloadItem } from 'types/PivotData.types'
import { TableChartRowType, SegmentationLabel, ChartHeading } from 'types/components/Charts.types'
import { WidgetMenuOptions } from 'types/components/WidgetMenu.types'
import { WidgetConfig } from 'src/types/DashboardTypes'
import { APIResponseDataItem as PhraseData } from 'components/DataWidgets/KeyPhrases/KeyPhrases.utils'
import { SavedQuery } from 'src/types/Query.types'
import { Analytics } from 'src/analytics'
import { makeBarChartSlide } from '../DataWidgetUtils'
import { ExpandedGroup } from 'src/pages/dashboard/Dashboard.utils'
import { formatDisplayForConfig, formatDisplayForLabel, getScoreOptionsForRequirements } from './ScoreUtils'
import { SchemaColumn } from 'src/types/SchemaTypes'
import { truncate } from 'src/utils/formatters'
import SortControls from '../SortControls.vue'

interface PayloadType {
  overall: PayloadItem[]
  phrases?: PhraseData[]
  sliceOne?: PayloadItem[]
  sliceTwo?: PayloadItem[]
}

type PayloadDataType = Record<string, CompareItem>

const ThemesWidget = defineComponent({
  components: {
    WidgetFrame,
    WidgetMenu,
    SegmentationChart,
    DownloadExportButton,
    DataToolTip,
    WidgetMessagePanel,
    SortControls,
  },
  props: {
    sliceOneName: { type: String, required: true },
    sliceTwoName: { type: String, required: true },
    /** route to navigate to when row is clicked on */
    toQueryRoute: { type: Object, required: false, default: null },
    toConceptRoute: { type: Object, required: false, default: null },
    /** for export filename */
    exportName: { type: String, required: false, default: '' },
    /** is this DataWidget in zoomed mode?  */
    isZoomed: { type: Boolean, required: false, default: false },
    /** route object for zoom button */
    zoomToRoute: { type: Object, required: false, default: null },
    /** data to render */
    data: { type: Object, required: false, default: null },
    sliceOneFilters: { type: Array, required: false, default: () => [] },
    sliceTwoFilters: { type: Array, required: false, default: () => [] },
    /** explanatory text to display at the bottom of the widget **/
    footerText: { type: String, required: false, default: '' },
    /** show developer tools in widget */
    devMode: { type: Boolean, required: false, default: false },
    /** list of queries for the chart */
    queries: { type: Array as PropType<SavedQuery[]>, required: false, default: () => [] },
    /** list of concepts queries for the chart */
    concepts: { type: Array as PropType<SavedQuery[]>, required: false, default: () => [] },
    /** if filters have been applied to the dashboard, then we can calculate
     * the expected values for the data */
    isFiltered: { type: Boolean, required: false, default: false },
    /** fields for menu */
    segmentFields: { type: Array as PropType<{ name: string; type: string }[]>, required: false, default: () => [] },
    /** does this data contain NPS? */
    hasNps: { type: Boolean, required: false, default: false },
    /** does this data contain sentiment? */
    hasSentiment: { type: Boolean, required: false, default: false },
    /** does this data contain numeric fields? */
    hasNumericFields: { type: Boolean, default: false, required: false },
    /** widget banner to display */
    banner: { type: Object, default: () => null, required: false },
    /** Add a skeleton mask (used when reloading state between dashboards) */
    masked: { type: Boolean, required: false, default: false },
    config: {
      type: Object as PropType<WidgetConfig<'compare-themes-concepts'> | null>,
      required: false,
      default: null,
    },
    themeGroups: { type: Array as PropType<ExpandedGroup[]>, required: false, default: () => [] },
    schema: { type: Array as PropType<SchemaColumn[]>, required: false, default: () => [] },
  },
  emits: ['requires-phrases', 'requires', 'go-to-theme', 'go-to-theme-group', 'go-to-concept', 'config-changed'],
  setup(props, { emit }) {
    const analytics = inject<Analytics>('analytics')
    const featureFlags = inject<Record<string, boolean>>('featureFlags', {})
    const store = useStore()
    const themeToGroupNameMap = computed<Record<number, string>>(() => store.getters['themeToGroupNameMapById'])
    const groupToGroupNameMap = computed<Record<number, string>>(() => store.getters['groupToGroupNameMapById'])
    const themeNameMap = inject<ComputedRef<Record<number, string>>>(
      'themeNameMap',
      computed(() => ({})),
    )
    const themeGroupNameMap = inject<ComputedRef<Record<number, string>>>(
      'themeGroupNameMap',
      computed(() => ({})),
    )
    const showGroupLabels = inject<Ref<boolean>>('showGroupLabels', toRef(true))

    const root = ref<InstanceType<typeof WidgetFrame> | null>(null)
    const wrapper = ref<InstanceType<typeof HTMLDivElement> | null>(null)

    const selectedData = ref('Themes')
    const selectedDisplay = ref('Frequency (%)')
    const validOptions = ref(true)
    const sortBy = ref(2)
    const ascendingSort = ref(false)
    const clickedRowIndex = ref<number | null>(null)
    const hoveredRowIndex = ref<number | null>(null)
    const width = ref(300)
    const hasSetConfig = ref(false)

    // Truncation sorting values
    const sortSlice = ref<0 | 1>(0)
    const rowLimit = ref(15)
    const sortOption = ref('')
    const totalRowCount = computed<number>(() => {
      // -1 because it contains the overall row
      return payload.value.overall.length - 1
    })
    const sortOptions = computed<string[]>(() => {
      // In compare mode, we only want to sort the first 2 columns
      // because columns 2 and 3 are the same data for each slice
      return headings.value.slice(0, 2).flatMap((h, i) => {
        return i === 0 ?
            [`${h.label} name (Desc)`, `${h.label} name (Asc)`]
          : ['Highest ' + h.label, 'Lowest ' + h.label]
      })
    })
    watch([sortOption, rowLimit, sortSlice], () => {
      if (hasSetConfig.value) {
        updateConfig()
      }
    })

    const seriesLabels = computed<Record<string, string>>(() => {
      // Theme Groups are prefixed with 'group_' to avoid conflicts with Themes
      const labels = props.themeGroups.map((g) => [g.name, g.name.replace(/^group_/, '')])
      return Object.fromEntries(labels)
    })

    const payload = computed((): PayloadType => {
      return {
        overall: props.data?.overall?.data?.payload,
        phrases: props.data?.phrases?.data?.payload,
        sliceOne: props.data?.sliceOne?.data?.payload,
        sliceTwo: props.data?.sliceTwo?.data?.payload,
      }
    })

    const phrases = computed((): PhraseData[] => {
      return payload.value.phrases ?? []
    })

    const topPhrasesAsQueries = computed(() => {
      // copy the concepts and sort them as expected here:
      // returning with the same Type as a regular query
      if (phrases.value === undefined) return []
      return (
        phrases.value
          .slice()
          // sort by frequency (not by name)
          .sort((a, b) => a.count_phrase - b.count_phrase)
          // translate top concepts into "queries"
          .map(
            (c: { phrase_text: string }) =>
              ({
                name: c.phrase_text,
                query_value: {
                  type: 'match_any',
                  includes: [
                    {
                      type: 'text',
                      value: c.phrase_text,
                    },
                  ],
                },
              }) as SavedQuery,
          )
      )
    })

    const themesOrConceptsQueries = computed<Partial<SavedQuery | ExpandedGroup>[]>(() => {
      if (selectedData.value === 'Themes') {
        return props.queries.map((q) => ({
          ...q,
          name: `q_${q.id}`,
        }))
      } else if (selectedData.value === 'Theme Groups') {
        return props.themeGroups.map((q) => ({
          ...q,
          name: `group_${q.id}`,
        }))
      } else if (selectedData.value === 'Top Concepts') {
        return props.concepts
      } else if (selectedData.value === 'Top Phrases') {
        return topPhrasesAsQueries.value
      } else {
        throw new Error(`Unknown data type ${selectedData.value}`)
      }
    })

    const payloadData = computed((): PayloadDataType => {
      return payloadToDataCompare(payload.value, themesOrConceptsQueries.value, selectedData.value)
    })

    const selectedDataLabel = computed((): 'Theme' | 'Concept' | 'Phrase' | 'Theme Group' => {
      if (selectedData.value === 'Themes') {
        return 'Theme'
      } else if (selectedData.value === 'Theme Groups') {
        return 'Theme Group'
      } else if (selectedData.value === 'Top Concepts') {
        return 'Concept'
      } else if (selectedData.value === 'Top Phrases') {
        return 'Phrase'
      } else {
        throw new Error(`Unknown data type ${selectedData.value}`)
      }
    })

    const numericalFields = computed((): string[] => {
      return props.segmentFields.reduce(
        (list, field) =>
          field.type === ProjectAPI.COLUMN_LABELED_TYPES.get('NUMBER') ? list.concat(field.name) : list,
        [] as string[],
      )
    })

    const groupTags = computed(() => {
      if (selectedData.value === 'Themes') {
        return showGroupLabels.value ? themeToGroupNameMap.value : {}
      }
      if (selectedData.value === 'Theme Groups') {
        return groupToGroupNameMap.value
      }
      return {}
    })

    const scoreColumns = computed((): SchemaColumn[] => {
      return props.schema.filter((col) => col.type === 8)
    })

    const scoreFields = computed((): string[] => {
      let score_type = ProjectAPI.COLUMN_LABELED_TYPES.get('SCORE')
      return props.segmentFields.filter((field) => field.type === score_type).map((field) => field.name)
    })

    const isLoading = computed((): boolean => {
      if (!validOptions.value) return true
      return (
        props.data?.sliceOne?.status === 'fetching' ||
        props.data?.overall?.status === 'fetching' ||
        props.data?.sliceTwo?.status === 'fetching' ||
        props.data?.phrases?.status === 'fetching'
      )
    })

    const rowsForChart = computed((): TableChartRowType[] => {
      if (isLoading.value) return []

      let nameFormatter = (name: string) => name
      if (selectedData.value === 'Themes') {
        nameFormatter = (name: string) => {
          const queryId = Number(name.replace(/^q_/, ''))
          const query = props.queries.find((q) => q.id === queryId)
          const queryName = query?.name ?? name
          return seriesLabels.value[queryName] ?? queryName
        }
      }
      if (selectedData.value === 'Theme Groups') {
        nameFormatter = (name: string) => {
          const queryId = Number(name.replace(/^group_/, ''))
          const query = props.themeGroups.find((q) => q.id === queryId)
          const queryName = query?.name ?? name
          return seriesLabels.value[queryName] ?? queryName
        }
      }

      let rows = payloadDataToRowsCompare(
        payloadData.value,
        themesOrConceptsQueries.value,
        selectedDisplay.value,
        nameFormatter,
      )

      // Truncation sorting (seperate to column sorting)
      let optionIndex = sortOptions.value.indexOf(sortOption.value)
      rows = truncationSort(rows, optionIndex, rowLimit.value, sortSlice.value)

      return sortRows(rows, sortBy.value, ascendingSort.value)
    })

    const range = computed((): { min: number; max: number } => {
      // min/max values are rounded (down for min, up for max) to next integer)
      const minMax = minMaxValues(rowsForChart.value, selectedDisplay.value, sortBy.value, false, 1)
      return minMax ?? { min: 0, max: 0 }
    })

    const hoverTheme = computed(() => {
      if (hoveredRowIndex.value == null) return null

      const chartRow = rowsForChart.value[hoveredRowIndex.value]
      if (!chartRow) return null

      let label = chartRow?.label ?? ''
      let lookup = label

      if (selectedData.value === 'Themes') {
        lookup = `q_${chartRow.id}`
      }

      if (selectedData.value === 'Theme Groups') {
        lookup = `group_${chartRow.id}`
      }

      const dataItem = payloadData.value[lookup]
      if (!dataItem) return null

      const name = seriesLabels.value[label] ?? label
      return toolTipDataCompare(name, dataItem, props.hasNps, props.sliceOneName, props.sliceTwoName)
    })

    const interactionLabel = computed((): Record<number, string> => {
      return rowsForChart.value.map((r: TableChartRowType) => r.label ?? '')
    })

    const validatedOptions = computed((): Required<WidgetConfig<'compare-themes-concepts'>['options']> => {
      let display = props.config?.options?.display
      let data = props.config?.options?.data
      let sortBy = props.config?.options?.sortBy
      let ascendingSort = !!props.config?.options?.ascendingSort
      let rowLimit = props.config?.options?.rowLimit ?? 15
      let sortOption = props.config?.options?.sortOption
      let sortSlice = props.config?.options?.sortSlice ?? 0

      if (!sortOption || !sortOptions.value.includes(sortOption)) {
        sortOption = sortOptions.value[0]
      }

      // validate display
      if (
        !display ||
        (display === `nps_` && !props.hasNps) ||
        (display?.endsWith('Sentiment') && !props.hasSentiment) ||
        (numericalFields.value.includes(display) && !props.hasNumericFields)
      ) {
        display = 'Frequency (%)'
      }

      if (display.startsWith('Impact on') && display.endsWith('Sentiment')) {
        display = display.slice(10)
      }

      const scoreColNames: string[] = scoreColumns.value.map((col) => col.name)
      // These 2 conditions mean that the numerical column has been converted to score column.
      // Reformat accordingly
      if (
        display.startsWith(`__avg__`) &&
        scoreColNames.includes(display.slice(7)) &&
        !numericalFields.value.includes(display.slice(7))
      ) {
        display = `__score__${display.slice(7)}`
      } else if (
        display.startsWith('__impact_on_avg__') &&
        scoreColNames.includes(display.slice(17)) &&
        !numericalFields.value.includes(display.slice(17))
      ) {
        display = `__score__impact__${display.slice(17)}`
      }

      if (display.startsWith('__score__')) {
        const scoreColName = display.startsWith('__score__impact') ? display.slice(17) : display.slice(9)
        const scoreCol = scoreColumns.value.find((col) => col.name === scoreColName)
        if (!scoreCol) {
          // This means that the column might have been of score type previously, but may have been
          // converted to a numerical column. Reformat accordingly.
          display = display.startsWith('__score__impact') ? `__impact_on_avg__${scoreColName}` : scoreColName
        } else {
          // This is a valid score column
          const formattedCol = formatScoreColumns(scoreCol, true)
          display = display.startsWith('__score__impact') ? formattedCol[1].value : formattedCol[0].value
        }
      }

      // validate data
      if (!data) {
        data = 'Themes'
      }
      if (data === 'Themes' && props.queries.length === 0) {
        data = 'Top Concepts'
      }
      if (data === 'Theme Groups' && props.themeGroups.length === 0) {
        data = 'Top Concepts'
      }

      // validate sortBy
      if (typeof sortBy !== 'number' || sortBy < 0 || sortBy > 2) {
        sortBy = 2
      }

      return { display, data, sortBy, ascendingSort, rowLimit, sortOption, sortSlice }
    })

    const headings = computed(() => {
      const label = selectedDataLabel.value
      const display = selectedDisplay.value

      const getSortStatus = (index: number) => {
        if (index !== sortBy.value) return null
        return ascendingSort.value
      }

      const regular_headers: Record<string, ChartHeading[]> = {
        'Frequency (#)': [
          { label, sortable: true, sortAsc: getSortStatus(0) },
          { sortable: true, sortAsc: getSortStatus(1), label: 'FREQUENCY', color: '#11ACDF' },
          { sortable: true, sortAsc: getSortStatus(2), label: 'FREQUENCY', color: '#8064AA' },
        ],
        'Frequency (%)': [
          { label, sortable: true, sortAsc: getSortStatus(0) },
          { sortable: true, sortAsc: getSortStatus(1), label: 'FREQUENCY', color: '#11ACDF' },
          { sortable: true, sortAsc: getSortStatus(2), label: 'FREQUENCY', color: '#8064AA' },
        ],
        'nps_': [
          { label, sortable: true, sortAsc: getSortStatus(0) },
          { sortable: true, sortAsc: getSortStatus(1), label: 'NPS', color: '#11ACDF' },
          { sortable: true, sortAsc: getSortStatus(2), label: 'NPS', color: '#8064AA' },
        ],
        'npsi_': [
          { label, sortable: true, sortAsc: getSortStatus(0) },
          { sortable: true, sortAsc: getSortStatus(1), label: 'NPS Impact', color: '#11ACDF' },
          { sortable: true, sortAsc: getSortStatus(2), label: 'NPS Impact', color: '#8064AA' },
        ],
        'Positive Sentiment': [
          { label, sortable: true, sortAsc: getSortStatus(0) },
          { sortable: true, sortAsc: getSortStatus(1), label: 'POSITIVE SENT.', color: '#11ACDF' },
          { sortable: true, sortAsc: getSortStatus(2), label: 'POSITIVE SENT.', color: '#8064AA' },
        ],
        'Negative Sentiment': [
          { label, sortable: true, sortAsc: getSortStatus(0) },
          { sortable: true, sortAsc: getSortStatus(1), label: 'NEGATIVE SENT.', color: '#11ACDF' },
          { sortable: true, sortAsc: getSortStatus(2), label: 'NEGATIVE SENT.', color: '#8064AA' },
        ],
        'Mixed Sentiment': [
          { label, sortable: true, sortAsc: getSortStatus(0) },
          { sortable: true, sortAsc: getSortStatus(1), label: 'MIXED SENT.', color: '#11ACDF' },
          { sortable: true, sortAsc: getSortStatus(2), label: 'MIXED SENT.', color: '#8064AA' },
        ],
        'Neutral Sentiment': [
          { label, sortable: true, sortAsc: getSortStatus(0) },
          { sortable: true, sortAsc: getSortStatus(1), label: 'NEUTRAL SENT.', color: '#11ACDF' },
          { sortable: true, sortAsc: getSortStatus(2), label: 'NEUTRAL SENT.', color: '#8064AA' },
        ],
      }

      const formatLabel = (str: string) => {
        let result = str
        result = result.replace('__avg__', 'AVG ')
        result = result.replace('__impact_on_avg__', 'IMPACT ON AVG ')
        return result
      }

      let header = regular_headers[display as keyof typeof regular_headers] ?? [
        { label, sortable: true, sortAsc: getSortStatus(0) },
        { sortable: true, sortAsc: getSortStatus(1), label: formatLabel(display), color: '#11ACDF' },
        { sortable: true, sortAsc: getSortStatus(2), label: formatLabel(display), color: '#8064AA' },
      ]

      if (header[sortBy.value]) header[sortBy.value].sortAsc = ascendingSort.value
      if (display.startsWith('__score')) {
        let displayLabel = formatDisplayForLabel(display)
        header = [
          { label, sortable: true, sortAsc: getSortStatus(0) },
          { sortable: true, sortAsc: getSortStatus(1), label: displayLabel, color: '#11ACDF' },
          { sortable: true, sortAsc: getSortStatus(2), label: displayLabel, color: '#8064AA' },
        ]
      }
      return header
    })

    const hasErrored = computed((): boolean => {
      return (
        !!props.data?.sliceOne?.error ||
        !!props.data?.overall?.error ||
        !!props.data?.sliceTwo?.error ||
        !!props.data?.phrases?.error
      )
    })

    const hasQueries = computed(() => {
      return props.queries?.length > 0
    })

    const hasThemeGroups = computed(() => {
      return props.themeGroups?.length > 0
    })

    const NpsField = computed((): string | null => {
      if (!props.hasNps) return null
      type fieldType = { type: string; name: string }
      const field: fieldType | undefined = props.segmentFields.find(
        (f) => f.type === ProjectAPI.COLUMN_LABELED_TYPES.get('NPS'),
      )
      return field ? field.name : null
    })

    const legend = computed((): SegmentationLabel[] => {
      return [
        {
          label: props.sliceOneName,
          color: '#11ACDF',
        },
        {
          label: props.sliceTwoName,
          color: '#8064AA',
        },
      ]
    })

    const menus = computed((): WidgetMenuOptions[] => {
      return makeMenuCompare(
        hasQueries.value,
        selectedData.value,
        props.hasNps,
        NpsField.value,
        selectedDisplay.value,
        props.hasSentiment,
        props.hasNumericFields,
        numericalFields.value,
        props.isZoomed,
        hasThemeGroups.value,
        featureFlags,
        scoreColumns.value,
      )
    })

    const getCsvData = () => {
      let nameMap: Record<number, string> = {}
      let groupNameMap: Record<number, string> = {}
      if (selectedDataLabel.value === 'Theme') {
        nameMap = themeNameMap.value
        groupNameMap = themeToGroupNameMap.value
      }
      if (selectedDataLabel.value === 'Theme Group') {
        nameMap = themeGroupNameMap.value
        groupNameMap = groupToGroupNameMap.value
      }

      return payloadDataToCSVExport(
        payloadData.value,
        selectedDisplay.value,
        selectedDataLabel.value,
        themesOrConceptsQueries.value,
        false,
        props.hasNps,
        props.hasSentiment,
        props.hasNumericFields,
        numericalFields.value,
        [props.sliceOneName, props.sliceTwoName],
        nameMap,
        groupNameMap,
        scoreColumns.value.length > 0,
        scoreFields.value,
      )
    }

    const getChartEl = () => {
      return root.value?.$el.querySelector('div.content')
    }

    const getSvgExportConfig = () => {
      const bb = getChartEl()?.getBoundingClientRect()
      if (!bb) return
      return {
        dims: { height: bb.height, width: bb.width },
        padding: 20,
        css: `
          svg {
            top: 0;
            left: 0;
          }
          .chart {
            background-color: #fff;
            cursor: default;
          }
          text.first {
            text-anchor: start;
          }
          text.end {
            text-anchor: end;
          }
          g.row rect.bar-0 {
            fill: #11ACDF;
          }
          g.row rect.bar-1 {
            fill: #8064AA;
          }
          rect.background {
            fill: #F1F1F1;
          }
          rect.indicator {
            fill: #8064AA;
          }
          rect.hover  {
            cursor: pointer;
            fill-opacity: 0;
          }
          text.hover {
            fill: #068CCC;
          }
          text.sorted {
            fill: #068CCC;
            font-weight: bold;
          }
          g.header > g.clickable > text.sorted {
            fill: #383838;
          }
          g.header .clickable {
            cursor: pointer;
            user-select: none;
          }
          .header text {
            fill: #95A6AC;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: bold;
          }
          rect.legend-icon-one {
            fill: #11ACDF;
          }
          rect.legend-icon-two {
            fill: #8064AA;
          }
          .legend {
            cursor: default;
          }
          .up-down > path {
            fill: #95a6ac;
            fill-opacity: 1;
          }
          .up-down > path.active {
            fill: #068ccc;
          }
        `,
      }
    }

    const fetchData = (force = false) => {
      if (!selectedData.value) return
      let numericSelected = null
      if (selectedDisplay.value.startsWith('__avg__')) numericSelected = [selectedDisplay.value.slice(7)]
      if (selectedDisplay.value.startsWith('__impact_on_avg__')) numericSelected = [selectedDisplay.value.slice(17)]

      // Check for score Options
      const scoreOptions = getScoreOptionsForRequirements(selectedDisplay.value, scoreColumns.value)
      let filters = []
      if (Object.keys(scoreOptions).length > 0 && scoreOptions.excludeOutOfRange) {
        filters.push({ field: scoreOptions.name, op: '>=', value: scoreOptions.range?.[0] })
        filters.push({ field: scoreOptions.name, op: '<=', value: scoreOptions.range?.[1] })
      }

      const requirements = makeRequirements(
        props.hasNps,
        props.hasSentiment,
        numericSelected,
        scoreOptions,
        themesOrConceptsQueries.value,
        selectedData.value === 'Top Concepts' ? 25 : false,
      )

      emit(
        'requires-phrases',
        'compare-themes-concepts__phrases',
        {
          limit: props.isZoomed ? 100 : 25,
          min_concept_freq: 5,
          // If no queries are given, no NPMI will be calculated,
          // but we'll still get the top phrases by frequency. So the same
          // backend endpoint serves "Top Phrases" (on the Overview Dashboard)
          // as well as "Key Phrases" (on the Query Dashboard). In the former
          // case, as below, no queries are given. In the latter case, queries
          // are given, and thus the NPMI available in the output are with
          // respect to those queries.
          queries: [],
          sort_asc: false,
          sort_field: 'count_pair',
        },
        force,
        [...props.sliceOneFilters, ...filters], // ensure this request has filters applied
      )

      emit('requires', 'compare-themes-concepts__overall', requirements, force, filters)

      emit('requires', 'compare-themes-concepts__sliceOne', requirements, force, [...props.sliceOneFilters, ...filters])

      emit('requires', 'compare-themes-concepts__sliceTwo', requirements, force, [...props.sliceTwoFilters, ...filters])
    }

    const handleRowClicked = (index: number | null) => {
      if (index === clickedRowIndex.value) {
        clickedRowIndex.value = null
      } else {
        clickedRowIndex.value = index
      }
    }

    const navigateTo = (row: TableChartRowType) => {
      if (!selectedData.value) return

      switch (selectedData.value) {
        case 'Themes': {
          const id = row.id
          emit('go-to-theme', id)
          break
        }
        case 'Theme Groups': {
          const id = row.id
          emit('go-to-theme-group', id)
          break
        }
        case 'Top Concepts':
        case 'Top Phrases': {
          const label = row.label
          emit('go-to-concept', label)
          break
        }
        default:
          return
      }
    }

    const setSelectedDisplay = (field: [string | null, string], fromConfig: boolean) => {
      let fieldname = field[1]
      selectedDisplay.value = fieldname
      if (!fromConfig) {
        // don't track programmatic setting of this option
        analytics?.track?.themesWidget.changeDisplay(field[0]!, fieldname)
      }
    }

    const updateConfig = () => {
      const display = formatDisplayForConfig(selectedDisplay.value)

      const options: NonNullable<typeof props.config>['options'] = {
        ascendingSort: ascendingSort.value,
        display: display,
        data: selectedData.value,
        sortBy: sortBy.value,
        rowLimit: rowLimit.value,
        sortOption: sortOption.value,
        sortSlice: sortSlice.value,
      }
      const updated = Object.assign({}, props.config, { options })
      emit('config-changed', updated)
    }

    const setSelectedData = (field: [string | null, string], fromConfig: boolean) => {
      let fieldname = field[1]
      selectedData.value = fieldname
      if (!fromConfig) {
        // don't track programmatic setting of this option
        analytics?.track?.themesWidget.changeData(field[0]!, fieldname)
      }
    }

    const setSelection = (menuName: 'Display' | 'Data', field: [string | null, string], fromConfig: boolean) => {
      if (menuName === 'Display') {
        setSelectedDisplay(field, fromConfig)
      } else if (menuName === 'Data') {
        setSelectedData(field, fromConfig)
      }
      if (!fromConfig) {
        updateConfig()
      }
      fetchData()
    }

    const setSorting = (column: number, ascending = null as boolean | null, fromConfig: boolean) => {
      if (ascending === null) {
        ascendingSort.value = sortBy.value === column ? !ascendingSort.value : ascendingSort.value
      } else {
        ascendingSort.value = ascending
      }
      sortBy.value = column
      if (!fromConfig) {
        // don't track programmatic setting of this option
        updateConfig()
        analytics?.track.themesWidget.changeSort(headings.value[column].label)
      }
    }

    const setOptionsFromConfig = () => {
      setSelection('Display', [null, validatedOptions.value.display], true)
      setSelection('Data', [null, validatedOptions.value.data], true)
      setSorting(validatedOptions.value.sortBy, validatedOptions.value.ascendingSort, true)
      rowLimit.value = validatedOptions.value.rowLimit
      sortOption.value = validatedOptions.value.sortOption
      sortSlice.value = validatedOptions.value.sortSlice
    }

    const clickedOutside = (e: MouseEvent) => {
      if (clickedRowIndex.value === null) return
      const contained = wrapper.value?.contains(e.target as Node)
      if (!contained) {
        clickedRowIndex.value = null
      }
    }

    const setHoverRowIndex = (index: number) => {
      hoveredRowIndex.value = index
    }

    const refresh = () => {
      window.location.reload()
    }

    const reload = () => {
      fetchData(true)
    }

    const contact = () => {
      try {
        window.Intercom('show')
      } catch {
        console.warn('intercom show failed')
      }
    }

    const setChartDimensions = (w: number): void => {
      width.value = w
    }

    const makePptSlide = (pptx: PptxGenJS) => {
      if (!rowsForChart.value.length) return

      let label = selectedDisplay.value
      if (label === 'nps_') label = 'NPS'
      if (label === 'npsi_') label = 'NPS Impact'
      if (label.endsWith('Sentiment')) label += ' (%)'

      const slide = pptx.addSlide()

      const getLabel = (childId: number, themeName: string) => {
        let groupNameMap

        if (selectedData.value === 'Themes') {
          groupNameMap = themeToGroupNameMap.value
        }
        if (selectedData.value === 'Theme Groups') {
          groupNameMap = groupToGroupNameMap.value
        }

        if (groupNameMap?.[childId]) {
          let groupName = groupNameMap[childId]
          groupName = truncate(groupName, 20)
          return `${themeName} [${groupName}]`
        }

        return themeName ?? ''
      }

      makeBarChartSlide(
        pptx,
        slide,
        [
          {
            name: props.sliceOneName,
            labels: rowsForChart.value.flatMap((r) => [getLabel(r.id!, r.label!)]),
            values: rowsForChart.value.flatMap((r) => [r.columns[0].value]),
          },
          {
            name: props.sliceTwoName,
            labels: rowsForChart.value.flatMap((r) => [getLabel(r.id!, r.label!)]),
            values: rowsForChart.value.flatMap((r) => [r.columns[1].value]),
          },
        ],
        `${props.exportName} - Compare Themes, Concepts & Phrases`,
        label,
        selectedData.value,
        {
          chartColors: ['11ACDF', '8064AA'],
          showLegend: true,
        },
      )
    }

    watch(
      () => props.config,
      () => {
        setOptionsFromConfig()
      },
      {
        deep: true,
      },
    )

    watch(
      () => props.queries,
      (new_queries) => {
        if (new_queries.length === 0) selectedData.value = 'Top Concepts'
        setOptionsFromConfig()
        fetchData()
      },
      {
        deep: true,
      },
    )

    watch(phrases, () => {
      if (!hasErrored.value) {
        fetchData()
      }
    })

    watch(
      () => props.isFiltered,
      () => {
        setOptionsFromConfig()
        fetchData()
      },
      {
        deep: true,
      },
    )

    watch(
      () => props.data,
      (newVal, oldVal) => {
        if (!isEqual(oldVal, newVal)) {
          clickedRowIndex.value = null
        }
      },
      {
        deep: true,
      },
    )

    onMounted(() => {
      setOptionsFromConfig()
      fetchData()

      if (typeof window !== 'undefined') {
        document.addEventListener('click', clickedOutside)
      }

      setTimeout(() => {
        hasSetConfig.value = true
      })
    })

    onBeforeUnmount(() => {
      if (typeof window !== 'undefined') {
        document.removeEventListener('click', clickedOutside)
      }
    })

    return {
      getSvgExportConfig,
      getCsvData,
      getChartEl,
      root,
      icon,
      errorIcon,
      reload,
      refresh,
      contact,
      range,
      handleRowClicked,
      hoverTheme,
      navigateTo,
      interactionLabel,
      hasErrored,
      wrapper,
      isLoading,
      setChartDimensions,
      menus,
      setSelection,
      rowsForChart,
      headings,
      legend,
      width,
      setSorting,
      setHoverRowIndex,
      clickedRowIndex,
      makePptSlide,
      seriesLabels,
      selectedData,
      groupTags,
      sortOptions,
      sortOption,
      rowLimit,
      totalRowCount,
      sortSlice,
    }
  },
})

export default ThemesWidget
</script>
<style lang="sass" scoped>
@import '../../../../semantic/dist/semantic.css'
@import '~assets/kapiche.sass'

div.empty-message
  text-align: center
  font-size: 1.4rem
  color: rgba(149, 166, 172, 0.9)
  margin-top: 10%
  margin-bottom: 25%

  p
    margin-bottom: 0.5em

.error-panel
  display: flex
  flex-direction: column
  align-items: center
  font-size: 16px
  padding-bottom: 30px

  button
    background: none
    border: none
    border-bottom: 2px solid $blue
    padding: 3px 4px
    &:hover
      background-color: $grey-light
    &:focus
      border: 2px solid $blue-light
      outline: none

.message
  display: flex
  flex-direction: row
  justify-content: center
  background-color: rgba(255,0,0, 0.1)
  padding: 6px
  color: $text-black
  width: 100%
  max-height: 30px
  position: absolute
  bottom: 0


.errorIcon
  position: relative
  height: 32px
  width: 32px
  display: inline-block
  top: 10px

.action
  padding-top: 20px

.default-actions
  display: flex
  flex-direction: row-reverse
  width: 100%
.ove-switch
  margin-top: 10px

.show-all-link
  font-weight: bold
  margin-top: 16px
  display: inline-block
  color: $blue-light
</style>
