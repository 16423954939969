<template>
  <div class="info-wrapper">
    <h2>Fill out the details below:</h2>
    <div class="input-wrapper">
      <el-input
        :class="{
          empty: !projectDescription,
          error: showErrors && !isValid.projectDescription,
        }"
        :model-value="projectDescription"
        type="textarea"
        :rows="1"
        placeholder="Project Description"
        @update:model-value="projectDescription = $event"
      />
      <div class="dataset-type-row">
        This data is a
        <el-dropdown trigger="click" :model-value="datasetType" @command="datasetType = $event">
          <span class="el-dropdown-link">
            {{ DATASET_OPTIONS[datasetType] }}
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-for="option in Object.keys(DATASET_OPTIONS)" :key="option" :command="option">
                {{ DATASET_OPTIONS[option as DatasetType] }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        for an
        <el-input
          :class="{
            empty: !datasetDescription,
            error: showErrors && !isValid.datasetDescription,
          }"
          :model-value="datasetDescription"
          placeholder="online clothes store"
          @update:model-value="datasetDescription = $event"
        />
      </div>
    </div>
    <div v-if="datasetType === 'survey' && textFieldMetadata.length">
      <div v-for="(field, i) in textFieldMetadata" :key="field.text_field_name" class="text-field-row">
        <h4>Please confirm the question asked for the field "{{ field.text_field_name }}":</h4>
        <el-input
          :class="{
            empty: !field.value,
            error: showErrors && !isValid.textFieldMetadata[i],
          }"
          :model-value="field.value"
          type="textarea"
          :autosize="{ minRows: 1, maxRows: 3 }"
          placeholder="Survey question"
          @update:model-value="field.value = $event"
        />
      </div>
    </div>
    <div class="pii-redaction">
      PII redaction
      <help-icon
        :constrain-width="true"
        :width="220"
        content="Kapiche will remove any personally identifiable information (PII) from your data before processing it."
      />
      <el-switch :model-value="piiRedaction" @update:model-value="piiRedaction = $event" />
    </div>
    <div v-if="showErrors" class="errors">
      <div v-if="!isValid.projectDescription">Please provide a project description.</div>
      <div v-if="!isValid.datasetDescription">Please provide a dataset description.</div>
      <div v-if="!isValid.textFieldMetadata.every((v) => v)">Please provide a question for each text field.</div>
    </div>
  </div>
</template>
<script lang="ts">
import { ref, defineComponent, computed, watch, PropType } from 'vue'

import * as ProjectAPI from '../api/project'
import { SchemaColumn } from 'src/types/SchemaTypes'
import HelpIcon from 'components/project/analysis/results/widgets/HelpIcon.vue'

const DATASET_OPTIONS = {
  survey: 'Feedback Survey',
  other: 'Other',
} as const

type DatasetType = keyof typeof DATASET_OPTIONS

export default defineComponent({
  name: 'TrialDataUpload',
  components: {
    HelpIcon,
  },
  props: {
    schema: {
      type: Array as PropType<SchemaColumn[] | undefined>,
      required: true,
    },
    showErrors: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['value-change'],
  setup(props, { emit }) {
    const piiRedaction = ref(true)
    const projectDescription = ref('')
    const datasetDescription = ref('')
    const datasetType = ref<DatasetType>('survey')
    const textFieldMetadata = ref<ProjectAPI.TextFieldMetadata[]>([])

    const isValid = computed(() => {
      const valid = {
        projectDescription: projectDescription.value.length > 0,
        datasetDescription: datasetDescription.value.length > 0,
        textFieldMetadata: textFieldMetadata.value.map(
          (field) => datasetType.value === 'other' || field.value.length > 0,
        ),
        allValid: true,
      }
      for (const key of Object.keys(valid)) {
        const value = valid[key as keyof typeof valid]
        const keyValid = Array.isArray(value) ? value.every((v) => v) : value
        if (!keyValid) {
          valid.allValid = false
          break
        }
      }
      return valid
    })

    watch(
      [projectDescription, datasetDescription, textFieldMetadata],
      () => {
        emit('value-change', {
          projectDescription: projectDescription.value,
          datasetDescription: datasetDescription.value,
          datasetType: datasetType.value,
          textFieldMetadata: textFieldMetadata.value,
          piiRedaction: piiRedaction.value,
          allValid: isValid.value.allValid,
        })
      },
      {
        deep: true,
        immediate: true,
      },
    )

    watch(
      () => props.schema,
      (schema) => {
        if (schema) {
          textFieldMetadata.value = schema
            .filter((col) => col.typename === 'TEXT')
            .map((column) => ({
              text_field_name: column.name,
              key: 'survey_question',
              value: '',
            }))
        }
      },
      {
        immediate: true,
        deep: true,
      },
    )

    return {
      projectDescription,
      datasetDescription,
      datasetType,
      textFieldMetadata,
      DATASET_OPTIONS,
      piiRedaction,
      isValid,
    }
  },
})
</script>
<style lang="scss" scoped>
@import 'assets/kapiche.sass';

.info-wrapper {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-wrapper {
  .input-wrapper {
    min-width: 400px;
    > * {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .dataset-type-row {
    display: flex;
    white-space: nowrap;
    line-height: 30px;
    justify-content: center;
    :deep {
      .el-dropdown {
        margin: 0 8px;
        cursor: pointer;
        line-height: unset;
      }
      .el-input {
        margin-left: 8px;
        width: 140px;
      }
      .el-input__wrapper {
        padding: 0;
      }
    }
  }

  :deep {
    .el-input__wrapper,
    .el-textarea__inner {
      background: transparent;
      border: none;
      border-radius: 0;
      box-shadow: none;
      color: $blue;
      padding: 6px 0px;
      border-bottom: 2.5px dotted $blue;
      transition:
        border-color 0.2s ease,
        color 0.2s ease;
      font-size: 16px;
    }

    .el-input__wrapper {
      padding-bottom: 3px;
    }

    .el-input__inner {
      color: $blue;
    }

    .el-input.empty,
    .el-textarea.empty {
      .el-input__wrapper,
      .el-textarea__inner {
        border-color: $grey-mid-light;
        color: $grey-dark;
      }
    }

    .el-input.error,
    .el-textarea.error {
      .el-input__wrapper,
      .el-textarea__inner {
        border-color: $red;
        color: $red;
      }
    }

    .el-dropdown {
      border-bottom: 2.5px dotted $blue;
      font-size: 16px;
      color: $blue;
    }
  }
}

.text-field-row {
  margin-top: 20px;
  &:first-of-type {
    margin-top: 40px;
  }
}

.pii-redaction {
  display: flex;
  align-items: center;
  margin-top: 30px;
  font-size: 16px;

  :deep {
    .el-switch {
      margin-left: 20px;
    }
  }
}

.errors {
  margin-top: 20px;
  color: $red;
  text-align: center;
  font-size: 16px;
}
</style>
