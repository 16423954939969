<template>
  <div>
    <div class="ui segments" :class="preview ? 'borderless' : ''">
      <div class="ui inverted dimmer" :class="{ active: isLoading }">
        <div class="ui loader"></div>
      </div>
      <div v-if="header === true" class="ui clearing segment header">
        <div class="left floated title">
          {{ number(count) }} Verbatims
          <span v-if="subtext" class="subtext">({{ number(countExcerpts, '0.00%') }} of verbatims)</span>
        </div>
        <div v-if="helpIcon" class="ui title right floated">
          <help-icon :content="excerptsHelp"></help-icon>
        </div>
        <div v-show="textValues.length > 0" class="title right floated">
          <div v-if="allowHighlightToggle" class="item">
            <div class="ui toggle checkbox">
              <input v-model="isHighlighted" type="checkbox" name="" class="highlight-toggle" />
              <label>Highlight</label>
            </div>
          </div>
        </div>
        <div v-if="!preview && !hideExcerptsPerPage" class="title right floated num-pages">
          <span> Verbatims per page: </span>
          <span id="field" class="num-pages-select">
            <div class="ui scrolling dropdown num-pages-select">
              <div class="text" v-text="hitsPerPage"></div>
              <i class="dropdown icon"></i>
              <div class="menu">
                <div :value="5" class="item">5</div>
                <div :value="10" class="item">10</div>
                <div :value="20" class="item">20</div>
                <div :value="50" class="item">50</div>
              </div>
            </div>
          </span>
        </div>
        <!-- Allow custom context/detail within the header section -->
        <div style="clear: both; padding: 0 17.5px 5px 17.5px">
          <slot name="subheader"></slot>
        </div>
      </div>

      <div v-for="hit in hits" :key="hit._doc_id + hit._field" class="ui segment body hit">
        <!-- eslint-disable vue/no-v-html -->
        <div v-if="hit._trunc_text && !hit._expanded" class="hit-content" v-html="hit._trunc_text"></div>
        <div v-else-if="!hit._trunc_text || hit._expanded" class="hit-content" v-html="hit._full_text"></div>
        <!-- eslint-enable vue/no-v-html -->

        <div class="ui grid hit-footer-controls">
          <div class="twelve wide column">
            <div class="ui relaxed horizontal list">
              <div class="item">
                <span class="control copy-text" :data-clipboard-text="hit[hit._field]">COPY TEXT</span>
              </div>
              <div class="item">
                <span class="control" :class="{ active: hit._show_info }" @click="showInfo(hit)">
                  <span v-if="!hit._show_info">SHOW INFO</span>
                  <span v-else>HIDE INFO</span>
                </span>
              </div>
              <div v-if="showAnnotations && hit._attributes && hit._attributes.sentiment" class="item">
                <template v-if="featureFlags && featureFlags.sentiment_update">
                  <span class="indicator capitalize"> Sentiment: </span>
                  <el-select
                    :model-value="hit._attributes.sentiment"
                    placeholder="Select"
                    size="small"
                    class="select-input-parent"
                    :class="{
                      neutral: hit._attributes.sentiment === 'neutral',
                      mixed: hit._attributes.sentiment === 'mixed',
                      negative: hit._attributes.sentiment === 'negative',
                      positive: hit._attributes.sentiment === 'positive',
                    }"
                    @update:model-value="hit._attributes.sentiment = $event"
                    @change="changeSentiment(hit)"
                  >
                    <el-option
                      v-for="item in sentimentValues"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                      <span
                        class="sentiment metadata"
                        :class="{
                          neutral: item.value === 'neutral',
                          mixed: item.value === 'mixed',
                          negative: item.value === 'negative',
                          positive: item.value === 'positive',
                        }"
                      >
                        {{ item.label }}
                      </span>
                    </el-option>
                  </el-select>
                </template>
                <template v-else>
                  <span class="indicator capitalize"
                    >Sentiment:
                    <span :class="sentimentStyle(hit._attributes.sentiment)">{{
                      hit._attributes.sentiment
                    }}</span></span
                  >
                </template>
              </div>
              <div v-if="showAnnotations && hit['NPS Category']" class="item">
                <span class="indicator capitalize"
                  >NPS Category: <span :class="hit._npsStyle">{{ hit['NPS Category'] }}</span></span
                >
              </div>
            </div>
          </div>
          <div v-if="hit._trunc_text" class="four wide column right aligned">
            <span class="control" :class="{ active: hit._expanded }" @click="expand(hit)">
              <span v-if="!hit._expanded">SHOW MORE</span>
              <span v-else>SHOW LESS</span>
            </span>
          </div>
          <div v-else class="four wide column"></div>
        </div>

        <div v-if="hit._show_info" class="ui segment metadata">
          <p>
            Excerpt taken from row <strong>{{ hit._doc_id }}</strong> from file:
            <strong>{{ hit[`${invisPrefix}filename`] }}</strong>
          </p>
          <p>
            From field: <strong>{{ hit._field }}</strong>
          </p>
          <div class="ui four column grid">
            <!-- Only show non-hidden metadata fields (check char[0]) -->
            <template v-for="item in Object.keys(hit)">
              <template
                v-if="
                  item.substring(0, 1) !== '_' &&
                  !item.startsWith(invisPrefix) &&
                  item !== hit._field &&
                  item !== 'NPS Category'
                "
              >
                <div :key="`label_${hit._doc_id}${hit._field}${item}`" class="label right aligned column">
                  {{ item }}:
                </div>
                <div
                  :key="`item_${hit._doc_id}${hit._field}${item}${hit[item]}`"
                  class="column"
                  style="overflow-wrap: break-word"
                >
                  {{ hit[item] }}
                </div>
              </template>
              <!-- Display colourised sentiment -->
              <template v-if="item === '_attributes' && hasSentiment">
                <div :key="hit._doc_id + hit._field + item" class="label right aligned column">Sentiment:</div>
                <div
                  :key="hit.doc_hash + hit._field + item + hit[item]"
                  class="sentiment column"
                  :class="{
                    neutral: hit[item].sentiment === 'neutral',
                    mixed: hit[item].sentiment === 'mixed',
                    negative: hit[item].sentiment === 'negative',
                    positive: hit[item].sentiment === 'positive',
                  }"
                >
                  <div v-if="featureFlags && featureFlags.sentiment_update">
                    <el-select
                      :model-value="hit[item].sentiment"
                      placeholder="Select"
                      size="small"
                      class="select-input-parent"
                      :class="{
                        neutral: hit[item].sentiment === 'neutral',
                        mixed: hit[item].sentiment === 'mixed',
                        negative: hit[item].sentiment === 'negative',
                        positive: hit[item].sentiment === 'positive',
                      }"
                      @update:model-value="hit[item].sentiment = $event"
                      @change="changeSentiment(hit)"
                    >
                      <el-option
                        v-for="sent in sentimentValues"
                        :key="sent.value"
                        :label="sent.label"
                        :value="sent.value"
                      >
                        <span
                          class="sentiment metadata"
                          :class="{
                            neutral: sent.value === 'neutral',
                            mixed: sent.value === 'mixed',
                            negative: sent.value === 'negative',
                            positive: sent.value === 'positive',
                          }"
                        >
                          {{ sent.label }}
                        </span>
                      </el-option>
                    </el-select>
                  </div>
                  <div v-else>
                    {{ hit[item].sentiment }}
                  </div>
                </div>
              </template>

              <!-- Display detailed sentiment info -->
              <template
                v-if="
                  item === '_attributes' &&
                  hasSentiment &&
                  featureFlags.sentiment_info &&
                  currentProject.sentiment_classifier === 'google'
                "
              >
                <div :key="hit.doc_hash + hit._field + item + 'polarity'" class="label right aligned column">
                  Sentiment Polarity:
                </div>
                <div :key="hit.doc_hash + hit._field + item + 'polarity' + hit[item]" class="sentiment column">
                  {{ number(hit[item][`${invisPrefix}sentiment_polarity`]) }}
                </div>
                <div :key="hit.doc_hash + hit._field + item + 'magnitude'" class="label right aligned column">
                  Sentiment Magnitude:
                </div>
                <div :key="hit.doc_hash + hit._field + item + 'magnitude' + hit[item]" class="sentiment column">
                  {{ number(hit[item][`${invisPrefix}sentiment_magnitude`]) }}
                </div>
              </template>
              <!--Display colourised NPS-->
              <template v-if="item === 'NPS Category'">
                <div :key="hit.doc_hash + hit._field + item" class="label right aligned column">{{ item }}:</div>
                <div
                  :key="hit.doc_hash + hit._field + item + hit[item]"
                  class="column"
                  :class="{
                    mixed: hit[item] === 'Passive',
                    negative: hit[item] === 'Detractor',
                    positive: hit[item] === 'Promoter',
                  }"
                >
                  {{ hit[item] }}
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>

      <div v-if="!preview" class="ui segment center aligned show-more">
        <div class="center aligned">
          <template v-if="count > hits.length">
            <div class="ui borderless pagination menu">
              <a class="item" :class="{ disabled: pageNumber === 1 }" @click="loadPageExcerpts(pageNumber - 1)">
                Prev
              </a>
              <a
                v-for="page in paginationRange"
                :key="page"
                class="item"
                :class="{ active: page === pageNumber }"
                @click="loadPageExcerpts(page)"
              >
                {{ page }}
              </a>
              <a
                class="item"
                :class="{ disabled: pageNumber === numberOfPages }"
                @click="loadPageExcerpts(pageNumber + 1)"
              >
                Next
              </a>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- Jump to page modal -->
    <Modal :visible="jumpToPageModalVisible" @close="hideModal('jumpToPageModalVisible')">
      <template #header>
        <div class="header">Jump to page</div>
      </template>
      <template #content>
        <div class="content center">
          <VeeForm ref="form" v-slot="{ errors }">
            <div class="ui input">
              <Field v-slot="{ field }" name="page-number" :rules="`numeric|min_value:1|max_value:${numberOfPages}`">
                <input
                  v-bind="field"
                  v-model="goToPage"
                  name="page-number"
                  type="text"
                  placeholder="1"
                  size="3"
                  @keyup.enter="loadPageFromModal(goToPage)"
                />
              </Field>
            </div>
            of {{ numberOfPages }}
            <div>
              <a v-show="errors['page-number']" class="ui label pointing basic red">{{ errors['page-number'] }}</a>
            </div>
          </VeeForm>
        </div>
      </template>
      <template #buttons>
        <div class="actions">
          <button class="ui large button" @click="hideModal('jumpToPageModalVisible')">Cancel</button>
          <button class="ui large blue button" @click="loadPageFromModal(goToPage)">Confirm</button>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import $ from 'jquery'
import { Form as VeeForm, Field } from 'vee-validate'

import Data from 'src/utils/data'
import HelpIcon from './HelpIcon.vue'
import Util from 'src/utils/general'
import ExcerptsBase from 'components/project/analysis/results/widgets/ExcerptsBase.vue'
import Modal from 'components/Modal.vue'
import { ElSelect, ElOption } from 'element-plus'
import FormatUtils from 'src/utils/formatters'
import Project from 'src/api/project'
import Query from 'src/api/query'
import ClipboardJS from 'clipboard'

const RESPONSE_LENGTH = 1000 // Character length to truncate a response at
const NPS_STYLE = {
  Promoter: 'nps-promoter',
  Detractor: 'nps-detractor',
}
const SENTIMENT_STYLE = {
  positive: 'sentiment-positive',
  negative: 'sentiment-negative',
  mixed: 'sentiment-mixed',
}

export default defineComponent({
  components: {
    HelpIcon,
    Modal,
    ElSelect,
    ElOption,
    VeeForm,
    Field,
  },
  extends: ExcerptsBase,
  props: {
    /** Allow concept highlighting to be toggled */
    allowHighlightToggle: { type: Boolean, default: true },
    helpIcon: { type: Boolean, default: true },
    preview: { type: Boolean, default: true }, // is this a preview
    hideExcerptsPerPage: { type: Boolean, default: false }, // Allow the user to choose excerpts per page (topbar dropdown)
    header: { type: Boolean, default: true }, // display the header?
    numExcerpts: { type: Number, default: 50 }, // The number of excerpts to load
    subtext: { type: Boolean, default: true },
    unmappedBody: { type: Object, default: null },
    selectedConcept: { type: String, default: '' },
  },
  data() {
    return {
      hitsPerPage: this.numExcerpts, // Default hits per page value passed by props
      pageNumber: 1,
      goToPage: 1,
      sentimentValues: [
        { value: 'positive', label: 'Positive' },
        { value: 'negative', label: 'Negative' },
        { value: 'mixed', label: 'Mixed' },
        { value: 'neutral', label: 'Neutral' },
      ],
      jumpToPageModalVisible: false,
    }
  },
  computed: {
    ...mapGetters(['currentModel', 'hasSentiment', 'currentAnalysis', 'featureFlags', 'currentProject', 'currentSite']),
    showAnnotations() {
      return this.currentProject?.show_verbatim_annotations
    },
    invisPrefix() {
      return Data.INVISIBLE_PREFIX
    },
    numberOfPages() {
      return Math.ceil(this.count / this.hitsPerPage)
    },
    paginationRange() {
      return Util.pagination(this.pageNumber, this.numberOfPages)
    },
    countExcerpts() {
      return this.count / (this.currentModel.stats.n_frames - this.currentModel.stats.empty_frames)
    },
  },
  watch: {
    selectedConcept: {
      handler: function () {
        this.getExcerpts(0, this.hitsPerPage, true)
      },
      immediate: true,
    },
    hitsPerPage: function (val) {
      this.loadPageExcerpts(1)
      this.$analytics.track.verbatimsWidget.changePageLimit(false, val)
    },
    isHighlighted: function (val) {
      this.$analytics.track.verbatimsWidget.toggleHighlight(false, val)
    },
  },
  mounted() {
    this.getExcerpts(0, this.hitsPerPage, true)
    this.$nextTick(() => {
      $('.ui.dropdown.num-pages-select').dropdown({
        onChange: (v) => {
          this.hitsPerPage = v
        },
      })
    })
  },
  methods: {
    async getExcerpts(start = 0, limit = 50, override = false) {
      this.isLoading = true
      try {
        let data = await Query.runUnmapped(
          this.currentAnalysis.project,
          this.unmappedBody,
          this.currentAnalysis.topic_framework_id,
          this.currentProject.chrysalis_ref,
          {
            networkModel: false,
            numNetworkConcepts: 50,
            start: start,
            limit: limit,
          },
        )
        if (this._isDestroyed || this._isBeingDestroyed) return
        this.loadHits(data, override)
      } finally {
        this.isLoading = false
      }
    },
    loadHits(data, override = false) {
      if (override) {
        this.hits = []
      }
      // Reshape the data for display purposes
      data.hits.forEach((hit) => {
        hit._show_info = false
        hit._expanded = false
        hit._full_text = this.processText(hit[hit._field], [], this.query)
        // Replace newlines with pagebreaks
        hit._full_text = Util.excerptNewlineReplace(hit._full_text)
        // Create a truncated display text field for very long verbatims
        if (hit[hit._field].length > RESPONSE_LENGTH) {
          hit._trunc_text = this.processText(hit[hit._field].substring(0, RESPONSE_LENGTH) + '...', [], this.query)
          hit._trunc_text = Util.excerptNewlineReplace(hit._trunc_text)
        } else {
          hit._trunc_text = null
        }
        hit._npsStyle = (hit['NPS Category'] && NPS_STYLE[hit['NPS Category']]) || ''

        // Format AI topics if they exist and feature flag is enabled
        if (this.featureFlags.show_ai_topics) {
          hit['ai topics'] = hit['ai_topics']?.length > 0 ? hit['ai_topics'].join(', ') : 'N/A'
        }
        delete hit['ai_topics']
      })
      this.hits = this.hits.concat(data.hits) // concat hits to the existing hits (in case of view more)
      this.count = data.total_hits
      // Re-instantiate clipboard elements to account for new excerpts
      new ClipboardJS('span.control.copy-text')
      // Colourize the excerpts based on value 'highlighted'
      this.$nextTick(() => {
        this._toggleColours()
      })
    },
    async changeSentiment(hit) {
      this.isLoading = true
      try {
        await Project.changeSentiment(
          this.currentProject.chrysalis_ref,
          this.currentProject.id,
          hit._id,
          hit._attributes.sentiment,
        )
      } finally {
        this.isLoading = false
        this.$analytics.track.verbatimsWidget.updateSentiment(this.currentProject.id, hit._attributes.sentiment)
      }
    },
    number: FormatUtils.number,
    async loadPageFromModal(page) {
      const { valid } = await this.$refs.form.validate()
      if (!valid) {
        return
      }
      this.loadPageExcerpts(page)
      this.hideModal('jumpToPageModalVisible')
    },
    loadPageExcerpts(page, modal) {
      // Pop the page select modal if the passed value is the elipsis
      if (page === '...') {
        this.jumpToPageModalVisible = true
      } else {
        // Now the value has to be an int, we should parse it to one to avoid accidental concatenations
        page = parseInt(page)
        if (page > 0 && page <= this.numberOfPages) {
          this.pageNumber = page
          let start = this._pageToRange(page)
          this.getExcerpts(start, this.hitsPerPage, true)
        }
      }
    },
    showInfo(hit) {
      hit._show_info = !hit._show_info
      if (hit._show_info) {
        this.$analytics.track.verbatimsWidget.showInfo(false)
      }
    },
    _pageToRange(page) {
      const index = page * this.hitsPerPage - this.hitsPerPage
      this.$analytics.track.verbatimsWidget.paginate(false, this.hitsPerPage, index)
      return index
    },
    hideModal(modal) {
      this[modal] = false
    },
  },
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

div.segments.borderless
  border: 0
.ui.borderless.pagination.menu
  border: 0 !important
  box-shadow: none
  font-weight: bold
  @media (max-width: 1370px)
    font-size: 11px !important
  .active.item
    background: none !important
    color: #068ccc
    font-weight: bold

.ui.clearing.segment.header
  .left.floated.title
    font-weight: bold
    font-size: 1.25rem
    .subtext
      font-weight: normal
      color: rgba(149, 166, 172, 0.9)
  .title.right.floated.num-pages
    font-size: 1.1rem !important
    .num-pages-select
      color: #068ccc

.el-select-dropdown__item
  text-align: left
  .positive
    color: $green
  .negative
    color: $red
  .neutral
    color: $grey-dark
  .mixed
    color: $orange

::v-deep .el-input__prefix
  left: 15px
  display: flex
  align-items: center
::v-deep input.el-input__inner
  font-size: 1rem
  font-family: $standard-font
  text-align: left
  padding-left: 5px
  line-height: 25px
  height: 30px

::v-deep .el-select__caret
  margin-top: 4px

::v-deep div.select-input-parent.positive > div > input
  color: $green
::v-deep div.select-input-parent.negative > div > input
  color: $red
::v-deep div.select-input-parent.neutral > div > input
  color: $grey-dark
::v-deep div.select-input-parent.mixed > div > input
  color: $orange
</style>
