<template>
  <div class="top-bar">
    <div class="controls">
      <el-dropdown trigger="click" :disabled="viewerMode">
        <div class="dashboard-title">
          <h1>
            {{ currentDashboard.name }}
          </h1>
          <i v-if="!viewerMode" class="kapiche-icon-chevron-down"></i>
        </div>
        <template #dropdown>
          <div class="dashboards-menu menu">
            <div class="dashboards-menu-column">
              <h2>Dashboards</h2>
              <div class="dashboards-menu-list dashboard-selector">
                <template v-for="dashboard in sortedDashboards">
                  <span
                    v-if="dashboard.id === currentDashboard.id"
                    :key="dashboard.id + '_current'"
                    class="dashboards-menu-list-item selected"
                  >
                    {{ dashboard.name }}
                  </span>

                  <router-link
                    v-else
                    :key="dashboard.id"
                    class="dashboards-menu-list-item"
                    :to="{
                      name: 'analysis-workbench',
                      params: {
                        site: route.params.site,
                        projectId: route.params.projectId,
                        analysisId: route.params.analysisId,
                        dashboardId: dashboard.id,
                      },
                    }"
                  >
                    {{ dashboard.name }}
                  </router-link>
                </template>
              </div>
            </div>
            <div v-if="currentDashboard" class="dashboards-menu-column">
              <h2>This dashboard</h2>
              <ul class="dashboards-menu-list dashboard-actions">
                <li class="dashboards-menu-list-item">
                  <button role="button" @click="editDashboardModalVisible = true">
                    <icon color="#068ccc" name="edit" :size="16" /> Rename
                  </button>
                </li>
                <li class="dashboards-menu-list-item">
                  <el-popover
                    effect="dark"
                    :hide-after="0"
                    :popper-options="[
                      {
                        placement: 'right',
                        modifiers: [
                          {
                            offset: { offset: '0,6px' },
                            preventOverflow: { boundariesElement: 'window' },
                          },
                        ],
                      },
                    ]"
                  >
                    <template #default>
                      <div>Reset all filters, themes, widget visibility and widget configurations to defaults</div>
                    </template>
                    <template #reference>
                      <button role="button" @click="$emit('reset-workbench')">
                        <icon color="#068ccc" name="revert" :size="16" /> Reset to Defaults
                      </button>
                    </template>
                  </el-popover>
                </li>
                <li
                  class="dashboards-menu-list-item dashboards-menu-list-item--danger"
                  :class="{ disabled: !canDeleteDashboard }"
                >
                  <el-popover
                    effect="dark"
                    :disabled="canDeleteDashboard"
                    :popper-options="[
                      {
                        placement: 'bottom',
                        modifiers: [
                          {
                            offset: { offset: '0,6px' },
                            preventOverflow: { boundariesElement: 'window' },
                          },
                        ],
                      },
                    ]"
                  >
                    <template #default>
                      <div>You cannot delete the only Dashboard in an Analysis</div>
                    </template>
                    <template #reference>
                      <div>
                        <button
                          :disabled="!canDeleteDashboard"
                          role="button"
                          @click="deleteDashboardModalVisible = true"
                        >
                          <icon color="#068ccc" name="trash" :size="16" /> Delete
                        </button>
                      </div>
                    </template>
                  </el-popover>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </el-dropdown>
      <el-dropdown trigger="click">
        <bf-button size="tiny" color="white" :disabled="!dateFields.length">
          {{ dateLabel }}
        </bf-button>
        <template #dropdown>
          <date-range-picker
            v-bind="dashboardDateRange"
            :date-fields="dateFields"
            :default-date-field="defaultDateField"
            @apply="setDateRange"
          />
        </template>
      </el-dropdown>
      <template v-if="!viewerMode">
        <bf-button size="tiny" color="blue" @click="saveAsModalVisible = true"> SAVE AS... </bf-button>
        <el-popover v-if="unsavedChanges" effect="dark" popper-class="wide" :hide-after="0">
          <div class="changes">
            <header>Save all changes made to this Dashboard. Details below:</header>
            <dashboard-changes-tooltip
              :filter-diff="filterDiff"
              :themes-diff="themesDiff"
              :date-range-diff="dateRangeDiff"
              :date-filters-changed="unsavedDateFilters"
              :speaker-field-diff="speakerFieldDiff"
              :compare-mode-new-value="compareModeNewValue"
              :modified-overview-widgets="widgetConfigDiff('overview')"
              :modified-drilldown-widgets="widgetConfigDiff('drilldown')"
            />
          </div>
          <template #reference>
            <bf-button :class="['save-button']" size="tiny" color="green" @click="saveDashboard"> SAVE </bf-button>
          </template>
        </el-popover>
      </template>
      <el-popover v-if="unsavedChanges" effect="dark" popper-class="wide" :hide-after="0">
        <div class="changes">
          <header>Discard all changes made to this Dashboard. Details below:</header>
          <dashboard-changes-tooltip
            :themes-diff="themesDiff"
            :filter-diff="filterDiff"
            :date-filters-changed="unsavedDateFilters"
            :date-range-diff="dateRangeDiff"
            :modified-overview-widgets="widgetConfigDiff('overview')"
            :modified-drilldown-widgets="widgetConfigDiff('drilldown')"
            :speaker-field-diff="speakerFieldDiff"
            :compare-mode-new-value="compareModeNewValue"
          />
        </div>
        <template #reference>
          <bf-button class="clear" size="tiny" color="transparent" @click="showDiscardModal = true">
            <icon color="#068ccc" name="revert" :size="16" /> DISCARD
          </bf-button>
        </template>
      </el-popover>
    </div>
    <div class="controls right">
      <el-dropdown trigger="click">
        <bf-button size="tiny" color="white">
          <icon color="#068ccc" name="share" :size="16" />
          Share
        </bf-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item v-if="!viewerMode" @click="shareModalVisible = true">
              <i class="kapiche-icon-share-arrow icon"></i> Share / Invite
            </el-dropdown-item>
            <el-dropdown-item @click="$emit('export-csv')">
              <i class="kapiche-icon-download icon"></i> Export to CSV
            </el-dropdown-item>
            <el-dropdown-item @click="$emit('export-ppt')">
              <i class="kapiche-icon-download icon"></i> Export to PPT
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <bf-button v-if="!viewerMode" size="tiny" color="white" @click="showDigestModal">
        <icon color="#068ccc" name="envelope" :size="16" />
        Digests
      </bf-button>
      <el-dropdown trigger="click">
        <bf-button size="tiny" color="white">
          <icon color="#068ccc" name="gear" :size="16" />
          Customise
        </bf-button>
        <template #dropdown>
          <el-dropdown-menu>
            <template v-if="!viewerMode">
              <el-dropdown-item @click="showThemesModal = true"> Show/Hide Themes </el-dropdown-item>
              <el-dropdown-item @click="showWidgetsModal"> Show/Hide Widgets </el-dropdown-item>
            </template>
            <el-dropdown-item>
              <span class="customize-options">
                <span :style="{ marginRight: '10px' }">Show theme group labels</span>
                <el-switch
                  :model-value="dashboardOptions.showGroupLabels"
                  @change="$emit('update:show-group-labels', $event)"
                />
              </span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <show-themes-modal
      :visible="showThemesModal"
      :available-queries="savedQueries"
      :theme-group-tree="themeGroupTree"
      @close="showThemesModal = false"
    />
    <dashboard-widgets-modal
      :visible="dashboardWidgetsModalVisible"
      @close="dashboardWidgetsModalVisible = false"
      @widgets-updated="onWidgetConfigChange"
    />
    <email-digest-management-modal
      :visible="digestManagementModalVisible"
      @close="digestManagementModalVisible = false"
    />
    <dashboard-sharing-modal
      :visible="shareModalVisible"
      @close="shareModalVisible = false"
      @link-copied="onLinkCopy"
    />
    <save-as-modal :visible="saveAsModalVisible" @close="saveAsModalVisible = false" />
    <rename-modal :visible="editDashboardModalVisible" @close="editDashboardModalVisible = false" />
    <delete-modal
      :visible="deleteDashboardModalVisible"
      :users-with-access="0"
      @close="deleteDashboardModalVisible = false"
    />
    <modal-confirm-prompt
      :visible="showDiscardModal"
      confirm-text="Discard Changes"
      title="Discard all changes?"
      @close="showDiscardModal = false"
      @confirm="discardDashboardChanges"
    >
      <p>Discard all changes made to this dashboard?</p>
      <p>This action cannot be undone.</p>
    </modal-confirm-prompt>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed, PropType, inject, ComputedRef } from 'vue'
import { RouterLink, useRoute } from 'vue-router'
import { isEqual } from 'lodash'
import { useStore } from 'vuex'
import dayjs from 'dayjs'

import BfButton from 'src/components/Butterfly/BfButton/BfButton.vue'
import Icon from 'src/components/Icon.vue'
import ShowThemesModal from './ShowThemesModal.vue'
import { QueryRow, SavedQuery } from 'src/types/Query.types'
import { generateConfig, GroupOrTheme, processQueries } from 'src/pages/dashboard/Dashboard.utils'
import DashboardWidgetsModal from 'src/components/widgets/DashboardWidgetsModal/DashboardWidgetsModal.vue'
import { Dashboard, DashboardConfig, DateRangeConfig, DateRangeTypeEnum } from 'src/types/DashboardTypes'
import {
  DISCARD_DASHBOARD_CONFIG_CHANGES,
  SAVE_DASHBOARD_DATE_RANGE,
  SET_WIDGET_CONFIG,
  UPDATE_DASHBOARD,
} from 'src/store/types'
import { DashboardOptions, getFilterDiff, getThemesDiff, getWidgetConfigDiff } from './Workbench.utils'
import EmailDigestManagementModal from 'src/components/widgets/EmailDigest/EmailDigestManagementModal.vue'
import DashboardSharingModal from 'src/components/project/analysis/results/dashboards/DashboardSharingModal.vue'
import { Analysis, Model } from 'src/types/AnalysisTypes'
import DateRangePicker from 'components/widgets/DateRangePicker/DateRangePicker.vue'
import SaveAsModal from './modals/SaveAsModal.vue'
import RenameModal from './modals/RenameModal.vue'
import DeleteModal from './modals/DeleteModal.vue'
import DashboardChangesTooltip from 'src/components/widgets/DashboardChangesTooltip/DashboardChangesTooltip.vue'
import { Analytics } from 'src/analytics'
import ModalConfirmPrompt from 'src/components/widgets/ModalConfirmPrompt/ModalConfirmPrompt.vue'

export default defineComponent({
  name: 'WorkbenchTopBar',
  components: {
    BfButton,
    Icon,
    ShowThemesModal,
    DashboardWidgetsModal,
    EmailDigestManagementModal,
    DashboardSharingModal,
    DateRangePicker,
    SaveAsModal,
    RouterLink,
    RenameModal,
    DeleteModal,
    DashboardChangesTooltip,
    ModalConfirmPrompt,
  },
  props: {
    themeGroupTree: {
      type: Array as PropType<GroupOrTheme[]>,
      required: true,
    },
    speakerField: {
      type: String,
      required: true,
    },
    compareMode: {
      type: Boolean,
      required: true,
    },
    filterRows: {
      type: Array as PropType<QueryRow[]>,
      required: true,
    },
    compareFilterRows: {
      type: Array as PropType<QueryRow[]>,
      required: true,
    },
    viewerMode: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'update:show-group-labels',
    'update:group-by',
    'export-csv',
    'export-ppt',
    'discard-changes',
    'reset-workbench',
  ],
  setup(props, { emit }) {
    const store = useStore()
    const route = useRoute()

    const analytics = inject<Analytics>('analytics')
    const dashboardOptions = inject('dashboardOptions') as ComputedRef<DashboardOptions>

    const savedQueries = computed(() => store.getters.savedQueries as SavedQuery[])
    const currentDashboard = computed(() => store.getters.currentDashboard as Dashboard)
    const currentModel = computed(() => store.getters.currentModel as Model)
    const dashboardDateRange = computed(() => store.getters.dashboardDateRange as DateRangeConfig)
    const defaultDateField = computed(() => store.getters.defaultDateField as string)
    const currentAnalysis = computed(() => store.getters.currentAnalysis as Analysis)
    const dashboardQueries = computed(() => store.getters.dashboardQueries as SavedQuery[])
    const dashboardWidgetConfig = computed(() => store.getters.dashboardWidgetConfig as DashboardConfig['widgets'])

    const showDiscardModal = ref(false)
    const showThemesModal = ref(false)
    const dashboardWidgetsModalVisible = ref(false)
    const digestManagementModalVisible = ref(false)
    const shareModalVisible = ref(false)
    const saveAsModalVisible = ref(false)
    const editDashboardModalVisible = ref(false)
    const deleteDashboardModalVisible = ref(false)

    const dateFields = computed(() => {
      return currentModel.value.dateFields
    })

    const onWidgetConfigChange = (config: DashboardConfig['widgets']) => {
      analytics?.track.dashboard.customization.widgetConfigApplied(currentDashboard.value.id, config, true)
      store.commit(SET_WIDGET_CONFIG, { widgets: config })
      dashboardWidgetsModalVisible.value = false
    }

    const onLinkCopy = () => {
      analytics?.track.dashboard.linkCopied(currentDashboard.value.id, true)
    }

    const canDeleteDashboard = computed(() => {
      return true
    })

    const dateLabel = computed(() => {
      if (dashboardDateRange.value?.type === DateRangeTypeEnum.CUSTOM) {
        const from = dashboardDateRange.value?.dateFrom || 'All time'
        const to = dashboardDateRange.value?.dateTo || 'All time'
        if (from === to && from === 'All time') return 'All time'
        return `${from} - ${to}`
      }
      const weekStart = currentDashboard.value.project.week_start
      if (weekStart == 'Monday') {
        dayjs.Ls.en.weekStart = 1
      } else if (weekStart == 'Sunday') {
        dayjs.Ls.en.weekStart = 0
      }
      return dashboardDateRange.value?.type ?? ''
    })

    const sortedDashboards = computed(() => {
      return (
        [...(currentAnalysis.value.dashboards ?? [])]
          // sort alphabetically (taking into account numbers may be in the string)
          .sort((a, b) => `${a.name || ''}`.localeCompare(`${b.name || ''}`, undefined, { numeric: true }))
      )
    })

    const setDateRange = (dateField: string, from: string, to: string, type: string) => {
      store.dispatch(SAVE_DASHBOARD_DATE_RANGE, {
        dateRange: {
          type: type,
          dateField,
          dateTo: to,
          dateFrom: from,
        },
      })
    }

    const unsavedChanges = computed(() => {
      return (
        unsavedSegmentFilters.value ||
        unsavedDateFilters.value ||
        unsavedWidgets.value ||
        unsavedQueries.value ||
        unsavedSpeakerField.value ||
        unsavedCompareMode.value ||
        unsavedShowGroupLabels.value
      )
    })

    const unsavedDateFilters = computed(() => {
      return !isEqual(currentDashboard.value.config?.dateRange, dashboardDateRange.value)
    })

    const unsavedSegmentFilters = computed(() => {
      const saved = currentDashboard.value.config?.queryRows
      const unsaved = props.filterRows
      return !isEqual(saved, unsaved)
    })

    const unsavedSpeakerField = computed(() => {
      return props.speakerField !== currentDashboard.value.speaker_field
    })

    const unsavedWidgets = computed(() => {
      return !isEqual(currentDashboard.value.config?.widgets, dashboardWidgetConfig.value)
    })

    const unsavedQueries = computed(() => {
      return !isEqual(
        currentDashboard.value.queries.map((q) => q.name),
        dashboardQueries.value.map((q) => q.name),
      )
    })

    const unsavedCompareMode = computed(() => {
      return props.compareMode !== !!currentDashboard.value.config?.compareMode
    })

    const unsavedShowGroupLabels = computed(() => {
      const savedShowGroupLabel = currentDashboard.value.config?.showGroupLabels ?? true
      return dashboardOptions.value.showGroupLabels !== savedShowGroupLabel
    })

    const filterDiff = computed(() => {
      return getFilterDiff(props.filterRows, currentDashboard.value.config?.queryRows)
    })

    const themesDiff = computed(() => {
      return getThemesDiff(dashboardQueries.value, currentDashboard.value.queries)
    })

    const dateRangeDiff = computed(() => {
      return {
        original: currentDashboard.value.config?.dateRange as DateRangeConfig,
        current: dashboardDateRange.value,
      }
    })

    const speakerFieldDiff = computed(() => {
      if (!currentDashboard.value || !unsavedSpeakerField.value) {
        return undefined
      }
      return {
        original: currentDashboard.value.speaker_field || 'None',
        current: props.speakerField || 'None',
      }
    })

    const widgetConfigDiff = (type: 'overview' | 'drilldown') => {
      return getWidgetConfigDiff(type, dashboardWidgetConfig.value, currentDashboard.value.config?.widgets)
    }

    const compareModeNewValue = computed(() => {
      if (props.compareMode !== !!currentDashboard.value.config?.compareMode) {
        return props.compareMode
      }
      return undefined
    })

    const saveDashboard = () => {
      const dashboard = {
        id: currentDashboard.value.id,
        analysis_id: currentDashboard.value.analysis.id,
        name: currentDashboard.value.name,
        queries: processQueries(dashboardQueries.value),
        config: generateConfig(
          dashboardWidgetConfig.value,
          dashboardDateRange.value,
          props.filterRows,
          props.compareFilterRows,
          props.compareMode,
          dashboardOptions.value.showGroupLabels,
        ),
        speaker_field: props.speakerField,
      }

      store.dispatch({ type: UPDATE_DASHBOARD, dashboard, rethrowErrors: true })
      analytics?.track.dashboard.save(currentDashboard.value.id, true)
    }

    const discardDashboardChanges = () => {
      showDiscardModal.value = false
      store.dispatch(DISCARD_DASHBOARD_CONFIG_CHANGES)
      emit('discard-changes')
    }

    const showWidgetsModal = () => {
      dashboardWidgetsModalVisible.value = true
      analytics?.track.dashboard.customization.showWidgetsModal(currentDashboard.value.id)
    }

    const showDigestModal = () => {
      digestManagementModalVisible.value = true
      analytics?.track.dashboard.digest.modalOpened(currentDashboard.value.id, true)
    }

    return {
      showThemesModal,
      savedQueries,
      dashboardWidgetsModalVisible,
      onWidgetConfigChange,
      dashboardOptions,
      digestManagementModalVisible,
      shareModalVisible,
      onLinkCopy,
      currentDashboard,
      canDeleteDashboard,
      dateFields,
      dateLabel,
      dashboardDateRange,
      defaultDateField,
      saveAsModalVisible,
      sortedDashboards,
      route,
      editDashboardModalVisible,
      deleteDashboardModalVisible,
      setDateRange,
      unsavedChanges,
      filterDiff,
      themesDiff,
      dateRangeDiff,
      unsavedDateFilters,
      widgetConfigDiff,
      speakerFieldDiff,
      compareModeNewValue,
      saveDashboard,
      showDiscardModal,
      discardDashboardChanges,
      showWidgetsModal,
      showDigestModal,
    }
  },
})
</script>
<style lang="scss" scoped>
@import 'assets/kapiche.sass';

.top-bar {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .controls {
    display: flex;
    align-items: center;
  }

  .controls > *:not(:last-child) {
    margin-right: 10px;
  }

  .right {
    margin-left: auto;
  }
}

.bf-button {
  padding: 0px 10px;
  margin: 0;
  height: 28px;
  text-transform: uppercase;

  .icon-wrapper {
    margin-right: 5px;
  }
}

.group-by {
  > span {
    color: $blue-light;
  }
}

.dashboard-title {
  display: flex;
  align-items: center;
  margin-right: 15px;
  color: $text-black;
  cursor: pointer;

  h1 {
    font-size: 22px;
    font-weight: bold;
    margin: 0;
  }

  i {
    margin-left: 5px;
    font-size: 10px;
    margin-top: 4px;
  }
}

.dashboards-menu {
  display: flex;
  min-width: 540px;
  max-width: 50vw;
  width: max-content;
  margin: -0.5rem 0;
  font-size: 16px;

  .dashboards-menu-column {
    padding: 20px 20px 0 30px;
    display: flex;
    flex-direction: column;
    max-height: 60vh;
    &:first-child {
      flex: 1 1 auto;
      padding-right: 0;
      min-width: 230px;
    }
    &:not(:first-child) {
      width: 210px;
      border-left: 1px solid $grey;
      padding-right: 0;
      padding-left: 30px;
    }
    h2 {
      margin: 0 0 5px 0;
      padding: 0;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      color: $blue;
      margin-bottom: 5px;
    }
    .dashboards-menu-list {
      margin: 0 0 0 -10px;
      padding: 0 20px 0 0;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      &.dashboard-selector {
        .dashboards-menu-list-item {
          padding: 10px;
        }
      }
      .dashboards-menu-list-item {
        word-break: break-word;
        width: 100%;
        padding: 0;
        cursor: pointer;
        font-size: 16px;
        text-align: left;
        margin: 0;
        color: $text-black;
        .icon-wrapper {
          margin-right: 5px;
        }
        &:last-child {
          margin-bottom: 15px;
        }
        &:not(.disabled):hover {
          background-color: #f4f6f7;
          text-decoration: none;
        }
        &.disabled:hover {
          cursor: default;
        }
        &.selected {
          font-weight: bold;
          cursor: default;
          &:hover {
            background-color: inherit;
          }
        }
        a {
          color: $text-black;
          text-decoration: none;
        }
        button {
          width: 100%;
          padding: 10px;
          display: flex;
          align-items: center;
          border: none;
          white-space: nowrap;
          background-color: rgba(0, 0, 0, 0);
          cursor: pointer;
          &:disabled {
            cursor: default !important;
            opacity: 0.5;
            pointer-events: none;
          }
        }
        &.dashboards-menu-list-item--danger:hover {
          button:not([disabled]) {
            color: $red !important;
          }
        }
      }
    }
  }
}
</style>
