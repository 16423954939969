<template>
  <div class="root">
    <div class="ui grid">
      <div class="one column row search-container">
        <div class="column">
          <query-builder
            ref="queryBuilder"
            :query="query"
            :query-scope="queryScope"
            :minimal="query.length !== 0 && !isQueryVisible"
            :disable-saving="!!queryConflict"
            @query-saved="onQuerySaved"
            @query-selected="onQuerySelected"
            @query-updated="onQueryUpdated"
            @query-deleted="onQueryDeleted"
            @change-query-scope="changeQueryScope"
          >
          </query-builder>
        </div>
      </div>

      <div v-show="query.length > 0" class="one column row query-container">
        <div v-show="isQueryVisible" class="column">
          <div class="query-rows">
            <div class="eight wide column sliderbox" v-if="featureFlags.synonyms_calibrate_radius">
              <label>Suggest synonyms within</label>
              <bf-slider v-model="synonymsMaxRadius" :min="40" :max="70" :step="1" />
              <label>{{ synonymsMaxRadius / 100 }}</label>
            </div>
            <query-row
              v-for="(q, i) in query"
              :key="computeQueryId(i, q)"
              :query="q"
              :editable="true"
              :first-row="i === firstRow"
              :synonyms-max-radius="synonymsMaxRadius / 100"
              :query-name="queryName"
              @row-deleted="deleteRow(i)"
              @update-row="updateRow(i, $event)"
              @execute-query="onExecuteQuery"
            ></query-row>
          </div>
        </div>
        <div class="record-count">
          <div v-if="!queryLoading && isQueryValid && currentModel && recordHits !== null">
            <strong>{{ number(recordHits) }}</strong> records ({{
              number((recordHits / currentModel.stats.n_documents) * 100, '0.00')
            }}%)
          </div>
          <div v-else>&nbsp;</div>
        </div>
        <div v-show="query.length > 0 && !queryLoading" class="right-side-controls">
          <div class="export-button" :class="{ loading: exportingQuery }" @click="exportQuery">
            <template v-if="exportingQuery === true">
              <bf-spinner size="mini" text-pos="right"> Exporting CSV... </bf-spinner>
            </template>
            <template v-else> <i class="kapiche-icon-download icon"></i> Export as CSV </template>
          </div>
          <div class="toggle-query-show" @click="isQueryVisible = !isQueryVisible">
            {{ isQueryVisible ? 'Hide Query' : 'Show Query' }}
          </div>
        </div>
      </div>

      <template v-if="!queryLoading && !noResultQuery && isQueryValid">
        <!-- Sentiment Summary -->
        <div v-if="hasSentiment" class="one column row">
          <div class="column">
            <sentiment-summary-segment
              :query="botanicQuery"
              @sentiment-clicked="addSentimentToQuery"
            ></sentiment-summary-segment>
          </div>
        </div>

        <!--NPS summary-->
        <div v-if="hasNPS" class="one column row nps-summary">
          <div class="column">
            <nps-summary
              :query="botanicQuery"
              :query-result-count="recordHits"
              @nps-clicked="addNpsToQuery"
            ></nps-summary>
          </div>
        </div>

        <!-- Timeline trend widget -->
        <div v-if="hasDate && recordHits > 0 && !isSingleDateQuery" class="one column row timeline-trend">
          <div class="column">
            <timeline-trend
              :queries="[{ query_value: botanicQuery }]"
              :legend="false"
              :can-drilldown="true"
              @date-selected="addDateToQuery"
            ></timeline-trend>
          </div>
        </div>

        <!-- Context network -->
        <div class="sixteen wide mobile ten wide large screen ten wide widescreen column">
          <context-network
            v-if="modelLoading || featureFlags.disable_context_network || (networkModel && networkModel.concepts)"
            :disabled="featureFlags.disable_context_network"
            :model="networkModel"
            :loading="modelLoading"
            :height="400"
            @concept-selected="(name) => addTermToQuery(name, 'network')"
          ></context-network>
          <old-context-network v-else :terms="queryTerms" @term-selected="addTermToQuery"></old-context-network>
        </div>

        <!-- Context chart -->
        <div class="sixteen wide mobile six wide large screen six wide widescreen column">
          <context-chart
            v-if="modelLoading || (networkModel && networkModel.concepts)"
            :model="networkModel"
            :loading="modelLoading"
            :height="400"
            @concept-selected="(name) => addTermToQuery(name, 'chart')"
          ></context-chart>
          <old-context-graph v-else :terms="queryTerms" :height="375"></old-context-graph>
        </div>

        <!-- Excerpts -->
        <div class="one column row excerpts">
          <div class="column">
            <excerpts :query="botanicQuery" :exclude-empty="true" :preview="false"></excerpts>
          </div>
        </div>

        <div class="sixteen wide mobile eight wide large screen eight wide widescreen column">
          <!-- Concept correlations -->
          <topic-correlations
            v-if="topicsData"
            :segments="query"
            :topics-data="topicsData"
            :height="450"
            @concept-clicked="(name) => addTermToQuery(name, 'correlations')"
          ></topic-correlations>
          <div v-else style="height: 450px" class="ui segment topics-loader">
            <bf-spinner />
          </div>
        </div>

        <div :class="'sixteen wide mobile eight wide large screen eight wide widescreen column'">
          <!-- Segment correlations -->
          <segment-correlations
            v-if="segmentsData"
            :segments="query"
            :segments-data="segmentsData"
            :height="450"
            @segment-clicked="addSegmentToQuery"
          ></segment-correlations>
          <div v-else style="height: 450px" class="ui segment segments-loader">
            <bf-spinner />
          </div>
        </div>
      </template>

      <template v-if="!queryLoading && noResultQuery && isQueryValid && query.length > 0">
        <div class="ui container center aligned no-queries">
          <img
            class="mark-faded"
            src="../../../../../assets/img/mark-faded.png"
            srcset="../../../../../assets/img/mark-faded@2x.png 2x, ../../../../../assets/img/mark-faded@3x.png 3x"
          />
          <p class="no-matches-title">No matches for this query</p>
          <p class="no-matches-body">Make sure there's no conflicting arguments in the query.</p>
          <p class="no-matches-body">
            <a target="_blank" :href="CONST.intercom_links.QUERIES">Learn more</a> about using our custom query tool
          </p>
        </div>
      </template>

      <template v-if="queryLoading">
        <div class="ui container center aligned">
          <bf-spinner />
        </div>
      </template>

      <!-- Incomplete query message -->
      <template v-if="!isQueryValid && query.length !== 0">
        <div class="ui container center aligned no-queries">
          <img
            class="mark-faded"
            src="../../../../../assets/img/mark-faded.png"
            srcset="../../../../../assets/img/mark-faded@2x.png 2x, ../../../../../assets/img/mark-faded@3x.png 3x"
          />
          <template v-if="queryConflict">
            <p class="no-matches-title">Invalid query</p>
            <p v-if="queryConflict.id === savedQueryId" class="no-matches-body">
              You cannot have a Theme as part of its own query.
            </p>
            <p v-else class="no-matches-body">
              This theme, "<span>{{ queryName }}</span
              >", is used in the theme "<span>{{ queryConflict.name }}</span
              >".<br />
              "<span>{{ queryConflict.name }}</span
              >" cannot be added to this query due to an unresolvable reference.
            </p>
          </template>
          <template v-else>
            <p class="no-matches-title">Complete your query to show results</p>
            <p class="no-matches-body">You have pending selections to be made in your query.</p>
            <p class="no-matches-body">
              <a target="_blank" :href="CONST.intercom_links.QUERIES">Learn more</a> about using our custom query tool
            </p>
          </template>
        </div>
      </template>

      <!-- Help text for user with no query selected -->
      <template v-if="!queryLoading && query.length === 0">
        <div class="left aligned queries-help">
          <h4>You have all the power with Kapiche Queries.</h4>
          <p>
            Explore the data in any way you want by clicking the search bar above.<br />
            <a :href="CONST.intercom_links.SAVED_QUERIES" target="_blank"
              >Learn more about Queries. <i class="kapiche-icon-new-tab"></i
            ></a>
          </p>
        </div>
        <div class="keyline fullwidth"></div>
        <div class="left aligned saved-queries-list">
          <h3>Themes</h3>
          <template v-if="savedQueries && savedQueries.length > 0">
            <p
              v-for="q in savedQueries"
              :key="q.id"
              class="query-link"
              @click.prevent="onQuerySelected(q.id, q.name, q.query_value)"
            >
              {{ q.name }}
            </p>
          </template>
          <template v-else>
            <p>
              Any Themes for this analysis will appear here. <br />
              Themes aren’t just a convenience feature – they’re used to build
              <router-link
                :to="{
                  name: 'analysis-dashboard-overview',
                  params: {
                    projectId: $route.params.projectId,
                    analysisId: $route.params.analysisId,
                    dashboardId: currentAnalysis?.dashboards?.[0]?.id,
                  },
                }"
              >
                Dashboards.<i class="kapiche-icon-new-tab"></i>
              </router-link>
            </p>
          </template>
        </div>
      </template>
    </div>
    <!-- Export limit prompt -->
    <modal-prompt :visible="showDataExportLimitModal" @close="showDataExportLimitModal = false">
      <template #header>
        <div>Sorry! This export is unavailable..</div>
      </template>
      <template #body>
        <div>
          We currently only support this export when you have {{ exportLimit }} records or less, but this query has
          {{ recordHits }}. <br /><br />
          Please <a href="javascript:window.Intercom('show');">contact us</a> if you would like data exports for this
          query.
        </div>
      </template>
    </modal-prompt>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { stringify } from 'csv-stringify'
import { isEmpty } from 'lodash'

import { LOAD_SAVED_QUERIES, SET_ERRORS, CLEAR_ERRORS } from 'src/store/types'
import ContextChart from '../widgets/ContextChart.vue'
import OldContextGraph from '../widgets/ContextGraph_old.vue'
import ContextNetwork from '../widgets/ContextNetwork.vue'
import OldContextNetwork from '../widgets/ContextNetwork_old.vue'
import Excerpts from '../widgets/Excerpts.vue'
import NpsSummary from '../widgets/NpsSummary.vue'
import QueryBuilder from './QueryBuilder.vue'
import QueryRow from './QueryRow.vue'
import SentimentSummarySegment from '../widgets/SentimentSummarySegment.vue'
import TopicCorrelations from '../widgets/TopicCorrelations.vue'
import SegmentCorrelations from '../widgets/SegmentCorrelations.vue'
import TimelineTrend from '../widgets/TimelineTrend.vue'
import ModalPrompt from 'components/widgets/ModalPrompt.vue'

import Query from 'src/api/query'
import QueryUtils from 'src/utils/query'
import Utils from 'src/utils/general'
import FormatUtils from 'src/utils/formatters'
import { BfSpinner } from 'components/Butterfly'
import { QueryType } from 'types/Query.types'
import { isQueryValid as isValid } from './utils'
import { BfSlider } from 'components/Butterfly'

export default defineComponent({
  components: {
    BfSpinner,
    QueryBuilder,
    QueryRow,
    Excerpts,
    TimelineTrend,
    NpsSummary,
    SentimentSummarySegment,
    TopicCorrelations,
    SegmentCorrelations,
    ContextChart,
    OldContextGraph,
    ContextNetwork,
    OldContextNetwork,
    ModalPrompt,
    BfSlider,
  },
  beforeRouteEnter(to, from, next) {
    // Set the query from the url
    next((vm) => {
      vm.$store
        .dispatch({
          type: LOAD_SAVED_QUERIES,
          projectId: vm.$route.params.projectId,
          analysisId: vm.$route.params.analysisId,
        })
        .then(() => {
          let urlQuery = vm.$route.query
          if (!isEmpty(urlQuery)) {
            if (urlQuery.savedQuery) {
              vm.savedQueryId = Number(urlQuery.savedQuery)
              // Make sure saved query is selected
              vm.$nextTick(() => {
                vm.$refs.queryBuilder.selectQuery(urlQuery.savedQuery)
              })
            }
            vm.query = Utils.parseRouteQuery(urlQuery.q)
            vm.queryScope = urlQuery.queryScope ?? vm.$store.getters.currentProject.query_scope_default
          }
        })
    })
  },
  beforeRouteUpdate(to, from, next) {
    if (!this.allowRouteUpdateToExecuteQuery) {
      // First check whether we should do any work. It's possible the
      // route got updated by another function that already executed the
      // new query. If so, we won't run it here again. Note that that
      // other function is responsible managing the state of the "allow"
      // variable. We only check it here.
      //
      // Note that it is necessary that the other function use `await`
      // on the `router.push` call, which will return after the navigation
      // is completed so that the state variable can be reset.
      next()
      return
    }

    // This guard can be triggered both by a query builder driven query update,
    // or by back/forwards navigation; we only want to catch the latter.
    // To do this we can tentatively parse the query from the new url and see if it
    // matches the currently stored one, and if so suppress the event.
    let query = Utils.parseRouteQuery(to.query.q)
    if (QueryUtils.areQueriesEquivalent(query, this.query) && to.query.savedQuery === this.savedQueryId) {
      // Suppress the event from a regular query change
    } else {
      // Continue by setting query value which will trigger page update
      this.query = query
      if (to.query.savedQuery) {
        this.savedQueryId = to.query.savedQuery
        // Make sure saved query is selected
        this.$refs.queryBuilder.selectQuery(to.query.savedQuery)
      } else {
        this.savedQueryId = null
        this.$refs.queryBuilder.deselectSavedQuery()
      }
    }
    next()
  },
  data() {
    return {
      queryRef: null, // record query ref to avoid handling outdated queries
      query: [],
      recordHits: null, // The number of records hits returned to pass to multiselect
      savedQueryId: null,
      queryLoading: false, // If we are waiting for a query check to return (for noresultquery)
      exportingQuery: false, // Are we generating the CSV for a query
      isQueryVisible: true,
      noResultQuery: false, // If a query has no results
      hasDate: Utils.checkSchemaHasDate(this.$store.getters.currentProject.schema),
      networkModel: null,
      modelLoading: false,
      // Chart data for correlations charts
      topicsData: null,
      segmentsData: null,
      sentimentData: null,
      showDataExportLimitModal: false,
      exportLimit: 0,
      allowRouteUpdateToExecuteQuery: true,
      queryScopeOptions: [
        {
          value: 'frame',
          label: 'match on verbatims',
        },
        {
          value: 'sentence',
          label: 'match on sentences',
        },
      ],
      queryScope: this.$store.getters.currentProject?.query_scope_default ?? 'frame',
      synonymsMaxRadius: 65,
    }
  },
  computed: {
    ...mapGetters([
      'currentModel',
      'hasNPS',
      'hasSentiment',
      'currentProject',
      'currentAnalysis',
      'currentSite',
      'savedQueries',
      'featureFlags',
    ]),
    // Return if the current query is restricted to a single date
    isSingleDateQuery() {
      for (let q of this.query) {
        if (q.is_date && q.operator === 'is') {
          return true
        }
      }
      return false
    },
    // Return first conflicting query, i.e a query containing the current saved query
    queryConflict() {
      if (!this.savedQuery) return false

      const filter = (arr) => arr.filter((v) => !!v).flat()

      const recurse = (query) => (row) => {
        if (row.type === 'query') {
          if (row.values.includes(this.savedQueryId.toString())) {
            return query
          }

          const children = row.values.map((value) => {
            const query = this.savedQueries.find(({ id }) => id.toString() === value)
            if (!query) return null

            const rows = QueryUtils.botanicToQueryRows(query.query_value)
            return rows.some(recurse(query)) ? query : null
          })

          return filter(children)[0]
        }

        return null
      }

      const conflicts = filter(this.query.map(recurse(this.savedQuery)))
      return conflicts[0]
    },
    // Returns false if the query has no rows, or any of the rows have no values.
    // We don't want to send off queries in an invalid state, which may
    // happen in the midst of user edits.
    isQueryValid() {
      return this.query.length > 0 && isValid(this.query) && !this.queryConflict
    },
    firstRow() {
      // What index element should be our "first row"? Return the first row that isn't visible
      for (let [idx, q] of this.query.entries()) {
        if (q['type'] !== 'all_data') {
          return idx
        }
      }
      return undefined
    },
    queryTerms() {
      let values = []
      this.query.forEach((q) => {
        if (q.type === 'text' && q.operator === 'includes') {
          q.values.forEach((v) => {
            let term = this.currentModel.terms[v]
            if (term && values.indexOf(term) < 0) {
              values.push(term)
            }
          })
        }
      })
      return values
    },
    botanicQuery() {
      return QueryUtils.queryRowsToBotanic(this.query, this.queryScope)
    },
    savedQuery() {
      return this.savedQueries.find(({ id }) => id === this.savedQueryId)
    },
    queryName() {
      return this.savedQuery ? this.savedQuery.name : ''
    },
  },
  metaInfo() {
    return {
      title: this.currentAnalysis ? `${this.currentAnalysis.name} Query - Kapiche` : null,
    }
  },
  watch: {
    query: {
      async handler(query, oldVal): Promise<void> {
        // Check for meaningful changes in the query
        const hasChanged = this.hasQueryChangedMeaningfully(query, oldVal)
        if (!hasChanged) return

        let urlQuery = { q: Utils.generateEncodedQuery(query) }
        if (this.savedQueryId !== null) {
          urlQuery.savedQuery = this.savedQueryId
        }
        if (this.queryScope) {
          urlQuery.queryScope = this.queryScope
        }

        // Update route if necessary
        if (!(query.length === 0 && (this.$route.query.q || '').length === 0) && urlQuery.q !== this.$route.query.q) {
          try {
            this.allowRouteUpdateToExecuteQuery = false
            await this.$router.push({ name: 'browse-excerpts', query: urlQuery })
          } finally {
            this.allowRouteUpdateToExecuteQuery = true
          }
        }
        // Execute query if valid
        if (this.isQueryValid) {
          await this.executeQuery(query)
        } else {
          this.recordHits = null
        }
      },
      deep: true,
    },
  },
  updated() {
    setTimeout(() => {
      // Set some styling so we are as dynamic as possible
      const searchContainer = this.$el.querySelector('.search-container')
      const queryContainer = this.$el.querySelector('.query-container')
      if (searchContainer && queryContainer) {
        searchContainer.style.top = `0px`
        queryContainer.style.top = `${searchContainer.offsetHeight}px`
      } else {
        // Handle the case where elements are not found
        console.warn('Elements not found. DOM might not be ready.')
      }
    }, 1000)
  },
  methods: {
    ...mapActions({ SET_ERRORS, CLEAR_ERRORS }),
    number: FormatUtils.number,
    // Quick passthrough for the botanic queries method
    botanicToQueryRows(query: QueryType) {
      return QueryUtils.botanicToQueryRows(query)
    },
    changeQueryScope(newQueryScope: string) {
      this.queryScope = newQueryScope
      this.executeQuery(this.query)
    },
    /**
     * Modify query row attributes
     * @param {number} index required.
     * @param {Object} change required.
     */
    updateRow(index, change) {
      this.query = this.query.map((v, i) => (i === index ? { ...v, ...change } : v))
      this.$nextTick(() => {
        this.onExecuteQuery()
      })
    },
    // Delete query row at specified `index`
    deleteRow(index) {
      this.query.splice(index, 1)
      if (this.query.length === 0) {
        this.recordHits = null
        this.$refs.queryBuilder.$refs.querySelector.$refs.savedQueries.clearSelection()
        this.savedQueryId = null
      }
      this.$nextTick(() => {
        this.onExecuteQuery()
      })
    },
    // Add a date (range) to query when clicked in the timeline widget.
    addDateToQuery(date, field, resolution) {
      this.query = this.query.concat(QueryUtils.generateDateQuery(date, field, resolution))
      this.$analytics.track.query.dateDrilldown(date.format('YYYY-MM-DD'), field, resolution)
    },
    // Add an nps type to query when clicked in the nps widget.
    addNpsToQuery(npsType) {
      this.query = this.query.concat({
        type: 'segment',
        field: 'NPS Category',
        operator: 'is',
        values: [npsType],
      })
      this.$analytics.track.query.npsDrilldown(npsType)
    },
    // Add a sentiment type to query when clicked in the sentiment widget.
    addSentimentToQuery(sentimentType) {
      this.query = this.query.concat({
        type: 'attribute',
        field: 'sentiment',
        operator: 'is',
        values: [sentimentType],
      })
      this.$analytics.track.query.sentimentDrilldown(sentimentType)
    },
    // Add a segment to query when clicked in the segment comparison widget.
    addSegmentToQuery(segmentName) {
      let field, value
      ;[field, value] = segmentName.split('=')
      this.query = this.query.concat({
        type: 'segment',
        field: field,
        operator: 'is',
        values: [value],
      })
      this.$analytics.track.query.segmentDrilldown(field, value)
    },
    // Add term to query when selected in nested visualiation.
    addTermToQuery(termName, source) {
      this.query = this.query.concat({
        type: 'text',
        operator: 'includes',
        values: [termName],
      })
      this.$analytics.track.query.conceptDrilldown(termName, source)
    },
    // A unique query id that Vue uses to determine caching/updating of QueryRow components.
    // This is important because when we delete query rows, Vue will try to reuse components which can
    // lead to polluting our updated components with stale state.
    computeQueryId(i, queryRow) {
      return `${i}_${JSON.stringify(queryRow)}`
    },
    // Execute the query
    executeQuery(query) {
      this.CLEAR_ERRORS()
      for (let q of query) {
        // Protect against not queries, which have an "all data" query which does not contain an operator
        if (q.hasOwnProperty('operator') && q.operator.endsWith('in the range') && q.values.length < 2) {
          // Don't execute range queries until we have both values set
          this.recordHits = 0
          this.noResultQuery = true
          return
        }
      }
      this.queryLoading = true
      const queryRef = Utils.uuid()
      this.queryRef = queryRef

      // Outer query that blocks the page
      Query.runQuery(
        this.$route.params.projectId,
        this.$route.params.analysisId,
        this.botanicQuery,
        this.savedQueries,
        {
          limit: 0,
          documents: true,
          networkModel: false,
        },
      )
        .then((data) => {
          if (queryRef !== this.queryRef) {
            // When our component-level queryRef doesn't match the method-scoped queryRef
            // it means that a re-entrant query has happened and we are executing the callback
            // for an old query. So we just bail out.
            return
          }
          this.recordHits = data.count
          this.$analytics.track.query.run(JSON.stringify(this.botanicQuery), this.recordHits)
          // At this point the query is valid, so we set the query
          // This endpoint is MUCH FASTER than drilldown, so we do it here.
          this.noResultQuery = data.count <= 0
        })
        .catch((errors) => {
          this.SET_ERRORS({ errors })
          // The query is not valid, we don't pass anything to the child components
          this.noResultQuery = true
        })
        .finally(() => {
          this.queryLoading = false
        })

      this.generateContextNetwork(queryRef)

      let dataTypes = ['topics', 'segments', 'attributes']
      dataTypes.forEach((dataType) => {
        Query.drilldown(
          this.currentProject.id,
          this.currentAnalysis.id,
          this.botanicQuery,
          dataType,
          0,
          this.savedQueries,
        )
          .then((data) => {
            if (queryRef !== this.queryRef) {
              // Callback for outdated query, so just bail out here
              return
            }
            if (dataType === 'attributes') {
              // handle attributes specially - extract sentiment
              if (data.counts.sentiment) {
                this.sentimentData = {
                  counts: data.counts.sentiment,
                  numExcerpts: data.total_hits,
                }
              } else {
                this.sentimentData = null
              }
            } else {
              // segments or topics
              this[`${dataType}Data`] = {
                correlations: data.npmi,
                counts: data.counts,
                numExcerpts: data.total_hits,
              }
            }
          })
          .catch((errors) => {
            this.SET_ERRORS({ errors })
          })
      })
    },
    onExecuteQuery() {
      if (this.isQueryValid) {
        this.executeQuery(this.query)
      } else {
        this.recordHits = null
      }
    },
    async generateContextNetwork(queryRef: string) {
      if (this.featureFlags.disable_context_network) {
        return
      }
      this.modelLoading = true
      this.networkModel = null
      let networkConfig = {
        num_network_concepts: 25,
      }
      // Provide a baseline for determining influence;
      // Useful for calculating expected frequencies when
      // structured data filters are applied.
      const baselineQuery = QueryUtils.extractStructuredFiltersFromQuery(this.botanicQuery)
      networkConfig.baseline_query = JSON.stringify(baselineQuery)
      Query.generateContextNetwork(
        this.currentProject.id,
        this.currentAnalysis.id,
        this.currentProject.chrysalis_ref,
        this.currentAnalysis.topic_framework_id,
        this.botanicQuery,
        this.savedQueries,
        networkConfig,
      )
        .then((data) => {
          if (queryRef !== this.queryRef) {
            // When our component-level queryRef doesn't match the method-scoped queryRef
            // it means that a re-entrant query has happened and we are executing the callback
            // for an old query. So we just bail out.
            return
          }
          this.networkModel = data.model
        })
        .catch((errors) => {
          this.SET_ERRORS({ errors })
        })
        .finally(() => {
          this.modelLoading = false
        })
    },
    async exportQuery() {
      this.exportingQuery = true
      try {
        const res = await Query.runQueryExport(
          this.$route.params.projectId,
          this.$route.params.analysisId,
          this.botanicQuery,
          this.savedQueries,
        )
        stringify([res.headers].concat(res.rows), (_, csvString) => {
          Utils.downloadCsv(csvString, 'query-result')
        })
        this.$analytics.track.analysis.downloadExport('Query Results', 'CSV', {
          queryName: this.queryName,
          queryValue: JSON.stringify(this.botanicQuery),
        })
      } catch (e) {
        const exportLimit = e.body.match(/Limit is (\d+)/)?.[1]
        if (exportLimit) {
          this.showDataExportLimitModal = true
          this.exportLimit = parseInt(exportLimit)
        }
      } finally {
        this.exportingQuery = false
      }
    },
    // Handle query saved
    onQuerySaved(savedQueryId) {
      this.savedQueryId = savedQueryId
      this.$router.push({ name: 'browse-excerpts', query: { q: this.$route.query.q, savedQuery: savedQueryId } })
    },
    // Handle saved query selection, bubbled up from QueryBuilder component
    onQuerySelected(savedQueryId: number, queryName: string, queryJson: QueryType) {
      let queryRows = this.botanicToQueryRows(queryJson)
      this.savedQueryId = savedQueryId
      this.query = queryRows
      this.queryScope = queryJson.level || 'frame'
      this.$refs.queryBuilder.selectQuery(savedQueryId)
    },
    // Remove references to the deleted query from the current state
    onQueryDeleted(queryId) {
      this.query = this.query.reduce((query, row) => {
        if (row.type === 'query') {
          const values = row.values.filter((v) => v !== queryId.toString())
          return values.length ? query.concat({ ...row, values }) : query // row is empty and is removed
        }

        return query.concat(row)
      }, [])
    },
    // Handle updated `query` from the QueryBuilder component
    onQueryUpdated(query) {
      this.query = query
      if (query.length === 0) {
        // Clear selected saved query if the entire query is cleared
        this.$refs.queryBuilder.$refs.querySelector.$refs.savedQueries.clearSelection()
        this.queryScope = this.currentProject?.query_scope_default ?? 'frame'
        this.savedQueryId = null
        // Always make sure query is visible when it is empty
        this.isQueryVisible = true
      }
    },
    hasQueryChangedMeaningfully(newQuery, oldQuery) {
      if (newQuery.length !== oldQuery.length) return true

      return newQuery.some((newRow, index) => {
        const oldRow = oldQuery[index]
        if (!oldRow) return true

        if (newRow.type !== oldRow.type || newRow.field !== oldRow.field || newRow.operator !== oldRow.operator) {
          return true
        }

        // Special handling for date ranges
        if (
          newRow.type === 'segment' &&
          this.currentModel.dateFieldIndex &&
          this.currentModel.dateFieldIndex[newRow.field]
        ) {
          return JSON.stringify(newRow.values) !== JSON.stringify(oldRow.values)
        }

        // For non-date fields, check if values have changed
        return JSON.stringify(newRow.values) !== JSON.stringify(oldRow.values)
      })
    },
  },
})
</script>

<style lang="sass" scoped>
  @import '../../../../../assets/kapiche.sass'
  @import '../../../../../assets/mixins.sass'

  div.topics-loader, div.segments-loader
    display: flex
    align-items: center
    justify-content: space-around

  div.query-rows
    background: white
    box-shadow: $box-shadow
    margin-top: rem(10px)
    padding: rem(5px) rem(20px) rem(20px) rem(20px)

  div.results-statistics
    p
      font-size: 16px

  div.row.search-container
    background-color: $grey-light-background
    position: sticky !important
    z-index: 9

  div.row.query-container
    position: sticky !important
    background-color: $grey-light-background
    z-index: 8
    padding-top: 0

    .record-count
      font-size: rem(16px)
      margin-left: rem(15px)
      margin-top: rem(15px)

    .right-side-controls
      display: flex
      position: absolute
      right: 20px
      bottom: 15px
      align-items: center

      .export-button
        display: flex
        align-items: center
        color: $subdued
        cursor: pointer
        margin-right: 1rem
        min-height: 24px
        i
          opacity: 0.7
        &:hover:not(.loading)
          color: $blue
        &.loading
          cursor: progress

      .toggle-query-show
        color: $blue
        cursor: pointer
        &:hover
          color: $blue-light

  p.no-matches-title
    font-size: 30px
    color: #95a6ac

  p.no-matches-body
    font-size: 18px
    color: #95a6ac

  div.keyline
    width: 100px
    height: 1px
    background-color: rgb(229, 229, 229)
    margin-left: auto
    margin-right: auto
    &.fullwidth
      width: 100%

  div.queries-help
    color: #383838
    margin-top: 20px
    margin-bottom: 30px
    h4
      font-size: 24px
      margin-bottom: 0.2rem
      margin-top: 1rem
      font-weight: bold
      line-height: 1.17
      margin-bottom: 10px
    p
      font-size: 18px
      margin-bottom: 0.75rem
      line-height: 1.56

    a
      font-size: 18px
      margin-bottom: 0.75rem
      line-height: 1.56

    .kapiche-icon-new-tab
      font-size: 12px
      margin-left: 3px

  div.saved-queries-list
    color: #383838
    margin-top: 20px
    margin-bottom: 30px

    h3
      font-size: 24px
      margin-bottom: 0.2rem
      margin-top: 1rem
      line-height: 1.17
      margin-bottom: 10px
      color: #383838

    p
      font-size: 16px
      margin-bottom: 0.75rem
      line-height: 1.63
      color: #95a6ac
      &.query-link
        font-weight: bold
        color: #068ccc
        cursor: pointer
        &:hover
          color: darken(#068ccc, 10%)

    a
      font-size: 16px
      margin-bottom: 0.75rem
      line-height: 1.63

    .kapiche-icon-new-tab
      font-size: 12px
      margin-left: 3px

  img.mark-faded
    padding: 4rem

  i.kapiche-icon-download
    color: $grey-dark

.sliderbox
  display: flex !important
  justify-content: flex-start
  align-items: center

  .bf-slider
    width: 100px
    margin: 0 15px
</style>
