<template>
  <div class="cues-collapse">
    <el-collapse @change="cuesTrigger">
      <el-collapse-item>
        <template #title>
          <div class="cues-header">
            <span class="label"> Insight Cues <span class="ai-label beta">AI GENERATED</span> </span>
          </div>
        </template>
        <el-container
          v-loading="loading"
          element-loading-text="Generating Cues ..."
          :style="{ minHeight: '50px', margin: '5px' }"
        >
          <template v-if="!cuesStale">
            <div class="cues-content">
              <!-- Add comparison insights section -->
              <div v-if="comparisonInsights" class="comparison-insights">
                <div class="comparison-summary">{{ comparisonInsights.summary }}</div>
              </div>
              <div v-if="parsedCues.length > 0" class="events-section">
                <div
                  v-for="(event, index) in paginatedParsedCues"
                  :key="index"
                  class="event"
                  @mouseover="hoverStart(event)"
                  @mouseleave="hoverEnd()"
                >
                  <el-dropdown trigger="click">
                    <div>
                      <div class="event-header">
                        <div v-if="event.date_range" class="event-date">
                          <span class="date-filter">
                            {{ formatDate(event.date_range.from) }}
                          </span>
                        </div>
                        <span v-if="event.metric.name"><b>&middot;</b></span>
                        <div class="event-metric">
                          <template v-if="event.metric.name && event.metric.name.includes('[')">
                            <div class="tree-row-name">
                              {{ event.metric.name.split(' [')[0] }}
                              <span class="group-tag"> [{{ event.metric.name.split('[')[1].replace(']', '') }}] </span>
                            </div>
                          </template>
                          <template
                            v-else-if="event.metric.name && Object.keys(themeGroupIdMap).includes(event.metric.name)"
                          >
                            <div class="tree-row-name">
                              {{ event.metric.name }}
                            </div>
                          </template>
                          <template v-else-if="npsMetricTypes.includes(event.metric.metric_type)">
                            <span class="tree-row-name">{{ event.metric.name }}</span>
                          </template>
                          <template v-else-if="event.metric.name === 'Sentiment'">
                            <span class="tree-row-name">{{ event.metric.metric_type }}</span>
                          </template>
                          <template v-else>
                            <span class="metric-name">{{ event.metric.name }}</span>
                            <div class="event-info">
                              <span class="metric-type">{{ event.metric.metric_type }}</span>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div class="summary">{{ event.summary }}</div>
                    </div>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          v-if="event.date_range"
                          @click="dateFilter(selectedDateField, [event.date_range.from, event.date_range.to])"
                        >
                          Add
                          <b>{{ formatDate(event.date_range.from, 'DD MMM YYYY') }}</b> to
                          <b>{{ formatDate(event.date_range.to, 'DD MMM YYYY') }}</b>
                          as a filter
                        </el-dropdown-item>
                        <el-dropdown-item
                          v-if="event.metric.metric_type && event.metric.name === 'Sentiment'"
                          @click="$emit('set-filters', getFilters(event, ['sentiment']))"
                        >
                          Add filter <b>Sentiment is {{ event.metric.metric_type }}</b>
                        </el-dropdown-item>
                        <el-dropdown-item
                          v-if="event.date_range && event.metric.name === 'Sentiment'"
                          @click="$emit('set-filters', getFilters(event, ['date', 'sentiment']))"
                        >
                          Add <b> Sentiment is {{ event.metric.metric_type }}</b> and
                          <b>{{ formatDate(event.date_range.from, 'DD MMM YYYY') }}</b>
                          as a filter
                        </el-dropdown-item>
                        <el-dropdown-item
                          v-if="
                            event.metric.metric_type &&
                            npsMetricTypes.includes(event.metric.metric_type) &&
                            event.metric.metric_type !== 'nps'
                          "
                          @click="$emit('set-filters', getFilters(event, ['nps']))"
                        >
                          Drill into<b>{{ event.metric.name.split(' [')[0] }}</b>
                        </el-dropdown-item>
                        <el-dropdown-item
                          v-if="
                            event.date_range &&
                            npsMetricTypes.includes(event.metric.metric_type) &&
                            event.metric.metric_type !== 'nps'
                          "
                          @click="$emit('set-filters', getFilters(event, ['date', 'nps']))"
                        >
                          Drill into<b>{{ event.metric.name.split(' [')[0] }}</b> and Add
                          <b>{{ formatDate(event.date_range.from, ' DD MMM YYYY') }}</b>
                          as a filter
                        </el-dropdown-item>
                        <el-dropdown-item
                          v-if="event.metric.name && event.metric.name.includes('[')"
                          @click="$emit('go-to-theme', getThemeOrThemeGroupId(event.metric.name, 'theme'))"
                        >
                          Drill into<b>{{ event.metric.name.split(' [')[0] }}</b>
                        </el-dropdown-item>
                        <el-dropdown-item
                          v-if="event.metric.name && Object.keys(themeGroupIdMap).includes(event.metric.name)"
                          @click="$emit('go-to-theme-group', getThemeOrThemeGroupId(event.metric.name, 'themeGroup'))"
                        >
                          Drill into<b>{{ event.metric.name.split(' [')[0] }}</b>
                        </el-dropdown-item>
                        <el-dropdown-item
                          v-if="event.date_range && event.metric.name && event.metric.name.includes('[')"
                          @click="
                            $emit('go-to-theme', getThemeOrThemeGroupId(event.metric.name, 'theme')) &&
                            $emit('set-filters', getFilters(event, ['date']))
                          "
                        >
                          Drill into<b>{{ event.metric.name.split(' [')[0] }}</b> and Add
                          <b>{{ formatDate(event.date_range.from, 'DD MMM YYYY') }}</b>
                          as a filter
                        </el-dropdown-item>
                        <el-dropdown-item
                          v-if="
                            event.date_range &&
                            event.metric.name &&
                            Object.values(themeGroupNameMap).includes(event.metric.name)
                          "
                          @click="
                            $emit('go-to-theme-group', getThemeOrThemeGroupId(event.metric.name, 'themeGroup')) &&
                            $emit('set-filters', getFilters(event, ['date']))
                          "
                        >
                          Drill into<b>{{ event.metric.name }}</b> and Add
                          <b>{{ formatDate(event.date_range.from, 'DD MMM YYYY') }}</b>
                          as a filter
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
              </div>
              <div v-else-if="!comparisonInsights" class="content" v-html="content" />
              <span v-if="parsedCues" :style="{ display: 'flex', justifyContent: 'center' }">
                <el-pagination
                  layout="pager"
                  hide-on-single-page
                  :total="parsedCues.length"
                  :current-page="currentPage"
                  :page-size="pageSize"
                  @update:current-page="currentPage = $event"
                />
              </span>
            </div>
          </template>
          <template v-else>
            <a class="refresh-link" @click="updateCue"> <i class="icon refresh" />Update cues </a>
          </template>
        </el-container>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, ref, computed, PropType } from 'vue'
import dayjs from 'dayjs'
import { QueryRow, SavedQuery } from 'types/Query.types'
import { Analytics } from 'src/analytics'
import { useStore } from 'vuex'

// For now the returning format is inconsistent in which field returns
//   what data.
//For example, in case of nps metric, we get the format
//   name: 'Passives'
//   metric_type: 'passive'
//In case of sentiment metric, we get the format
//   name: 'Sentiment'
//   metric_type: 'neutral'
//Inn case of theme metric, we get the format
//   name: 'Theme Name [Theme Group]'
//   metric_type: 'Frequency (%)'
interface InsightEvent {
  date_range: { from: string; to: string }
  event_type: string
  metric: {
    metric_type: string
    name: string
    value: string | number
    z_score: number
    significance: string
    summary: string
  }
}

const InsightCues = defineComponent({
  components: {},
  props: {
    cuesStale: { type: Boolean, required: false, default: true },
    loading: { type: Boolean, required: false, default: false },
    content: { type: String, required: false, default: '' },
    selectedDateField: { type: String, required: true },
    themeNameMap: {
      type: Object,
      required: false,
      default: null,
    },
    themeToGroupMap: {
      type: Object,
      required: false,
      default: null,
    },
    queries: {
      type: Array as PropType<SavedQuery[]>,
      required: false,
      default: () => [],
    },
  },
  emits: [
    'set-filters',
    'fetch',
    'date-hover-start',
    'date-hover-end',
    'toggle-filter',
    'go-to-theme',
    'go-to-theme-group',
    'metric-hover-start',
    'metric-hover-end',
    'go-to-segment',
  ],
  setup(props, { emit }) {
    const metricMap = ref({
      Promoters: ['NPS Category', 'Promoter'],
      Detractors: ['NPS Category', 'Detractor'],
      Passives: ['NPS Category', 'Passive'],
      positive: ['sentiment', 'positive'],
      negative: ['sentiment', 'negative'],
      neutral: ['sentiment', 'neutral'],
      mixed: ['sentiment', 'mixed'],
    })
    const pageSize = ref(3)
    const currentPage = ref(1)
    const npsMetricTypes = ref(['promotor', 'detractor', 'passive', 'nps'])
    const analytics = inject<Analytics>('analytics')
    const themeGroupNameMap = inject('themeGroupNameMap', ref({}))
    const themeGroupIdMap = computed(() => {
      let map = {}
      for (const [k, v] of Object.entries(themeGroupNameMap.value)) {
        map[v] = parseInt(k)
      }
      return map
    })

    const store = useStore()
    const dashboardId = computed(() => store.getters.currentDashboard.id)

    const parsedContent = computed(() => {
      try {
        return JSON.parse(props.content)
      } catch {
        return {}
      }
    })

    const comparisonInsights = computed(() => {
      return parsedContent.value?.comparison_insights || null
    })

    const parsedCues = computed(() => {
      if (!parsedContent.value || !parsedContent.value.significant_events) return []
      const compareByDate = (event1: InsightEvent, event2: InsightEvent) => {
        return dayjs(event2.date_range.from).diff(dayjs(event1.date_range.from))
      }
      return parsedContent.value.significant_events.toSorted(compareByDate)
    })

    const paginatedParsedCues = computed(() => {
      let start = (currentPage.value - 1) * pageSize.value
      let end = start + pageSize.value
      return parsedCues.value.slice(start, end)
    })

    const isClickableMetric = (metricName: string, metricType?: string): boolean => {
      if (!metricName || !metricType) return false
      return (
        metricName.includes('Promoters') ||
        metricName.includes('Detractors') ||
        metricName.includes('Passives') ||
        (metricName === 'Sentiment' &&
          metricType &&
          ['positive', 'negative', 'neutral', 'mixed'].includes(metricType.toLowerCase()))
      )
    }

    const getQueryIdFromMetric = (metricName: string | number) => {
      const cleanName = String(metricName).split(' [')[0]
      const query = props.queries.find((q) => q.name === cleanName)
      return query?.id
    }

    const hoverStart = (event: InsightEvent) => {
      if (event.date_range) emit('date-hover-start', event.date_range.from)
      if (event.metric) emit('metric-hover-start', event.metric)
    }

    const hoverEnd = () => {
      emit('date-hover-end')
      emit('metric-hover-end')
    }

    const getFilters = (event: InsightEvent, metricTypes: string[]): QueryRow[] => {
      let filters = []
      if (metricTypes.includes('date') && event.date_range) {
        filters.push(getDateFilter(props.selectedDateField, [event.date_range.from, event.date_range.to]))
      }
      if (metricTypes.includes('nps') && metricMap.value[event.metric.name]) {
        filters.push(getNpsFilter(metricMap.value[event.metric.name]))
      }
      if (metricTypes.includes('sentiment') && metricMap.value[event.metric.metric_type]) {
        filters.push(getSentimentFilter(metricMap.value[event.metric.metric_type]))
      }
      return filters
    }

    const getThemeOrThemeGroupId = (metricName: string, type: 'theme' | 'themeGroup'): number => {
      if (type === 'theme') {
        analytics?.track.dashboard.insightCuesThemeFilter(dashboardId.value)
        return getQueryIdFromMetric(metricName)
      }
      if (type === 'themeGroup') return themeGroupIdMap.value[metricName]
    }

    const dateHover = (date: string) => {
      emit('date-hover-start', date)
    }

    const dateHoverEnd = () => {
      emit('date-hover-end')
    }

    const formatDate = (dateStr: string, format: string = 'MMMM YYYY') => {
      return dayjs(dateStr).format(format)
    }

    const getDateFilter = (field: string, dateRange: [string, string]): QueryRow => {
      if (!field) {
        console.error('Date field is undefined')
        return null
      }
      const [from, to] = dateRange
      const filter = {
        field: field,
        type: 'segment',
        operator: 'is in the range',
        is_date: true,
        values: [dayjs(from).format('YYYY-MM-DDT00:00:00'), dayjs(to).format('YYYY-MM-DDT23:59:59')],
      }
      return filter
    }

    const getNpsFilter = (metricMapValue: [string, string]): QueryRow => {
      return {
        field: metricMapValue[0],
        type: 'segment',
        operator: 'is',
        values: [metricMapValue[1]],
      }
    }

    const getSentimentFilter = (metricMapValue: [string, string]): QueryRow => {
      return {
        field: metricMapValue[0],
        type: 'attribute',
        operator: 'is',
        values: [metricMapValue[1]],
      }
    }

    const dateFilter = (field: string, dateRange: [string, string]) => {
      const filter = getDateFilter(field, dateRange)
      emit('set-filters', [filter])
      analytics?.track.dashboard.insightCuesDateFilter(dashboardId.value)
    }

    const cuesTrigger = () => {
      if (props.cuesStale && !props.loading) {
        emit('fetch')
      }
    }

    const updateCue = () => {
      emit('fetch')
    }

    return {
      parsedCues,
      comparisonInsights,
      formatDate,
      cuesTrigger,
      updateCue,
      dateFilter,
      dateHover,
      dateHoverEnd,
      isClickableMetric,
      paginatedParsedCues,
      pageSize,
      currentPage,
      getDateFilter,
      getQueryIdFromMetric,
      hoverStart,
      hoverEnd,
      themeGroupNameMap,
      themeGroupIdMap,
      npsMetricTypes,
      metricMap,
      getNpsFilter,
      getFilters,
      getThemeOrThemeGroupId,
    }
  },
})
export default InsightCues
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.cues-collapse
  width: inherit

.cues-header
  display: flex
  justify-content: space-between
  width: inherit

::v-deep
  .content
    h1, h2, h3
      font-size: 15px
      font-weight: bold

.label
  font-size: 14px
  font-weight: bold
  letter-spacing: 0.6px
  color: $text-black
  margin-bottom: 3px
  text-transform: uppercase
  align-self: center

.ai-label
  font-size: 14px
  letter-spacing: 0.6px
  color: rgb(149, 166, 172)
  margin-bottom: 3px
  text-transform: uppercase
  align-self: center

.events-section
  :hover
    background-color: $grey-light-background
    cursor: pointer

.event
  margin-bottom: 16px
  padding: 0 12px

.event-header
  display: flex
  align-items: center
  gap: 8px
  margin-bottom: 4px

.tree-row-name, .metric-name.clickable, .date-filter
  cursor: pointer
  padding-bottom: 2px
  border-bottom: 2px transparent
  font-size: 14px
  color: $blue
  font-weight: bold

.event-metric
  display: flex
  gap: 6px
  align-items: center
  .metric-name
    font-size: 14px
    text-transform: capitalize
    color: $grey-dark

.event-date
  display: flex
  flex-direction: column
  gap: 2px

.event-info
  display: flex
  gap: 8px
  align-items: center
  .metric-type
    color: #666
    font-size: 0.9em

.summary
  color: #4b5563
  font-size: 13px
  line-height: 1.4

.group-tag
  color: $blue
  font-size: 1.0em
  margin-left: 4px

:deep(.el-dropdown-menu__item)
  line-height: 1.5
  padding: 8px 12px
  font-size: 14px

  b
    margin-left: 4px
    margin-right: 4px

.comparison-insights
  padding: 12px
  margin-bottom: 16px
  background-color: #f8fafc
  border-radius: 6px

  .comparison-summary
    font-size: 14px
    color: $text-black
    line-height: 1.5
    margin-bottom: 12px
</style>
