<template>
  <div>
    <div ref="wrapper" @mousemove="$emit('mousemove', $event)">
      <div v-if="!tree?.isEmpty" class="tree-row header">
        <div class="row-content">
          <div v-for="(header, i) in headers" :key="header">
            <span :class="['header-name', `column-${i}`, { sorted: i === sortAttribute }]" @click="toggleSort(i)">
              {{ header }}&nbsp;
              <up-down :up="i === sortAttribute && isAscending" :down="i === sortAttribute && !isAscending" />
            </span>
          </div>
        </div>
      </div>
      <lazy-tree
        ref="tree"
        :key="treeKey"
        class="theme-tree"
        :load="lazyLoadNodes"
        node-key="key"
        :props="{ isLeaf }"
        :allow-drop="allowDrop"
        :allow-drag="allowDrag"
        :draggable="allowDragDrop"
        :filter-string="filterString"
        :highlight-key="dropTarget?.key"
        :force-expand="forceExpand"
        @node-collapse="resize"
        @node-expand="resize"
        @node-drop="handleDrop"
        @node-drag-enter="nodeDragEnter"
        @node-drag-start="handleDrag"
        @node-drag-end="
          () => {
            draggingNode = null
            dropTarget = undefined
          }
        "
      >
        <template #empty>
          <slot name="empty" />
        </template>
        <template #loading>
          <slot name="loading" />
        </template>
        <template #default="{ data, level, canExpand }">
          <div
            :class="[
              'tree-row',
              {
                unsaved: data.type === 'theme' && unsavedMap[data.id],
                drilldown: isFocusNode(data),
                group: data.type === 'group',
                selected: data.key === focusNode?.key,
              },
            ]"
            @mouseleave="mouseLeaveRow"
            @mouseenter="mouseOverRow($event, data)"
          >
            <div class="row-content">
              <div>
                <el-dropdown
                  class="row-name-dropdown"
                  :disabled="$slots.dropdown == null"
                  trigger="click"
                  placement="bottom-start"
                  popper-class="drilldown-menu"
                  @visible-change="$emit('dropdown-visible-change', $event)"
                >
                  <div
                    :class="[
                      'tree-row-name',
                      {
                        'can-hover': $slots.dropdown != null,
                        'highlighted': dropTarget?.key === data.key,
                      },
                    ]"
                    :title="data.name"
                    @click.stop="$emit('name-click', data)"
                    @mouseenter="nameHovered = true"
                    @mouseleave="nameHovered = false"
                    v-html="wrapSubstring(data.name, filterString)"
                  ></div>
                  <template #dropdown>
                    <slot name="dropdown" :data="data" />
                  </template>
                </el-dropdown>
                <div v-if="data.type === 'group'" class="node-summary">
                  <template v-if="unsavedChildMap[data.id]">
                    ({{ countThemes(data) }} themes, <span class="red">{{ unsavedChildMap[data.id] }} unsaved</span>)
                  </template>
                  <template v-else> ({{ countThemes(data) }} themes) </template>
                </div>
                <div
                  v-if="!columnValueMap[`${data.type}_${data.id}`] || loadingKeys.includes(`${data.type}_${data.id}`)"
                  class="row-spinner"
                >
                  <bf-spinner />
                </div>
                <div v-else-if="canExpand" class="expand-collapse">
                  <icon
                    :size="10"
                    color="#888"
                    name="chevron-right"
                    :class="[
                      'expanded-icon',
                      {
                        expanded: data.expanded,
                      },
                    ]"
                  />
                  <div
                    v-if="showExpandText"
                    :class="[
                      'expanded-text',
                      {
                        hidden: nameHovered,
                      },
                    ]"
                  >
                    {{ data.expanded ? 'collapse' : 'expand' }}
                  </div>
                </div>
              </div>
              <template v-if="showContextKey !== data.key && columnValueMap[data.key]">
                <div
                  v-for="(val, i) in columnValueMap[data.key]"
                  :key="`${data.name}_${val}`"
                  :class="[
                    `column-${i + 1}`,
                    {
                      sorted: i + 1 === sortAttribute,
                    },
                  ]"
                  :style="{ minWidth: `${colWidths[i + 1]}px` }"
                >
                  {{ val != null ? number(val) : 'N/A' }}{{ isPercent[i] ? '%' : '' }}
                </div>
              </template>
              <template v-if="showContextKey === data.key">
                <el-dropdown
                  trigger="click"
                  placement="bottom-end"
                  popper-class="drilldown-menu"
                  @visible-change="contextToggle(data, $event)"
                  @command="$emit('context-command', $event)"
                >
                  <icon class="context-dots" color="#aaa" name="horizontal-dots" :size="14" @click.stop />
                  <template #dropdown>
                    <slot name="contextMenu" :data="data" :level="level" />
                  </template>
                </el-dropdown>
              </template>
            </div>
            <div class="progress-wrapper">
              <div class="tree-row-progress" :style="{ maxWidth: `${maxProgressWidth}px` }">
                <div v-if="hasNegativeValue" class="negative">
                  <div
                    v-if="getSortedVal(data.type, data.id) < 0"
                    :style="{ width: `${getRelativePercent(data.type, data.id)}%` }"
                  />
                </div>
                <div v-if="hasPositiveValue">
                  <div
                    v-if="getSortedVal(data.type, data.id) >= 0"
                    :style="{ width: `${getRelativePercent(data.type, data.id)}%` }"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </lazy-tree>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, watch, onMounted } from 'vue'
import { escapeRegExp, throttle } from 'lodash'

import icon from 'assets/img/dashboards/dash-queries.svg'
import errorIcon from 'assets/icons/alert-bubble.svg'
import { GroupOrTheme } from 'src/pages/dashboard/Dashboard.utils'
import {
  findNode,
  findParentNode,
  isNodeContained,
} from 'components/project/analysis/results/ThemeBuilder/ThemeBuilder.utils'
import BfSpinner from 'components/Butterfly/BfSpinner/BfSpinner.vue'
import UpDown from 'components/widgets/UpDown/UpDown.vue'
import Icon from 'components/Icon.vue'
import { number } from 'src/utils/formatters'
import { ThemeGroup } from 'src/api/query'
import LazyTree from './LazyTree.vue'
import { NodeData } from './LazyTreeNode.vue'

export const createNode = (node: GroupOrTheme | NodeData): NodeData => {
  const nodeData: NodeData = {
    id: Number(node.id),
    name: node.name,
    type: node.type,
    key: `${node.type}_${node.id}`,
  }

  if ('children' in node) {
    nodeData.children = node.children?.map(createNode)
  }

  return nodeData
}

const ThemeTree = defineComponent({
  name: 'ThemeTree',
  components: {
    BfSpinner,
    UpDown,
    Icon,
    LazyTree,
  },
  props: {
    headers: { type: Array as PropType<string[]>, required: true },
    fetchColumnData: { type: Function as PropType<(nodes: NodeData[]) => Promise<number[][]>>, required: true },
    projectId: { type: Number, required: true },
    analysisId: { type: Number, required: true },
    focusNode: { type: Object as PropType<NodeData | null>, default: null },
    isPercent: { type: Array as PropType<boolean[]>, required: true },
    fetchTree: { type: Function as PropType<() => Promise<NodeData[]>>, required: true },
    allowDragDrop: { type: Boolean, default: false },
    loadingKeys: { type: Array as PropType<string[]>, default: () => [] },
    filterString: { type: String, default: '' },
    unsavedMap: { type: Object as PropType<Record<string, boolean>>, default: () => ({}) },
    showExpandText: { type: Boolean, default: true },
    maxBarValue: { type: Number, default: 0 },
    forceExpand: { type: Boolean, default: false },
  },
  emits: [
    'node-toggle',
    'tree-loading',
    'node-collapse',
    'node-expand',
    'name-click',
    'mousemove',
    'mouseenter-row',
    'mouseleave-row',
    'dropdown-visible-change',
    'node-moved',
    'tree-updated',
    'drag-start',
    'context-command',
    'tree-sorted',
  ],
  setup(props, { emit, slots }) {
    const wrapper = ref<HTMLDivElement>()
    const tree = ref<InstanceType<typeof LazyTree> | null>(null)

    const columnValueMap = ref<Record<string, number[]>>({})
    const sortAttribute = ref(0)
    const isAscending = ref(true)
    const nameHovered = ref(false)
    const treeKey = ref(0)
    const colWidths = ref<number[]>([])

    const treeData = computed(() => {
      return tree.value?.treeData ?? ([] as NodeData[])
    })

    // The max value of the currently selected column
    // used to calculate the relative width of the progress bars
    const selectedMaxVal = computed(() => {
      if (props.maxBarValue) return props.maxBarValue
      const index = Math.max(0, sortAttribute.value - 1)
      const allColVals = Object.values(columnValueMap.value).map((vals) => Math.abs(vals[index]))
      return Math.max(...allColVals)
    })

    // These dictate how the progress bars are displayed
    const hasNegativeValue = computed(() => {
      const index = Math.max(0, sortAttribute.value - 1)
      const allColVals = Object.values(columnValueMap.value).map((vals) => vals[index])
      return allColVals.some((val) => val < 0)
    })
    const hasPositiveValue = computed(() => {
      const index = Math.max(0, sortAttribute.value - 1)
      const allColVals = Object.values(columnValueMap.value).map((vals) => vals[index])
      return allColVals.some((val) => val >= 0)
    })

    // Many nodes may be expanded at once, so we batch data fetching
    const nodesToFetch = ref<NodeData[]>([])
    const batchTimeout = ref<ReturnType<typeof setTimeout> | null>(null)
    const batchColumnData = (nodes: NodeData[]) => {
      nodesToFetch.value.push(...nodes)
      if (batchTimeout.value) {
        clearTimeout(batchTimeout.value)
      }
      batchTimeout.value = setTimeout(() => {
        setColumnData(nodesToFetch.value).then(() => {
          sortTree()
        })
        nodesToFetch.value = []
      }, 200)
    }

    // Fetch and store coverage stats for a group of tree nodes
    const setColumnData = async (nodes: NodeData[]) => {
      if (!nodes.length) return
      const coverageStats = await props.fetchColumnData(nodes)
      for (const [i, node] of nodes.entries()) {
        columnValueMap.value[`${node.type}_${node.id}`] = coverageStats[i]
      }
    }

    // This is called by the tree component to load child nodes when a node is expanded
    const lazyLoadNodes = async (node: NodeData | null, resolve: (nodes: NodeData[] | null) => void): Promise<void> => {
      // Load the root nodes (fetch tree)
      if (node === null) {
        emit('tree-loading', true)
        resize()
        const groupTree = await props.fetchTree()
        const nodes = groupTree.map(createNode)
        resolve(nodes)
        // Sort before setting column data in case we are
        // sorting by name, then after setting column data
        sortTree()
        batchColumnData(nodes)
        emit('tree-loading', false)

        setTimeout(() => {
          // Expand focus node if specified
          if (props.focusNode) setExpandedNode(props.focusNode)
        })
        return
      }

      if (node.type === 'theme') {
        resolve(null)
        return
      }

      const treeNode = findNode(treeData.value, { id: node.id, type: 'group' })
      const children = (treeNode?.children || []).map(createNode)
      resolve(children)

      batchColumnData(children)
    }

    const setExpandedNode = (targetNode: { type: NodeData['type']; id: number }) => {
      const parentKeys: string[] = []

      // Target node
      let node = findNode(treeData.value, { type: targetNode.type, id: targetNode.id })

      // If the target node is a theme, find the parent group
      if (node?.type === 'theme') {
        node = findParentNode(treeData.value, { id: node.id, type: 'theme' })
      }
      if (!node) return

      // Expand all parent nodes
      do {
        parentKeys.unshift(node.key)
        node = findParentNode(treeData.value, node)
      } while (node)

      parentKeys.forEach((key) => {
        setTimeout(() => {
          const node = findNode(treeData.value, { key })
          if (node) node.expanded = true
        })
      })
    }

    // Get the value of the sorted column for a node
    const getSortedVal = (type: string, id: number) => {
      const index = Math.max(0, sortAttribute.value - 1)
      const key = `${type}_${id}`
      if (!columnValueMap.value[key]) return 0
      return columnValueMap.value[key][index]
    }

    // Get the relative width of the progress bar for a node
    const getRelativePercent = (type: string, id: number) => {
      if (selectedMaxVal.value === 0) return 0
      const val = getSortedVal(type, id)
      return (Math.abs(val) / selectedMaxVal.value) * 100
    }

    const toggleSort = (index: number) => {
      if (sortAttribute.value === index) {
        isAscending.value = !isAscending.value
      } else {
        sortAttribute.value = index
      }
      emit('tree-sorted')
    }

    // Computed property that returns a sorted version of the tree data
    const sortTreeData = (data: NodeData[]): NodeData[] => {
      return data.sort((a, b) => {
        let compare = 0

        if (sortAttribute.value === 0) {
          compare = a.name.localeCompare(b.name)
        } else {
          const index = sortAttribute.value - 1
          const aKey = `${a.type}_${a.id}`
          const bKey = `${b.type}_${b.id}`
          const aValues = columnValueMap.value[aKey]
          const bValues = columnValueMap.value[bKey]
          if (aValues && bValues) {
            compare = aValues[index] - bValues[index]
          }
        }

        return isAscending.value ? compare : -compare
      })
    }

    // Calculate the maximum allowed width of the progress bar
    const maxProgressWidth = ref(Infinity)
    const calcMaxProgressWidth = throttle(() => {
      if (!wrapper.value) return Infinity

      const bars = Array.from(wrapper.value.querySelectorAll<HTMLDivElement>('.progress-wrapper'))
      if (!bars?.length) return Infinity

      return bars.reduce((acc, el) => {
        const width = el.offsetWidth
        return width < acc && width > 0 ? width : acc
      }, Infinity)
    }, 100)

    // Initial data fetch
    const resetTree = async () => {
      columnValueMap.value = {}
      // Force a re-render of the tree
      treeKey.value += 1
    }

    const resize = () => {
      const val = calcMaxProgressWidth()
      if (val) {
        maxProgressWidth.value = val
      }
    }

    const sortTree = () => {
      const node = tree.value?.treeData
      const sortLevel = (nodes: NodeData[]) => {
        sortTreeData(nodes)
        nodes.forEach((n) => n.children && sortLevel(n.children))
      }

      if (node) {
        sortLevel(node)
      }
    }

    // Reset tree state when sorting changes
    watch([sortAttribute, isAscending], () => {
      sortTree()
    })

    // Check if a node is, or is contained within, the focus node
    const isFocusNode = (node: NodeData) => {
      const target = props.focusNode
      if (target) {
        return (
          (node.id === target.id && node.type === target.type) ||
          isNodeContained(treeData.value, { id: node.id, type: node.type }, { id: target.id, type: target.type })
        )
      }
      return false
    }

    // Mimic table column resizing i.e set to column to widest cell
    const updateColumns = () => {
      props.headers.forEach((header, i) => {
        const cells = wrapper.value?.querySelectorAll<HTMLDivElement>(`.column-${i}`)
        if (!cells) return
        let maxWidth = 0
        cells.forEach((col) => {
          if (i < 2 && col.classList.contains('header-name')) {
            return
          }
          const width = col.getBoundingClientRect().width
          if (width > maxWidth) {
            maxWidth = width
          }
        })
        colWidths.value[i] = Math.round(maxWidth)
      })
    }

    watch(
      columnValueMap,
      () => {
        setTimeout(() => {
          updateColumns()
        }, 0)
      },
      {
        deep: true,
      },
    )

    const countThemes = (item: NodeData): number => {
      return item.children?.reduce((acc, child) => acc + 1 + countThemes(child), 0) ?? 0
    }

    // All nested groups in the tree
    const allGroups = computed(() => {
      const recursiveGroups = (nodes: NodeData[]): NodeData[] => {
        return nodes.reduce((acc, node) => {
          if (node.type === 'group') {
            acc.push(node)
          }
          if (node.children) {
            acc.push(...recursiveGroups(node.children))
          }
          return acc
        }, [] as NodeData[])
      }
      return recursiveGroups(treeData.value)
    })

    // Map groups to number of unsaved children
    const unsavedChildMap = computed(() => {
      return allGroups.value.reduce(
        (acc, group) => {
          const unsaved = group.children?.filter((child) => child.type === 'theme' && props.unsavedMap[child.id])
          acc[group.id] = unsaved?.length ?? 0
          return acc
        },
        {} as Record<string, number>,
      )
    })

    const allowDrop = (dropNode: NodeData, node: NodeData, parentNode: NodeData | null) => {
      if (!dropNode) return false
      // Can't drop on self
      if (node.id === dropNode.id) return false
      if (dropNode.type === 'theme' && parentNode?.id === node.id) return false
      if (node.type === 'group') {
        // Can't drop group into Ungrouped themes
        if (dropNode.id === -1 && dropNode.type === 'group') return false
        if (parentNode?.id === -1 && parentNode?.type === 'group') return false
      }
      return true
    }

    const allowDrag = (node: NodeData) => {
      // Allow dragging of all nodes except Ungrouped themes
      return node?.key !== 'group_-1'
    }

    const dropTarget = ref<NodeData | undefined>()
    const draggingNode = ref<NodeData | null>(null)
    const draggingParent = ref<NodeData | undefined>() // Parent of draggingNode

    const handleDrop = async (child: NodeData, other: NodeData): Promise<void> => {
      try {
        // Determine parent based on drop target
        const parent: NodeData | undefined =
          other.type === 'theme' ? findParentNode(treeData.value, { id: other.id, type: other.type }) : other
        if (!parent) return

        // Set parentId; -1 means "Ungrouped themes" so use undefined
        const parentId: number | undefined = parent.id === -1 ? undefined : Number(parent.id)
        const currentParentId = draggingParent.value?.id !== undefined ? Number(draggingParent.value.id) : undefined
        if (parentId === currentParentId) return

        tree.value?.removeNode(child.key)
        parent.children && parent.children.push(child)
        sortTree()

        const endpoint = child.type === 'theme' ? ThemeGroup.updateThemeParent : ThemeGroup.updateGroupParent

        await endpoint(props.projectId, props.analysisId, child.id, parentId ?? null)

        emit('node-moved', child, draggingParent.value, parent)
      } catch (error) {
        console.warn('Error during drag and drop:', error)
      }
    }

    const nodeDragEnter = (dragNode: NodeData, dropNode: NodeData): void => {
      const currentParent = findParentNode(treeData.value, {
        id: dragNode.id,
        type: dragNode.type,
      })

      const target: NodeData | undefined =
        dropNode.type === 'group' ? dropNode : findParentNode(treeData.value, { id: dropNode.id, type: dropNode.type })

      dropTarget.value = target && currentParent?.id !== target.id ? target : undefined
    }

    const handleDrag = (node: NodeData) => {
      emit('drag-start')
      draggingNode.value = node
      const currentParent = findParentNode(treeData.value, {
        id: node.id,
        type: node.type,
      })

      draggingParent.value = currentParent
    }

    const hoverKey = ref<string | null>(null)
    const showContextKey = computed(() => {
      if (!slots.contextMenu) return null
      return hoverKey.value ?? contextKey.value
    })

    const contextKey = ref<string | null>(null)
    const contextToggle = (node: NodeData, visible: boolean) => {
      if (visible) {
        contextKey.value = `${node.type}_${node.id}`
      } else {
        contextKey.value = null
      }
    }

    const mouseOverRow = (event: MouseEvent, data: NodeData) => {
      emit('mouseenter-row', event, data)
      if (contextKey.value) return
      hoverKey.value = `${data.type}_${data.id}`
    }

    const mouseLeaveRow = (event: MouseEvent) => {
      emit('mouseleave-row', event)
      hoverKey.value = null
    }

    const wrapSubstring = (str: string, substring: string): string => {
      if (!substring) return str

      // Escape special regex characters in substring so we can use it in a regex
      const escapedSubstring = escapeRegExp(substring)
      const regex = new RegExp(escapedSubstring, 'gi')
      return str.replace(regex, (match) => `<span class="search-match">${match}</span>`)
    }

    const getNode = (key: string) => {
      return tree.value?.getNode(key)
    }
    const getParentNode = (key: string) => {
      return tree.value?.getParentNode(key)
    }
    const updateColumnData = (key: string, data: number[]) => {
      columnValueMap.value[key] = data
    }
    const deleteColumnData = (key: string) => {
      delete columnValueMap.value[key]
    }
    const removeNode = (key: string) => {
      tree.value?.removeNode(key)
    }
    const insertNode = (parentId: number | null, node: NodeData) => {
      tree.value?.insertNode(parentId, node)
    }
    const updateNodeData = (key: string, props: Partial<NodeData>) => {
      tree.value?.updateNode(key, props)
    }
    const getOpenNodes = () => {
      return tree.value?.getOpenNodes()
    }
    const getVisibleNodes = () => {
      return tree.value?.getVisibleNodes()
    }

    onMounted(() => {
      if (props.focusNode) {
        setExpandedNode(props.focusNode)
      }
    })

    return {
      isFocusNode,
      icon,
      errorIcon,
      refresh: window.location.reload,
      wrapper,
      userErrors: null,
      resetTree,
      Intercom: window.Intercom,
      sortAttribute,
      isAscending,
      lazyLoadNodes,
      columnValueMap,
      number,
      toggleSort,
      getRelativePercent,
      hasNegativeValue,
      hasPositiveValue,
      getSortedVal,
      isLeaf: (n: NodeData) => n.type === 'theme',
      isEmpty: computed(() => tree.value?.isEmpty ?? true),
      maxProgressWidth,
      nameHovered,
      tree,
      treeKey,
      setExpandedNode,
      resize,
      colWidths,
      updateColumnData,
      removeNode,
      updateNodeData,
      countThemes,
      allowDrop,
      allowDrag,
      handleDrop,
      dropTarget,
      nodeDragEnter,
      draggingNode,
      handleDrag,
      deleteColumnData,
      sortTree,
      insertNode,
      hoverKey,
      showContextKey,
      contextToggle,
      contextKey,
      mouseOverRow,
      mouseLeaveRow,
      treeData,
      getNode,
      getParentNode,
      unsavedChildMap,
      getOpenNodes,
      getVisibleNodes,
      wrapSubstring,
    }
  },
})

export default ThemeTree
</script>
<style lang="scss" scoped>
@import '~assets/kapiche.sass';

.row-spinner {
  :deep(.bf-spinner-container) {
    height: 100%;
    .bf-spinner {
      width: 13px !important;
      height: 13px !important;
      border-width: 2px !important;
      margin-right: 0px;
    }
  }
}

.theme-tree {
  width: 100%;

  :deep {
    .el-dropdown.is-disabled {
      color: unset;
    }
  }
}

.tree-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  cursor: default;
  font-size: 14px;
  white-space: nowrap;

  &.unsaved::after {
    content: '*';
    position: absolute;
    top: 10px;
    right: -16px;
    z-index: 1;
    color: $red;
    font-size: 18px;
  }

  &:hover {
    color: $blue;
    .tree-row-name {
      color: $blue;
    }
    .expanded-text {
      opacity: 0.5;
    }
  }

  &.group {
    cursor: pointer;
  }

  .sorted {
    color: $blue;
  }

  .row-content {
    width: 100%;
    display: flex;
    height: 20px;

    > :nth-child(1) {
      flex-basis: 100%;
      min-width: 0;
      user-select: none;
      display: flex;
      align-items: center;
    }

    > div:not(:nth-child(1)) {
      text-align: right;
      margin-left: 20px;
    }
  }

  &.header {
    user-select: none;
    text-transform: uppercase;
  }
}

.row-name-dropdown {
  min-width: 0;
}

.progress-wrapper {
  display: flex;
  justify-content: flex-end;
  $color: #c8c8c8;
  background-image: linear-gradient(
    135deg,
    $grey-light 25%,
    $color 25%,
    $color 50%,
    $grey-light 50%,
    $grey-light 75%,
    $color 75%,
    $color 100%
  );
  background-size: 8.49px 8.49px;
  height: 8px;
  margin-top: 4px;
}

.tree-row-progress {
  width: 100%;
  height: 100%;
  background: $grey-light;
  display: flex;
  max-width: 100%;
  transition: max-width 0.3s ease;

  > div {
    height: 100%;
    flex: 1;
    display: flex;
    &.negative {
      justify-content: end;
    }
    > div {
      height: 100%;
      background: $blue-light;
      transition: width 0.3s ease;
    }
  }
}

.drilldown {
  .tree-row-progress {
    > div {
      > div {
        background: #8064aa;
      }
    }
  }
}

.header-name {
  color: $text-grey;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;

  .up-down {
    position: relative;
    top: 1px;
  }
}

.expand-collapse {
  display: flex;
  align-items: center;
  margin-top: -6px;
  margin-left: 6px;
}

.expanded-icon {
  margin-right: 2px;
  margin-top: 3px;
  transition: transform 0.3s ease;
  transform-origin: 45% center;
  transform: rotate(90deg);
  &.expanded {
    transform: rotate(-90deg);
  }
}

.expanded-text {
  opacity: 0;
  transition: opacity 0.3s ease;
  font-style: italic;
  color: #888;
  font-size: 13px;

  &.hidden {
    opacity: 0 !important;
  }
}

.content {
  width: 100%;
}

.tree-row-name {
  cursor: pointer;
  padding-bottom: 2px;
  border-bottom: 2px dotted transparent;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;

  &.highlighted {
    color: $blue;
  }

  &.can-hover:hover {
    border-bottom: 2px dotted $blue;
  }
}

.node-summary {
  font-size: 12px;
  margin-left: 4px;
  color: $text-grey;
  font-style: italic;
  position: relative;
  top: -2px;
}

.selected {
  .tree-row-name {
    color: $blue;
  }
}

.context-dots {
  cursor: pointer;
}

.red {
  color: $red;
}

:deep(.search-match) {
  color: $blue;
  background: rgba($blue, 0.1);
}
</style>
