<template>
  <widget-frame
    ref="root"
    :zoomed="false"
    :masked="masked"
    :is-loading="false"
    :dev-mode="devMode"
    :has-errored="false"
    class="score-timeline"
  >
    <template #icon>
      <img class="header-icon" :src="icon" alt="Dashboard themes icon" />
    </template>

    <template #header> Score range </template>

    <template #actions>
      <download-export-button
        :name="`${exportName}-Score-Timeline`"
        :is-loading="isLoading"
        :get-el="getTrendEl"
        :get-csv-data="getCsvData"
        :make-ppt-slide="hasDate ? makePptSlide : undefined"
        :get-svg-export-config="getExportConfig"
        short-name="Score-Timeline"
        show-alerts
      ></download-export-button>
    </template>

    <template #menu>
      <div class="menu-list">
        <widget-menu :menus="menus" :vertical="false" :bound="$el" @onSelect="setMenuSelection" />
      </div>
    </template>

    <template #error-panel> </template>

    <template #content>
      <div v-if="isLoading" class="loading">
        <bf-spinner></bf-spinner>
      </div>
      <div v-else class="content">
        <div v-if="overallData" class="overall-stats">
          <div>
            <div>{{ formatNumber(overallAggVal) }}{{ overallSuffix }}</div>
            <div>{{ dataDisplayLabel }}</div>
          </div>
          <div v-if="hasImpact">
            <div>{{ formatNumber(overallImpactVal) }}{{ overallSuffix }}</div>
            <div>Impact on {{ dataDisplayLabel }}</div>
          </div>
        </div>
        <template v-if="hasDate">
          <div v-if="timelineSeries.length > 0" class="timeline-container">
            <insight-cues
              v-if="aiUse && getTimelineCues"
              ref="cueContent"
              :loading="timelineCuesLoading"
              :content="formattedTimelineCues"
              :selected-date-field="dateField"
              :cues-stale="areTimelineCuesStale"
              @date-hover-start="dateHover"
              @date-hover-end="dateHoverEnd"
              @set-filters="(filters) => $emit('set-filters', filters)"
              @toggle-filter="toggleFilters"
              @fetch="fetchTimelineCues"
            />
            <timeline
              ref="timeline"
              timeline-id="score-timeline"
              :all-series="timelineSeries"
              :y-label="dataDisplayLabel"
              :y-label-right="hasImpact ? `Impact on ${dataDisplayLabel}` : undefined"
              :y-axis-right-names="hasImpact ? [`Impact on ${dataDisplay}`] : undefined"
              :y-axis-left-color="hasImpact ? '#068CCC' : '#383838'"
              :y-axis-right-color="'#f89516'"
              :y-range="yRange"
              :y-range-right="yRangeRight"
              :y-value-number-format="yNumberFormat"
              :resolution="resolution.toLowerCase()"
              :x-label="dateField"
              :records="[]"
            />
          </div>
          <widget-message-panel v-else>
            <template #title>
              <span>No Data</span>
            </template>
            <template #message>
              <span>There is not sufficient data to display this widget.</span>
            </template>
          </widget-message-panel>
        </template>
      </div>
    </template>
  </widget-frame>
</template>

<script lang="ts">
import PptxGenJS from 'pptxgenjs'
import { PropType, computed, defineComponent, inject, onMounted, ref, watch } from 'vue'
import WidgetMenu from 'components/DataWidgets/WidgetMenu/WidgetMenu.vue'
import WidgetFrame from 'components/widgets/WidgetFrame/WidgetFrame.vue'
import WidgetMessagePanel from 'components/widgets/WidgetMessagePanel/WidgetMessagePanel.vue'
import icon from 'assets/img/dashboards/dash-score.svg'
import { BfSpinner } from 'components/Butterfly'
import { MenuEntry, WidgetMenuOptions } from 'src/types/components/WidgetMenu.types'
import { Resolution, PivotData, TrendLine, FetchStatus } from 'src/types/widgets.types'
import { Block, Requirements } from 'src/types/PivotData.types'
import { WidgetConfig } from 'src/types/DashboardTypes'
import { COLUMN_LABELED_TYPES } from 'src/api/project'
import Timeline from 'components/project/analysis/results/widgets/Timeline.vue'
import InsightCues from 'components/DataWidgets/InsightCues/InsightCues.vue'
import { getAggregationOffset, getBoxValues, makeDoubleTimelineSlide, makeTimelineSlide } from '../DataWidgetUtils'
import dayjs from 'dayjs'
import { SchemaColumn } from 'src/types/SchemaTypes'
import { ChrysalisFilter } from 'src/types/DashboardFilters.types'
import { Analytics } from 'src/analytics'
import DownloadExportButton from 'components/project/analysis/results/widgets/DownloadExportButton.vue'
import { formatCsvData } from './ScoreTimeline.utils'

// These regex match the `top/bottom x` agg method saved on the project schema.
const topBoxRegexLower = /top (\d+) box/
const botBoxRegexLower = /bot (\d+) box/

// These regex match the `top/bottom x` agg method used in the widget and saved in
// the dashboard config.
const topBoxRegexUpper = /Top (\d+) box/
const botBoxRegexUpper = /Bottom (\d+) box/

const resolutionOptions: Resolution[] = ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly']

type TimelineRecords = Record<string, Record<string, { countDocument: number }>>

export const regroup = (
  data: PivotData,
  dateField: string,
  group: string,
  seriesSettings: {
    name: string
    color: string
    lineStyle: TrendLine['lineStyle']
    visible: boolean
    accessor: string | ((d: PivotData['payload'][0]) => number)
  }[],
): [TimelineRecords, TrendLine[]] => {
  const records: TimelineRecords = Object.fromEntries(seriesSettings.map((series) => [series.name, {}]))

  const seriesMap: Record<string, TrendLine> = {}

  for (const series of seriesSettings) {
    seriesMap[series.name] = {
      counts: [],
      datetimes: [],
      color: series.color,
      name: series.name,
      lineStyle: series.lineStyle,
      visible: series.visible,
    }
  }

  data.payload.forEach((dataPoint) => {
    if (dataPoint.group__ !== group) return

    const timestamp = dayjs(dataPoint[dateField]).valueOf()

    for (const series of seriesSettings) {
      seriesMap[series.name].counts.push(
        typeof series.accessor === 'string' ? +dataPoint[series.accessor] : series.accessor(dataPoint),
      )
      seriesMap[series.name].datetimes.push(timestamp)

      records[series.name][dataPoint[dateField]] = {
        countDocument: +dataPoint['frequency_cov'],
      }
    }
  })

  return [records, Object.values(seriesMap)]
}

const roundDecimal = (val: number) => {
  return Math.round(val * 100) / 100
}

const formatNumber = (val: number): string => {
  return roundDecimal(val).toLocaleString('en', { useGrouping: true })
}

export default defineComponent({
  components: {
    WidgetFrame,
    WidgetMenu,
    BfSpinner,
    Timeline,
    WidgetMessagePanel,
    DownloadExportButton,
    InsightCues,
  },
  props: {
    devMode: { type: Boolean, required: false, default: false },
    masked: { type: Boolean, required: false, default: false },
    dashboardId: { type: Number, required: false, default: null },
    projectId: { type: Number, required: false, default: null },
    currentSite: { type: Object, default: () => null, required: false },
    currentProject: { type: Object, default: () => null, required: false },
    currentAnalysis: { type: Object, default: () => null, required: false },
    config: { type: Object as PropType<WidgetConfig<'score-timeline'> | null>, required: false, default: null },
    dateFields: { type: Array as PropType<SchemaColumn[]>, required: true },
    defaultDateField: { type: String, required: false, default: null },
    segmentFields: { type: Array as PropType<SchemaColumn[]>, required: false, default: () => [] },
    weekStart: { type: String, required: false, default: null },
    data: { type: Object as PropType<PivotData>, required: false, default: null },
    status: { type: String as PropType<FetchStatus>, required: true },
    overallData: { type: Object as PropType<PivotData>, required: false, default: null },
    group: { type: String, required: false, default: 'overall__' },
    schema: { type: Array as PropType<SchemaColumn[]>, required: true },
    dashboardFilters: { type: Array as PropType<ChrysalisFilter[]>, required: false, default: () => [] },
    hasDate: { type: Boolean, required: false, default: false },
    exportName: { type: String, required: false, default: '' },
    dayFirstDates: { type: Boolean, required: false, default: false },
    aiUse: { type: Boolean, required: false, default: false },
    timelineCuesLoading: { type: Boolean, required: false, default: true },
    getTimelineCues: { type: Boolean, required: false, default: false },
    timelineCues: { type: String, required: false, default: null },
  },
  setup(props, { emit }) {
    const root = ref<InstanceType<typeof WidgetFrame> | null>(null)

    const isLoading = computed(() => props.status === 'fetching')
    const analytics = inject<Analytics>('analytics')

    const dataDisplay = ref<string>('')
    const resolution = ref<Resolution>('Monthly')
    const aggMethod = ref<string>('Average')
    const dateField = ref<string>('')

    const timeline = ref<InstanceType<typeof Timeline> | null>(null)
    const timelineSeries = ref<TrendLine[]>([])
    const records = ref({})

    const overallAggVal = ref<number>(0)
    const overallImpactVal = ref<number>(0)

    const overallSuffix = computed(() => {
      const isBoxAgg = isTopBox.value || isBottomBox.value
      return isBoxAgg ? '%' : ''
    })

    const lastCueReqs = ref<string>('')
    const formattedTimelineCues = computed(() => {
      return props.timelineCues || null
    })

    const getTrendEl = () => {
      return root.value?.$el.querySelector('div.content')
    }

    const getExportConfig = () => {
      return {
        dims: getTrendEl()?.getBoundingClientRect(),
        css: `
          text {
            color: #383838;
            font-size: 14px;
            stroke: none;
          }
          .line {
            stroke-width: 2px;
          }
          .axis path, .axis line {
            shape-rendering: crispEdges;
            stroke: #ebebeb;
            stroke-width: 2px;
            opacity: 0.5;
          }
        `,
      }
    }

    const dateHover = (date: string) => {
      if (timeline.value) {
        timeline.value.showDateVerticalLine(date)
      }
    }

    const dateHoverEnd = () => {
      if (timeline.value) {
        timeline.value.hideDateVerticalLine()
      }
    }

    const updateConfig = () => {
      // For Top x and Bottom x, we don't specify the int box value (x) in the string,
      // This can cause clashes if it top/bottom x is changed from the project settings.
      let agg = aggMethod.value
      if (isTopBox.value) {
        agg = 'Top x box'
      } else if (isBottomBox.value) {
        agg = 'Bottom x box'
      }
      const options: NonNullable<typeof props.config>['options'] = {
        dataDisplay: dataDisplay.value,
        resolution: resolution.value,
        aggMethod: agg,
        dateField: dateField.value,
      }
      const updated = Object.assign({}, props.config, { options })
      emit('config-changed', updated)
    }

    const isTopBox = computed(() => {
      // Indicates if the current selected aggMethod is topBox.
      return Boolean(typeof aggMethod.value === 'string' && aggMethod.value.match(topBoxRegexUpper))
    })

    const isBottomBox = computed(() => {
      // Indicates if the current selected aggMethod is bottomBox.
      return Boolean(typeof aggMethod.value === 'string' && aggMethod.value.match(botBoxRegexUpper))
    })

    const currentSchemaColumn = computed(() => {
      return props.schema.find((col) => col.name === dataDisplay.value)
    })

    const topBoxVal = computed(() => {
      // This represents the X value for Top X aggregation if set in the score column
      let topBox = 2
      let agg = currentSchemaColumn.value?.score_aggregation
      let match
      if (typeof agg === 'string' && (match = agg.match(topBoxRegexLower))) {
        topBox = parseInt(match[1])
      }
      return topBox
    })

    const botBoxVal = computed(() => {
      // This represents the X value for Bottom X aggregation if set in the score column
      let botBox = 2
      let agg = currentSchemaColumn.value?.score_aggregation
      let match
      if (typeof agg === 'string' && (match = agg.match(botBoxRegexLower))) {
        botBox = parseInt(match[1])
      }
      return botBox
    })

    const defaultAggMethod = computed<string>(() => {
      // This computed prop gets the agg method from the schema and maps it to the method names we use in the
      // widget.
      // The possible agg methods from the schema at the time of write can be:
      // sum, average, median, top x box, bot x box.
      let agg = currentSchemaColumn.value?.score_aggregation || 'Average'
      if (agg.match(topBoxRegexLower)) {
        agg = `Top ${topBoxVal.value} box`
      } else if (agg.match(botBoxRegexLower)) {
        agg = `Bottom ${botBoxVal.value} box`
      } else if (agg === 'average') {
        agg = 'Average'
      } else if (agg === 'median') {
        agg = 'Median'
      } else if (agg === 'sum') {
        agg = 'Sum'
      }
      return agg
    })

    const aggOptions = computed(() => {
      let options: Record<string, string> = {
        Average: 'mean',
        Median: 'median',
        Sum: 'sum',
      }
      options[`Top ${topBoxVal.value} box`] = `top ${topBoxVal.value} box`
      options[`Bottom ${botBoxVal.value} box`] = `bot ${botBoxVal.value} box`
      return options
    })

    const setOptionsFromConfig = () => {
      let dataDisplay = props.config?.options?.dataDisplay || allFields.value[0]
      let agg = props.config?.options?.aggMethod || defaultAggMethod.value

      // If dataDisplay is a numerical field and agg is somehow top x/bottom x box,
      // we change it to average since those are supported by score fields only.
      if (numericalFields.value.includes(dataDisplay) && ['Top x box', 'Bottom x box'].includes(agg)) {
        agg = 'Average'
      }

      // If the aggregation Method in dashboard config is 'Top x box', 'Bottom x box',
      // we substitute the value of x with the computed topBoxVal || botBoxVal respectively.
      if (agg === 'Top x box') {
        agg = `Top ${topBoxVal.value} box`
      } else if (agg === 'Bottom x box') {
        agg = `Bottom ${botBoxVal.value} box`
      }
      const options = {
        dataDisplay: props.config?.options?.dataDisplay || allFields.value[0],
        resolution: props.config?.options?.resolution || 'Monthly',
        aggMethod: agg,
        dateField: props.config?.options?.dateField || props.defaultDateField,
      }
      setMenuSelection('Data', ['aggMethod', options.aggMethod], true)
      setMenuSelection('Data', ['dataDisplay', options.dataDisplay], true)
      setMenuSelection('Data', ['dateField', options.dateField], true)
      setMenuSelection('Resolution', ['resolution', options.resolution], true)
    }

    const refresh = () => {
      window.location.reload()
    }

    const contact = () => {
      try {
        window.Intercom('show')
      } catch {
        console.warn('intercom show failed')
      }
    }

    const setMenuSelection = (menu: string, [title, value]: [string, string], fromConfig = false) => {
      if (title === 'dataDisplay') {
        dataDisplay.value = value
      } else if (title === 'resolution') {
        resolution.value = value as Resolution
      } else if (title === 'aggMethod') {
        aggMethod.value = value
      } else if (title === 'dateField') {
        dateField.value = value
      }

      if (!fromConfig) {
        analytics?.track.scoreTimeline.changeField(title, value)
        updateConfig()
      }
    }

    const fetchData = (force = false) => {
      const blocks: Block[] = []
      const scoreRange = currentSchemaColumn.value?.score_range
      let filters = []
      const excludeOutOfRange = currentSchemaColumn.value?.exclude_out_of_range ?? false
      if (excludeOutOfRange && isScore.value && scoreRange) {
        filters.push({ field: dataDisplay.value, op: '>=', value: scoreRange[0] })
        filters.push({ field: dataDisplay.value, op: '<=', value: scoreRange[1] })
      }
      if (isTopBox.value || isBottomBox.value) {
        if (!scoreRange) return
        let boxValues = getBoxValues(
          isTopBox.value ? 'top' : 'bottom',
          scoreRange,
          isTopBox.value ? topBoxVal.value : botBoxVal.value,
        )
        blocks.push({
          pivot_field: dataDisplay.value,
          aggfuncs: [
            {
              new_column: 'frequency',
              src_column: 'document_id',
              aggfunc: 'count',
            },
          ],
          metric_calculator: {
            type: 'box',
            field: dataDisplay.value,
            impact: hasImpact.value,
            box_values: boxValues,
          },
        })
      } else {
        blocks.push({
          aggfuncs: [
            {
              new_column: 'aggVal',
              src_column: dataDisplay.value,
              aggfunc: aggOptions.value[aggMethod.value] || 'sum',
            },
          ],
        })

        if (hasImpact.value && !(isTopBox.value || isBottomBox.value)) {
          blocks.push({
            aggfuncs: [
              {
                new_column: 'aggVal|count',
                src_column: dataDisplay.value,
                aggfunc: 'count',
              },
              {
                new_column: 'aggVal|mean__',
                src_column: dataDisplay.value,
                aggfunc: aggOptions.value[aggMethod.value] || 'sum',
              },
            ],
            metric_calculator: 'mean_impact',
          })
        }
      }

      if (!dataDisplay.value) return
      const requirements: Requirements = {
        blocks,
        date_fieldname: dateField.value,
        date_aggregation_offset: getAggregationOffset(resolution.value),
        week_start: props.weekStart,
      }

      emit('requires', 'score-timeline', requirements, force, [...props.dashboardFilters, ...filters])

      // Overall data
      emit(
        'requires',
        'score-timeline-overall',
        {
          blocks: blocks,
        },
        force,
        [...props.dashboardFilters, ...filters],
      )
    }

    const regroupData = (): void => {
      const isBoxAgg = isTopBox.value || isBottomBox.value
      const series: Parameters<typeof regroup>[3] = [
        {
          name: dataDisplay.value,
          color: '#068CCC',
          lineStyle: 'solid-line',
          visible: true,
          accessor: isBoxAgg ? (data) => +data[`${dataDisplay.value}|box%__`] / 100 : 'aggVal',
        },
      ]

      if (hasImpact.value) {
        series.push({
          name: `Impact on ${dataDisplay.value}`,
          color: '#f89516',
          lineStyle: 'solid-line',
          visible: true,
          accessor: isBoxAgg ? (data) => +data[`${dataDisplay.value}|box%i_rto__`] / 100 : 'aggVal|mean__i_rto__',
        })
      }

      const [recs, groupedData] = regroup(props.data, dateField.value, props.group, series)

      timelineSeries.value = groupedData.filter(({ counts = [] }) => counts.length > 0)
      records.value = recs

      const overallDataPoint = props.overallData?.payload.find((dataPoint) => dataPoint.group__ === props.group)

      overallAggVal.value =
        isBoxAgg ?
          Number(overallDataPoint?.[`${dataDisplay.value}|box%__`] ?? 0)
        : Number(overallDataPoint?.['aggVal'] ?? 0)
      if (hasImpact.value) {
        overallImpactVal.value =
          isBoxAgg ?
            Number(overallDataPoint?.[`${dataDisplay.value}|box%i_rto__`] ?? 0)
          : Number(overallDataPoint?.['aggVal|mean__i_rto__'] ?? 0)
      }
    }

    const fetchTimelineCues = () => {
      const data = getCsvData()
      emit('timeline-cues', 'score', data, dateField.value, 'score_timeline_cues', {
        aggregation_method: aggMethod.value,
      })
      lastCueReqs.value = JSON.stringify({
        dataDisplay: dataDisplayLabel.value,
        resolution: resolution.value,
      })
    }

    const areTimelineCuesStale = computed((): boolean => {
      const currentState = JSON.stringify({
        dataDisplay: dataDisplayLabel.value,
        resolution: resolution.value,
      })
      return currentState !== lastCueReqs.value
    })

    const hasImpact = computed(() => {
      return props.group !== 'overall__'
    })

    const dataDisplayLabel = computed(() => {
      return dataDisplay.value ? `${dataDisplay.value} (${aggMethod.value})` : 'No Numerical/Score columns Found'
    })

    const numericalFields = computed((): string[] => {
      return props.segmentFields.reduce(
        (list, field) => (field.type === COLUMN_LABELED_TYPES.get('NUMBER') ? list.concat(field.name) : list),
        [] as string[],
      )
    })

    const scoreFields = computed((): string[] => {
      return props.segmentFields.reduce(
        (list, field) => (field.type === COLUMN_LABELED_TYPES.get('SCORE') ? list.concat(field.name) : list),
        [] as string[],
      )
    })

    const isScore = computed(() => {
      return currentSchemaColumn.value?.type === COLUMN_LABELED_TYPES.get('SCORE')
    })

    const allFields = computed(() => {
      return [...scoreFields.value, ...numericalFields.value]
    })

    type OnSelectParam = Parameters<NonNullable<WidgetMenuOptions['onSelect']>>

    const menus = computed<WidgetMenuOptions[]>(
      () =>
        [
          {
            name: 'Data',
            applyButton: true,
            selection: dataDisplayLabel.value,
            validate: {
              dataDisplay: (value: unknown) => {
                return !!value
              },
            },
            onSelect: (changes: OnSelectParam[0], applyChange: OnSelectParam[1]) => {
              // If Top/Bot 2 is selected, unselect numerical fields as
              // they are not compatible with this aggregation method
              const agg = changes['aggMethod']?.[0] || aggMethod.value
              if (agg.match(topBoxRegexUpper) || agg.match(botBoxRegexUpper)) {
                const data = changes['dataDisplay']?.[0] || dataDisplay.value
                const isNumerical = numericalFields.value.includes(data)
                if (isNumerical) {
                  applyChange('dataDisplay', undefined)
                }
              }
            },
            options: [
              numericalFields.value.length && [
                {
                  title: 'Numerical Field',
                  group: 'dataDisplay',
                  type: 'menu',
                  options: numericalFields.value,
                  selected: [dataDisplay.value],
                  showSelected: true,
                  disabled: (changes: Record<string, [string]>) => {
                    const agg = changes['aggMethod']?.[0] || aggMethod.value
                    const disabled = agg.match(topBoxRegexUpper) || agg.match(botBoxRegexUpper)
                    return disabled && `Not compatible with ${agg}`
                  },
                },
              ],
              scoreFields.value.length && [
                {
                  title: 'Score Field',
                  group: 'dataDisplay',
                  type: 'menu',
                  options: scoreFields.value,
                  selected: [dataDisplay.value],
                  showSelected: true,
                },
              ],
            ].filter(Boolean) as MenuEntry[][],
            leftOptions: [
              [
                {
                  title: 'Display as:',
                  group: 'aggMethod',
                  type: 'radio',
                  options: Object.keys(aggOptions.value),
                  selected: [aggMethod.value],
                  showSelected: true,
                },
                {
                  title: 'Date Field',
                  group: 'dateField',
                  type: 'radio',
                  options: props.dateFields.map((field) => field.name),
                  selected: [dateField.value],
                  showSelected: true,
                },
              ],
            ],
          },
          props.hasDate && {
            name: 'Resolution',
            applyButton: false,
            selection: resolution.value,
            options: [
              [
                {
                  title: 'Resolution',
                  group: 'resolution',
                  type: 'menu',
                  options: resolutionOptions,
                  selected: [resolution.value],
                  showSelected: true,
                },
              ],
            ],
          },
        ].filter(Boolean) as WidgetMenuOptions[],
    )

    const yRange = computed(() => {
      const minVal = Math.floor(Math.min(0, ...timelineSeries.value[0].counts))
      const maxVal = Math.ceil(Math.max(...timelineSeries.value[0].counts))

      if (isScore.value && ['Average', 'Median'].includes(aggMethod.value) && currentSchemaColumn.value?.score_range) {
        return [
          Math.min(minVal, currentSchemaColumn.value?.score_range[0] ?? minVal),
          Math.max(maxVal, currentSchemaColumn.value?.score_range[1] ?? maxVal),
        ]
      }
      return [minVal, maxVal]
    })

    const yRangeRight = computed(() => {
      if (!hasImpact.value) return undefined
      return [
        Math.floor(Math.min(0, ...timelineSeries.value[1].counts)),
        Math.ceil(Math.max(...timelineSeries.value[1].counts)),
      ]
    })

    const yNumberFormat = computed(() => {
      if (['Top 2 box', 'Bot 2 box'].includes(aggMethod.value)) {
        return 'percentage'
      }
      return 'signAwareRoundedFloat'
    })

    const getCsvData = () => {
      return formatCsvData(timelineSeries.value, dateField.value, resolution.value, aggMethod.value)
    }

    const makePptSlide = (pptx: PptxGenJS) => {
      if (!props.hasDate) return

      const slide = pptx.addSlide()
      if (hasImpact.value) {
        makeDoubleTimelineSlide(
          pptx,
          slide,
          [timelineSeries.value.slice(0, 1), timelineSeries.value.slice(1)],
          props.exportName + ' - Score Timeline',
          [dataDisplayLabel.value, `Impact on ${dataDisplayLabel.value}`],
          props.dayFirstDates,
        )
      } else {
        makeTimelineSlide(
          pptx,
          slide,
          timelineSeries.value,
          props.exportName + ' - Score Timeline',
          dataDisplayLabel.value,
          props.dayFirstDates,
        )
      }
    }

    watch([dataDisplay, defaultAggMethod], (newVal, oldVal) => {
      // If the data display field changes, update the aggregation method
      // to match the schema's score aggregation method if set. Only do this
      // if the data display field has changed and the old value is not null,
      // so by default we use the widget's config.
      if ((newVal[0] !== oldVal[0] && oldVal[0]) || (newVal[1] !== oldVal[1] && oldVal[1])) {
        aggMethod.value = defaultAggMethod.value
      }
    })

    watch(
      () => props.dashboardFilters,
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          fetchData()
        }
      },
      { deep: true },
    )

    watch(
      () => props.config,
      () => {
        setOptionsFromConfig()
      },
      {
        deep: true,
      },
    )

    watch([dataDisplay, resolution, aggMethod, dateField], () => {
      fetchData()
    })

    watch(
      () => [props.group],
      () => {
        fetchData()
      },
    )

    watch(
      () => [props.data, props.overallData],
      (val) => {
        if (!val[0] || !val[1]) return
        regroupData()
      },
      { deep: true },
    )

    watch(aggMethod, () => {
      if (!props.data) return
      regroupData()
    })

    onMounted(() => {
      setOptionsFromConfig()
    })

    return {
      icon: icon as string,
      refresh,
      contact,
      isLoading,
      setMenuSelection,
      menus,
      timelineSeries,
      dataDisplay,
      resolution,
      dateField,
      yRange,
      yRangeRight,
      hasImpact,
      overallAggVal,
      overallImpactVal,
      dataDisplayLabel,
      formatNumber,
      yNumberFormat,
      aggMethod,
      defaultAggMethod,
      overallSuffix,
      getTrendEl,
      root,
      getCsvData,
      makePptSlide,
      getExportConfig,
      fetchData,
      formattedTimelineCues,
      fetchTimelineCues,
      areTimelineCuesStale,
      dateHover,
      dateHoverEnd,
      timeline,
    }
  },
})
</script>

<style lang="sass" scoped>
@import '~assets/kapiche.sass'

.header-icon
  height: 32px
  width: 100%

.loading
  text-align: center
  padding: 100px 0

.content
  width: 100%

:deep(.insufficient-data-panel)
  height: unset

.overall-stats
  display: flex
  justify-content: center
  margin: 16px 0

  > div
    text-align: center
    font-weight: bold

    > div:nth-child(1)
      font-size: 24px
      margin-bottom: 6px

    > div:nth-child(2)
      font-size: 16px

    &:not(:first-child)
      margin-left: 32px

    &:nth-child(1)
      color: $blue

    &:nth-child(2)
      color: $orange
</style>
