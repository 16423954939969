<template>
  <div v-show="visible && options" :style="styleObject" class="dropdown-menu" @click.stop="">
    <div v-for="(side, sideIndex) in [leftOptions, options]" :key="sideIndex" class="dropdown-wrapper">
      <div v-if="side && side.length">
        <div
          v-for="column of side"
          :key="objHash(column)"
          :class="[
            'dropdown-column',
            {
              'left-side-menu': sideIndex === 0,
            },
          ]"
        >
          <template v-for="row of getRows(column)" :key="objHash(row)">
            <el-popover effect="dark" :disabled="typeof isDisabled(row) !== 'string'" :popper-options="options">
              <template #default>
                <div>
                  {{ isDisabled(row) }}
                </div>
              </template>
              <template #reference>
                <div
                  :class="[
                    'dropdown-row',
                    {
                      disabled: isDisabled(row),
                    },
                  ]"
                >
                  <div v-if="row.title && !row.hideTitle" class="dropdown-title">
                    {{ row.title }}
                  </div>
                  <template v-if="row.type === 'menu'">
                    <menu-option
                      v-for="(option, index) in ensureOptionsLabelled(row.options)"
                      :key="`${option.label}_${option.value}_${index}`"
                      :option="option"
                      :is-selected="
                        (option) => {
                          return (
                            !!row.showSelected &&
                            !!row.selected &&
                            (Array.isArray(row.selected) ?
                              row.selected.includes(option.value)
                            : row.selected === option.value)
                          )
                        }
                      "
                      :show-selected="row.showSelected"
                      :max-label-length="maxLabelLength"
                      @option-click="(value) => changeOption(row, value, true)"
                      @tooltip-move="moveToolTip"
                    />
                  </template>

                  <div v-if="row.type === 'radio'" class="radio option">
                    <RadioButtons
                      :items="ensureOptionsLabelled(row.options).filter((r) => r.label.trim() !== '')"
                      :selected="
                        (
                          row.selected &&
                          typeof row.selected === 'object' &&
                          'length' in row.selected &&
                          row.selected.length > 0
                        ) ?
                          row.selected[0]
                        : row.selected
                      "
                      @change="(value, dismiss) => changeOption(row, value, dismiss)"
                    />
                  </div>

                  <div v-if="row.type === 'checkbox'" class="checkbox option">
                    <toggle-checkbox
                      v-for="option in ensureOptionsLabelled(row.options)"
                      :key="option.label"
                      :value="
                        row.selected &&
                        (Array.isArray(row.selected) ?
                          row.selected.includes(option.value)
                        : row.selected === option.value)
                      "
                      :disabled="option.disabled"
                      @input="() => changeCheckboxOption(row, option.value, false)"
                    >
                      <span v-truncate="maxLabelLength">{{ option.label }}</span>
                    </toggle-checkbox>
                  </div>

                  <div v-if="row.type === 'inputNumber'" class="option">
                    <el-input-number
                      v-bind="row.settings"
                      :model-value="row.selected"
                      @change="(value: number | string) => changeOption(row, value, false)"
                    >
                    </el-input-number>
                  </div>

                  <!-- Add a null state where there is a title but no row -->
                  <div v-if="!row.options || row.options.length === 0" class="option-disabled text-subdued">
                    No Data
                  </div>
                </div>
              </template>
            </el-popover>
          </template>
        </div>
      </div>
      <div v-if="sideIndex !== 0 && applyButton" class="apply-wrapper">
        <hr />
        <bf-button color="grey" @click="cancelClick"> Cancel </bf-button>
        <bf-button color="blue" @click="applyClick" :disabled="!isValid"> Apply </bf-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import objectHash from 'object-hash'
import { BfButton } from 'components/Butterfly'
import RadioButtons from 'components/widgets/RadioButtons.vue'
import ToggleCheckbox from 'components/widgets/ToggleCheckbox.vue'
import MenuOption from './MenuOption.vue'
import { PropType, computed, defineComponent, onBeforeUnmount, onMounted, ref } from 'vue'
import {
  MenuOption as MenuOptionType,
  MenuOptionLabelled,
  MenuEntry,
  WidgetMenuOptions,
} from 'types/components/WidgetMenu.types'

export const ensureOptionsLabelled = (menu_options: MenuOptionType[]): MenuOptionLabelled[] => {
  /** Both objects and plain strings are supported for this prop. If it's not
   * the "object" kind, then make a conforming object structure so that
   * the template only ever needs to know about the object kind.
   *
   * Since the template only knows about the object kind, what
   * do we emit if user selects an option? The answer is that
   * we always emit the *value* (check with the emit events). It is up to
   * the caller how * to map that back to their option. */
  return menu_options.map((option) => {
    if (typeof option === 'string') {
      return { label: option, value: option }
    }

    // If option is an object with children, make sure children are also properly formatted
    if (typeof option === 'object' && option.children && Array.isArray(option.children)) {
      return {
        ...option,
        children: ensureOptionsLabelled(option.children),
      } as MenuOptionLabelled
    }

    return option as MenuOptionLabelled
  })
}

export default defineComponent({
  components: {
    RadioButtons,
    ToggleCheckbox,
    BfButton,
    MenuOption,
  },
  props: {
    /** `options` is an array of "columns". Inside each element (column),
     * there is another array, of "cells". These cells are rendered top-down
     * in the same order as the array of cells. Inside each cell are some
     * metadata for that cell, like "title", and the "type" of cell it is.
     * In the typescript definition above, the cell is called "MenuEntry".
     * The cell also contains a property called "options" which are the
     * selectable items the user sees and can choose from for selection. */
    options: { type: Array as PropType<Array<MenuEntry[]>>, required: true, default: () => [] },
    leftOptions: { type: Array as PropType<Array<MenuEntry[]>>, required: false, default: () => [] },
    displayCenter: { type: Boolean, default: true },
    styleObject: { type: Object, default: () => ({}) },
    visible: { type: Boolean, default: false },
    bound: { type: HTMLElement, default: null, required: false },
    maxLabelLength: { type: Number, required: false, default: 25 },
    applyButton: { type: Boolean, default: false },
    validate: { type: Object as PropType<WidgetMenuOptions['validate']>, required: false },
    onSelect: { type: Function as PropType<WidgetMenuOptions['onSelect']>, required: false },
  },
  setup(props, { emit }) {
    const changes = ref<Record<string, [string | undefined]>>({})

    const objHash = (value: unknown): string => {
      return objectHash(value as Record<string, unknown>)
    }

    const applyChange = (group: string, selected: string | string[] | number | number[]) => {
      if (props.applyButton) {
        changes.value = { ...changes.value, [group]: Array.isArray(selected) ? selected : [selected] }
        if (props.onSelect) {
          props.onSelect(changes.value, (group, selected) => {
            changes.value = { ...changes.value, [group]: [selected] }
          })
        }
      } else {
        emit('changeOption', [group, selected])
      }
    }

    /**
     * This method is a simple passthrough to emitting a value on menu option change.
     * The expected return value is an array of two values, [ColumnLabel, Field]
     * i.e ['Impact On', 'Sentiment'] */
    const changeOption = (row: MenuEntry, value: string | number, dismiss: boolean): void => {
      if (![].concat(row.selected as any).includes(value)) {
        applyChange(row.group ?? row.title ?? '', String(value))
        if (dismiss && !props.applyButton) emit('hide')
      }
    }

    const changeCheckboxOption = (row: MenuEntry, value: string | number, dissmiss: boolean): void => {
      if (row.selected && (row.selected as any).includes(value)) {
        applyChange(
          row.group ?? row.title ?? '',
          (row.selected as any[]).filter((v: string | number) => v !== value),
        )
      } else {
        applyChange(row.group ?? row.title ?? '', row.selected ? [...(row.selected as any[]), value] : [value])
      }
    }

    // Hide/reset popover when the document background is clicked.
    const onDocumentClick = (e: MouseEvent): void => {
      // Don't close if the element clicked is a date picker.
      if (!e.target) return
      const isDatePicker = (e.target as HTMLElement).closest?.('.pika-single')
      if (props.visible && !isDatePicker) emit('hide')
    }

    const moveToolTip = (el: MouseEvent): void => {
      const target = el.target as HTMLElement
      const column = target?.parentElement?.parentElement as HTMLElement
      const tooltip = target?.querySelector('.tooltip') as HTMLElement
      if (target && column && tooltip) {
        tooltip.style.transform = `translate3d(${target.offsetLeft}px, ${
          // calculate height to place tooltip relative to .dropdown-menu
          // Adjustment of 2px helps with removing the tooltip visibility
          // on menu option mouseout (so the tooltip doesn't gain hover focus)
          target.offsetTop + target.offsetHeight - column.scrollTop + 2
        }px, 0)`
      }
    }

    const cancelClick = () => {
      changes.value = {}
      emit('hide')
    }

    const isValid = computed<boolean>(() => {
      if (props.validate) {
        for (const [key, value] of Object.entries(changes.value)) {
          const validator = props.validate[key]
          if (validator && !validator(value[0])) {
            return false
          }
        }
      }
      return true
    })

    const applyClick = () => {
      if (!isValid.value) return
      for (const [key, value] of Object.entries(changes.value)) {
        emit('changeOption', [key, value[0]])
      }
      changes.value = {}
      emit('hide')
    }

    const getRows = (column: MenuEntry[]): MenuEntry[] => {
      return column.map((row) => {
        const group = row.group ?? row.title
        if (group && changes.value.hasOwnProperty(group)) {
          return {
            ...row,
            selected: changes.value[group],
          }
        }
        return row
      })
    }

    const isDisabled = (row: MenuEntry): boolean | string => {
      if (typeof row.disabled === 'function') {
        return row.disabled(changes.value)
      }

      if (typeof row.disabled === 'boolean') {
        return row.disabled
      }

      return false
    }

    onMounted(() => {
      document.addEventListener('click', onDocumentClick.bind(this))
    })

    onBeforeUnmount(() => {
      // Cleanup click handler for custom display dropdown (when clicking outside element)
      document.removeEventListener('click', onDocumentClick)
    })

    return {
      moveToolTip,
      ensureOptionsLabelled,
      changeCheckboxOption,
      changeOption,
      objHash,
      cancelClick,
      applyClick,
      getRows,
      changes,
      isDisabled,
      isValid,
    }
  },
})
</script>
<style lang="sass" scoped>
@import "assets/kapiche.sass"

.text-subdued
  color: $text-grey

.dropdown-wrapper
  display: flex
  flex-direction: column
  > div:first-child
    display: flex
    flex: 1

.apply-wrapper
  padding: 10px
  text-align: right

  button
    margin: 0 10px 0 0
    &:last-child
      margin: 0

  hr
    border: 0
    border-top: 1px solid $grey
    margin-bottom: 15px

div.dropdown-menu
  display: flex
  justify-content: center
  align-items: stretch
  position: absolute
  z-index: 3
  background-color: white
  border-radius: 4px
  box-shadow: 0 2px 15px 0 rgba(0, 1, 1, 0.1)
  border: solid 1px rgb(230, 230, 230)
  // padding: 10px 5px
  min-width: max-content
  // margin-left aligns the upper left corner of the dropdown with the center
  // of the bound's width and then transform moves the menu left 50% of the
  // menu's width.
  margin-left: 50%
  transform: translateX(-50%)

  .dropdown-column
    text-align: left
    padding: 10px
    padding-bottom: 0
    flex: 1 0 auto
    overflow-y: auto
    // height: 100%

    &.left-side-menu
      background-color: $grey-light-background
      border-right: 1px solid $grey

  .dropdown-row
    margin-bottom: 10px

    &.disabled
      .option
        pointer-events: none
        opacity: 0.5

  .dropdown-title
    padding-left: 10px
    padding-right: 10px
    margin-top: 10px
    font-size: 12px
    font-weight: bold
    letter-spacing: 0.6px
    text-transform: uppercase
    color: $blue
    margin-bottom: 5px

  div.option-disabled
    font-size: 1rem
    padding: 10px
    cursor: default

  div.option
    width: 100%
    padding: 5px 20px 5px 10px
    cursor: pointer
    font-size: 1rem
    text-align: left
    margin: 5px 0
    &:not(.radio):not(.tree):hover
      background-color: #f4f6f7

    &.radio
      padding: 0

    &.checkbox
      .toggle-checkbox
        display: flex
        margin-bottom: 5px

  div.option.selected:not(.tree)
    font-weight: bold
    cursor: default
    &:hover
      background-color: inherit

  div.option.disabled
    cursor: default
    color: $text-grey
    &:hover
      background-color: inherit

  div.option

    &:hover
      div.tooltip
        visibility: visible
        opacity: 1
        &:hover
          visibility: hidden
          opacity: 0

    div.tooltip
      display: block
      background: $grey-extra-light
      color: $text-grey
      position: absolute
      top: 0
      left: 0
      white-space: nowrap
      font-size: 14px
      visibility: hidden
      opacity: 0
      transition: opacity 0.3s ease
      box-shadow: 0 0 3px 0px rgba(#000, 0.15)
      z-index: 1
      padding: 20px !important

  div.option.divider
    border-bottom: 1px solid #F1F1F1
    height: 1px
    padding: 0
    margin: 0 10px
    width: calc(100% - 20px)
</style>
