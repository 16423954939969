<template>
  <div
    class="option tree"
    :title="option.label"
    :class="{
      'divider': option.label.trim() === '',
      'selected': showSelected && isSelected(option),
      'disabled': option.disabled,
      'has-children': hasChildren,
      'expanded': expanded,
    }"
    @click.stop="handleClick"
    @mouseover="moveToolTip"
  >
    <div class="option-content">
      <span v-truncate="maxLabelLength">{{ option.label }}</span>
      <div v-if="option.tooltip" class="tooltip">
        {{ option.tooltip }}
      </div>
      <icon
        v-if="hasChildren"
        :size="10"
        color="#888"
        name="chevron-right"
        :class="[
          'expanded-icon',
          {
            expanded: expanded,
          },
        ]"
        @click.stop="toggleExpand"
      />
    </div>

    <div v-if="hasChildren && expanded && option.children" class="children-container">
      <menu-option
        v-for="(childOption, index) in ensureOptionsLabelled(option.children)"
        :key="`${childOption.label}_${childOption.value}_${index}`"
        :option="childOption"
        :is-selected="isSelected"
        :show-selected="showSelected"
        :max-label-length="maxLabelLength"
        class="child-option"
        @option-click="$emit('option-click', $event)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent, ref, computed } from 'vue'
import { MenuOptionLabelled } from 'types/components/WidgetMenu.types'
import { ensureOptionsLabelled } from './DisplayPopoutTwo.vue'
import Icon from 'src/components/Icon.vue'

export default defineComponent({
  name: 'MenuOption',
  components: {
    Icon,
  },
  props: {
    option: {
      type: Object as PropType<MenuOptionLabelled>,
      required: true,
    },
    isSelected: {
      type: Function as PropType<(option: MenuOptionLabelled) => boolean>,
      required: true,
    },
    showSelected: {
      type: Boolean,
      default: false,
    },
    maxLabelLength: {
      type: Number,
      default: 25,
    },
  },
  emits: ['option-click', 'tooltip-move'],
  setup(props, { emit }) {
    const expanded = ref(false)

    const hasChildren = computed(() => {
      return props.option.children && props.option.children.length > 0
    })

    const toggleExpand = () => {
      expanded.value = !expanded.value
    }

    const handleClick = () => {
      if (!props.option.disabled) {
        emit('option-click', props.option.value)
      }
    }

    const moveToolTip = (el: MouseEvent) => {
      const target = el.currentTarget as HTMLElement
      const column = target?.parentElement?.parentElement as HTMLElement
      const tooltip = target?.querySelector('.tooltip') as HTMLElement
      if (target && column && tooltip) {
        tooltip.style.transform = `translate3d(${target.offsetLeft}px, ${
          // calculate height to place tooltip relative to .dropdown-menu
          // Adjustment of 2px helps with removing the tooltip visibility
          // on menu option mouseout (so the tooltip doesn't gain hover focus)
          target.offsetTop + target.offsetHeight - column.scrollTop + 2
        }px, 0)`
      }
    }

    return {
      expanded,
      hasChildren,
      toggleExpand,
      handleClick,
      moveToolTip,
      ensureOptionsLabelled,
    }
  },
})
</script>

<style lang="scss" scoped>
@import 'assets/kapiche.sass';

div.option {
  width: 100%;
  padding: 5px 20px 0px 10px;
  cursor: pointer;
  font-size: 1rem;
  text-align: left;
  margin: 5px 0;
  user-select: none;
  &.has-children {
    cursor: pointer;
  }
  &.expanded {
    > .children-container {
      display: block;
    }
  }
  .option-content {
    display: flex;
    align-items: center;
    position: relative;
    &:hover {
      text-decoration: underline;
      text-decoration-style: dotted;
      text-decoration-color: rgba($text-black, 0.2);
      text-underline-offset: 3px;
    }
  }
  .children-container {
    display: none;
    border-left: 2px solid $grey-mid-light;
    padding-left: 10px;
    margin-top: 5px;

    .child-option {
      margin: 3px 0 0;
      padding: 3px 10px 0px 5px;
      text-decoration: none !important;
      .children-container {
        margin-left: 10px;
      }
    }
  }
  &.selected {
    > .option-content {
      font-weight: bold;
      cursor: default;
    }
  }
  &.disabled {
    cursor: default;
    color: $text-grey;
  }
  &:hover {
    div.tooltip {
      visibility: visible;
      opacity: 1;
      &:hover {
        visibility: hidden;
        opacity: 0;
      }
    }
  }
  div.tooltip {
    display: block;
    background: $grey-extra-light;
    color: $text-grey;
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    font-size: 14px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease;
    box-shadow: 0 0 3px 0px rgba(#000, 0.15);
    z-index: 1;
    padding: 20px !important;
  }
}
div.option.divider {
  border-bottom: 1px solid #f1f1f1;
  height: 1px;
  padding: 0;
  margin: 0 10px;
  width: calc(100% - 20px);
}
.expanded-icon {
  margin-left: 3px;
  padding: 3px;
  transition: transform 0.3s ease;
  transform-origin: 45% center;
  transform: rotate(90deg);
  cursor: pointer;
  &.expanded {
    transform: rotate(-90deg);
  }
}
</style>
