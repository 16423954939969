<template>
  <widget-frame
    ref="root"
    :zoomed="false"
    :masked="masked"
    :is-loading="isLoading"
    :dev-mode="devMode"
    :has-errored="sliceOneData && sliceOneData.error != null"
    :banner="banner"
    class="compare-nps-timeline"
  >
    <template #icon>
      <img class="header-icon" :src="icon" alt="Dashboard themes icon" />
    </template>

    <template #header> Net Promoter Score (NPS) </template>

    <template #actions>
      <download-export-button
        :name="exportName + '-Compare NPS Timeline'"
        :is-loading="isLoading"
        :get-el="getTrendEl"
        :get-csv-data="getCsvData"
        :get-svg-export-config="getExportConfig"
        :make-ppt-slide="makePptSlide"
        short-name="Compare NPS Timeline"
        show-alerts
      ></download-export-button>
    </template>

    <template v-if="hasDate" #menu>
      <widget-menu :menus="menus" :vertical="false" :bound="$el" @onSelect="setMenuSelection" />
    </template>

    <template #devPanel>
      <div>
        Start: {{ new Date(sliceOneData.startTime || 0) }}<br />
        Done: {{ new Date(sliceOneData.doneTime || 0) }}<br />
        Elapsed: {{ ((sliceOneData.doneTime || 0) - (sliceOneData.startTime || 0)) / 1000 }} seconds<br />
        Status: {{ sliceOneData.status }}<br />
        Error: {{ sliceOneData.error }}
        <hr />
        <h2>this.props</h2>
        <code style="white-space: pre">
          {{ JSON.stringify($props, null, 2) }}
        </code>
      </div>
    </template>

    <template #error-panel>
      <div v-if="sliceOneData.error" class="error-panel">
        <h3>
          <img class="errorIcon" :src="errorIcon" alt="widget error icon" />
          Opps, something went wrong while loading this widget.
        </h3>
        <div class="action">
          Try
          <button @click.stop="reload">reloading this widget</button>
          or
          <button @click.stop="refresh">reloading the page</button>
        </div>
        <div class="action">
          <button @click.stop="contact">Contact support</button>
          if the problem persists.
        </div>
        <div v-if="userError" class="message">
          {{ userError }}
        </div>
      </div>
    </template>

    <template v-if="hasData" #content>
      <div v-if="overallStats" class="statistic">
        <insight-cues
          v-if="getTimelineCues && hasDate && aiUse"
          ref="cueContent"
          :cues-stale="areTimelineCuesStale"
          :loading="timelineCuesLoading"
          :content="formattedTimelineCues"
          :selected-date-field="menuSelections['Date Field']"
          @set-filters="(filters) => $emit('set-filters', filters)"
          @toggle-filter="toggleFilters"
          @fetch="fetchTimelineCues"
          @date-hover-start="dateHover"
          @date-hover-end="dateHoverEnd"
        ></insight-cues>
        <div class="stats-labels">
          <div>{{ sliceOneName }}</div>
          <div>{{ sliceTwoName }}</div>
        </div>
        <div class="stats-items">
          <div v-for="(stat, i) in overallStats" :key="stat.label + '_' + i" class="stat-item" :class="stat.class">
            <div class="numeral">{{ i % 2 ? getPrefix(stat.value, stat.percent) : '' }}{{ stat.value.toFixed(2) }}</div>
            <div class="label">
              {{ stat.label }}
            </div>
          </div>
        </div>
      </div>

      <div class="row timeline-container">
        <timeline
          v-if="hasDate"
          ref="timeline"
          :timeline-id="'timeline-trend'"
          :all-series="selectedDataInPlottableFormat"
          :y-label="displayOptions.yAxisLabelLeft"
          :y-label-right="rightYAxisOptions.label"
          :series-labels="displayOptions.seriesLabels"
          :resolution="menuSelections['Resolution'].toLowerCase()"
          :y-value-number-format="displayOptions.numberType"
          :y-range="yRangeLeft"
          :y-range-right="yRangeRight"
          :x-label="menuSelections['Date Field']"
          :records="records"
          y-axis-left-color="#333"
          :y-axis-right-color="rightYAxisOptions.color"
          :y-axis-right-names="rightYAxisOptions.names"
          @series-visibility-changed="calculateYRange"
        ></timeline>
      </div>
    </template>
    <template v-else #content>
      <widget-message-panel>
        <template #title>
          <span>No Data</span>
        </template>
        <template #message>
          <span>There is not sufficient data to display this widget.</span>
        </template>
      </widget-message-panel>
    </template>
  </widget-frame>
</template>

<script lang="ts">
import PptxGenJS from 'pptxgenjs'
import { PropType, computed, defineComponent, onMounted, ref, watch } from 'vue'
import WidgetMenu from 'components/DataWidgets/WidgetMenu/WidgetMenu.vue'
import WidgetFrame from 'components/widgets/WidgetFrame/WidgetFrame.vue'
import icon from 'assets/img/dashboards/dash-satisfaction.svg'
import errorIcon from 'assets/icons/alert-bubble.svg'
import DownloadExportButton from 'components/project/analysis/results/widgets/DownloadExportButton.vue'
import Timeline from 'components/project/analysis/results/widgets/Timeline.vue'
import InsightCues from 'components/DataWidgets/InsightCues/InsightCues.vue'
import { getCsvData as CSVData, regroupData as regroup } from './NPSTimeline.utils'
import { getAggregationOffset, getDataAbsMax, makeDoubleTimelineSlide, adjustColor } from '../DataWidgetUtils'
import { WidgetMenuOptions } from 'types/components/WidgetMenu.types'
import { WidgetConfig } from 'types/DashboardTypes'
import { FetchState } from 'src/store/modules/data/mutations'
import { PivotData, Resolution, TrendLine } from 'src/types/widgets.types'
import QueryUtils from 'src/utils/query'
import { ChrysalisFilter } from 'types/DashboardFilters.types'
import WidgetMessagePanel from 'components/widgets/WidgetMessagePanel/WidgetMessagePanel.vue'
import { markdown } from 'src/utils/formatters'
import { SchemaColumn } from 'src/types/SchemaTypes'

export default defineComponent({
  components: {
    WidgetFrame,
    DownloadExportButton,
    Timeline,
    WidgetMenu,
    WidgetMessagePanel,
    InsightCues,
  },
  props: {
    sliceOneName: { type: String, required: true },
    sliceTwoName: { type: String, required: true },
    sliceOneFilters: { type: Array as PropType<ChrysalisFilter[]>, required: false, default: () => [] },
    sliceTwoFilters: { type: Array as PropType<ChrysalisFilter[]>, required: false, default: () => [] },
    sliceOneData: { type: Object as PropType<FetchState<PivotData>>, required: false, default: null },
    sliceTwoData: { type: Object as PropType<FetchState<PivotData>>, required: false, default: null },
    sliceOneDataTotal: { type: Object as PropType<FetchState<PivotData>>, required: false, default: null },
    sliceTwoDataTotal: { type: Object as PropType<FetchState<PivotData>>, required: false, default: null },
    dashboardDateFilters: { type: Array as PropType<ChrysalisFilter[]>, required: false, default: () => [] },
    exportName: { type: String, required: false, default: '' },
    devMode: { type: Boolean, required: false, default: false },
    banner: { type: Object, default: () => null, required: false },
    dateFields: { type: Array as PropType<SchemaColumn[]>, required: true },
    defaultDateField: { type: String, required: false, default: null },
    weekStart: { type: String, required: false, default: null },
    group: { type: String, required: false, default: 'overall__' },
    masked: { type: Boolean, required: false, default: false },
    config: { type: Object as PropType<WidgetConfig<'compare-nps-timeline'> | null>, required: false, default: null },
    dayFirstDates: { type: Boolean, required: false, default: false },
    aiUse: { type: Boolean, required: false, default: false },
    getTimelineCues: { type: Boolean, required: false, default: false },
    timelineCues: { type: String, required: false, default: null },
    timelineCuesLoading: { type: Boolean, required: false, default: true },
  },
  setup(props, { emit }) {
    const root = ref<InstanceType<typeof WidgetFrame> | null>(null)
    const timeline = ref<InstanceType<typeof Timeline> | null>(null)
    const cueContent = ref(null)
    const clickedRow = ref(null)

    const selectedDataInPlottableFormat = ref<TrendLine[]>([])
    const records = ref({})
    const yRangeLeft = ref([0, 1])
    const yRangeRight = ref([0, 1])

    const lastEmittedReqs = ref<string>('')
    const lastCueReqs = ref<string>('')

    const menuSelections = ref({
      'Date Field': '',
      'Resolution': 'Monthly' as Resolution,
    })

    const displayOptions = ref({
      seriesLabels: {
        impact: 'Impact on NPS',
        nps: 'NPS',
      },
      numberType: 'signAwareInteger',
      yAxisLabelLeft: 'NPS',
      yCapValue: 200,
      yMultipleOf: 5,
    })

    // If the timeline is being shown on the dashboard overview, then the data
    // is the overall data. This means we cannot calculate impact on X because
    // there is no subset of data to use in the calculation. (e.g if we were
    // showing the stats for a theme, the impact would be calculated using
    // the impact of the theme ON the overall dataset).
    const rightYAxisOptions = computed(
      (): {
        label: string
        color: string
        names: string[]
      } => {
        return {
          label: 'Impact on NPS',
          color: '#333',
          names: [`impact (${props.sliceOneName})`, `impact (${props.sliceTwoName})`],
        }
      },
    )

    const formattedTimelineCues = computed((): string => {
      return props.timelineCues || null
    })

    const closeRowMenu = () => {
      clickedRow.value = null
    }

    const toggleFilters = (segments: [string, string][]) => {
      segments.forEach(([field, segment]) => {
        emit('toggle-filter', field, segment)
      })
      closeRowMenu()
    }

    const overallStats = computed(
      (): {
        label: string
        class: string
        value: number
        percent: boolean
      }[] => {
        const sliceOne = props.sliceOneDataTotal?.data?.payload.find(({ group__ }) => group__ === 'filter_query')
        const sliceTwo = props.sliceTwoDataTotal?.data?.payload.find(({ group__ }) => group__ === 'filter_query')

        const stats = []

        if (sliceOne) {
          stats.push(
            {
              label: 'NPS',
              class: 'nps',
              value: +sliceOne['NPS Category|nps__'] ?? 0,
              percent: false,
            },
            {
              label: 'Impact on NPS',
              class: 'impact',
              value: +sliceOne['NPS Category|npsi_rto__'] ?? 0,
              percent: false,
            },
          )
        }

        if (sliceTwo) {
          stats.push(
            {
              label: 'NPS',
              class: 'nps',
              value: +sliceTwo['NPS Category|nps__'] ?? 0,
              percent: false,
            },
            {
              label: 'Impact on NPS',
              class: 'impact',
              value: +sliceTwo['NPS Category|npsi_rto__'] ?? 0,
              percent: false,
            },
          )
        }

        return stats
      },
    )

    const menus = computed((): WidgetMenuOptions[] => {
      return [
        {
          name: 'Date Field',
          selection: menuSelections.value['Date Field'],
          options: [
            [
              {
                title: 'Date Field',
                type: 'menu',
                showSelected: true,
                selected: menuSelections.value['Date Field'],
                options: props.dateFields.map(({ name }) => name),
              },
            ],
          ],
        },
        {
          name: 'Resolution',
          selection: menuSelections.value['Resolution'],
          options: [
            [
              {
                title: 'Resolution',
                type: 'menu',
                showSelected: true,
                selected: menuSelections.value['Resolution'],
                options: ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly'],
              },
            ],
          ],
        },
      ]
    })

    const hasDate = computed(() => {
      return props.dateFields.length > 0
    })

    const areTimelineCuesStale = computed((): boolean => {
      const currentState = {
        dateField: menuSelections.value['Date Field'],
        resolution: menuSelections.value['Resolution'],
      }
      return JSON.stringify(currentState) !== lastCueReqs.value
    })

    const hasData = computed(() => {
      return (props.sliceOneData?.data?.payload.length ?? 0) > 0 || (props.sliceTwoData?.data?.payload.length ?? 0) > 0
    })

    const isLoading = computed(() => {
      return (
        props.sliceOneData?.status === 'fetching' ||
        props.sliceTwoData?.status === 'fetching' ||
        props.sliceOneDataTotal?.status === 'fetching' ||
        props.sliceTwoDataTotal?.status === 'fetching'
      )
    })

    const getPrefix = (n: number, percent: boolean) => {
      if (percent) return ''
      if (n > 0) return '+'
      return ''
    }

    const getSuffix = (percent: boolean) => {
      if (percent) return '%'
      return ''
    }

    const fetchData = (force = false) => {
      const blocks = [
        {
          aggfuncs: [
            {
              new_column: 'frequency',
              src_column: 'document_id',
              aggfunc: 'count',
            },
          ],
          pivot_field: 'NPS Category',
          metric_calculator: 'nps',
        },
      ]

      const requirements = {
        blocks,
        date_fieldname: menuSelections.value['Date Field'],
        date_aggregation_offset: getAggregationOffset(menuSelections.value['Resolution']),
        week_start: props.weekStart,
      }

      const queryField = (filters: ChrysalisFilter[]) => ({
        queries: [
          {
            name: 'filter_query',
            value: QueryUtils.convertDashboardFiltersToBotanicQueries(
              filters,
              props.dateFields.map(({ name }) => name),
            ),
          },
        ],
      })

      const getDateFilters = (filters: ChrysalisFilter[]) => {
        const dateFields = props.dateFields.map((d) => d.name)
        return filters.filter(({ field }) => dateFields.includes(field))
      }

      // Data by date resolution
      emit(
        'requires',
        'compare-nps-timeline-slice-one',
        {
          ...requirements,
          ...queryField(props.sliceOneFilters),
        },
        force,
        getDateFilters(props.sliceOneFilters),
      )

      emit(
        'requires',
        'compare-nps-timeline-slice-two',
        {
          ...requirements,
          ...queryField(props.sliceTwoFilters),
        },
        force,
        getDateFilters(props.sliceTwoFilters),
      )

      // Overall data
      emit(
        'requires',
        'compare-nps-timeline-overall-slice-one',
        {
          blocks: [
            {
              aggfuncs: [
                {
                  new_column: 'frequency',
                  src_column: 'document_id',
                  aggfunc: 'count',
                },
              ],
              pivot_field: 'NPS Category',
              metric_calculator: 'nps',
            },
          ],
          ...queryField(props.sliceOneFilters),
        },
        force,
        props.dashboardDateFilters,
      )

      emit(
        'requires',
        'compare-nps-timeline-overall-slice-two',
        {
          blocks: [
            {
              aggfuncs: [
                {
                  new_column: 'frequency',
                  src_column: 'document_id',
                  aggfunc: 'count',
                },
              ],
              pivot_field: 'NPS Category',
              metric_calculator: 'nps',
            },
          ],
          ...queryField(props.sliceTwoFilters),
        },
        force,
        props.dashboardDateFilters,
      )
      lastEmittedReqs.value = JSON.stringify(requirements)
    }

    const refresh = () => {
      window.location.reload()
    }

    const contact = () => {
      try {
        window.Intercom('show')
      } catch {
        console.warn('intercom show failed')
      }
    }

    const reload = () => {
      fetchData(true)
    }

    const updateConfig = () => {
      const options: NonNullable<typeof props.config>['options'] = {
        dateField: menuSelections.value['Date Field'],
        resolution: menuSelections.value['Resolution'],
      }
      const updated = Object.assign({}, props.config, { options })
      emit('config-changed', updated)
    }

    const setMenuSelection = (_: unknown, path: [keyof typeof menuSelections.value, string], fromConfig = false) => {
      menuSelections.value[path[0]] = path[1] as any
      if (!fromConfig) updateConfig()
    }

    const setOptionsFromConfig = () => {
      setMenuSelection(null, ['Resolution', props.config?.options?.resolution ?? 'Monthly'], true)
      if (hasDate.value) {
        setMenuSelection(
          null,
          ['Date Field', props.config?.options?.dateField ?? props.defaultDateField ?? props.dateFields[0].name],
          true,
        )
      } else {
        setMenuSelection(null, ['Date Field', ''], true)
      }
    }

    const calculateYRange = () => {
      const visibleDataset = selectedDataInPlottableFormat.value.filter((series) => series.visible)
      if (visibleDataset.length === 0) return

      const dataMaxNPS = getDataAbsMax(
        visibleDataset.filter(({ name }) => name.startsWith('nps'))!,
        displayOptions?.value.yCapValue,
        displayOptions?.value.yMultipleOf,
      )
      yRangeLeft.value = [-dataMaxNPS, dataMaxNPS]

      const dataMaxImpact = getDataAbsMax(
        visibleDataset.filter(({ name }) => name.startsWith('impact'))!,
        displayOptions?.value.yCapValue,
        displayOptions?.value.yMultipleOf,
      )
      yRangeRight.value = [-dataMaxImpact, dataMaxImpact]
    }

    const regroupData = (): void => {
      if (!hasDate.value) return

      records.value = {}
      selectedDataInPlottableFormat.value = []

      const process = (data: PivotData, name: string, color: string) => {
        const [recs, groupedData] = regroup(
          data,
          menuSelections.value['Date Field']!,
          'filter_query',
          ['nps', 'impact'],
          name,
        )

        const rows = groupedData.filter(({ counts = [] }) => counts.length > 0)

        rows.forEach((row) => {
          if (row.name.startsWith('impact')) {
            row.lineStyle = 'dashed-line'
          }
          row.color = color
        })

        selectedDataInPlottableFormat.value.push(...rows)
        Object.assign(records.value, recs)
      }

      props.sliceOneData?.data && process(props.sliceOneData.data, ` (${props.sliceOneName})`, '#068ccc')

      props.sliceTwoData?.data && process(props.sliceTwoData.data, ` (${props.sliceTwoName})`, '#DC7070')

      calculateYRange()
    }

    const getCsvData = () => {
      return CSVData(selectedDataInPlottableFormat.value, menuSelections.value)
    }

    const fetchTimelineCues = async () => {
      const data = CSVData(selectedDataInPlottableFormat.value, menuSelections.value)
      const queryField = (filters: ChrysalisFilter[]) => ({
        queries: [
          {
            name: 'filter_query',
            value: QueryUtils.convertDashboardFiltersToBotanicQueries(
              filters,
              props.dateFields.map(({ name }) => name),
            ),
          },
        ],
      })
      emit('timeline-cues', 'nps-compare', data, menuSelections.value['Date Field'], 'nps_compare_timeline_cues', {
        resolution: menuSelections.value['Resolution'],
        slice_one_name: props.sliceOneName,
        slice_one_filters: queryField(props.sliceOneFilters),
        slice_one_data: props.sliceOneData.data,
        slice_two_name: props.sliceTwoName,
        slice_two_filters: queryField(props.sliceTwoFilters),
        slice_two_data: props.sliceTwoData.data,
      })
      lastCueReqs.value = JSON.stringify({
        dateField: menuSelections.value['Date Field'],
        resolution: menuSelections.value['Resolution'],
      })
    }

    const getTrendEl = () => {
      return root.value?.$el.querySelector('div.content')
    }

    const getExportConfig = () => {
      return {
        dims: getTrendEl()?.getBoundingClientRect(),
        css: `
          text {
            color: #383838;
            font-size: 14px;
            stroke: none;
          }
          .line {
            stroke-width: 2px;
          }
          .axis path, .axis line {
            shape-rendering: crispEdges;
            stroke: #ebebeb;
            stroke-width: 2px;
            opacity: 0.5;
          }
        `,
      }
    }

    const makePptSlide = (pptx: PptxGenJS) => {
      const slide = pptx.addSlide()

      const nps = selectedDataInPlottableFormat.value.filter(({ name }) => name.startsWith('nps'))

      const impact = selectedDataInPlottableFormat.value
        .filter(({ name }) => name.startsWith('impact'))
        .map((s) => ({ ...s, color: adjustColor(s.color, 60) }))

      makeDoubleTimelineSlide(
        pptx,
        slide,
        [nps, impact],
        props.exportName + ' - Compare NPS Timeline',
        ['NPS', 'Impact On NPS'],
        props.dayFirstDates,
      )
    }

    const dateHover = (date: string) => {
      if (timeline.value) {
        timeline.value.showDateVerticalLine(date)
      }
    }

    const dateHoverEnd = () => {
      if (timeline.value) {
        timeline.value.hideDateVerticalLine()
      }
    }

    const collapseCues = () => {
      if (cueContent.value?.$el) {
        cueContent.value.$el.querySelector('.el-collapse-item').classList.remove('is-active')
      }
    }

    watch(
      menuSelections,
      () => {
        fetchData()
      },
      {
        deep: true,
      },
    )

    watch(
      () => [props.sliceOneData?.data, props.sliceTwoData?.data],
      (val) => {
        if (val === null) return
        regroupData()
      },
      {
        deep: true,
      },
    )

    watch(
      () => props.config,
      () => {
        setOptionsFromConfig()
      },
      {
        deep: true,
      },
    )

    watch(
      () => [props.sliceOneFilters, props.sliceTwoFilters, props.group],
      () => {
        fetchData()
      },
      {
        deep: true,
      },
    )

    watch(
      () => [props.sliceOneName, props.sliceTwoName],
      () => {
        regroupData()
      },
      {
        deep: true,
      },
    )

    onMounted(() => {
      setOptionsFromConfig()

      if (
        props.sliceOneData === null ||
        props.sliceTwoData === null ||
        props.sliceOneDataTotal === null ||
        props.sliceTwoDataTotal === null
      ) {
        // regroupData and set OverallData will get called by the watchers
        // when the data is available
        fetchData()
      } else {
        // the data we need is available so we can immediately call the
        // wrangler functions
        regroupData()
      }
    })

    return {
      icon,
      errorIcon,
      refresh,
      contact,
      reload,
      getPrefix,
      getSuffix,
      overallStats,
      displayOptions,
      rightYAxisOptions,
      menuSelections,
      menus,
      root,
      getCsvData,
      getExportConfig,
      getTrendEl,
      hasDate,
      setMenuSelection,
      selectedDataInPlottableFormat,
      yRangeLeft,
      yRangeRight,
      records,
      calculateYRange,
      isLoading,
      makePptSlide,
      hasData,
      fetchTimelineCues,
      formattedTimelineCues,
      areTimelineCuesStale,
      dateHover,
      dateHoverEnd,
      collapseCues,
      clickedRow,
      toggleFilters,
      closeRowMenu,
      cueContent,
    }
  },
})
</script>

<style lang="sass" scoped>
@import "assets/colours.sass"
@import '~assets/kapiche.sass'

$dark-orange: #DC7070

.statistic
  margin-top: 14px
  min-width: 30%
  display: flex
  align-items: center
  justify-content: space-around
  flex-direction: column
  .stat-item
    font-size: 26px
    font-weight: bold
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    margin: 0px 15px
    color: $blue

    &:nth-child(3)
      margin-left: 60px

    &:nth-child(3),
    &:nth-child(4)
      color: $dark-orange

    .label
      margin: 10px
    .label
      font-size: 16px
      font-weight: normal

.header-icon
  height: 32px
  width: 100%

.row
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center

.stats
  display: flex
  flex-direction: row
  justify-content: space-evenly
  align-items: center

.stat
  font-size: 26px
  min-width: 5em
  font-weight: bold
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  flex-grow: 1

.label
  margin: 10px
  font-size: 16px
  font-weight: normal

::v-deep
  .legend-buttons
    display: none
  .timeline-legend-container
    column-count: 2
    margin-top: -6px
    li
      text-align: center
      .clickable-legend
        display: inline-flex

.error-panel
  display: flex
  flex-direction: column
  align-items: center
  font-size: 16px
  padding-bottom: 30px

.message
  display: flex
  flex-direction: row
  justify-content: center
  background-color: rgba(255, 0, 0, 0.1)
  padding: 6px
  color: $text-black
  width: 100%
  max-height: 30px
  position: absolute
  bottom: 0

.errorIcon
  position: relative
  height: 32px
  width: 32px
  display: inline-block
  top: 10px

.action
  padding-top: 20px

button
  background: none
  border: none
  border-bottom: 2px solid $blue
  padding: 3px 4px

  &:hover
    background-color: $grey-light

  &:focus
    border: 2px solid $blue-light
    outline: none

// These settings are required to allow the chart to resize correctly. If you comment these out,
// weird things happen with the sizing of the timeline.
.timeline-container
  width: inherit
  align-items: unset

.stats-labels
  display: flex
  justify-content: space-around
  width: 100%
  font-size: 14px
  font-weight: bold
  font-style: italic
  margin-bottom: 20px
  margin-top: 20px

.stats-items
  display: flex
  margin-bottom: 20px
</style>
