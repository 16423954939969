<template>
  <widget-frame
    ref="root"
    :zoomed="false"
    :masked="masked"
    :is-loading="false"
    :dev-mode="devMode"
    :has-errored="false"
    class="summary"
    @resize="setChartDimensions"
  >
    <!--======================== ACTIONS -->
    <template #actions>
      <div>
        <div class="default-actions">
          <!-- Removed until we have a widget-specific help page -->
          <!-- <a :href="CONST.widget_help_links.ai_summary" class="widget-action help" target="_blank">
            <i class="kapiche-icon-info"></i>
          </a> -->
        </div>
      </div>
    </template>

    <template #icon>
      <img class="header-icon" :src="icon" alt="Dashboard themes icon" />
    </template>

    <template #header>
      {{ widgetTitle }}
    </template>

    <template #menu>
      <span class="label">AI GENERATED </span>
    </template>

    <template #devPanel>
      <div>
        <!-- Start: {{ new Date(sliceOneData.startTime || 0) }}<br />
        Done: {{ new Date(sliceOneData.doneTime || 0) }}<br />
        Elapsed: {{ ((sliceOneData.doneTime || 0) - (sliceOneData.startTime || 0)) / 1000 }} seconds<br />
        Status: {{ sliceOneData.status }}<br />
        Error: {{ sliceOneData.error }} -->
        <hr />
        <h2>this.props</h2>
        <code style="white-space: pre">
          {{ JSON.stringify($props, null, 2) }}
        </code>
      </div>
    </template>

    <template #error-panel> </template>

    <template #content>
      <div v-if="isLoading" class="loading">
        <br />
        <bf-spinner></bf-spinner>
        <br />
        <br />
        One moment, reading your data...
        <br />
        <br />
      </div>
      <widget-message-panel v-else-if="hasOnlyTextFields">
        <template #title>
          <span>No Data</span>
        </template>
        <template #message>
          <span>No structured segments exist to generate overview.</span>
        </template>
      </widget-message-panel>
      <div v-else-if="!hasEnoughThemes" class="insufficient-themes">
        At least 5 themes are required to generate an overview
      </div>
      <div v-else-if="shouldShowSummary" class="summary-wrapper">
        <div class="summary-text">
          <p>Below is What's Working Well and Pain Points in this dataset:</p>
          <div v-for="metric in metricsWithData" :key="metric">
            <p style="font-weight: 700">{{ metric }}</p>
            <ul>
              <li v-for="theme in summary_items[metric]" :key="theme.id">
                {{ theme.isGroup ? 'Theme Group' : 'Theme' }}
                <a @click.prevent="$emit(theme.isGroup ? 'go-to-theme-group' : 'go-to-theme', theme.id)">
                  {{ theme.name }}
                </a>
                {{ theme.tagline }}
                <b>{{ formatNumber(theme.value) }}</b>
                points.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <bf-button v-else color="blue" @click="fetchData"> Generate </bf-button>
    </template>
  </widget-frame>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted, computed, PropType } from 'vue'
import WidgetFrame from 'components/widgets/WidgetFrame/WidgetFrame.vue'
import icon from 'assets/img/dashboards/dash-summary.svg'
import { BfButton, BfSpinner } from 'components/Butterfly'
import { useFetchData } from 'components/project/analysis/results/ThemeBuilder/ThemeBuilder.utils'
import { number } from 'src/utils/formatters'
import { isEqual, startCase } from 'lodash'
import { makeRequirements } from './AiOverviewSummary.requirements'
import { SavedQuery } from 'types/Query.types'
import { fetch_pivot_data } from 'src/store/modules/data/api'
import { PivotData } from 'src/types/widgets.types'
import { ExpandedGroup } from 'src/pages/dashboard/Dashboard.utils'
import { SchemaColumn } from 'src/types/SchemaTypes'
import { getScorePivotOptions } from 'src/utils/score'

interface ThemeValue {
  name: string
  tagline: string
  isGroup: boolean
  value: number
  id: number
  sortDirection?: 'asc' | 'desc'
  isPositiveDriver?: boolean
}

interface TopThemesResult {
  [category: string]: ThemeValue[]
}

interface StatInfo {
  sort: 'asc' | 'desc'
  tagline: string
}

const { fetch } = useFetchData()

export default defineComponent({
  components: {
    WidgetFrame,
    BfSpinner,
    BfButton,
  },
  props: {
    themes: { type: Array as PropType<(SavedQuery | ExpandedGroup)[]>, required: true },
    devMode: { type: Boolean, required: false, default: false },
    /** Add a skeleton mask (used when reloading state between dashboards) */
    masked: { type: Boolean, required: false, default: false },
    dashboard: { type: Object, default: () => null },
    mergedFilters: { type: Array, default: () => null, required: false },
    /** does this data contain NPS? */
    hasNps: { type: Boolean, required: false, default: false },
    /** does this data contain sentiment? */
    hasSentiment: { type: Boolean, required: false, default: false },
    widgetTitle: { type: String, required: false, default: 'Dataset Overview' },
    automaticFetch: { type: Boolean, required: false, default: false },
    isStaff: { type: Boolean, required: false, default: false },
    schema: { type: Array as PropType<SchemaColumn[]>, required: true },
  },
  emits: ['go-to-theme', 'go-to-theme-group'],
  setup(props) {
    const root = ref<InstanceType<typeof WidgetFrame> | null>(null)

    const isLoading = ref(false)
    const summary_items = ref<TopThemesResult>({})
    const width = ref(300)

    const refresh = () => {
      window.location.reload()
    }

    const setChartDimensions = (w: number): void => {
      width.value = w
    }

    const contact = () => {
      try {
        window.Intercom('show')
      } catch {
        console.warn('intercom show failed')
      }
    }
    const reload = () => {
      fetchData()
    }

    const formatNumber = (value: number) => {
      return number(value)
    }

    const hasOnlyTextFields = computed(() => {
      const COLUMN_LABELED_TYPES = new Map([['TEXT', 1]])
      const textFields = props.schema.filter((col) => COLUMN_LABELED_TYPES.get('TEXT') === col.type)
      return textFields.length === props.schema.length
    })

    const fetchData = async () => {
      if (!props?.themes?.length || hasOnlyTextFields.value) {
        summary_items.value = {}
        return
      }

      try {
        isLoading.value = true

        const scoreColumns = props.schema.filter((col) => col.type === 8)
        const scoreOptions = scoreColumns.map((col) => {
          return getScorePivotOptions(col)
        })

        const stats_info: Record<string, StatInfo> = {
          'NPS Category|npsi_rto__': {
            sort: 'asc',
            tagline: 'is having an impact on NPS of',
          },
          'sentiment__|negative%i_rto__': {
            sort: 'desc',
            tagline: 'is having an impact on Negative Sentiment of',
          },
          'sentiment__|positive%i_rto__': {
            sort: 'desc',
            tagline: 'is having an impact on Positive Sentiment of',
          },
        }

        scoreOptions.forEach((option) => {
          if (option.range && option.boxVal != null) {
            const aggName = startCase(option.agg).replace('X', option.boxVal.toString())
            stats_info[`${option.name}|box%i_rto__`] = {
              sort: 'asc',
              tagline: `shows a shift in the ${option.name} ${aggName} by`,
            }
          } else {
            stats_info[`${option.name}|mean__i_rto__`] = {
              sort: 'asc',
              tagline: `is having an impact on ${option.name} (${startCase(option.agg)}) of`,
            }
          }
        })

        const requirements = makeRequirements(
          props.hasNps,
          props.hasSentiment,
          scoreOptions,
          props.themes.map((theme) => ({
            ...theme,
            name: theme.group ? `group_${theme.id}` : `theme_${theme.id}`,
          })),
          false,
        )

        const fetchParams = [
          {
            dashboardId: props.dashboard.id,
            projectId: props.dashboard.project.id,
            chrysalisRef: props.dashboard.project.chrysalis_ref,
            topicId: props.dashboard.analysis.topic_framework_id,
          },
          requirements,
          props.mergedFilters,
        ]

        const cacheKey = { fetchParams, name: 'ai-overview-summary' }
        const data = await fetch<PivotData>(cacheKey, fetch_pivot_data, fetchParams, false)
        const payload = data.payload

        const allDrivers: ThemeValue[] = []

        // Process each item in the payload
        payload.forEach((item) => {
          const groupName = item['group__']
          if (groupName === 'overall__') {
            // Skip the "overall__" group
            return
          }

          const id = Number(groupName.replace(/^(group_|theme_)/, ''))
          const isGroup = groupName.startsWith('group_')
          // Find theme with more lenient matching
          const theme = props.themes.find((t) => {
            // If group is undefined in the theme, treat it as false
            const themeIsGroup = t.group === true
            return t.id === id && themeIsGroup === isGroup
          })
          if (!theme) {
            return
          }

          // Combine all metric values for this theme
          Object.entries(item).forEach(([key, value]) => {
            const statInfo = stats_info[key]
            if (!statInfo) return // Skip if we don't have info for this metric
            const numValue = parseFloat(value.toString())
            const roundedValue = formatNumber(numValue)

            if (isNaN(numValue) || roundedValue === '0') return
            if (key === 'sentiment__|negative%i_rto__' && numValue <= 0) {
              return
            }

            let isPositiveDriver = false
            if (key === 'NPS Category|npsi_rto__') {
              isPositiveDriver = numValue > 0
            } else if (key === 'sentiment__|negative%i_rto__') {
              isPositiveDriver = numValue < 0
            } else if (key === 'sentiment__|positive%i_rto__') {
              isPositiveDriver = numValue > 0
            } else if (key.includes('|mean__i_rto__') || key.includes('|box%i_rto__')) {
              isPositiveDriver = numValue > 0
            }

            allDrivers.push({
              name: theme.name,
              tagline: statInfo.tagline,
              isGroup,
              value: numValue,
              id,
              sortDirection: statInfo.sort,
              isPositiveDriver,
            })
          })
        })

        // Sort and split into positive and negative drivers
        const sortedDrivers = allDrivers.sort((a, b) => Math.abs(b.value) - Math.abs(a.value))

        const positiveDrivers = sortedDrivers
          .filter((driver) => driver.isPositiveDriver)
          .slice(0, 3)
          .map(({ isPositiveDriver, ...driver }) => driver)

        const negativeDrivers = sortedDrivers
          .filter((driver) => !driver.isPositiveDriver)
          .slice(0, 3)
          .map(({ isPositiveDriver, ...driver }) => driver)

        summary_items.value = {
          'Top Pain Points': negativeDrivers,
          "What's Working Well": positiveDrivers,
        }
      } catch (e) {
        console.warn(`Error ${e}`)
      } finally {
        isLoading.value = false
      }
    }

    const metricsWithData = computed(() => {
      return Object.keys(summary_items.value).filter((metric) => summary_items.value[metric].length > 0)
    })

    const hasEnoughThemes = computed(() => {
      return (props?.themes?.length || 0) >= 5
    })

    const shouldShowSummary = computed(() => {
      return !hasOnlyTextFields.value && Object.keys(summary_items.value).length > 0 && hasEnoughThemes.value
    })

    watch(
      () => props.mergedFilters,
      (newFilters, oldFilters) => {
        if (!isEqual(oldFilters, newFilters)) {
          if (props.automaticFetch) {
            fetchData()
          } else {
            summary_items.value = {}
          }
        }
      },
      { deep: true },
    )

    watch(
      () => props.themes,
      (newVal, oldVal) => {
        if (!isEqual(newVal, oldVal)) {
          fetchData()
        }
      },
    )

    onMounted(() => {
      if (props.automaticFetch) {
        fetchData()
      }
    })

    return {
      icon,
      root,
      refresh,
      contact,
      reload,
      isLoading,
      fetchData,
      summary_items,
      setChartDimensions,
      metricsWithData,
      hasEnoughThemes,
      shouldShowSummary,
      hasOnlyTextFields,
      width,
      formatNumber,
    }
  },
})
</script>

<style lang="scss" scoped>
@import 'assets/colours.sass';
@import '~assets/kapiche.sass';

$dark-orange: #dc7070;

.header-icon {
  height: 32px;
  width: 100%;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.error-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  padding-bottom: 30px;
}
.action {
  padding-top: 20px;
}
button {
  background: none;
  border: none;
  border-bottom: 2px solid $blue;
  padding: 3px 4px;

  &:hover {
    background-color: $grey-light;
  }
  &:focus {
    border: 2px solid $blue-light;
    outline: none;
  }
  // These settings are required to allow the chart to resize correctly. If you comment these out,
  // weird things happen with the sizing of the timeline.
}
.timeline-container {
  width: inherit;
  align-items: unset;
}
.summary-wrapper {
  max-width: 800px;
  padding: 20px;
  align-self: center;
}
.summary-text {
  color: $text-black;
  font-size: 16px;
  line-height: 1.7rem;
  align-self: center;
  margin-bottom: 20px;
  a {
    color: $blue;
    cursor: pointer;
  }
}
.loading {
  text-align: center;
}
.label {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.6px;
  color: #8064aa;
  margin-bottom: 3px;
  text-transform: uppercase;
  align-self: center;
}
.verbatim-text {
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.6px;
}
.subheader-text {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.6px;
}
.el-collapse {
  margin-top: 20px;
}
</style>
