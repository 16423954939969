import { RouteLocationNormalized, RouteRecordRaw, RouteRecordSingleView } from 'vue-router'

import Dashboard from 'pages/dashboard/Dashboard.vue'
import Overview from 'pages/dashboard/Overview.vue'
import WorkbenchV2 from 'src/pages/dashboard/workbench/WorkbenchV2.vue'

const dashboardRedirects: RouteRecordRaw[] = [
  {
    path: '/:site/v2/dashboards/:dashboardId/:path*',
    redirect: (to) => ({ path: to.path.replace('/v2/', '/') }),
  },
  {
    path: '/:site/projects/:projectId/analysis/:analysisId/v2/dashboards/:dashboardId/:path*',
    redirect: (to) => to.path.replace('/v2/', '/'),
  },
  {
    path: '/:site/projects/:projectId/analysis/:analysisId/dashboards/:dashboardId/:path*',
    redirect: (to) => to.path.replace('/dashboards/', '/workbench/'),
  },
  {
    path: '/:site/dashboards/:dashboardId/:path*',
    redirect: (to) => to.path.replace('/dashboards/', '/workbench/'),
  },
]

const get_concepts = (route: RouteLocationNormalized) => {
  return typeof route.query.concept === 'string' ? [route.query.concept] : route.query.concept
}

const viewerChildren = [
  // overview
  {
    path: 'overview',
    component: Overview,
    name: 'dashboard-overview',
    meta: {
      requiresAuth: true,
      title: 'Dashboard',
      showProgressBar: false,
      dashboardType: 'overview',
    },
    props: (route: RouteLocationNormalized) => ({
      dashboardId: route.params.dashboardId,
      inAnalysis: false,
    }),
  },
  // Redirect old zoom routes
  { path: 'segment-chart-overview', redirect: 'segments' },
  { path: 'segment-chart-query/:queryId', redirect: 'query/:queryId/segments' },
  { path: 'themes-overview', redirect: 'themes-concepts' },
  { path: 'themes', redirect: 'themes-concepts' },

  // concept views
  {
    path: 'concept',
    name: 'dashboard-concept-view',
    component: Overview,
    meta: { requiresAuth: true, title: 'Dashboard', dashboardType: 'concept' },
    props: (route: RouteLocationNormalized) => ({
      inAnalysis: false,
      dashboardId: route.params.dashboardId,
      concepts: get_concepts(route),
      backToRoute: {
        name: 'dashboard-overview',
        params: {
          dashboardId: route.params.dashboardId,
        },
        query: {
          filters: route?.query?.filters,
        },
      },
    }),
  },
  // widget zoom from concept view
  {
    path: 'concept/:widget',
    name: 'viewer-concept-datawidget-zoom',
    component: Overview,
    meta: { dashboardType: 'concept' },
    props: (route: RouteLocationNormalized) => ({
      inAnalysis: false,
      dashboardId: route.params.dashboardId,
      widget: route.params.widget,
      concepts: get_concepts(route),
      backToRoute: {
        name: 'dashboard-concept-view',
        params: {
          dashboardId: route.params.dashboardId,
        },
        query: {
          concept: get_concepts(route),
          filters: route?.query?.filters,
        },
      },
    }),
  },
  // widget zoom from segment view
  {
    path: 'segment/:fieldName/:segment/:widget',
    name: 'viewer-segment-datawidget-zoom',
    component: Overview,
    meta: { dashboardType: 'segment' },
    props: (route: RouteLocationNormalized) => ({
      inAnalysis: false,
      dashboardId: route.params.dashboardId,
      widget: route.params.widget,
      segment: {
        fieldName: route.params.fieldName,
        segment: route.params.segment,
      },
      backToRoute: {
        name: 'dashboard-segment-view',
        params: {
          dashboardId: route.params.dashboardId,
        },
        query: {
          filters: route?.query?.filters,
        },
      },
    }),
  },
  // widget zoom from overview
  {
    path: ':widget',
    name: 'viewer-datawidget-zoom',
    component: Overview,
    meta: { requiresAuth: true, title: 'Dashboard', dashboardType: 'overview' },
    props: (route: RouteLocationNormalized) => ({
      inAnalysis: false,
      dashboardId: route.params.dashboardId,
      widget: route.params.widget,
      backToRoute: {
        name: 'dashboard-overview',
        params: {
          dashboardId: route.params.dashboardId,
        },
        query: {
          filters: route?.query?.filters,
        },
      },
    }),
  },
  // query view from overview
  {
    path: 'query/:queryId',
    component: Overview,
    name: 'dashboard-query-view',
    meta: { requiresAuth: true, title: 'Query View', dashboardType: 'query' },
    props: (route: RouteLocationNormalized) => ({
      inAnalysis: false,
      dashboardId: route.params.dashboardId,
      queryId: parseInt(route.params.queryId as string),
      backToRoute: {
        name: 'dashboard-overview',
        params: {
          dashboardId: route.params.dashboardId,
        },
        query: {
          filters: route?.query?.filters,
        },
      },
    }),
  },
  {
    path: 'theme-group/:queryId',
    component: Overview,
    name: 'dashboard-theme-group-view',
    meta: { requiresAuth: true, title: 'Theme Group View', dashboardType: 'theme-group' },
    props: (route: RouteLocationNormalized) => ({
      inAnalysis: false,
      dashboardId: route.params.dashboardId,
      queryId: parseInt(route.params.queryId as string),
      backToRoute: {
        name: 'dashboard-overview',
        params: {
          dashboardId: route.params.dashboardId,
        },
        query: {
          filters: route?.query?.filters,
        },
      },
    }),
  },
  {
    path: 'segment/:fieldName/:segment',
    component: Overview,
    name: 'dashboard-segment-view',
    meta: { requiresAuth: true, title: 'Segment View', dashboardType: 'segment' },
    props: (route: RouteLocationNormalized) => ({
      inAnalysis: false,
      dashboardId: route.params.dashboardId,
      segment: {
        fieldName: route.params.fieldName,
        segment: route.params.segment,
      },
      backToRoute: {
        name: 'dashboard-overview',
        params: {
          dashboardId: route.params.dashboardId,
        },
        query: {
          filters: route?.query?.filters,
        },
      },
    }),
  },
  // widget zoom from query
  {
    path: 'query/:queryId/:widget',
    name: 'viewer-query-datawidget-zoom',
    component: Overview,
    meta: { requiresAuth: true, title: 'Dashboard', dashboardType: 'query' },
    props: (route: RouteLocationNormalized) => ({
      inAnalysis: false,
      dashboardId: route.params.dashboardId,
      queryId: parseInt(route.params.queryId as string),
      widget: route.params.widget,
      backToRoute: {
        name: 'dashboard-query-view',
        params: { dashboardId: route.params.dashboardId, queryId: route.params.queryId },
        query: {
          filters: route?.query?.filters,
        },
      },
    }),
  },
]

// viewer tailored dashboards
const viewerDashboards = {
  path: '/:site/workbench/:dashboardId',
  component: Dashboard,
  name: 'viewer-dashboard',
  meta: {
    requiresAuth: true,
    loginRoute: { name: 'register-viewer', params: { site: 'site', dashboardId: 'dashboardId' } },
  },
  redirect: { name: 'dashboard-overview' },
  props: (route: RouteLocationNormalized) => ({
    dashboardId: route.params.dashboardId,
    inAnalysis: false,
  }),
  children: viewerChildren,
}

const analystChildren = [
  // overview
  {
    path: 'overview',
    name: 'analysis-dashboard-overview',
    component: Overview,
    meta: {
      requiresAuth: true,
      showProgressBar: false,
      title: 'Dashboard',
      dashboardType: 'overview',
    },
    props: (route: RouteLocationNormalized) => ({
      inAnalysis: true,
      projectId: parseInt(route.params.projectId as string),
      analysisId: parseInt(route.params.analysisId as string),
      dashboardId: parseInt(route.params.dashboardId as string),
    }),
  },
  // Redirect old zoom routes
  { path: 'segment-chart-overview', redirect: 'segments' },
  { path: 'segment-chart-query/:queryId', redirect: 'query/:queryId/segments' },
  { path: 'themes-overview', redirect: 'themes-concepts' },
  { path: 'themes', redirect: 'themes-concepts' },

  // concept views
  {
    path: 'concept',
    name: 'analysis-dashboard-concept-view',
    component: Overview,
    meta: { requiresAuth: true, title: 'Dashboard', dashboardType: 'concept' },
    props: (route: RouteLocationNormalized) => ({
      inAnalysis: true,
      dashboardId: route.params.dashboardId,
      projectId: parseInt(route.params.projectId as string),
      analysisId: parseInt(route.params.analysisId as string),
      concepts: get_concepts(route),
      backToRoute: {
        name: 'analysis-dashboard-overview',
        params: {
          projectId: parseInt(route.params.projectId as string),
          analysisId: parseInt(route.params.analysisId as string),
          dashboardId: parseInt(route.params.dashboardId as string),
        },
        query: {
          filters: route?.query?.filters,
        },
      },
    }),
  },
  // widget zoom from concept view
  {
    path: 'concept/:widget',
    name: 'analysis-concept-datawidget-zoom',
    component: Overview,
    meta: { dashboardType: 'concept' },
    props: (route: RouteLocationNormalized) => ({
      inAnalysis: true,
      projectId: parseInt(route.params.projectId as string),
      analysisId: parseInt(route.params.analysisId as string),
      dashboardId: parseInt(route.params.dashboardId as string),
      widget: route.params.widget,
      concepts: get_concepts(route),
      backToRoute: {
        name: 'analysis-dashboard-concept-view',
        params: {
          projectId: parseInt(route.params.projectId as string),
          analysisId: parseInt(route.params.analysisId as string),
          dashboardId: parseInt(route.params.dashboardId as string),
        },
        query: {
          concept: get_concepts(route),
          filters: route?.query?.filters,
        },
      },
    }),
  },
  // widget zoom from segment view
  {
    path: 'segment/:fieldName/:segment/:widget',
    name: 'analysis-segment-datawidget-zoom',
    component: Overview,
    meta: { dashboardType: 'segment' },
    props: (route: RouteLocationNormalized) => ({
      inAnalysis: true,
      projectId: parseInt(route.params.projectId as string),
      analysisId: parseInt(route.params.analysisId as string),
      dashboardId: parseInt(route.params.dashboardId as string),
      widget: route.params.widget,
      segment: {
        fieldName: route.params.fieldName,
        segment: route.params.segment,
      },
      backToRoute: {
        name: 'analysis-dashboard-segment-view',
        params: {
          projectId: parseInt(route.params.projectId as string),
          analysisId: parseInt(route.params.analysisId as string),
          dashboardId: parseInt(route.params.dashboardId as string),
        },
        query: {
          filters: route?.query?.filters,
        },
      },
    }),
  },
  // widget zoom from overview
  {
    path: ':widget',
    name: 'analysis-datawidget-zoom',
    component: Overview,
    meta: { requiresAuth: true, title: 'Dashboard', dashboardType: 'overview' },
    props: (route: RouteLocationNormalized) => ({
      inAnalysis: true,
      projectId: parseInt(route.params.projectId as string),
      analysisId: parseInt(route.params.analysisId as string),
      dashboardId: parseInt(route.params.dashboardId as string),
      widget: route.params.widget,
      backToRoute: {
        name: 'analysis-dashboard-overview',
        params: {
          projectId: parseInt(route.params.projectId as string),
          analysisId: parseInt(route.params.analysisId as string),
          dashboardId: parseInt(route.params.dashboardId as string),
        },
        query: {
          filters: route?.query?.filters,
        },
      },
    }),
  },
  // query view from overview
  {
    path: 'query/:queryId',
    component: Overview,
    name: 'analysis-dashboard-query-view',
    meta: { requiresAuth: true, title: 'Query View', dashboardType: 'query' },
    props: (route: RouteLocationNormalized) => ({
      inAnalysis: true,
      dashboardId: parseInt(route.params.dashboardId as string),
      projectId: parseInt(route.params.projectId as string),
      analysisId: parseInt(route.params.analysisId as string),
      queryId: parseInt(route.params.queryId as string),
      concepts: get_concepts(route),
      backToRoute: {
        name: 'analysis-dashboard-overview',
        params: {
          projectId: parseInt(route.params.projectId as string),
          analysisId: parseInt(route.params.analysisId as string),
          dashboardId: parseInt(route.params.dashboardId as string),
        },
        query: {
          filters: route?.query?.filters,
        },
      },
    }),
  },
  {
    path: 'theme-group/:queryId',
    component: Overview,
    name: 'analysis-dashboard-theme-group-view',
    meta: { requiresAuth: true, title: 'Theme Group View', dashboardType: 'theme-group' },
    props: (route: RouteLocationNormalized) => ({
      inAnalysis: true,
      dashboardId: parseInt(route.params.dashboardId as string),
      projectId: parseInt(route.params.projectId as string),
      analysisId: parseInt(route.params.analysisId as string),
      queryId: parseInt(route.params.queryId as string),
      concepts: get_concepts(route),
      backToRoute: {
        name: 'analysis-dashboard-overview',
        params: {
          projectId: parseInt(route.params.projectId as string),
          analysisId: parseInt(route.params.analysisId as string),
          dashboardId: parseInt(route.params.dashboardId as string),
        },
        query: {
          filters: route?.query?.filters,
        },
      },
    }),
  },
  // segment view from overview
  {
    path: 'segment/:fieldName/:segment',
    component: Overview,
    name: 'analysis-dashboard-segment-view',
    meta: { requiresAuth: true, title: 'Segment View', dashboardType: 'segment' },
    props: (route: RouteLocationNormalized) => ({
      inAnalysis: true,
      dashboardId: parseInt(route.params.dashboardId as string),
      projectId: parseInt(route.params.projectId as string),
      analysisId: parseInt(route.params.analysisId as string),
      segment: {
        fieldName: route.params.fieldName,
        segment: route.params.segment,
      },
      concepts: get_concepts(route),
      backToRoute: {
        name: 'analysis-dashboard-overview',
        params: {
          projectId: parseInt(route.params.projectId as string),
          analysisId: parseInt(route.params.analysisId as string),
          dashboardId: parseInt(route.params.dashboardId as string),
        },
        query: {
          filters: route?.query?.filters,
        },
      },
    }),
  },
  // widget zoom from query
  {
    path: 'query/:queryId/:widget',
    name: 'analysis-query-datawidget-zoom',
    component: Overview,
    meta: { requiresAuth: true, title: 'Dashboard', dashboardType: 'query' },
    props: (route: RouteLocationNormalized) => ({
      inAnalysis: true,
      dashboardId: route.params.dashboardId,
      projectId: parseInt(route.params.projectId as string),
      analysisId: parseInt(route.params.analysisId as string),
      queryId: parseInt(route.params.queryId as string),
      concepts: get_concepts(route),
      widget: route.params.widget,
      backToRoute: {
        name: 'analysis-dashboard-query-view',
        params: {
          projectId: parseInt(route.params.projectId as string),
          analysisId: parseInt(route.params.analysisId as string),
          dashboardId: parseInt(route.params.dashboardId as string),
          queryId: parseInt(route.params.queryId as string),
        },
        query: {
          concept: get_concepts(route),
          filters: route?.query?.filters,
        },
      },
    }),
  },
]

// analyst tailored dashboards
const analystDashboards = {
  path: '/:site/projects/:projectId/analysis/:analysisId/workbench/:dashboardId',
  meta: { requiresAuth: true },
  name: 'analysis-dashboard',
  component: Dashboard,
  redirect: { name: 'analysis-dashboard-overview' },
  props: (route: RouteLocationNormalized) => ({
    inAnalysis: true,
    projectId: parseInt(route.params.projectId as string),
    analysisId: parseInt(route.params.analysisId as string),
    dashboardId: parseInt(route.params.dashboardId as string),
    queryId: parseInt(route.params.queryId as string),
  }),
  children: analystChildren,
}

const analystDashboardsV2 = [
  {
    path: '/:site/projects/:projectId/analysis/:analysisId/workbench/v2/:dashboardId',
    meta: { requiresAuth: true },
    name: 'analysis-workbench',
    component: WorkbenchV2,
    props: (route: RouteLocationNormalized) => ({
      dashboardId: parseInt(route.params.dashboardId as string),
    }),
  },
  {
    path: '/:site/projects/:projectId/analysis/:analysisId/workbench/v2/:dashboardId/theme/:drilldownId',
    meta: { requiresAuth: true },
    name: 'analysis-workbench-drilldown-theme',
    component: WorkbenchV2,
    props: (route: RouteLocationNormalized) => ({
      dashboardId: parseInt(route.params.dashboardId as string),
      drilldownId: parseInt(route.params.drilldownId as string),
      drilldown: 'theme',
    }),
  },
  {
    path: '/:site/projects/:projectId/analysis/:analysisId/workbench/v2/:dashboardId/theme-group/:drilldownId',
    meta: { requiresAuth: true },
    name: 'analysis-workbench-drilldown-theme-group',
    component: WorkbenchV2,
    props: (route: RouteLocationNormalized) => ({
      dashboardId: parseInt(route.params.dashboardId as string),
      drilldownId: parseInt(route.params.drilldownId as string),
      drilldown: 'theme_group',
    }),
  },
  {
    path: '/:site/projects/:projectId/analysis/:analysisId/workbench/v2/:dashboardId/concept/:concept',
    meta: { requiresAuth: true },
    name: 'analysis-workbench-drilldown-concept',
    component: WorkbenchV2,
    props: (route: RouteLocationNormalized) => ({
      dashboardId: parseInt(route.params.dashboardId as string),
      drilldownId: route.params.concept as string,
      drilldown: 'concept',
    }),
  },
  {
    path: '/:site/projects/:projectId/analysis/:analysisId/workbench/v2/:dashboardId/segment/:fieldName/:segment',
    meta: { requiresAuth: true },
    name: 'analysis-workbench-drilldown-segment',
    component: WorkbenchV2,
    props: (route: RouteLocationNormalized) => ({
      dashboardId: parseInt(route.params.dashboardId as string),
      drilldownId: `${route.params.fieldName}:${route.params.segment}`,
      drilldown: 'segment',
    }),
  },
]

const viewerDashboardsV2 = [
  {
    path: '/:site/workbench/v2/:dashboardId',
    meta: { requiresAuth: true },
    name: 'viewer-workbench',
    component: WorkbenchV2,
    props: (route: RouteLocationNormalized) => ({
      dashboardId: route.params.dashboardId as string,
      viewerMode: true,
    }),
  },
  {
    path: '/:site/workbench/v2/:dashboardId/theme/:drilldownId',
    meta: { requiresAuth: true },
    name: 'viewer-workbench-drilldown-theme',
    component: WorkbenchV2,
    props: (route: RouteLocationNormalized) => ({
      dashboardId: route.params.dashboardId as string,
      drilldownId: parseInt(route.params.drilldownId as string),
      drilldown: 'theme',
      viewerMode: true,
    }),
  },
  {
    path: '/:site/workbench/v2/:dashboardId/theme-group/:drilldownId',
    meta: { requiresAuth: true },
    name: 'viewer-workbench-drilldown-theme-group',
    component: WorkbenchV2,
    props: (route: RouteLocationNormalized) => ({
      dashboardId: route.params.dashboardId as string,
      drilldownId: parseInt(route.params.drilldownId as string),
      drilldown: 'theme_group',
      viewerMode: true,
    }),
  },
  {
    path: '/:site/workbench/v2/:dashboardId/concept/:concept',
    meta: { requiresAuth: true },
    name: 'viewer-workbench-drilldown-concept',
    component: WorkbenchV2,
    props: (route: RouteLocationNormalized) => ({
      dashboardId: route.params.dashboardId as string,
      drilldownId: route.params.concept as string,
      drilldown: 'concept',
      viewerMode: true,
    }),
  },
  {
    path: '/:site/workbench/v2/:dashboardId/segment/:fieldName/:segment',
    meta: { requiresAuth: true },
    name: 'viewer-workbench-drilldown-segment',
    component: WorkbenchV2,
    props: (route: RouteLocationNormalized) => ({
      dashboardId: route.params.dashboardId as string,
      drilldownId: `${route.params.fieldName}:${route.params.segment}`,
      drilldown: 'segment',
      viewerMode: true,
    }),
  },
]

// Returns a widget zoom variation of the given route
const addZoomRoute = (baseRoute: RouteRecordSingleView): RouteRecordSingleView[] => {
  const getProps = typeof baseRoute.props === 'function' ? baseRoute.props : () => ({})
  const widgetRoute: RouteRecordSingleView = {
    ...baseRoute,
    path: `${baseRoute.path}/widget/:zoomWidgetKey`,
    name: `${String(baseRoute.name)}-zoom`,
    props: (route) => ({
      ...getProps(route),
      zoomWidgetKey: route.params.zoomWidgetKey as string,
    }),
  }
  return [baseRoute, widgetRoute]
}

export const dashboardRoutes: RouteRecordRaw[] = [
  ...dashboardRedirects,
  viewerDashboards,
  analystDashboards,
  ...analystDashboardsV2.flatMap(addZoomRoute),
  ...viewerDashboardsV2.flatMap(addZoomRoute),
]
